import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import AccountInformation from './AccountInformation';
import { UtilsService } from '../../services/UtilsService';

class AddAccount extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            accountInformation: {}
        };
        this.handleAccountInfoChange = this.handleAccountInfoChange.bind(this);
        this.createAccount = this.createAccount.bind(this);
    }

    handleAccountInfoChange(value) {
        this.setState({
            accountInformation: value
        })
    }

    createAccount() {
        // prepare data for api call
        var userDefinedFields = this.utils.prepareUserDefinedFieldParams(this.state.accountInformation.userDefinedFields);

        var data = {
            name: this.state.accountInformation.name,
            accountTypeID: this.state.accountInformation.accountTypeId,
            accountNumber: this.state.accountInformation.accountNumber,
            userDefinedFields: userDefinedFields,
        }
        this.http.post('/api/Crm/SaveAccount', data).then(data => {
            this.props.history.push('/crm/account/' + data);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Add Account</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-title">General Account Information</div>
                            </div>
                            <div className="card-body">
                                <AccountInformation onChange={this.handleAccountInfoChange}></AccountInformation>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-body pull-right">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createAccount}>Create Account</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddAccount;
