import moment from 'moment'
export class UtilsService {

  constructor() {
    this.currencyFormatters = {
      "USD": new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      "EUR": new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
      }),
      "GBP": new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
      }),
      "CAD": new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
      }),
    }
  }
  alert(message) {
    window.swal(message, {
      icon: 'error',
      buttons: false
    })
  }

  warning(message) {
    window.swal(message, {
      icon: 'warning',
      buttons: false,
    })
  }

  info(message) {
    window.swal(message, {
      icon: 'info',
      buttons: false,
    })
  }

  success(message) {
    return window.swal(message, {
      icon: 'success',
      buttons: false,
    })
  }

  confirm(message, ok, cancel, confirmText = 'Yes!', cancelText = 'Cancel') {
    window.swal({
      text: message,
      allowOutsideClick: false,
      buttons: {
        confirm: {
          text: confirmText,
          className: 'btn btn-primary'
        },
        deny: {
          text: cancelText,
          visible: true,
          className: 'btn btn-danger'
        }
      }
    }).then((confirmed) => {
      if (confirmed === true) {
        if (!!ok) {
          ok();
        }
      } else if(confirmed === "deny") {
        if (!!cancel) {
          cancel();
        }
      }
    });
  }

  prepareSessionState(state, sessionKey) {
    var sessionParams = {};
    if (sessionKey && sessionStorage.getItem(sessionKey) != null) {
      try {
        sessionParams = JSON.parse(sessionStorage.getItem(sessionKey));
      }
      catch (err) { }
    }

    for (var key in state) {
      if (sessionParams[key]) {
        if (key.toLowerCase().indexOf('date') >= 0) {
          state[key] = moment(sessionParams[key], "MM/DD/YYYY").toDate();
          if (state[key].toString() === "Invalid Date") {
            state[key] = sessionParams[key];
          }
        }
        else {
          if (Array.isArray(state[key])) {
            if (Array.isArray(sessionParams[key])) {
              state[key] = sessionParams[key];
            }
            else if (typeof sessionParams[key] === "string") {
              const arr = sessionParams[key].split(",");
              if (arr.length > 0 && parseInt(arr[0])) {
                for (let i = 0; i < arr.length; i++) {
                  arr[i] = parseInt(arr[i]);
                }
              }
              state[key] = arr;
            }
          }
          else {
            state[key] = sessionParams[key];
          }
        }
      }
    }
    return state;
  }


  hexToRgba(hex, a) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return "rgba(" + parseInt(result[1], 16).toString() + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16) + "," + a.toString() + ")";
  }

  dictToArray(dictionary) {
    var arr = [];
    var props = Object.keys(dictionary);
    for (var i = 0; i < props.length; i++) {
      arr.push(dictionary[props[i]]);
    }
    return arr;
  }

  fileToBinary(file) {
    var promise = new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function () {
        var arrayBuffer = this.result;
        var array = new Uint8Array(arrayBuffer);
        resolve(array);
      }
      reader.readAsArrayBuffer(file);
    })

    return promise;

  }

  fileToBase64(file) {
    var promise = new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function () {
        var arrayBuffer = this.result;
        var array = new Uint8Array(arrayBuffer);
        var binaryString = String.fromCharCode.apply(null, array);;
        resolve(binaryString);
      }
      reader.readAsArrayBuffer(file);
    })

    return promise;
  }

  prepareUserDefinedFieldParams(userDefinedFields) {
    var params = [];
    for (var i = 0; i < userDefinedFields.length; i++) {
      var userDefinedField = userDefinedFields[i];
      if (userDefinedField.selection === "Single") {
        params.push({
          userDefinedFieldID: userDefinedField.userDefinedFieldID,
          userDefinedFieldOptionID: userDefinedField.value
        });
      }
      else {
        params.push({
          userDefinedFieldID: userDefinedField.userDefinedFieldID,
          userDefinedFieldOptionIDs: userDefinedField.value
        });
      }
    }
    return params;
  }

  toCurrency(number, currencyCode) {
    if (!currencyCode) {
      currencyCode = "USD"
    }
    return this.currencyFormatters[currencyCode].format(number);
  }

  getCurrencySymbol(currencyCode) {
    if (!currencyCode) {
      currencyCode = "USD"
    }
    return this.currencyFormatters[currencyCode].formatToParts("")[0].value
  }
}
