import React, { Component } from 'react';
import StateSelect from '../../common/StateSelect';
import CountrySelect from '../../common/CountrySelect';
import Checkbox from '../../common/Checkbox';
import RadioGroup from '../../common/RadioGroup';

class CustomerAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addressId: props.address ? props.address.addressID : 0,
            companyName: props.address ? props.address.companyName : "",
            contactName: props.address ? props.address.contactName : "",
            addressLine1: props.address ? props.address.addressLine1 : "",
            addressLine2: props.address ? props.address.addressLine2 : "",
            addressLine3: props.address ? props.address.addressLine3 : "",
            municipality: props.address ? props.address.municipality : "",
            county: props.address ? props.address.county : "",
            district: props.address ? props.address.district : "",
            city: props.address ? props.address.city : "",
            stateId: props.address ? props.address.state.stateID : 0,
            zipCode: props.address ? props.address.zipCode : "",
            countryId: props.address ? props.address.country.countryID : 211,
            phoneNumber: props.address ? props.address.phoneNumber : "",
            primary: props.address ? props.address.primary : false,
            addressType: props.address ? (props.address.residential ? "Residential" : "Business") : "Residential",
            shipping: props.type === "shipping",
            copy: false
        }

        this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
        this.handleContactNameChange = this.handleContactNameChange.bind(this);
        this.handleAddressLine1Change = this.handleAddressLine1Change.bind(this);
        this.handleAddressLine2Change = this.handleAddressLine2Change.bind(this);
        this.handleAddressLine3Change = this.handleAddressLine3Change.bind(this);
        this.handleMunicipalityChange = this.handleMunicipalityChange.bind(this);
        this.handleCountyChange = this.handleCountyChange.bind(this);
        this.handleDistrictChange = this.handleDistrictChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handlePrimaryChange = this.handlePrimaryChange.bind(this);
        this.handleAddressTypeChange = this.handleAddressTypeChange.bind(this);
        this.handleCopyChange = this.handleCopyChange.bind(this);

        this.notifyChange();
    }

    handleCompanyNameChange(e) {
        this.setState({
            companyName: e.target.value
        }, this.notifyChange);
    }

    handleContactNameChange(e) {
        this.setState({
            contactName: e.target.value
        }, this.notifyChange);
    }

    handleAddressLine1Change(e) {
        this.setState({
            addressLine1: e.target.value
        }, this.notifyChange);
    }

    handleAddressLine2Change(e) {
        this.setState({
            addressLine2: e.target.value
        }, this.notifyChange);
    }

    handleAddressLine3Change(e) {
        this.setState({
            addressLine3: e.target.value
        }, this.notifyChange);
    }

    handleMunicipalityChange(e) {
        this.setState({
            municipality: e.target.value
        }, this.notifyChange);
    }

    handleCountyChange(e) {
        this.setState({
            county: e.target.value
        }, this.notifyChange);
    }

    handleDistrictChange(e) {
        this.setState({
            district: e.target.value
        }, this.notifyChange);
    }

    handleCityChange(e) {
        this.setState({
            city: e.target.value
        }, this.notifyChange);
    }

    handleStateChange(state) {
        this.setState({
            stateId: state.stateID
        }, this.notifyChange);
    }

    handleZipCodeChange(e) {
        this.setState({
            zipCode: e.target.value
        }, this.notifyChange);
    }

    handleCountryChange(country) {
        this.setState({
            countryId: country.countryID
        }, this.notifyChange);
    }

    handlePhoneNumberChange(e) {
        this.setState({
            phoneNumber: e.target.value
        }, this.notifyChange);
    }

    handlePrimaryChange(checked) {
        this.setState({
            primary: checked
        }, this.notifyChange);
    }

    handleCopyChange(checked) {
        this.setState({
            copy: checked
        }, this.notifyChange);
    }

    handleAddressTypeChange(value) {
        this.setState({
            addressType: value
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label>Business/Company (if applicable)</label>
                        <input type="text" className="form-control" value={this.state.companyName} onChange={this.handleCompanyNameChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Contact Name</label>
                        <input type="text" className="form-control" value={this.state.contactName} onChange={this.handleContactNameChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Address</label>
                        <input type="text" className="form-control" value={this.state.addressLine1} onChange={this.handleAddressLine1Change}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Address (Line 2)</label>
                        <input type="text" className="form-control" value={this.state.addressLine2} onChange={this.handleAddressLine2Change}></input>
                    </div>
                    {this.props.amazonInternationalAddress &&
                        <div className="form-group col-md-12">
                            <label>Address (Line 3)</label>
                            <input type="text" className="form-control" value={this.state.addressLine3} onChange={this.handleAddressLine3Change}></input>
                        </div>
                    }
                    <div className="form-group col-md-12">
                        <label>City</label>
                        <input type="text" className="form-control" value={this.state.city} onChange={this.handleCityChange}></input>
                    </div>
                    {this.props.amazonInternationalAddress &&
                        <React.Fragment>
                            <div className="form-group col-md-12">
                                <label>Municipality</label>
                                <input type="text" className="form-control" value={this.state.municipality} onChange={this.handleMunicipalityChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>County</label>
                                <input type="text" className="form-control" value={this.state.county} onChange={this.handleCountyChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>District</label>
                                <input type="text" className="form-control" value={this.state.district} onChange={this.handleDistrictChange}></input>
                            </div>
                        </React.Fragment>
                    }
                    {this.state.countryId === 211 &&
                        <div className="form-group col-md-12">
                            <label>State</label>
                            <StateSelect countryID={this.state.countryId} value={this.state.stateId} onChange={this.handleStateChange}></StateSelect>
                        </div>
                    }
                    <div className="form-group col-md-12">
                        <label>Zip/Postal Code</label>
                        <input type="text" className="form-control" value={this.state.zipCode} onChange={this.handleZipCodeChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Country</label>
                        <CountrySelect value={this.state.countryId} onChange={this.handleCountryChange}></CountrySelect>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Phone Number</label>
                        <input type="text" className="form-control" value={this.state.phoneNumber} onChange={this.handlePhoneNumberChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Address Type</label><br />
                        <RadioGroup groupName="addresType" value={this.state.addressType} items={[{ value: "Residential", text: "Residential" }, { value: "Business", text: "Business" }]} onChange={this.handleAddressTypeChange}></RadioGroup>
                    </div>
                    {!this.props.address &&
                        <div className="form-group col-md-12">
                            <Checkbox label="Default?" checked={this.state.primary} onChange={this.handlePrimaryChange}></Checkbox>
                        </div>
                    }
                    {this.state.addressId === 0 &&
                        <div className="form-group col-md-12">
                            <Checkbox label={this.state.shipping ? "Also save as billing address" : "Also save as shipping address"} checked={this.state.copy} onChange={this.handleCopyChange}></Checkbox>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default CustomerAddress