import React, { Component } from 'react';

class Pagination extends Component {

    constructor(props) {
        super(props);
        this.handlePrevClick = this.handlePrevClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }

    handlePrevClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onPageChanged(this.props.pageIndex - 1);
    }

    handlePageClick(page, e) {
        e.preventDefault();
        e.stopPropagation();
        if(page !== -1){
        this.props.onPageChanged(page);
        }
    }

    handleNextClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onPageChanged(this.props.pageIndex + 1);
    }

    prevLink() {
        return <li className={"page-item " + (this.props.pageIndex === 0 ? "disabled" : "")}>
            <a href="#/" className="page-link" onClick={this.handlePrevClick}>Previous</a>
        </li>
    }

    nextLink() {
        return <li className={"page-item " + (this.props.pageIndex === this.props.pageCount - 1 ? "disabled" : "")}>
            <a href="#/" className="page-link" onClick={this.handleNextClick}>Next</a>
        </li>
    }

    render() {
        var pages = [];
        if (this.props.pageCount <= 10) {
            for (var i = 0; i < this.props.pageCount; i++) {
                pages.push(i);
            }
        }
        else{
            pages.push(0);
            if(this.props.pageIndex - 3 > 0){
                pages.push(-1);
            }
            var index = this.props.pageIndex - 4;
            while(pages.length < 10 && index < this.props.pageCount - 1){
                if(index > 0 && index <= this.props.pageCount - 1){
                    pages.push(index);
                }
                index++;
            }
            if(this.props.pageIndex + index < this.props.pageCount - 1){
                pages.push(-1);
            }
            pages.push(this.props.pageCount - 1);
        }
        return (
            <ul className="pagination pull-right">
                {this.prevLink()}
                {pages.map(page =>
                    <li key={page} className={"page-item " + (this.props.pageIndex === page ? "active" : "")}>
                        <a href="#/" className="page-link" onClick={(e) => this.handlePageClick(page, e)}>{page >= 0 ? page + 1 : "..."}</a>
                    </li>
                )
                }
                {this.nextLink()}
            </ul>
        );
    }
}
export default Pagination