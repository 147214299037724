import React, { Component } from 'react';
import Checkbox from '../../common/Checkbox';

class ShippingCost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipTypeId: props.cost ? props.cost.shipTypeId : 0,
            siteId: props.cost ? props.cost.siteId : 0,
            companyId: props.cost ? props.cost.companyId : 0,
            shipTypeDesc: props.cost ? props.cost.shipTypeDesc : "",
            company: props.cost ? props.cost.company : "",
            site: props.cost ? props.cost.site : "",
            shippingCost: props.cost ? props.cost.shippingCost : 0,
            wholesaleCost: props.cost ? props.cost.wholesaleCost : 0,
            visible: props.cost? props.cost.visible : true
        }

        this.handleShippingCostChange = this.handleShippingCostChange.bind(this);
        this.handleWholesaleCostChange = this.handleWholesaleCostChange.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.notifyChange();
    }

    handleShippingCostChange(e) {
        this.setState({
            shippingCost: e.target.value
        }, this.notifyChange);
    }

    handleWholesaleCostChange(e) {
        this.setState({
            wholesaleCost: e.target.value
        }, this.notifyChange);
    }

    handleVisibleChange(checked) {
        this.setState({
            visible: checked
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label>{this.state.company + "/" + this.state.site + "/" + this.state.shipTypeDesc}</label>                  
                </div>
                <div className="form-group col-md-12">
                    <label>Ship Cost</label>
                    <input type="text" className="form-control" value={this.state.shippingCost} onChange={this.handleShippingCostChange} />
                </div>
                <div className="form-group col-md-12">
                    <label>Wholesale Cost</label>
                    <input type="text" className="form-control" value={this.state.wholesaleCost} onChange={this.handleWholesaleCostChange} />
                </div>
                <div className="form-group col-md-12">
                    <Checkbox label="Visible" checked={this.state.visible} onChange={this.handleVisibleChange}></Checkbox>
                </div>
            </div>
        );
    }
}

export default ShippingCost