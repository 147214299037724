import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';

class UserList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            users: [],
        }

        this.http = new HttpRequestService();
        this.getUsers = this.getUsers.bind(this);
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers() {
        this.http.get('/api/Users/GetUsers', null).then(data => {
            this.setState({ users: data });
        });
    }

    deleteUser(user) {
        var message = "This user will be deleted. Do you want to continue?"
        this.utils.confirm(message, () => {
            var params = {
                userId: user.userId,
                username: user.username
            }
            this.http.get('/api/Users/DeleteUser', params).then(data => {
                this.getUsers();
            });
        })
    }

    unlockUser(user) {
        var message = "This user will be unlocked. Do you want to continue?"
        this.utils.confirm(message, () => {
            var params = {
                userId: user.userId
            }
            this.http.get('/api/Users/UnlockUser', params).then(data => {
                this.getUsers();
            });
        })
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">User List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th>Username</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Initials</th>
                                                <th scope="col">Active</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.users.map((user) =>
                                                <tr key={user.userId}>
                                                    <td><Link to={"/user/" + user.userId}>{user.username}</Link></td>
                                                    <td>{user.lastName}</td>
                                                    <td>{user.firstName}</td>
                                                    <td>{user.initials}</td>
                                                    <td>{user.active ? "yes" : "no"}</td>
                                                    <td>
                                                        {user.lockedOut &&
                                                            <button className="btn btn-sm btn-info" onClick={e => this.unlockUser(user)}><i className="fas fa-lock-open"></i></button>
                                                        }
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-sm btn-danger" onClick={e => this.deleteUser(user)}><i className="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserList;
