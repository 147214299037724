import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PasswordLogin from './PasswordLogin';
import PinLogin from './PinLogin';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "password",
            loggedIn: false
        };
        this.onLoggedIn = this.onLoggedIn.bind(this);
    }

    onLoggedIn() {
        this.props.onLoggedIn();
    }

    render() {
        return (
            <div className="page-inner col-md-4 m-auto">
                <div className="row">
                    {this.state.mode === "password" &&
                        <div className="col-md-12 pt-5">
                            <PasswordLogin onLoggedIn={this.onLoggedIn}></PasswordLogin>
                            <div className="mt-4 pt-4">
                                <button className="btn btn-secondary btn-full btn-lg pt-4 pb-4" onClick={() => this.setState({ mode: "pin" })}>
                                    <i className="fas fa-warehouse"></i> Warehouse Login
                                </button>
                            </div>
                        </div>
                    }
                    {this.state.mode === "pin" &&
                        <div className="col-md-12 pt-5">
                            <PinLogin onLoggedIn={this.onLoggedIn} onBack={() => this.setState({ mode: "password" })}></PinLogin>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(Login)
