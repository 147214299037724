import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Report from '../../common/Report';
import SitesSelect from '../../common/SitesSelect';
import AccountTypesSelect from '../../common/AccountTypesSelect';
import RadioGroup from '../../common/RadioGroup';

class CustomerCommentsReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            beginDate: moment(new Date()).subtract(31, 'day').toDate(),
            endDate: moment(new Date()).toDate(),
            accountTypeId: -1,
            siteId: -1,
            displayedField: "Comment",
            report: null,
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.paramsKey = "params.customer-comments-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handleDisplayedFieldChange = this.handleDisplayedFieldChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
    }

    componentDidMount() {
        this.getReport();
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handleSiteChange(value) {
        this.setState({ siteId: value });
    }

    handleAccountTypeChange(value) {
        this.setState({ accountTypeId: value });
    }

    handleDisplayedFieldChange(value) {
        this.setState({ displayedField: value });
    }

    getReport(e) {
        if (!!e) {
            e.preventDefault();
        }
        var params = {
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            siteId: this.state.siteId,
            accountTypeId: this.state.accountTypeId,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Report/GetCustomerCommentsReport', params).then(data => {
            this.setState({ report: data });
        });
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Customer Comments Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={e => this.getReport(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="beginDate">Comments From</label>
                                            <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="endDate">Comments To</label>
                                            <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="endDate">Site</label>
                                            <SitesSelect value={this.state.siteId} onChange={this.handleSiteChange} hasAllOption={true}></SitesSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="endDate">Account Type</label>
                                            <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange} hasAllOption={true}></AccountTypesSelect>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Display On Report</label><br />
                                            <RadioGroup groupName="displayedField" value={this.state.displayedField} items={[{ value: "HeardFrom", text: "Heard From" }, { value: "Comment", text: "Comments" }]} onChange={this.handleDisplayedFieldChange}></RadioGroup>
                                        </div>
                                    </div>
                                    <div className="form-row justify-content-end">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="CustomerCommentsReport" hasData={this.state.report.items && this.state.report.items.length > 0} title="Customer Comments Report" description={this.state.report.description}>
                                <div className="form-row">
                                    <table className="table table-striped mt-1 report-table">
                                        <thead>
                                            <tr>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("DateEntered")}>DATE ENTERED</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("CustomerNumber")}>CUST #</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("Name")}>CUSTOMER</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("Salesman")}>SALESMAN</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("FollowedUp")}>FOLLOW UP</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("Site")}>SITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.report.items.map((item) =>
                                                <React.Fragment>
                                                    <tr key={item.customerID + item.dateEntered}>
                                                        <td>{moment(item.dateEntered).format('l')}</td>
                                                        <td><Link to={"/customer/" + item.customerID}>{item.customerNumber}</Link></td>
                                                        <td>{item.name}</td>
                                                        <td>{item.salesman}</td>
                                                        <td>{item.followedUp}</td>
                                                        <td>{item.site}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={6}><p>{this.state.displayedField === "Comment" ? item.comment : item.heardFrom}</p></td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default CustomerCommentsReport;
