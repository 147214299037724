import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from '../../common/Pagination';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
class CustomerHistoryList extends Component {

    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.pageSize = 100;
        this.state = {
            items: [],
            historicTotal: 0,
        }
    }

    componentDidMount() {
        if (this.props.all) {
            this.getAllHistory();
        }
        else {
            this.getHistory();
        }
    }

    getHistory() {
        var params = {
            customerId: this.props.customerId,
            rows: this.props.rows
        }
        this.http.get('/api/Customers/GetCustomerHistory', params).then(data => {
            this.setState({
                items: data.list,
                historicTotal: data.historicTotal
            }, () => {
                // init tooltips
                window.setTimeout(() => {
                    window.$('[data-toggle="tooltip"]').tooltip()
                }, 500);
            });
        });
    }

    getAllHistory() {
        var params = {
            customerId: this.props.customerId,
        }
        this.http.get('/api/Customers/GetAllCustomerHistory', params).then(data => {
            this.setState({
                items: data.list,
                historicTotal: data.historicTotal,
            }, () => {
                // init tooltips
                window.setTimeout(() => {
                    window.$('[data-toggle="tooltip"]').tooltip()
                }, 500);
            });
        });
    }

    getPrintPDFUrl(order, type) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + order.orderId + "&OrderNumber=" + order.orderNumber;
    }

    getInfoText(order) {
        return `<div style="text-align:left">Entered: ${order.dateTimeStamp}<br/> Entered by: ${order.salesmanInitials}<br/>Sent to Fulfillment by: ${order.sentToFulfillmentBy}<br/>Fulfilled By: ${order.fulfilledBy ? order.fulfilledBy : ""}</div>`;
    }

    render() {
        var total = 0;
        for (var i = 0; i < this.state.items.length; i++) {
            total += this.state.items[i].total;
        }
        return (
            <React.Fragment>
                <table className="table table-striped mt-1">
                    <thead>
                        <tr>
                            {this.props.printColumns &&
                                <th className="currency" colspan={6}>
                                    Historic "Orders Plus" Total Sales: {this.utils.toCurrency(this.state.historicTotal)}
                                </th>
                            }
                        </tr>
                        <tr>
                            <th scope="col">Fulfilled</th>
                            <th scope="col">Order #</th>
                            <th className="currency" scope="col">Total</th>
                            <th scope="col">Status</th>
                            {this.props.displayInfoTooltips &&
                                <th></th>
                            }
                            {this.props.printColumns &&
                                <React.Fragment>
                                    <th></th>
                                    <th></th>
                                </React.Fragment>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.items.map((order) =>
                            <tr key={order.orderId}>
                                <td>{order.fulfillDate}</td>
                                <td><Link to={"/order/" + order.orderId}>{order.orderNumber}</Link></td>
                                <td className="currency">{this.utils.toCurrency(order.total)}</td>
                                <td>{order.orderStatus}</td>
                                {this.props.displayInfoTooltips &&
                                    <td><i className="fas fa-info" data-toggle="tooltip" data-html="true" data-placement="top" title={this.getInfoText(order)}></i></td>
                                }
                                {this.props.printColumns &&
                                    <React.Fragment>
                                        <td><a href={this.getPrintPDFUrl(order, 'Invoice')} target="_blank" rel="noopener noreferrer">Invoice</a></td>
                                        <td><a href={this.getPrintPDFUrl(order, 'PackingSlip')} target="_blank" rel="noopener noreferrer">Packing Slip</a></td>
                                    </React.Fragment>
                                }
                            </tr>
                        )}
                    </tbody>
                    {this.props.displaySummary &&
                        <tfoot>
                            <tr>
                                <th className="currency" colSpan={3}>Total Sales: {this.utils.toCurrency(total)}</th>
                                <th></th>
                                {this.props.printColumns &&
                                    <React.Fragment>
                                        <th></th>
                                        <th></th>
                                    </React.Fragment>
                                }
                            </tr>
                        </tfoot>
                    }
                </table>
            </React.Fragment>
        )
    }
}

export default CustomerHistoryList
