import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SitesSelect from '../../common/SitesSelect'
import AccountTypesSelect from '../../common/AccountTypesSelect'
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import Accordion from '../../common/Accordion';
import ShipTypeSelect from '../../common/ShipTypeSelect';

class FulfillList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            siteId: -1,
            accountTypeId: -1,
            timeInTransit: -1,
            pricingType: '-- All --',
            sortBy: "",
            sortDirection: "ASC",
            orders: [],
            ordersToFulfill: [],
            summaryItems: [],
            groupOrders: false,
        }

        this.timeInTransitOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        this.paramsKey = props.paramsKey ? props.paramsKey : "params.fulfill-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handlePricingTypeChange = this.handlePricingTypeChange.bind(this);
        this.handleTimeInTransitChange = this.handleTimeInTransitChange.bind(this);
        this.handleColCheck = this.handleColCheck.bind(this);
        this.handleGroupOrdersChange = this.handleGroupOrdersChange.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.autoRefresh = this.autoRefresh.bind(this);
        this.batchFulfill = this.batchFulfill.bind(this);
        this.pageSize = 150;

        this.timer = setInterval(this.autoRefresh, 60000);
    }

    componentDidMount() {
        this.getOrders();
        this.getSummary();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    handleSiteChange(siteId) {
        this.setState({ siteId: siteId });
    }

    handleAccountTypeChange(accountTypeId) {
        this.setState({ accountTypeId: accountTypeId });
    }

    handleTimeInTransitChange(e) {
        this.setState({ timeInTransit: e.target.value });
    }

    handlePricingTypeChange(e) {
        this.setState({ pricingType: e.target.value });
    }

    handleGroupOrdersChange(checked){
        this.setState({
            groupOrders: checked
        });
    }

    handleColCheck(checked) {
        var rows = document.getElementsByClassName("row-check");
        for (var i = 0; i < rows.length; i++) {
            rows[i].checked = checked;
            this.state.orders[i].checked = checked;
        }
    }

    handleRowCheck(order, checked) {
        order.checked = checked;
    }

    sortOrders(sortBy) {
        this.setState({
            sortBy: sortBy,
            sortDirection: this.state.sortDirection === "ASC" ? "DESC" : "ASC"
        }, () => {
            this.getOrders()
        });
    }

    getOrders(e) {
        if (!!e) {
            e.preventDefault();
        }
        var params = {
            siteId: this.state.siteId,
            accountTypeId: this.state.accountTypeId,
            timeInTransit: this.state.timeInTransit,
            pricingType: this.state.pricingType,
            shipTypeId: -1,
            status: "",
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
        this.http.get('/api/Orders/SearchFulfillment', params).then(data => {
            this.setState({ orders: data.data });
        });
    }

    getSummary() {
        this.http.get('/api/Orders/GetFulfillmentSummary', { samplesOnly: true }).then(data => {
            this.setState({ summaryItems: data });
        });
    }

    autoRefresh() {
        // refresh only if there is no selection
        if (this.state.orders.findIndex(o => o.checked) < 0) {
            this.getOrders();
            this.getSummary();
        }
    }

    batchFulfill() {
        if (this.state.orders.findIndex(o => o.checked) < 0) {
            this.utils.warning("Please select orders to be fulfilled");
        }
        else {
            this.utils.confirm("Are you sure to fulfill selected orders?", () => {
                var selectedOrders = this.state.orders.filter(o => o.checked);
                var orderIds = [];
                for (var i = 0; i < selectedOrders.length; i++) {
                    orderIds.push(selectedOrders[i].orderId);
                }
                var params = {
                    orders: orderIds.join(",")
                }
                this.http.get('/api/Orders/FulfillOrders', params).then(data => {
                    this.getOrders();
                    this.getSummary();
                });
            });
        }
    }

    render() {
        var grandTotals = [];
        for (var i = 0; i < this.state.orders.length; i++) {
            if (grandTotals[this.state.orders[i].currency]) {
                grandTotals[this.state.orders[i].currency] += this.state.orders[i].total;
            }
            else {
                grandTotals[this.state.orders[i].currency] = this.state.orders[i].total;
            }
        }
        
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">{this.props.title ? this.props.title : "Orders to Fulfill"}</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={(e) => this.getOrders(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-2 col-sm-3 col-xs-6">
                                            <label htmlFor="site">Site</label>
                                            <SitesSelect value={this.state.siteId} onChange={this.handleSiteChange} hasAllOption={true}></SitesSelect>
                                        </div>
                                        <div className="form-group col-md-2 col-sm-3 col-xs-6">
                                            <label htmlFor="accountType">Account Type</label>
                                            <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange} hasAllOption={true}></AccountTypesSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="accountType">Time In Transit</label>
                                            <select className="form-control" value={this.state.timeInTransit} onChange={this.handleTimeInTransitChange}>
                                                <option value={-1}>--All--</option>
                                                {this.timeInTransitOptions.map(t =>
                                                    <option value={t}>{t}</option>
                                                )}
                                            </select>
                                        </div>
                                        {!this.props.web &&
                                            <div className="form-group col-md-2 col-sm-3 col-xs-6">
                                                <label htmlFor="pricingType">Pricing Type</label>
                                                <select className="form-control" value={this.state.pricingType} onChange={this.handlePricingTypeChange}>
                                                    <option value='-- All --'>--All --</option>
                                                    <option value='Sample'>Samples</option>
                                                    <option value='Paid'>Paid</option>
                                                </select>
                                            </div>
                                        }
                                        <div className="form-group col-md-2 col-sm-3 col-xs-6">
                                            <Checkbox label="Group Orders" checked={this.state.groupOrders} onChange={this.handleGroupOrdersChange}></Checkbox>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                        </div>                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                        <Accordion title="Sample/Promo Items Summary" icon="flaticon-list" id="samples">
                            <div className="form-row">
                                <table className="table table-striped mt-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.summaryItems.map((item) =>
                                            <tr key={item.name}>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item.quantity}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>
                        <div className="card">
                            <div className="card-body">
                                <div className="text-right">
                                    <button className="btn btn-default btn-sm" onClick={this.batchFulfill}>Batch Fulfill</button>
                                </div>
                                <div className="form-row">
                                    <table className="table table-striped mt-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <Checkbox onChange={this.handleColCheck}></Checkbox>
                                                </th>
                                                <th scope="col" className="sortable" onClick={() => this.sortOrders("OrderID")}>Order</th>
                                                <th scope="col">Customer</th>
                                                <th className="currency" scope="col">Total</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Account Type</th>
                                                <th scope="col">Salesman Initials</th>
                                                <th scope="col" className="sortable" onClick={() => this.sortOrders("TimeInTransit")}>Time In Transit (days)</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders.map((order) =>
                                                <tr key={order.orderId}>
                                                    <td>
                                                        <Checkbox className="row-check" onChange={e => this.handleRowCheck(order, e)}></Checkbox>
                                                    </td>
                                                    <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                                    <td>{order.companyName}</td>
                                                    <td className="currency">{this.utils.toCurrency(order.total, order.currency)}</td>
                                                    <td>{order.site}</td>
                                                    <td>{order.accountType}</td>
                                                    <td>{order.salesmanInitials}</td>
                                                    <td>{order.timeInTransit}</td>
                                                    <td>{order.dateTimeStamp}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            {Object.keys(grandTotals).map((currency, index) =>
                                                <tr>
                                                    <td colSpan={3} align="right">{index === 0 && <strong>Grand Total</strong>}</td>
                                                    <td className="currency"><strong>{this.utils.toCurrency(grandTotals[currency], currency)}</strong></td>
                                                    <td colSpan={4}></td>
                                                </tr>
                                            )}

                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

export default FulfillList;
