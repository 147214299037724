import React, { Component } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import { HttpRequestService } from './services/HttpRequestService';
import Scrollable from './common/Scrollable';

class HeaderNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      notifications: []
    }
    this.http = new HttpRequestService();
    this.getNotifications = this.getNotifications.bind(this);
    this.resolveNotification = this.resolveNotification.bind(this);
    this.timer = setInterval(this.getNotifications, 60000);
  }

  componentDidMount() {
    this.getNotifications();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getNotifications() {
    var params = {
      status: "Unresolved",
      pageSize: 5,
      pageIndex: 0
    }

    this.http.getSilent('/api/Orders/GetAmazonIntegrationLogs', params).then(data => {
      this.setState({ notifications: data.data, count: data.count });
    });
  }

  resolveNotification(notification, e) {
    var params = {
      id: notification.id
    }
    this.http.get('/api/Orders/ResolveAmazonLog', params).then(data => {
      this.getNotifications();
    });

    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    return (
      <li className="nav-item dropdown hidden-caret">
        <a className="nav-link dropdown-toggle" href="/" id="notifDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="fa fa-bell" />
          {this.state.count > 0 &&
            <span className="notification">{this.state.count}</span>
          }
        </a>
        <ul className="dropdown-menu notif-box animated fadeIn" aria-labelledby="notifDropdown">
          <li>
            <div className="dropdown-title">{this.state.count} unresolved notifications in Amazon Integration Log</div>
          </li>
          <li>
            <Scrollable id="notifications">
              <div className="notif-scroll scrollbar-outer">
                <div className="notif-center">
                  {this.state.notifications.map(item =>
                    <Link key={item.id} to="/amazon-orders/integration-log">
                      <div className="notif-icon notif-warning"><i className="fas fa-exclamation" /></div>
                      <div className="notif-content">
                        <span className="block">
                          {item.message}
                        </span>
                        <span className="time">{moment(item.timestamp).fromNow()}</span><br />
                        <button className="btn btn-xs btn-info" onClick={e => this.resolveNotification(item, e)}>Resolve <i className="fas fa-check"></i></button>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </Scrollable>
          </li>
          <li>
            <Link className="see-all" to="/amazon-orders/integration-log">See all notifications<i className="fa fa-angle-right" /></Link>
          </li>
        </ul>
      </li>

    )
  }
}
export default HeaderNotifications
