import React, { Component } from 'react';
import ProductTypeSelect from '../../common/ProductTypeSelect'
import Modal from '../../common/Modal';
import ProductMultipleSelect from '../../common/ProductMultipleSelect';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';

class ShoppingCart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.order.company.key,
            productTypeId: 0,
            selectedProduct: {},
            selectedProducts: [],
            selectedProductIds: [],
            salePrice: 0,
            comment: "",
            lot: "",
            actualAmount: 0,
            quantity: 1,
            updatedQuantity: 0,
            updatedComment: "",
            updatedLot: "",
            tempCartItems: [],
            tempSortedCartItems: [],
        };
        // this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleProductTypeChange = this.handleProductTypeChange.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.handleLotChange = this.handleLotChange.bind(this);
        this.handleActualAmountChange = this.handleActualAmountChange.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.removeCartItem = this.removeCartItem.bind(this);
        this.updateProductQuantity = this.updateProductQuantity.bind(this);
        this.handleUpdatedQuantityChange = this.handleUpdatedQuantityChange.bind(this);
        this.handleUpdatedCommentChange = this.handleUpdatedCommentChange.bind(this);
        this.handleUpdatedLotChange = this.handleUpdatedLotChange.bind(this);
        this.addTempCartItems = this.addTempCartItems.bind(this);
        this.handleProductsSelected = this.handleProductsSelected.bind(this);
        this.removeTempCartItem = this.removeTempCartItem.bind(this);
        this.saveCartItems = this.saveCartItems.bind(this);
        this.updateCartItems = this.updateCartItems.bind(this);
        this.orderCartItems = this.orderCartItems.bind(this);
        this.cancelOrderCartItems = this.cancelOrderCartItems.bind(this);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.order.company.key !== prevProps.order.company.key) {
            this.setState({
                companyId: this.props.order.company.key,
                productTypeId: 0,
                selectedProduct: {},
                selectedProducts: [],
                selectedProductIds: [],
                salePrice: 0,
                comment: "",
                lot: "",
                actualAmount: 0,
                quantity: 1,
                updatedQuantity: 0,
                updatedComment: "",
                tempCartItems: []
            });
        }
    }

    // handleCompanyChange(companyId) {
    //     this.setState({ companyId: companyId });
    // }

    handleProductTypeChange(productTypeId) {
        this.setState({ productTypeId: productTypeId });
    }

    handleProductsSelected(products) {
        if (products) {
            this.setState({
                selectedProducts: products,
                selectedProductIds: products.map(p => { return p.productID }),
            });
        }
    }

    addTempCartItems() {
        var price = 0;
        const tempCartItems = this.state.tempCartItems;
        if (this.state.selectedProducts.length > 0) {

            for (const product of this.state.selectedProducts) {
                if (this.props.order.salesman.value === "Web/Retail") {
                    price = product.salePrice > 0 ? product.salePrice : product.retailPrice;
                }
                else {
                    price = product.wholesalePrice;
                }
                if (product.allowDiscount) {
                    price = parseFloat((price * (1 - this.props.order.customer.discount)).toFixed(2));
                }
                const tempCartItem = {
                    product: product,
                    salePrice: price.toFixed(2),
                    actualAmount: price.toFixed(2),
                    comment: "",
                    lot: product.lotNumExpDateComment,
                    quantity: 1
                };

                if (tempCartItems.findIndex(c => c.product.productID === tempCartItem.product.productID) < 0) {
                    tempCartItems.push(tempCartItem);
                }
            }
            this.setState({
                tempCartItems: Array.from(tempCartItems),
                selectedProducts: [],
                selectedProductIds: [],
            });
        }
    }

    handleCommentChange(e, tempCartItem) {
        tempCartItem.comment = e.target.value;
        const tempCartItems = this.state.tempCartItems;
        this.setState({
            tempCartItems: Array.from(tempCartItems)
        });
    }

    handleLotChange(e, tempCartItem) {
        tempCartItem.lot = e.target.value;
        const tempCartItems = this.state.tempCartItems;
        this.setState({
            tempCartItems: Array.from(tempCartItems)
        });
    }

    handleActualAmountChange(e, tempCartItem) {
        tempCartItem.actualAmount = e.target.value;
        const tempCartItems = this.state.tempCartItems;
        this.setState({
            tempCartItems: Array.from(tempCartItems)
        });
    }

    handleQuantityChange(e, tempCartItem) {
        tempCartItem.quantity = e.target.value;
        const tempCartItems = this.state.tempCartItems;
        this.setState({
            tempCartItems: Array.from(tempCartItems)
        });
    }

    handleShippedChange(item, e) {
        var cartItems = this.getCartItems(this.props.shoppingCart);
        var cartItem = cartItems.find(c => c.productID === item.productID);
        cartItem.quantityShipped = e.target.value;
        this.props.onShippedChange(cartItem);
    }

    handleUpdatedQuantityChange(e) {
        this.setState({ updatedQuantity: e.target.value });
    }

    handleUpdatedCommentChange(e) {
        this.setState({ updatedComment: e.target.value });
    }

    handleUpdatedLotChange(e) {
        this.setState({ updatedLot: e.target.value });
    }


    getCartItems(shoppingCart) {
        if (!!shoppingCart) {
            var sortedProductItems = shoppingCart.sortedNutraCartProductItems;
            var cartItems = [];
            for (var key in sortedProductItems) {
                var item = sortedProductItems[key];
                item.id = key;
                cartItems.push(item);
            }
            return cartItems;
        }
        else {
            return [];
        }
    }

    getPayments(order) {
        if (!!order) {
            var payments = order.payments;
            var items = [];
            for (var key in payments) {
                var item = payments[key];
                item.id = item.paymentMethod.key !== undefined ? item.paymentMethod.key : item.paymentMethod.creditCardID;
                items.push(item);
            }
            return items;
        }
        else {
            return [];
        }
    }

    saveCartItems() {
        var params = {
            orderId: this.props.order.orderID,
            cartItems: this.state.tempCartItems.map((item) => {
                return {
                    productId: item.product.productID,
                    quantity: item.quantity,
                    actualAmount: item.actualAmount,
                    comment: item.comment,
                    lot: item.lot
                }
            })
        };

        if (params.cartItems && params.cartItems.length > 0) {
            this.http.post('/api/Orders/SaveCartItems', params).then(data => {
                window.$('#addProductModal').modal('hide');
                this.props.onItemAdded(data);
                this.setState({
                    tempCartItems: [
                    ]
                });
            });
        }
    }

    removeTempCartItem(index) {
        const tempCartItemsClone = [...this.state.tempCartItems];
        tempCartItemsClone.splice(index, 1);
        this.setState({
            tempCartItems: tempCartItemsClone
        });
    }

    moveTempCartItemUp(index) {
        if (index > 0) {
            const tempCartItemsClone = [...this.state.tempCartItems];
            const item = tempCartItemsClone[index];
            tempCartItemsClone.splice(index, 1);
            tempCartItemsClone.splice(index - 1, 0, item);
            this.setState({
                tempCartItems: tempCartItemsClone
            });
        }
    }

    moveTempCartItemDown(index) {
        if (index < this.state.tempCartItems.length - 1) {
            const tempCartItemsClone = [...this.state.tempCartItems];
            const item = tempCartItemsClone[index];
            tempCartItemsClone.splice(index, 1);
            tempCartItemsClone.splice(index + 1, 0, item);
            this.setState({
                tempCartItems: tempCartItemsClone
            });
        }
    }

    editCartItem(cartItem) {
        this.editedCartItem = cartItem;
        this.setState({
            updatedQuantity: cartItem.quantity,
            updatedComment: cartItem.comment,
            updatedLot: cartItem.lot,
        })
        window.$('#updateQuantityModal').modal('show');
    }

    updateProductQuantity() {
        var cartItem = this.editedCartItem;
        var params = {
            orderId: this.props.order.orderID,
            productId: cartItem.productID,
            cartPrice: cartItem.cartPrice.toFixed(2),
            comment: cartItem.comment,
            lot: cartItem.lot,
            quantity: this.state.updatedQuantity,
            newComment: this.state.updatedComment,
            newLot: this.state.updatedLot
        }

        this.http.get('/api/Orders/UpdateCartItemQuantity', params).then(data => {
            window.$('#updateQuantityModal').modal('hide');
            this.props.onItemAdded(data);
        });
    }

    removeCartItem(cartItem, e) {
        var params = {
            orderId: this.props.order.orderID,
            productId: cartItem.productID,
            cartPrice: cartItem.cartPrice.toFixed(2),
            comment: cartItem.comment,
            lot: cartItem.lot
        }

        this.http.get('/api/Orders/RemoveCartItem', params).then(data => {
            this.props.onItemAdded(data);
        });
    }

    moveCartItemUp(index) {
        if (index > 0) {
            const tempSortedCartItemsClone = [...this.state.tempSortedCartItems];
            const item = tempSortedCartItemsClone[index];
            tempSortedCartItemsClone.splice(index, 1);
            tempSortedCartItemsClone.splice(index - 1, 0, item);
            this.setState({
                tempSortedCartItems: tempSortedCartItemsClone
            });
        }
    }

    moveCartItemDown(index) {
        if (index < this.state.tempSortedCartItems.length - 1) {
            const tempSortedCartItemsClone = [...this.state.tempSortedCartItems];
            const item = tempSortedCartItemsClone[index];
            tempSortedCartItemsClone.splice(index, 1);
            tempSortedCartItemsClone.splice(index + 1, 0, item);
            this.setState({
                tempSortedCartItems: tempSortedCartItemsClone
            });
        }
    }

    sortCartItems() {
        const tempSortedCartItemsClone = [...this.state.tempSortedCartItems];
        tempSortedCartItemsClone.sort((item1, item2) => {
            if(item1.name < item2.name){
                return -1;
            }
            else if(item1.name > item2.name){
                return 1;
            }
            else{
                return 0;
            }
        });
        this.setState({
            tempSortedCartItems: tempSortedCartItemsClone
        });
    }

    orderCartItems(){
        this.setState({
            tempSortedCartItems: [...this.getCartItems(this.props.shoppingCart)]
        }, () => {
            window.$("#orderCartItemsModal").modal("show");
        });
    }

    cancelOrderCartItems() {
        this.setState({
            tempSortedCartItems: [
            ]
        });
    }

    updateCartItems() {
        var params = {
            orderId: this.props.order.orderID,
            cartItems: this.state.tempSortedCartItems.map((item) => {
                return {
                    productId: item.productID,
                    quantity: item.quantity,
                    actualAmount: item.cartPrice,
                    comment: item.comment,
                    lot: item.lot
                }
            })
        };

        if (params.cartItems && params.cartItems.length > 0) {
            this.http.post('/api/Orders/UpdateCartItems', params).then(data => {
                this.props.onItemAdded(data);
                this.setState({
                    tempSortedCartItems: [
                    ]
                });
                window.$("#orderCartItemsModal").modal("hide");
            });
        }
    }

    render() {
        const cartItems = this.getCartItems(this.props.shoppingCart);
        return (
            <div>
                <div className="row">
                    <div className="col-9">
                        <div className="form-row">
                            <table className="table table-striped table-bordered mt-3">
                                <thead>
                                    <tr>
                                        <th scope="col">DESCRIPTION</th>
                                        <th scope="col">PRICE</th>
                                        <th scope="col">QTY</th>
                                        <th scope="col"></th>
                                        <th scope="col">EXTENDED</th>
                                        {this.props.fulfillment &&
                                            <th scope="col">SHIPPED</th>
                                        }
                                        <th scope="col">COMMENTS</th>
                                        <th scope="col">LOT</th>
                                        {!this.props.fulfillment &&
                                            <th scope="col"></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartItems.map((cartItem, index) =>
                                        <tr key={cartItem.id}>
                                            <td>{cartItem.name}</td>
                                            <td>{this.utils.toCurrency(cartItem.cartPrice, this.props.order.currency.value)}</td>
                                            <td>{cartItem.quantity}</td>
                                            <td></td>
                                            <td>{this.utils.toCurrency(cartItem.extendedPrice, this.props.order.currency.value)}</td>
                                            {this.props.fulfillment &&
                                                <td><input type="text" className="form-control" value={cartItem.quantityShipped} onChange={e => this.handleShippedChange(cartItem, e)} /></td>
                                            }
                                            <td>{cartItem.comment}</td>
                                            <td>{cartItem.lot}</td>
                                            {!this.props.fulfillment &&
                                                <td>
                                                    <button className="btn btn-sm btn-default" onClick={(e) => this.editCartItem(cartItem, e)}><i className="fas fa-pen"></i></button>&nbsp;
                                                    <button className="btn btn-sm btn-danger" onClick={(e) => this.removeCartItem(cartItem, e)}><i className="fas fa-trash"></i></button>
                                                </td>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {!this.props.fulfillment &&
                            <div className="form-row">
                                <div className="col-md-12">
                                    <button type="button" className="btn btn-primary pull-right" data-toggle="modal" data-target="#addProductModal">Add Products</button>
                                    <button type="button" className="btn btn-default pull-right mr-2" onClick={this.orderCartItems}><i className="fas fa-sort" /> Order Products</button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-3">
                        <table className="table table-striped table-bordered mt-3">
                            <thead>
                                <tr>
                                    <th scope="col" colSpan="2">ORDER SUMMARY</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Subtotal</td>
                                    <td>{this.utils.toCurrency(this.props.order.shoppingCart.subTotal, this.props.order.currency.value)}</td>
                                </tr>
                                <tr>
                                    <td>Discount</td>
                                    <td>{this.utils.toCurrency(this.props.order.discount, this.props.order.currency.value)}</td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td>{this.utils.toCurrency(this.props.order.tax, this.props.order.currency.value)}</td>
                                </tr>
                                <tr>
                                    <td>{this.props.order.orderShipment !== null && this.props.order.orderShipment.shipType !== null ? this.props.order.orderShipment.shipType.description : ""}</td>
                                    <td>{this.utils.toCurrency(this.props.order.orderShipment.amount, this.props.order.currency.value)}</td>
                                </tr>
                                <tr>
                                    <td>Order Total</td>
                                    <td>{this.utils.toCurrency(this.props.order.total, this.props.order.currency.value)}</td>
                                </tr>
                                <tr>
                                    <td>{this.getPayments(this.props.order).map(p =>
                                        <span key={p.id}>{p.description}</span>
                                    )}
                                    </td>
                                    <td>{this.getPayments(this.props.order).map(p =>
                                        <span key={p.id}>{this.utils.toCurrency(p.amount, this.props.order.currency.value)}</span>
                                    )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <Modal id="addProductModal" title="Add Products" onOk={this.saveCartItems} width="1000px" okText="Save">
                    {/* <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="site">Company</label>
                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange}></CompanySelect>
                        </div>
                    </div> */}
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="accountType"><br />Product Type</label>
                            <ProductTypeSelect size={9} value={this.state.productTypeId} onChange={(e) => this.handleProductTypeChange(e)}></ProductTypeSelect>
                        </div>
                        <div className="form-group col-md-5">
                            <label htmlFor="orderStatus"><br />Product</label>
                            <ProductMultipleSelect size={20} companyId={this.state.companyId} value={this.state.selectedProductIds} productTypeId={this.state.productTypeId} onChange={(e) => this.handleProductsSelected(e)} onDoubleClick={(e) => this.addTempCartItems()}></ProductMultipleSelect>
                        </div>
                        <div className="form-group col-md-1">
                            <label>&nbsp;&nbsp; </label>
                            <button type="button" className="btn btn-default" style={{ marginTop: "45px" }} onClick={(e) => this.addTempCartItems()}>Add</button>
                        </div>
                    </div>
                    {this.state.tempCartItems && this.state.tempCartItems.length > 0 &&
                        <div className="form-row">
                            <div className="form-group col-md-3">
                                <label htmlFor="orderType">Product</label>
                            </div>
                            {this.props.order.salesman.value === "Web/Retail" &&
                                <div className="form-group col-md-1">
                                    <label htmlFor="orderType">Web/Retail<br />Amount</label>
                                </div>
                            }
                            <div className="form-group col-md-3">
                                <label htmlFor="orderNumber"><br />Comment</label>
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="orderNumber"><br />Lot</label>
                            </div>
                            <div className="form-group col-md-1">
                                <label htmlFor="customerNumber">Actual<br />Amount</label>
                            </div>
                            <div className="form-group col-md-1">
                                <label htmlFor="beginDate"><br />Quantity</label>
                            </div>
                        </div>
                    }
                    {this.state.tempCartItems.map((tempCartItem, index) =>
                        <React.Fragment key={index}>
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <input type="text" readOnly className="form-control" value={tempCartItem.product.name} />
                                </div>
                                {this.props.order.salesman.value === "Web/Retail" &&
                                    <div className="form-group col-md-1">
                                        <input type="text" readOnly value={tempCartItem.salePrice} onChange={(e) => this.handleActualAmountChange(e, tempCartItem)} className="form-control" />
                                    </div>
                                }
                                <div className="form-group col-md-3">
                                    <input type="text" className="form-control" value={tempCartItem.comment} onChange={(e) => this.handleCommentChange(e, tempCartItem)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <input type="text" className="form-control" value={tempCartItem.lot} onChange={(e) => this.handleLotChange(e, tempCartItem)} />
                                </div>
                                <div className="form-group col-md-1">
                                    <input type="text" className="form-control" value={tempCartItem.actualAmount} onChange={(e) => this.handleActualAmountChange(e, tempCartItem)} />
                                </div>
                                <div className="form-group col-md-1">
                                    <input type="text" className="form-control" value={tempCartItem.quantity} onChange={(e) => this.handleQuantityChange(e, tempCartItem)} />
                                </div>
                                <div className="form-group col-md-1">
                                    {index > 0 &&
                                        <button className="btn btn-xs btn-focus-highlight mr-1" type="button" onClick={(e) => this.moveTempCartItemUp(index)}><i className="fas fa-arrow-up"></i></button>
                                    }
                                    {index < this.state.tempCartItems.length - 1 &&
                                        <button className="btn btn-xs btn-focus-highlight" type="button" onClick={(e) => this.moveTempCartItemDown(index)}><i className="fas fa-arrow-down"></i></button>
                                    }
                                </div>
                                <div className="form-group col-md-1">
                                    <button className="btn btn-xs btn-danger btn-focus-highlight" type="button" onClick={(e) => this.removeTempCartItem(index)}><i className="fas fa-trash"></i></button>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Modal>

                <Modal id="updateQuantityModal" title="Update Product Quantity" onOk={this.updateProductQuantity}>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label>Quantity</label>
                            <input type="text" className="form-control" value={this.state.updatedQuantity} onChange={this.handleUpdatedQuantityChange}></input>
                        </div>
                        <div className="form-group col-md-12">
                            <label>Comment</label>
                            <input type="text" className="form-control" value={this.state.updatedComment} onChange={this.handleUpdatedCommentChange}></input>
                        </div>
                        <div className="form-group col-md-12">
                            <label>Lot</label>
                            <input type="text" className="form-control" value={this.state.updatedLot} onChange={this.handleUpdatedLotChange}></input>
                        </div>
                    </div>
                </Modal>

                <Modal id="orderCartItemsModal" title="Order Products" onOk={this.updateCartItems} onCancel={this.cancelOrderCartItems} width="1000px" okText="Save">
                    <div className="form-row">
                        <table className="table table-striped table-bordered mt-3">
                            <thead>
                                <tr>
                                    <th scope="col" onClick={(e) => this.sortCartItems()} className="sortable">DESCRIPTION</th>
                                    <th scope="col">PRICE</th>
                                    <th scope="col">QTY</th>
                                    <th scope="col"></th>
                                    <th scope="col">EXTENDED</th>
                                    <th scope="col">COMMENTS</th>
                                    <th scope="col">LOT</th>
                                    {!this.props.fulfillment &&
                                        <th scope="col"></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tempSortedCartItems.map((cartItem, index) =>
                                    <tr key={cartItem.id}>
                                        <td>{cartItem.name}</td>
                                        <td>{this.utils.toCurrency(cartItem.cartPrice)}</td>
                                        <td>{cartItem.quantity}</td>
                                        <td></td>
                                        <td>{this.utils.toCurrency(cartItem.extendedPrice)}</td>
                                        <td>{cartItem.comment}</td>
                                        <td>{cartItem.lot}</td>
                                        {!this.props.fulfillment &&
                                            <td>
                                                {index > 0 &&
                                                    <button className="btn btn-sm btn-focus-highlight ml-1" type="button" onClick={(e) => this.moveCartItemUp(index)}><i className="fas fa-arrow-up"></i></button>
                                                }
                                                {index < cartItems.length - 1 &&
                                                    <button className="btn btn-sm btn-focus-highlight ml-1" type="button" onClick={(e) => this.moveCartItemDown(index)}><i className="fas fa-arrow-down"></i></button>
                                                }
                                            </td>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                </Modal>
            </div>
        );
    }
}
export default ShoppingCart