import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import { Link } from "react-router-dom";

class CategoryList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            categories: [],
        }

        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getCategories = this.getCategories.bind(this);
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories(page) {
        this.http.get('/api/Admin/GetCategories', null).then(data => {
            this.setState({ categories: data });
        });
    }

    getSites(coupon) {
        var str = "";
        var sites = this.utils.dictToArray(coupon.sites);
        for (var i = 0; i < sites.length; i++) {
            str += sites[i];
            if (i !== sites.length - 1) {
                str += "<br/>";
            }
        }
        return str;
    }

    deleteCategory(category){
        var message = "This category will be deleted. Do you want to continue?";
        this.utils.confirm(message, () => {
            this.http.post('/api/Admin/DeleteCategory', {id: category.childCategoryId}).then(data => {
                this.getCategories();
            });
        })
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Category List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Site</th>
                                                <th scope="col">Parent Category</th>
                                                <th scope="col">Category</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.categories.map((category) =>
                                                <tr key={category.categoryID}>
                                                    <td>{category.site}</td>
                                                    {category.parentName !== "WEBSITE" &&
                                                        <td><Link to={"/admin/categories/" + category.parentCategoryId}>{category.parentName}</Link></td>
                                                    }
                                                    {category.parentName === "WEBSITE" &&
                                                        <td>{category.parentName}</td>
                                                    }
                                                    <td><Link to={"/admin/categories/" + category.childCategoryId}>{category.childName}</Link></td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={e => this.deleteCategory(category)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CategoryList;
