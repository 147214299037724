import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import ProductInformation from './ProductInformation';
import ProductSelect from '../../common/ProductSelect';
import WebsiteCategorySelect from '../../common/WebsiteCategorySelect';
import FileInput from '../../common/FileInput';

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      inventoryProducts: [],
      websiteCategories: [],
      deductionProduct: -1,
      deductionQuantity: 0,
      summary: "",
      description: "",
      featuredText: "",
      packaging: "",
      references: ""
    };
    this.http = new HttpRequestService();
    this.utils = new UtilsService();
    this.handleProductInfoChange = this.handleProductInfoChange.bind(this);
    this.handleDeductionProductChange = this.handleDeductionProductChange.bind(this);
    this.handleDeductionQuantityChange = this.handleDeductionQuantityChange.bind(this);
    this.handleWebsiteCategoryChange = this.handleWebsiteCategoryChange.bind(this);
    this.handleSummaryChange = this.handleSummaryChange.bind(this);
    this.handleFeaturedTextChange = this.handleFeaturedTextChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handlePackagingChange = this.handlePackagingChange.bind(this);
    this.handleReferencesChange = this.handleReferencesChange.bind(this);
    this.handleSmallImageChange = this.handleSmallImageChange.bind(this);
    this.handleLargeImageChange = this.handleLargeImageChange.bind(this);
    this.handleNutritionImageChange = this.handleNutritionImageChange.bind(this);
    this.handleOtherImageChange = this.handleOtherImageChange.bind(this);

    this.updateProduct = this.updateProduct.bind(this);
    this.addDeduction = this.addDeduction.bind(this);
    this.removeDeduction = this.removeDeduction.bind(this);
    this.addWebsiteCategory = this.addWebsiteCategory.bind(this);
    this.removeWebsiteCategory = this.removeWebsiteCategory.bind(this);
    this.updateWebsiteInformation = this.updateWebsiteInformation.bind(this);
  }

  componentDidMount() {
    this.getProduct();
  }

  componentWillUnmount() {
  }

  handleProductInfoChange(value) {
    this.productInfo = value;
  }

  handleDeductionProductChange(value) {
    this.setState({
      deductionProduct: value.productID
    })
  }

  handleDeductionQuantityChange(e) {
    this.setState({
      deductionQuantity: e.target.value
    })
  }

  handleWebsiteCategoryChange(value) {
    this.setState({
      websiteCategory: value
    })
  }

  handleSummaryChange(e) {
    this.setState({
      summary: e.target.value
    })
  }
  handleFeaturedTextChange(e) {
    this.setState({
      featuredText: e.target.value
    })
  }

  handleDescriptionChange(e) {
    this.setState({
      description: e.target.value
    })
  }

  handlePackagingChange(e) {
    this.setState({
      packaging: e.target.value
    })
  }

  handleReferencesChange(e) {
    this.setState({
      references: e.target.value
    })
  }

  handleSmallImageChange(file) {
    var params = {
      operation: "SaveSmallImage",
      productId: this.props.match.params.id
    }
    this.http.upload('/api/FileUpload/UploadFile/', file, params).then(data => {
      this.getProduct();
    });
  }

  handleLargeImageChange(file) {
    var params = {
      operation: "SaveLargeImage",
      productId: this.props.match.params.id
    }
    this.http.upload('/api/FileUpload/UploadFile/', file, params).then(data => {
      this.getProduct();
    });
  }

  handleNutritionImageChange(file) {
    var params = {
      operation: "SaveNutritionImage",
      productId: this.props.match.params.id
    }
    this.http.upload('/api/FileUpload/UploadFile/', file, params).then(data => {
      this.getProduct();
    });
  }

  handleOtherImageChange(file) {
    var params = {
      operation: "SaveOtherImage",
      productId: this.props.match.params.id
    }
    this.http.upload('/api/FileUpload/UploadFile/', file, params).then(data => {
      this.getProduct();
    });
  }

  addDeduction() {
    var params = {
      productId: this.props.match.params.id,
      deductionId: this.state.deductionProduct,
      quantity: this.state.deductionQuantity
    }
    this.http.get('/api/Inventory/AddDeduction', params).then(data => {
      window.$("#addDeductionModal").modal("hide");
      this.updateState(data);
    });
  }

  removeDeduction(deduction) {
    var params = {
      productId: this.props.match.params.id,
      deductionId: deduction.productID
    }
    this.http.get('/api/Inventory/RemoveDeduction', params).then(data => {
      this.updateState(data);
    });
  }

  addWebsiteCategory() {
    var params = {
      productId: this.props.match.params.id,
      categoryId: this.state.websiteCategory
    }
    this.http.get('/api/Inventory/AddWebsiteCategory', params).then(data => {
      window.$("#addWebsiteCategoryModal").modal("hide");
      this.updateState(data);
    });
  }

  removeWebsiteCategory(category) {
    var params = {
      productId: this.props.match.params.id,
      categoryId: category.categoryID
    }
    this.http.get('/api/Inventory/RemoveWebsiteCategory', params).then(data => {
      this.updateState(data);
    });
  }

  getProduct() {
    var params = {
      productId: this.props.match.params.id
    }
    this.http.get('/api/Inventory/GetProduct', params).then(data => {
      this.updateState(data);
    });
  }

  updateState(data) {
    this.setState({
      product: data,
      inventoryProducts: this.utils.dictToArray(data.inventoryProducts),
      websiteCategories: this.utils.dictToArray(data.categories),
      summary: data.summary,
      description: data.description,
      featuredText: data.featuredText,
      packaging: data.packaging,
      references: data.references
    });
  }

  updateProduct() {
    var model = this.productInfo;
    model.productId = this.props.match.params.id;
    var params = {
      model: JSON.stringify(model)
    }
    this.http.get('/api/Inventory/UpdateProduct', params).then(data => {
      this.utils.success("");
      this.updateState(data);
    });
  }

  updateWebsiteInformation() {
    var model = {
      productId: this.props.match.params.id,
      summary: this.state.summary,
      description: this.state.description,
      featuredText: this.state.featuredText,
      packaging: this.state.packaging,
      references: this.state.references
    }
    var params = {
      model: JSON.stringify(model)
    }
    this.http.get('/api/Inventory/UpdateWebsiteInformation', params).then(data => {
      this.utils.success("");
      this.updateState(data);
    });
  }

  render() {
    return (
      <div className="page-inner">
        <div className="page-header">
          <h4 className="page-title">Product Detail</h4>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.state.product &&
              <div className="card full-height">
                <div className="card-header">
                  <div className="card-title">Product Information</div>
                </div>
                <div className="card-body">
                  <ProductInformation product={this.state.product} onChange={this.handleProductInfoChange}></ProductInformation>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateProduct}>Update</button>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Inventory Deductions</div>
                  <div className="card-tools">
                    <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addDeductionModal">
                      <span className="btn-label">
                        <i className="fas fa-plus"></i>
                      </span>
                      Add
											</button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <table className="table table-striped table-bordered mt-3">
                    <thead>
                      <tr>
                        <th scope="col">Product Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.product && this.state.inventoryProducts.map(item =>
                        <tr key={item.productID}>
                          <td>{item.name}</td>
                          <td>{item.quantity}</td>
                          <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeDeduction(item, e)}><i className="fas fa-trash"></i></button></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Modal id="addDeductionModal" title="Add Inventory Deduction" onOk={this.addDeduction}>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>Product</label>
                      <ProductSelect allProducts={true} value={this.state.deductionProduct} onChange={this.handleDeductionProductChange}></ProductSelect>
                    </div>
                    <div className="form-group col-md-12">
                      <label>Quantity</label>
                      <input type="text" className="form-control" value={this.state.deductionQuantity} onChange={this.handleDeductionQuantityChange}></input>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Website Categories</div>
                  <div className="card-tools">
                    <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addWebsiteCategoryModal">
                      <span className="btn-label">
                        <i className="fas fa-plus"></i>
                      </span>
                      Add
											</button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <table className="table table-striped table-bordered mt-3">
                    <thead>
                      <tr>
                        <th scope="col">Site</th>
                        <th scope="col">Category</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.product && this.state.websiteCategories.map(item =>
                        item.name !== "WEBSITE" &&
                        <tr key={item.categoryID}>
                          <td>{item.siteName}</td>
                          <td>{item.name}</td>
                          <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeWebsiteCategory(item, e)}><i className="fas fa-trash"></i></button></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Modal id="addWebsiteCategoryModal" title="Add website Category" onOk={this.addWebsiteCategory}>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>Website Category</label>
                      <WebsiteCategorySelect value={this.state.websiteCategory} onChange={this.handleWebsiteCategoryChange}></WebsiteCategorySelect>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Website Information</div>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Product Summary</label>
                    <input type="text" className="form-control" value={this.state.summary} onChange={this.handleSummaryChange}></input>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Product Featured Text</label>
                    <input type="text" className="form-control" value={this.state.featuredText} onChange={this.handleFeaturedTextChange}></input>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Product Page Description</label>
                    <textarea className="form-control" rows={6} value={this.state.description} onChange={this.handleDescriptionChange}></textarea>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Product Packaging</label>
                    <textarea className="form-control" rows={6} value={this.state.packaging} onChange={this.handlePackagingChange}></textarea>
                  </div>
                  <div className="form-group col-md-4">
                    <label>Product References</label>
                    <textarea className="form-control" rows={6} value={this.state.references} onChange={this.handleReferencesChange}></textarea>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateWebsiteInformation}>Update</button>
              </div>
            </div>
          </div>
        </div>
        {this.state.product &&
          <div className="row">
            <div className="col-md-12">
              <div className="card full-height">
                <div className="card-header">
                  <div className="card-head-row">
                    <div className="card-title">Product Images</div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-row align-items-start">
                    <div className="form-group col-md-6">
                      <label>Small Image</label>
                      <div className="form-control">
                        {this.state.product.smallImage !== "" &&
                          <img className="col-md-12 mb-4 p-0" src={this.http.HOST + "/api/RenderObject?content=img&src=" + this.state.product.smallImage + "&c=" + new Date().getTime()} alt="Small" />
                        }
                        <FileInput label="Upload File" onChange={this.handleSmallImageChange}></FileInput>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Supplemental Facts Image</label>
                      <div className="form-control">
                        {this.state.product.nutritionImage !== "" &&
                          <img className="col-md-12 mb-4 p-0" src={this.http.HOST + "/api/RenderObject?content=img&src=" + this.state.product.nutritionImage + "&c=" + new Date().getTime()} alt="Small" />
                        }
                        <FileInput label="Upload File" onChange={this.handleNutritionImageChange}></FileInput>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Large Image</label>
                      <div className="form-control">
                        {this.state.product.largeImage !== "" &&
                          <img className="col-md-12 mb-4 p-0" src={this.http.HOST + "/api/RenderObject?content=img&src=" + this.state.product.largeImage + "&c=" + new Date().getTime()} alt="Small" />
                        }
                        <FileInput label="Upload File" onChange={this.handleLargeImageChange}></FileInput>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Product Page Header Image</label>
                      <div className="form-control">
                        {this.state.product.otherImage !== "" &&
                          <img className="col-md-12 mb-4 p-0" src={this.http.HOST + "/api/RenderObject?content=img&src=" + this.state.product.otherImage + "&c=" + new Date().getTime()} alt="Small" />
                        }
                        <FileInput label="Upload File" onChange={this.handleOtherImageChange}></FileInput>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

      </div>
    );
  }
}

export default ProductDetail;
