import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class SalesmanSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
        this.getUsers = this.getUsers.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getUsers();
    }


    getUsers() {
        this.http.get('/api/Users/GetUsers', {orderBy: "firstName"}).then(data => {
            this.setState({ items: data });
            if (data.length > 0 && this.props.value === 0) {
                this.props.onChange(data[0]);
            }
        });
    }

    handleChange(e) {
        var user = this.state.items.find(t => t.initials === e.target.value);
        if (user) {
            this.props.onChange(user);
        }
        else {
            this.props.onChange({ initials: e.target.value });
        }
    }

    render() {
        var options = this.state.items.map((item) =>
            <option key={item.initials} value={item.initials}>{item.firstName + " " + item.lastName + " <" + item.initials + ">"}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {this.props.hasAllOption === true &&
                    <option value={""}>-- ALL --</option>
                }
                {options}
            </select>
        );
    }
}
export default SalesmanSelect
