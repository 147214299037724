import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import { Link } from "react-router-dom";
import moment from "moment";

class CouponList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            coupons: []
        }

        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getCoupons = this.getCoupons.bind(this);
    }

    componentDidMount() {
        this.getCoupons();
    }

    getCoupons() {
        this.http.get('/api/Admin/GetCoupons', null).then(data => {
            this.setState({ coupons: data });
        });
    }

    getSites(coupon){
        var str = "";
        var sites = this.utils.dictToArray(coupon.sites);
        for(var i = 0; i < sites.length; i++){
            str += sites[i];
            if(i !== sites.length - 1){
                str += "<br/>";
            }
        }
        return str;
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Coupon List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                        <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-tools">
                                        <Link to="/admin/add-coupon" className="btn btn-info btn-border btn-round btn-sm mr-2">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add New
							            </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Coupon</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Active From</th>
                                                <th scope="col">ActiveTo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.coupons.map((coupon) =>
                                                <tr key={coupon.couponID}>
                                                    <td><Link to={"/admin/coupons/" + coupon.couponID}>{coupon.claimCode}</Link></td>
                                                    <td dangerouslySetInnerHTML={{__html: this.getSites(coupon)}}></td>
                                                    <td>{moment(coupon.activeFrom).format('l')}</td>
                                                    <td>{moment(coupon.activeTo).format('l')}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CouponList;
