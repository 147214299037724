import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            sendInvoicesFrom: "",
            sendInvoicesFromDisplayName: "",
            invoiceEmailBody: "",
        }
        this.handleSendInvoicesFromChange = this.handleSendInvoicesFromChange.bind(this);
        this.handleSendInvoicesFromDisplayNameChange = this.handleSendInvoicesFromDisplayNameChange.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
    }

    componentDidMount() {
        this.getSettings();
    }

    handleSendInvoicesFromChange(e) {
        this.setState({
            sendInvoicesFrom: e.target.value
        });
    }

    handleSendInvoicesFromDisplayNameChange(e) {
        this.setState({
            sendInvoicesFromDisplayName: e.target.value
        });
    }

    getSettings() {
        var params = {
            id: this.props.match.params.id
        }
        this.http.get('/api/Admin/GetSettings').then(data => {
            this.updateState(data);
        })
    }

    updateSettings() {
        var settings = [];
        settings.push({
            name: "SendInvoicesFrom",
            value: this.state.sendInvoicesFrom,
        });

        settings.push({
            name: "SendInvoicesFromDisplayName",
            value: this.state.sendInvoicesFromDisplayName,
        });

        settings.push({
            name: "InvoiceEmailBody",
            value: this.state.invoiceEmailBody,
        });

        this.http.post('/api/Admin/UpdateSettings', settings).then(data => {
            this.utils.success("");
        });
    }

    updateState(data) {
        var sendInvoicesFrom = "";
        var sendInvoicesFromDisplayName = "";
        var invoiceEmailBody = "";
        if (data.find(s => s.name === "SendInvoicesFrom")) {
            sendInvoicesFrom = data.find(s => s.name === "SendInvoicesFrom").value;
        }

        if (data.find(s => s.name === "SendInvoicesFromDisplayName")) {
            sendInvoicesFromDisplayName = data.find(s => s.name === "SendInvoicesFromDisplayName").value;
        }

        if (data.find(s => s.name === "InvoiceEmailBody")) {
            invoiceEmailBody = data.find(s => s.name === "InvoiceEmailBody").value;
        }

        this.setState({
            sendInvoicesFrom: sendInvoicesFrom,
            sendInvoicesFromDisplayName: sendInvoicesFromDisplayName,
            invoiceEmailBody: invoiceEmailBody,
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Edit Settings</h4>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    {/* <div className="form-group col-md-12">
                                        <label>Send Invoices From</label>
                                        <input type="text" value={this.state.sendInvoicesFrom} onChange={this.handleSendInvoicesFromChange} className="form-control" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Send Invoices From Name</label>
                                        <input type="text" value={this.state.sendInvoicesFromDisplayName} onChange={this.handleSendInvoicesFromDisplayNameChange} className="form-control" />
                                    </div> */}
                                    <div className="form-group col-md-12">
                                        <label>Invoice Email Body</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={this.state.invoiceEmailBody}
                                            config={{
                                                toolbar: [
                                                    'heading', '|',
                                                    'alignment', '|',
                                                    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                                    'link', '|',
                                                    'bulletedList', 'numberedList',
                                                    'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
                                                    'insertTable', '|',
                                                    'outdent', 'indent', '|',
                                                    'blockQuote', '|',
                                                    'undo', 'redo', '|',
                                                    'sourceEditing'
                                                ],
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();                                                this.setState({
                                                    invoiceEmailBody: data
                                                });
                                            }}
                                        />
                                        <small>Available Tokens: [AccountName], [AccountNumber], [InvoiceNumber], [FulfillDate]</small>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateSettings}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings