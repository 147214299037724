import React, { Component } from 'react';
import PhoneTypeSelect from './PhoneTypeSelect';

class PhoneNumber extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phoneNumberID: this.props.value ? this.props.value.phoneNumberID : 0,
            phoneTypeID: this.props.value ? this.props.value.phoneTypeID : 0,
            number: this.props.value ? this.props.value.number : ""
        }

        this.handlePhoneTypeChange = this.handlePhoneTypeChange.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.notifyChange = this.notifyChange.bind(this);
    }

    handlePhoneTypeChange(value){
        this.setState({
            phoneTypeID: value.phoneTypeID
        }, this.notifyChange)
    }

    handleNumberChange(e){
        this.setState({
            number: e.target.value
        }, this.notifyChange)
    }

    notifyChange(){
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-group col-md-12">
                    <label>Type</label>
                    <PhoneTypeSelect value={this.state.phoneTypeID} onChange={this.handlePhoneTypeChange}></PhoneTypeSelect>
                </div>
                <div className="form-group col-md-12">
                    <label>Number</label>
                    <input type="text" className="form-control" value={this.state.number} onChange={this.handleNumberChange}></input>
                </div>
            </React.Fragment>
        );
    }
}
export default PhoneNumber