import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import AccountInformation from './AccountInformation';
import PhoneNumber from '../../common/PhoneNumber';
import Scrollable from '../../common/Scrollable';
import NoteList from './NoteList';
import Note from './Note';
import CallbackList from './CallbackList';
import Callback from './Callback';
import CustomerHistoryList from '../customers/CustomerHistoryList';
import CustomerAccountInformation from '../customers/CustomerAccountInformation';
import CustomerAddress from '../customers/CustomerAddress';
import CustomerCreditCard from '../customers/CustomerCreditCard';
import CustomerAddressList from '../customers/CustomerAddressList';
import CustomerCreditCardList from '../customers/CustomerCreditCardList';
import AccountAssociations from './AccountAssociations';

class AccountDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountID: this.props.match.params.accountId,
            customerID: this.props.match.params.customerId,
            account: null,
            selectedCustomerCategory: 0,
            password: "",
            editedPhoneNumber: null
        };
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleAccountInfoChange = this.handleAccountInfoChange.bind(this);
        this.handleAccountAssociationsChange = this.handleAccountAssociationsChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.addPhoneNumber = this.addPhoneNumber.bind(this);
        this.removePhoneNumber = this.removePhoneNumber.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.updateAccountAssociations = this.updateAccountAssociations.bind(this);
        this.addNote = this.addNote.bind(this);
        this.handleNewNoteChange = this.handleNewNoteChange.bind(this);
        this.handleNoteListUpdate = this.handleNoteListUpdate.bind(this);
        this.handleNewAddressChange = this.handleNewAddressChange.bind(this);
        this.handleAddressListUpdate = this.handleAddressListUpdate.bind(this);
        this.handleNewCallbackChange = this.handleNewCallbackChange.bind(this);
        this.handleCallbackListUpdate = this.handleCallbackListUpdate.bind(this);

        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.handleCustomerInfoChange = this.handleCustomerInfoChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.updateCustomer = this.updateCustomer.bind(this);
        this.handleCustomerAddressSelect = this.handleCustomerAddressSelect.bind(this);
        this.handleNewCustomerAddressChange = this.handleNewCustomerAddressChange.bind(this);
        this.handleCustomerAddressListUpdate = this.handleCustomerAddressListUpdate.bind(this);
        this.addCustomerAddress = this.addCustomerAddress.bind(this);
        this.handleCreditCardSelect = this.handleCreditCardSelect.bind(this);
        this.handleNewCreditCardChange = this.handleNewCreditCardChange.bind(this);
        this.handleCreditCardListUpdate = this.handleCreditCardListUpdate.bind(this);
        this.addCreditCard = this.addCreditCard.bind(this);

        this.openEditPhoneNumberModal = this.openEditPhoneNumberModal.bind(this);
        this.editPhoneNumber = this.editPhoneNumber.bind(this);
        this.handleEditedPhoneNumberChange = this.handleEditedPhoneNumberChange.bind(this);

        this.addCallback = this.addCallback.bind(this);
    }

    componentDidMount() {
        this.getAccount();
        window.$(".card-body.scroll > .scrollbar-inner").scrollbar();
    }

    componentWillUnmount() {
    }

    handleAccountInfoChange(value) {
        this.accountInfo = value;
    }

    handleAccountAssociationsChange(value) {
        this.accountAssociations = value;
    }

    handlePhoneNumberChange(phoneNumber) {
        this.newPhoneNumber = phoneNumber;
    }

    addPhoneNumber() {
        var model = this.newPhoneNumber;
        model.ownerID = this.state.accountID;
        model.ownerTypeID = 2
        this.http.post('/api/Crm/AddPhoneNumber', model).then(data => {
            window.$("#addPhoneNumberModal").modal("hide");
            this.getAccount();
        });
    }

    removePhoneNumber(phone) {
        var params = {
            id: phone.phoneNumberID
        }
        this.http.post('/api/Crm/RemovePhoneNumber', params).then(data => {
            this.getAccount();
        });
    }

    handleNewAddressChange(address) {
        this.newAddress = address;
    }

    handleAddressListUpdate() {
        this.getAccount();
    }

    handleNewCallbackChange(note) {
        this.newCallback = note;
    }

    handleCallbackListUpdate(data) {
        this.getAccount();
    }

    addCallback() {
        var model = this.newCallback;
        model.accountID = this.state.accountID;
        this.http.post("/api/Crm/AddCallback", model).then(data => {
            window.$("#addCallbackModal").modal("hide");
            this.getAccount();
        });
    }

    getAccount() {
        var url = "";
        var id = 0;
        if (this.state.accountID) {
            url = '/api/Crm/GetAccount';
            id = this.state.accountID;
        }
        else if (this.state.customerID) {
            url = '/api/Crm/GetAccountByCustomerId';
            id = this.state.customerID;
        }
        var params = {
            id: id
        }
        this.http.get(url, params).then(data => {
            this.updateState(data);
            if (this.props.onAccountLoad) {
                this.props.onAccountLoad({
                    customerID: data.customerID
                });
            }
        });
    }

    updateAccount() {
        var data = {
            name: this.accountInfo.name,
            accountTypeID: this.accountInfo.accountTypeId,
            accountNumber: this.accountInfo.accountNumber,
            isActive: this.accountInfo.isActive,
            customerID: this.accountInfo.customerID,
            accountID: this.state.accountID
        }
        this.http.post('/api/Crm/SaveAccount', data).then(data => {
            this.utils.success("");
            if (this.props.onAccountUpdated) {
                this.props.onAccountUpdated();
            }
        });
    }

    updateAccountAssociations() {
        // prepare data for api call
        var userDefinedFields = this.utils.prepareUserDefinedFieldParams(this.accountAssociations.userDefinedFields);
        var data = {
            userDefinedFields: userDefinedFields,
            accountID: this.state.accountID
        }
        this.http.post('/api/Crm/SaveAccountAssociations', data).then(data => {
            this.utils.success("");
            if (this.props.onAccountUpdated) {
                this.props.onAccountUpdated();
            }
        });
    }

    handleNewNoteChange(note) {
        this.newNote = note;
    }

    handleNoteListUpdate() {
        this.getAccount();
    }

    addNote() {
        var model = this.newNote;
        model.ownerId = this.state.accountID;
        model.ownerTypeId = 2;
        this.http.post("/api/Crm/AddNote", model).then(data => {
            window.$("#addNoteModal").modal("hide");
            this.getAccount();
        });
    }

    deleteContact(contact, e) {
        var message = "This contact will be deleted. Do you want to continue?"
        this.utils.confirm(message, () => {
            var params = {
                id: contact.contactID
            }
            this.http.post('/api/Crm/RemoveContact', params).then(data => {
                this.getAccount();
            });
        })
    }

    handleCategoryChange(value) {
        this.setState({
            selectedCustomerCategory: value
        })
    }

    addCategory() {
        var params = {
            customerId: this.state.account.customerID,
            categoryId: this.state.selectedCustomerCategory
        }
        this.http.get('/api/Customers/AddCategory', params).then(data => {
            window.$("#addCategoryModal").modal("hide");
            this.setState({ customer: data });
        });
    }

    removeCategory(category) {
        var params = {
            customerId: this.state.account.customerID,
            categoryId: category.customerTypeID
        }
        this.http.get('/api/Customers/RemoveCategory', params).then(data => {
            this.setState({ customer: data });
        });
    }

    handleCustomerInfoChange(value) {
        this.customerInfo = value;
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        })
    }

    resetPassword(category) {
        var message = "User's password will be reset to app's default password. Do you want to continue?"
        this.utils.confirm(message, () => {
            var params = {
                customerId: this.state.customer.customerID,
            }
            this.http.get('/api/Customers/ResetPassword', params).then(data => {
                this.utils.success("Password is reset to: " + data);
            });
        })
    }

    changePassword() {
        var params = {
            customerId: this.state.customer.customerID,
            password: this.state.password
        }
        this.http.get('/api/Customers/ChangePassword', params).then(data => {
            this.utils.success("Password is reset to: " + data);
        });
    }

    updateCustomer() {
        var model = this.customerInfo;
        model.customerId = this.state.customer.customerID;
        var params = {
            model: JSON.stringify(model)
        }
        this.http.get('/api/Customers/UpdateCustomer', params).then(data => {
            this.utils.success("");
            this.getAccount();
        });
    }

    handleCustomerAddressSelect(address) {
        var params = {
            customerId: this.state.customer.customerID,
            addressId: address.addressID
        }
        var url = "/api/Customers/SetDefaultShippingAddress";
        if (!address.shipping) {
            url = "/api/Customers/SetDefaultBillingAddress";
        }
        this.http.get(url, params).then(data => {
            this.setState({
                customer: data
            });
        });
    }

    handleNewCustomerAddressChange(address) {
        this.newCustomerAddress = address;
    }

    handleCustomerAddressListUpdate(data) {
        this.setState({
            customer: data
        });
    }

    handleCreditCardSelect(card) {
        var params = {
            customerId: this.state.customer.customerID,
            creditCardId: card.creditCardID
        }
        this.http.get("/api/Customers/SetDefaultCreditCard", params).then(data => {
            this.setState({
                customer: data
            });
        });
    }

    handleNewCreditCardChange(card) {
        this.newCreditCard = card;
    }

    handleCreditCardListUpdate(data) {
        this.setState({
            customer: data
        });
    }

    addCustomerAddress(validate) {
        var model = this.newCustomerAddress;
        model.customerId = this.state.customer.customerID;
        var params = {
            address: model,
            validate: validate
        }
        var url = "/api/Customers/AddBillingAddress";
        if (this.newCustomerAddress.shipping) {
            url = "/api/Customers/AddShippingAddress";
        }
        this.http.post(url, params).then(data => {
            if (data.invalid) {
                this.utils.confirm(data.error, () => {
                    this.addCustomerAddress(false);
                }, null, "Ignore!", "Cancel");
            }
            else if (data.corrected) {
                const confirmText = `There seems to be a problem with the address, and the address validator suggests a correction.

                Suggested Address:
                ${data.correctedAddress.rawText}

                Original Address:
                ${data.originalAddress.rawText}
                `;
                this.utils.confirm(confirmText, () => {
                    this.newCustomerAddress = {
                        ...this.newCustomerAddress,
                        addressLine1: data.correctedAddress.addressLine1,
                        addressLine2: data.correctedAddress.addressLine2,
                        addressLine3: data.correctedAddress.addressLine3,
                        municipality: data.correctedAddress.municipality,
                        county: data.correctedAddress.county,
                        district: data.correctedAddress.district,
                        city: data.correctedAddress.city,
                        stateId: data.correctedAddress.state.stateID,
                        zipCode: data.correctedAddress.zipCode,
                        countryId: data.correctedAddress.country.countryID,
                        phoneNumber: data.correctedAddress.phoneNumber,
                        addressType: data.correctedAddress.residential ? "Residential" : "Business",
                    }
                    this.addCustomerAddress(false);
                }, () => {
                    this.addCustomerAddress(false);
                }, "Use Suggestion", "Use Original");
            }
            else {
                if (this.newCustomerAddress.shipping) {
                    window.$("#addShippingAddressModal").modal("hide");
                }
                else {
                    window.$("#addBillingAddressModal").modal("hide");
                }
                this.setState({
                    customer: data
                });
            }
        });
    }

    addCreditCard() {
        var model = this.newCreditCard;
        model.customerId = this.state.customer.customerID;
        var params = {
            model: JSON.stringify(model)
        }
        this.http.get("/api/Customers/AddCreditCard", params).then(data => {
            window.$("#addCreditCardModal").modal("hide");
            this.setState({
                customer: data
            });
        });
    }

    openAddAddresModal(type) {
        if (type === "billing") {
            window.$("#addBillingAddressModal").modal("show");
        }
        else if (type === "shipping") {
            window.$("#addShippingAddressModal").modal("show");
        }
    }

    openEditPhoneNumberModal(phoneNumber) {
        this.setState({
            editedPhoneNumber: phoneNumber
        });
        window.$("#editPhoneNumberModal").modal("show");
    }

    handleEditedPhoneNumberChange(value) {
        this.setState({
            editedPhoneNumber: value
        })
    }

    editPhoneNumber() {
        var model = this.state.editedPhoneNumber;
        model.ownerID = this.state.accountID;
        model.ownerTypeID = 2
        this.http.post('/api/Crm/UpdatePhoneNumber', model).then(data => {
            window.$("#editPhoneNumberModal").modal("hide");
            this.getAccount();
        });
    }

    updateState(data) {
        this.setState({
            account: data,
            customer: data.customer,
            accountID: data.accountID,
            customerID: data.customerID,
            contacts: data.contacts
        });
    }

    goBackToSearchResults() {
        this.props.history.push(this.props.location.state.searchUrl, { loadParams: true });
    }

    getAccountCssClass() {
        if (this.state.account) {
            if (this.state.account.accountTypeID === 11) {
                return "page-web-retail";
            }
            else if (this.state.account.userDefinedFields) {
                const accountTags = this.state.account.userDefinedFields.find(u => u.userDefinedFieldID === 2);
                if (accountTags && accountTags.userDefinedFieldOptionIDs.find(o => o === 175)) {
                    return "page-cso-account"
                }
            }
        }
        return "";
    }

    render() {
        if (this.state.account) {
            var tempAddress = {
                addressID: 0,
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: { stateID: 0 },
                zipCode: "",
                country: { countryID: 211 },
                phoneNumber: "",
                primary: false,
                residential: true,
            }
        }
        if (this.state.customer) {
            var addressContact = null;
            if (this.state.contacts && this.state.contacts.length > 0) {
                addressContact = this.state.contacts.find(c => c.isPrimary);
                if (!addressContact) {
                    addressContact = this.state.contacts[0];
                }
            }
            var tempCustomerAddress = {
                addressID: 0,
                companyName: this.state.customer.companyName,
                contactName: addressContact ? addressContact.firstName + " " + addressContact.lastName : "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: { stateID: 0 },
                zipCode: "",
                country: { countryID: 211 },
                phoneNumber: this.state.customer.phoneNumber,
                primary: false,
                residential: true,
            }
        }

        var displayBackButton = this.props.location.state && this.props.location.state.displayBackButton;
        return (
            <div className={"page-inner " + this.getAccountCssClass()}>
                <div className="page-header">
                    <div className="page-header">
                        <h4 className="page-title">Account Detail</h4>
                        {displayBackButton &&
                            <button className="btn btn-primary ml-4" onClick={(e) => this.goBackToSearchResults()}> &lt; Back to Search Results</button>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className={this.state.customer ? "col-md-3" : "col-md-6"}>
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-title">General Account Information</div>
                            </div>
                            <div className="card-body">
                                {this.state.account &&
                                    <AccountInformation account={this.state.account} onChange={this.handleAccountInfoChange}></AccountInformation>
                                }
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateAccount}>Update</button>
                            </div>
                        </div>
                    </div>
                    {this.state.customer &&
                        <div className="col-md-5">
                            <div className="card full-height">
                                <div className="card-header">
                                    <div className="card-head-row">
                                        <div className="card-title">General Customer Information</div>
                                        <div className="card-tools">
                                            <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#resetPasswordModal">
                                                <span className="btn-label">
                                                    <i className="fas fa-sync"></i>
                                                </span>
                                                Reset Password
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {this.state.customer &&
                                        <CustomerAccountInformation mode="editCustomer" customer={this.state.customer} onChange={this.handleCustomerInfoChange}></CustomerAccountInformation>
                                    }
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateCustomer}>Update</button>
                                </div>
                            </div>
                            <Modal id="resetPasswordModal" title="Reset/Change Password" hideButtons={true}>
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <button type="button" className="btn btn-warning pull-right" onClick={this.resetPassword}>Reset Password</button>
                                    </div>
                                </div>
                                <div className="separator-dashed"></div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>New password</label>
                                        <input type="password" className="form-control" value={this.state.password} onChange={this.handlePasswordChange}></input>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <button type="button" className="btn btn-default pull-right" onClick={this.changePassword}>Change Password</button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    }
                    <div className={this.state.customer ? "col-md-4" : "col-md-6"}>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Phone Numbers</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addPhoneNumberModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <Scrollable id="phoneNumber" height={200}>
                                <div className="card-body">
                                    <div className="form-row">
                                        <table className="table table-striped table-bordered mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Number</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.account && this.state.account.phoneNumbers.map(item =>
                                                    <tr key={item.phoneNumberID}>
                                                        <td>{item.type.name}</td>
                                                        <td>{item.number}</td>
                                                        <td>
                                                            <button className="btn btn-sm btn-default p-1" onClick={(e) => this.openEditPhoneNumberModal(item, e)}><i className="fas fa-pen"></i></button>&nbsp;
                                                            <button className="btn btn-sm btn-danger p-1" onClick={(e) => this.removePhoneNumber(item, e)}><i className="fas fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Modal id="addPhoneNumberModal" title="Add Phone Number" onOk={this.addPhoneNumber}>
                                        <div className="form-row">
                                            <PhoneNumber onChange={this.handlePhoneNumberChange}></PhoneNumber>
                                        </div>
                                    </Modal>
                                    <Modal id="editPhoneNumberModal" title="Edit Phone Number" onOk={this.editPhoneNumber}>
                                        <div className="form-row">
                                            <PhoneNumber value={this.state.editedPhoneNumber} onChange={this.handleEditedPhoneNumberChange}></PhoneNumber>
                                        </div>
                                    </Modal>
                                </div>
                            </Scrollable>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Contacts</div>
                                    <div className="card-tools">
                                        <Link to={"/crm/add-contact/" + this.state.accountID} className="btn btn-info btn-border btn-round btn-sm mr-2">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <Scrollable id="contacts" height={200}>
                                <div className="card-body">
                                    <div className="form-row">
                                        <table className="table table-striped table-bordered mt-3">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Phone Number</th>
                                                    <th scope="col">Primary</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.account && this.state.account.contacts.map(item =>
                                                    <tr key={item.contactID}>
                                                        <td><Link to={"/crm/contact/" + item.contactID}>{item.firstName} {item.lastName}</Link></td>
                                                        <td>{item.email}</td>
                                                        <td>{item.phoneNumbers.length > 0 ? item.phoneNumbers[0].number : ""}</td>
                                                        <td>{item.isPrimary && <i className="fas fa-check"></i>}</td>
                                                        <td><button className="btn btn-sm btn-danger p-1" onClick={(e) => this.deleteContact(item, e)}><i className="fas fa-trash"></i></button></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Scrollable>
                        </div>
                    </div>
                    {this.state.customer &&
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-head-row">
                                        <div className="card-title">Customer History</div>
                                    </div>
                                </div>
                                <Scrollable id="customerHistory" height={350}>
                                    <div className="card-body">
                                        <div className="col-md-12 p-0">
                                            {this.state.customer &&
                                                <CustomerHistoryList customerId={this.state.customer.customerID} rows={50} displayInfoTooltips={true}></CustomerHistoryList>
                                            }
                                        </div>
                                    </div>
                                </Scrollable>
                                <div className="card-footer">
                                    {this.state.customer &&
                                        <React.Fragment>
                                            <Link to={"/customer/history/" + this.state.customer.customerID}>View all customer history</Link>
                                            <Link className="btn btn-primary pull-right" to={"/orders/add/" + this.state.customer.customerID}>Place Order <i className="fas fa-angle-right"></i></Link>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.customer &&
                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-head-row">
                                        <div className="card-title">Associations</div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {this.state.account &&
                                        <AccountAssociations account={this.state.account} onChange={this.handleAccountAssociationsChange}></AccountAssociations>
                                    }
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateAccountAssociations}>Update</button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="card-head-row col-md-6">
                                        <div className="card-title">Billing</div>
                                        <div className="card-tools">
                                            <button className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={() => this.openAddAddresModal("billing")}>
                                                <span className="btn-label">
                                                    <i className="fas fa-plus"></i>
                                                </span>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-head-row col-md-6">
                                        <div className="card-title">Shipping</div>
                                        <div className="card-tools">
                                            <button className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={() => this.openAddAddresModal("shipping")}>
                                                <span className="btn-label">
                                                    <i className="fas fa-plus"></i>
                                                </span>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.customer &&
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Scrollable id="billingAddresses" height={400}>
                                                    <CustomerAddressList editable={true} customer={this.state.customer} type="billing" onSelect={this.handleCustomerAddressSelect} onUpdate={this.handleCustomerAddressListUpdate}></CustomerAddressList>
                                                </Scrollable>
                                            </div>
                                            <div className="col-md-6">
                                                <Scrollable id="shippingAddresses" height={400}>
                                                    <CustomerAddressList editable={true} customer={this.state.customer} type="shipping" onSelect={this.handleCustomerAddressSelect} onUpdate={this.handleCustomerAddressListUpdate}></CustomerAddressList>
                                                </Scrollable>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            <Modal id="addBillingAddressModal" title="Add Billing Address" onOk={() => this.addCustomerAddress(true)}>
                                <CustomerAddress address={tempCustomerAddress} type="billing" onChange={this.handleNewCustomerAddressChange}></CustomerAddress>
                            </Modal>
                            <Modal id="addShippingAddressModal" title="Add Shipping Address" onOk={() => this.addCustomerAddress(true)}>
                                <CustomerAddress address={tempCustomerAddress} type="shipping" onChange={this.handleNewCustomerAddressChange}></CustomerAddress>
                            </Modal>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Notes</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addNoteModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <Scrollable id="accountNotes" height={350}>
                                <div className="card-body">
                                    {this.state.account &&
                                        <NoteList editable={true} notes={this.state.account.notes} onUpdate={this.handleNoteListUpdate}></NoteList>
                                    }
                                </div>
                            </Scrollable>
                            <Modal id="addNoteModal" title="Add Note" onOk={this.addNote}>
                                <Note onChange={this.handleNewNoteChange}></Note>
                            </Modal>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Account Callbacks</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addCallbackModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <Scrollable id="accountCallbacks" height={350}>
                                <div className="card-body">
                                    {this.state.account &&
                                        <CallbackList editable={true} callbacks={this.state.account.callbacks} onUpdate={this.handleCallbackListUpdate}></CallbackList>
                                    }
                                </div>
                            </Scrollable>
                            <Modal id="addCallbackModal" title="Add callback" onOk={this.addCallback}>
                                <Callback onChange={this.handleNewCallbackChange}></Callback>
                            </Modal>
                        </div>
                    </div>
                    {
                        this.state.customer &&
                        <div className="col-md-4">
                            <div className="card full-height">
                                <div className="card-header">
                                    <div className="card-head-row">
                                        <div className="card-title">Credit Cards</div>
                                        <div className="card-tools">
                                            <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addCreditCardModal">
                                                <span className="btn-label">
                                                    <i className="fas fa-plus"></i>
                                                </span>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Scrollable id="creditCards" height={350}>
                                    <div className="card-body">
                                        {this.state.customer &&
                                            <CustomerCreditCardList editable={true} customer={this.state.customer} onSelect={this.handleCreditCardSelect} onUpdate={this.handleCreditCardListUpdate}></CustomerCreditCardList>
                                        }
                                    </div>
                                </Scrollable>
                                <Modal id="addCreditCardModal" title="Add Credit Card" onOk={this.addCreditCard}>
                                    <CustomerCreditCard onChange={this.handleNewCreditCardChange}></CustomerCreditCard>
                                </Modal>
                            </div>
                        </div>
                    }
                </div >
            </div >
        );
    }
}

export default withRouter(AccountDetail);
