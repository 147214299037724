import React, { Component } from 'react';
import AutoShipFrequencySelect from '../../common/AutoShipFrequencySelect';
import DatePicker from "react-datepicker";
import moment from 'moment'
import Checkbox from '../../common/Checkbox';

class AutoShip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: props.autoShip ? props.autoShip.orderId : 0,
            shipFrequency: props.autoShip ? props.autoShip.shipFrequency : "NoAutoShip",
            shipDuration: props.autoShip ? props.autoShip.shipDuration : 0,
            shipStartDate: props.autoShip ? moment(props.autoShip.shipStartDate).toDate() : new Date(),
            isActive: props.autoShip? props.autoShip.isActive : false
        }

        this.handleShipFreqChange = this.handleShipFreqChange.bind(this);
        this.handleShipDurationChange = this.handleShipDurationChange.bind(this);
        this.handleShipStartDateChange = this.handleShipStartDateChange.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.notifyChange();
    }


    handleShipFreqChange(freq) {
        if (freq === "NoAutoShip") {
            this.setState({
                shipFrequency: freq,
                shipDuration: 0
            }, this.notifyChange);
        }
        else {
            this.setState({
                shipFrequency: freq
            }, this.notifyChange);
        }
    }

    handleShipDurationChange(e) {
        this.setState({
            shipDuration: e.target.value
        }, this.notifyChange);
    }

    handleShipStartDateChange(date) {
        this.setState({
            shipStartDate: date
        }, this.notifyChange);
    }

    handleIsActiveChange(isActive) {
        this.setState({
            isActive: isActive
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label>Auto Ship Duration</label>
                    <AutoShipFrequencySelect value={this.state.shipFrequency} onChange={this.handleShipFreqChange}></AutoShipFrequencySelect>
                    <input type="text" disabled={this.state.shipFreqency === "NoAutoShip"} value={this.state.shipDuration} onChange={this.handleShipDurationChange} className="form-control" />
                </div>
                <div className="form-group col-md-12">
                    <label>Auto Ship Start Date</label>
                    <DatePicker className="form-control" selected={this.state.shipStartDate} onChange={this.handleShipStartDateChange} />
                </div>
                <div className="form-group col-md-12">
                    <Checkbox label="Active" checked={this.state.isActive} onChange={this.handleIsActiveChange}></Checkbox>
                </div>
            </div>
        );
    }
}

export default AutoShip