import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import SmtpAccountSelect from '../../common/SmtpAccountSelect';

class CompanyEmailSendingAccounts extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            sendingAccounts: []
        }

        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getCompanyAccounts = this.getCompanyAccounts.bind(this);
    }

    componentDidMount() {
        this.getCompanyAccounts();
    }

    getCompanyAccounts() {
        this.http.get('/api/Admin/GetCompanyEmailSendingAccounts', null).then(data => {
            this.setState({ sendingAccounts: data });
        });
    }

    updateSendingAccount(account, e){
        console.log(account);
        console.log(e);

        account.smtpAccountId = e;

        var params = {
            sendingAccountId: account.sendingAccountId,
            smtpAccountId: account.smtpAccountId
        };

        this.http.post('/api/Admin/UpdateCompanyEmailSendingAccount', params).then(data => {
            this.getCompanyAccounts();
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Company Email Sending Accounts</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                        <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-tools">
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Company</th>
                                                <th scope="col">Account</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.sendingAccounts.map(account =>
                                                <tr key={account.sendingAccountId}>
                                                    <td>{account.companyName}</td>
                                                    <td><SmtpAccountSelect value={account.smtpAccountId} onChange={(e) => this.updateSendingAccount(account, e)}></SmtpAccountSelect></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyEmailSendingAccounts;
