import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import { Link } from "react-router-dom";
import moment from "moment";

class SmtpAccountList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            accounts: []
        }

        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getAccounts = this.getAccounts.bind(this);
    }

    componentDidMount() {
        this.getAccounts();
    }

    getAccounts() {
        this.http.get('/api/Admin/GetSmtpAccounts', null).then(data => {
            this.setState({ accounts: data });
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">SMTP Accounts</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                        <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-tools">
                                        <Link to="/admin/add-smtp-account" className="btn btn-info btn-border btn-round btn-sm mr-2">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add New
							            </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Username</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Host</th>
                                                <th scope="col">Port</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.accounts.map(account =>
                                                <tr key={account.accountId}>
                                                    <td><Link to={"/admin/smtp-accounts/" + account.accountId}>{account.username}</Link></td>
                                                    <td>{account.name}</td>
                                                    <td>{account.host}</td>
                                                    <td>{account.port}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SmtpAccountList;
