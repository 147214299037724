import React, { Component } from 'react';
import Checkbox from '../../common/Checkbox';
import CreditCardTypeSelect from '../../common/CreditCardTypeSelect';

class CustomerCreditCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            creditCardId: props.creditCard ? props.creditCard.creditCardID : 0,
            nameOnCard: props.creditCard ? props.creditCard.nameOnCard : "",
            cardTypeId: props.creditCard ? props.creditCard.cardType.creditCardTypeID : 0,
            creditCardNumber: props.creditCard ? props.creditCard.creditCardNumber : "",
            cvvCode: "",
            expirationMonth: props.creditCard ? props.creditCard.expirationMonth : new Date().getMonth() + 1,
            expirationYear: props.creditCard ? props.creditCard.expirationYear : new Date().getFullYear(),
            primary: props.creditCard ? props.creditCard.primary : false,
        }

        this.handleNameOnCardChange = this.handleNameOnCardChange.bind(this);
        this.handleCardTypeChange = this.handleCardTypeChange.bind(this);
        this.handleCreditCardNumberChange = this.handleCreditCardNumberChange.bind(this);
        this.handleCvvCodeChange = this.handleCvvCodeChange.bind(this);
        this.handleExpirationMonthChange = this.handleExpirationMonthChange.bind(this);
        this.handleExpirationYearChange = this.handleExpirationYearChange.bind(this);
        this.handlePrimaryChange = this.handlePrimaryChange.bind(this);

        this.notifyChange();
    }

    handleNameOnCardChange(e) {
        this.setState({
            nameOnCard: e.target.value
        }, this.notifyChange);
    }

    handleCardTypeChange(value) {
        this.setState({
            cardTypeId: value
        }, this.notifyChange);
    }

    handleCreditCardNumberChange(e) {
        this.setState({
            creditCardNumber: e.target.value
        }, this.notifyChange);
    }

    handleCvvCodeChange(e) {
        this.setState({
            cvvCode: e.target.value
        }, this.notifyChange);
    }

    handleExpirationMonthChange(e) {
        this.setState({
            expirationMonth: e.target.value
        }, this.notifyChange);
    }

    handleExpirationYearChange(e) {
        this.setState({
            expirationYear: e.target.value
        }, this.notifyChange);
    }

    handlePrimaryChange(checked) {
        this.setState({
            primary: checked
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        var years = [];
        var thisYear = new Date().getFullYear();
        for(var i = -10; i < 10; i++){
            years.push(thisYear  + i);
        }
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label>Name On Card</label>
                        <input type="text" className="form-control" value={this.state.nameOnCard} onChange={this.handleNameOnCardChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Card Type</label>
                        <CreditCardTypeSelect value={this.state.cardTypeId} onChange={this.handleCardTypeChange}></CreditCardTypeSelect>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Card Number</label>
                        <input type="text" className="form-control" value={this.state.creditCardNumber} onChange={this.handleCreditCardNumberChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>CVV Code</label>
                        <input type="text" className="form-control" value={this.state.cvvCode} onChange={this.handleCvvCodeChange}></input>
                        {this.props.creditCard && 
                        <small>CVV exists but not displayed for security purposes</small>
                        }
                    </div>
                    <div className="form-group col-md-12">
                        <label>Expiration</label>
                        <div className="form-group form-inline p-0">
                            <select className="form-control col-md-6" value={this.state.expirationMonth} onChange={this.handleExpirationMonthChange}>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                            </select>
                            <select className="form-control col-md-6" value={this.state.expirationYear} onChange={this.handleExpirationYearChange}>
                            {years.map(year => 
                                <option key={year}>{year}</option>
                            )}
                            </select>
                        </div>
                    </div>
                    {!this.props.creditCard && 
                        <div className="form-group col-md-12">
                            <Checkbox label="Default?" checked={this.state.primary} onChange={this.handlePrimaryChange}></Checkbox>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default CustomerCreditCard