export class ScaleService {

    SCALE_PRODUCT_ID = 61440; // PS 60
    SCALE_VENDOR_ID = 3768; // Mettler Toledo
    connected = false;
    lastReading = null;

    init = async () => {
        let scale = await this.getScale();
        if (scale) {
            if (!scale.opened) {
                await scale.open();
            }
            this.connected = true;

            scale.oninputreport = (event) => {
                const { data, device, reportId } = event;
                if (reportId === 3) {
                    // Scale Data Input Report
                    const byte0 = data.getInt8(0); // scale status
                    const byte1 = data.getInt8(1); // scale unit
                    const byte2 = data.getInt8(2); // data scaling
                    const byte3 = data.getUint8(3); // weight LSB
                    const byte4 = data.getUint8(4); // weight MSB

                    const status = this.getStatus(byte0);
                    const unit = this.getUnit(byte1);
                    const weight = ((byte4 * 256) + byte3) * Math.pow(10, byte2);

                    if (this.onWeightChange) {
                        if (this.lastReading === null || this.lastReading.status !== status || this.lastReading.unit !== unit.name || this.lastReading.value !== weight) {
                            const reading = {
                                status: status,
                                unit: unit.name,
                                value: weight,
                                lbs: weight * unit.toPound
                            };
                            console.log("onWeightChange");
                            this.onWeightChange(reading);
                            this.lastReading = reading;
                        }
                    }
                }
            }
        }
    }

    connect = async () => {
        let scale = await this.getScale();
        if (!scale) {
            // request access to device
            await navigator.hid.requestDevice({
                filters: [
                    {
                        vendorId: this.SCALE_VENDOR_ID,
                        productId: this.SCALE_PRODUCT_ID
                    }
                ]
            });

            scale = await this.getScale();
        }

        if (scale) {
            await this.init();
        }
    }

    disconnect = async () => {
        const scale = await this.getScale();
        if (scale) {
            try {
                if (scale.opened) {
                    await scale.close();
                }

                await scale.forget();
            }
            catch {
            }
            this.scale = null;
        }
        this.connected = false;
    }

    getScale = async () => {
        let devices = await navigator.hid.getDevices();
        let scale = devices.find((d) => (d.vendorId === this.SCALE_VENDOR_ID && d.productId === this.SCALE_PRODUCT_ID));
        return scale
    }

    getStatus = (status) => {
        const statuses = {
            "1": "fault",
            "2": "stable_zero",
            "3": "in_motion",
            "4": "stable",
            "5": "under_zero",
            "6": "over_limit",
            "7": "need_cal",
            "8": "need_zero",
        }

        return statuses[status];
    }

    getUnit = (unit) => {
        const units = {
            "1": {
                name: "milligram",
                toPound: 2.20462262185 * Math.pow(10, -6)
            },
            "2": {
                name: "gram",
                toPound: 2.20462262185 * Math.pow(10, -3)
            },
            "3": {
                name: "kilogram",
                toPound: 2.20462262185
            },
            "4": {
                name: "carat",
                toPound: 0.000440925
            },
            "5": {
                name: "tael",
                toPound: 0.0833333 * Math.pow(10, -6)
            },
            "6": {
                name: "grain",
                toPound: 0.000142857
            },
            "7": {
                name: "pennyweight",
                toPound: 0.00342857
            },
            "8": {
                name: "mton",
                toPound: 2.20462262185 * Math.pow(10, 3)
            },
            "9": {
                name: "aton",
                toPound: 2.20462262185 * Math.pow(10, 3)
            },
            "10": {
                name: "ounce",
                toPound: 0.0625
            },
            "11": {
                name: "pound",
                toPound: 1
            },
            "12": {
                name: "lbs",
                toPound: 1
            },
        };

        return units[unit];
    }
}