import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { UtilsService } from '../../services/UtilsService';
import { HttpRequestService } from '../../services/HttpRequestService';
class Login extends Component {
    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            username: "",
            password: "",
            mode: "normal",
            loggedIn: false
        };
        this.utils = new UtilsService();
        this.http = new HttpRequestService();
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.login = this.login.bind(this);
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        })
    }

    handleUsernameChange(e) {
        this.setState({
            username: e.target.value
        })
    }

    handleKeyUp(e) {
        if (e.keyCode === 13) {
            this.login();
        }
    }

    login() {
        // Token endpoint expects application/x-www-form-urlencoded content
        var params = new URLSearchParams();
        params.append("grant_type", "password");
        params.append("username", this.state.username);
        params.append("password", this.state.password);
        localStorage.setItem("loginType", "password");

        localStorage.removeItem("auth-token");
        this.http.setLoading(true);
        axios.post(this.http.HOST + "/Token", params).then(data => {
            this.http.setLoading(false);
            localStorage.setItem("auth-token", data.data.access_token);
            localStorage.setItem("lastRequestTime", new Date().getTime());
            localStorage.setItem("username", this.state.username);
            this.props.onLoggedIn();
        }).catch(() => {
            this.http.setLoading(false);
            this.utils.alert("Unable to log in. Please check your username and password.");
        }
        );
    }

    render() {
        return (
            <div className="card">
                <div className="card-header card-primary">
                    <div className="card-title">
                        Login to PowerD <i className="fas fa-bolt"></i>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group col-md-12">
                                <label>Username</label>
                                <input type="text" value={this.state.username} onChange={this.handleUsernameChange} className="form-control" />
                            </div>
                            <div className="form-group col-md-12">
                                <label>Password</label>
                                <input type="password" value={this.state.password} onChange={this.handlePasswordChange} className="form-control" onKeyUp={this.handleKeyUp} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.login}>Log In</button>
                </div>
            </div>
        );
    }
}

export default withRouter(Login)
