import React, { Component } from 'react';
import Async from 'react-select/async';
import { HttpRequestService } from '../services/HttpRequestService';

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.handleChange = this.handleChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }

    handleChange(e) {
        this.props.onChange(e);
    }

    loadOptions(keyword) {
        var promise = new Promise((resolve, reject) => {
            this.debounce(() => {
                this.http.getSilent(this.props.endpoint, { keyword: keyword }).then(data => {
                    resolve(data.map(a => { return { label: a.name, value: a.accountID } }));
                });
            }, 1000, resolve)();
        })
        return promise;
    }

    debounce(func, delay, reject) {
        return () => {
            const context = this
            const args = arguments
            clearTimeout(this.inDebounce)
            if (this.reject) {
                this.reject();
            }
            this.inDebounce = setTimeout(() => func.apply(context, args), delay);
            this.reject = reject;
        }
    }

    render() {
        return (
            <Async value={this.props.value} placeholder="Search..."
                isClearable={true} openMenuOnClick={false}
                backspaceRemovesValue={true} escapeClearsValue={true}
                onChange={this.handleChange}
                loadOptions={this.loadOptions}></Async>
        );
    }
}

export default AutoComplete;