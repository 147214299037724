import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import CategoryInformation from './CategoryInformation';
import { UtilsService } from '../../services/UtilsService';

class AddCategory extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleCategoryInformationChange = this.handleCategoryInformationChange.bind(this);
        this.createCategory = this.createCategory.bind(this);
    }

    handleCategoryInformationChange(categoryInformation) {
        this.categoryInformation = categoryInformation;
    }

    createCategory() {
        var params = this.categoryInformation;
        params.userDefinedFields = this.utils.prepareUserDefinedFieldParams(this.categoryInformation.userDefinedFields);
        this.http.post('/api/Admin/CreateCategory', params).then(data => {
            this.props.history.push('/admin/categories/' + data.categoryID);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Create Category</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Category Information</div>
                            </div>
                            <div className="card-body">
                                <CategoryInformation onChange={this.handleCategoryInformationChange}></CategoryInformation>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createCategory}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddCategory)