import React, { Component } from 'react';
import { Link } from "react-router-dom";

class CustomerInfoCard extends Component {
    render() {
        return (
            <div className="card full-height">
                <div className="card-header">
                    <div className="card-title">Customer Information</div>
                </div>
                <div className="card-body">
                    {this.props.customer && this.props.customer.onHold &&
                        <div className="d-flex">
                            <div className="col pt-1">
                                <h6 className="fw-bold mb-1 text-danger">** CUSTOMER ON HOLD **</h6>
                            </div>
                        </div>
                    }
                    <div className="d-flex">
                        <div className="col pt-1">
                            <h6 className="fw-bold mb-1">CUSTOMER NUMBER</h6>
                            <small className="text-muted"><Link to={"/customer/" + this.props.customer.customerID}>{this.props.customer.customerNumber}</Link></small>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="d-flex">
                        <div className="col pt-1">
                            <h6 className="fw-bold mb-1">CUSTOMER NAME</h6>
                            <small className="text-muted">{this.props.customer.companyName}</small>
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="d-flex">
                        <div className="col pt-1">
                            <h6 className="fw-bold mb-1">CUSTOMER EMAIL</h6>
                            <small className="text-muted">{this.props.customer.email}</small>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerInfoCard