import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from '../../common/Pagination'
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import Modal from '../../common/Modal';
import AutoShip from './AutoShip';

class AutoShippedOrderList extends Component {

    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        var initialState = {
            criteria: "company_name",
            keyword: "",
            activeOnly: true,
            orders: [],
            editedAutoShip: null,
            pageCount: 0,
            pageIndex: 0
        }
        this.state = this.utils.prepareSessionState(initialState, "params.auto-shipped-order-list");
        this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleActiveOnlyChange = this.handleActiveOnlyChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.pageSize = 100;
        this.getOrders = this.getOrders.bind(this);
        this.displayAutoShip = this.displayAutoShip.bind(this);
        this.handleEditedAutoShipChange = this.handleEditedAutoShipChange.bind(this);
        this.handleEditAutoShip = this.handleEditAutoShip.bind(this);
    }

    componentDidMount() {
        this.getOrders(this.state.pageIndex);
    }

    handleCriteriaChange(e) {
        this.setState({ criteria: e.target.value });
    }

    handleKeywordChange(e) {
        this.setState({ keyword: e.target.value });
    }

    handleActiveOnlyChange(checked) {
        this.setState({ activeOnly: checked });
    }

    handlePageChange(page) {
        this.getOrders(page);
    }

    getOrders(page, e) {
        if(!!e){
            e.preventDefault();
        }
        this.setState({ pageIndex: page });
        var params = {
            criteria: this.state.criteria,
            keyword: this.state.keyword,
            activeOnly: this.state.activeOnly,
            pageSize: this.pageSize,
            pageIndex: page
        }
        sessionStorage.setItem("params.auto-shipped-order-list", JSON.stringify(params));
        this.http.get('/api/Orders/SearchAutoShippedOrders', params).then(data => {
            this.setState({ orders: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    displayAutoShip(order, e){
        var autoShip = {
            orderId: order.orderID,
            shipFrequency: order.shipFrequency,
            shipDuration: order.shipDuration,
            shipStartDate: order.shipStartDate,
            isActive: order.autoShipIsActive
        }
        this.setState({
            editedAutoShip : autoShip
        }, () => {
            window.$("#editAutoShipModal").modal("show");
        })
    }

    handleEditedAutoShipChange(autoShip){
        this.setState({
            editedAutoShip: autoShip
        });
    }

    handleEditAutoShip(){
        this.http.get('/api/Orders/UpdateAutoShip', this.state.editedAutoShip).then(data => {
            window.$("#editAutoShipModal").modal("hide");
            this.getOrders(this.state.pageIndex);
        });
    }    

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Auto Shipped Orders</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                            <form onSubmit={(e) => this.getOrders(0, e)}>
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label>Criteria</label>
                                        <select className="form-control" value={this.state.criteria} onChange={this.handleCriteriaChange}>
                                            <option value="company_name">Company</option>
                                            <option value="customer_number">Customer Number</option>
                                            <option value="full_name">Name</option>
                                            <option value="phone_number">Phone Number</option>
                                            <option value="zip_code">Zip Code</option>
                                            <option value="email">Email</option>
                                            <option value="po">Purchase Order #</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Keyword</label>
                                        <input type="text" className="form-control" value={this.state.keyword} onChange={this.handleKeywordChange}></input>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label>&nbsp;</label>
                                        <Checkbox label="Active Only" checked={this.state.activeOnly} onChange={this.handleActiveOnlyChange}></Checkbox>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Customer #</th>
                                                <th scope="col">Order #</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Ship Frequency</th>
                                                <th scope="col">Ship Starting</th>
                                                <th scope="col">Active</th>
                                                <th scope="col">Valid</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders.map((order) =>
                                                <tr key={order.orderID}>
                                                    <td><Link to={"/customer/" + order.customerID}>{order.customerNumber}</Link></td>
                                                    <td><Link to={"/order/" + order.orderID}>{order.orderNumber}</Link></td>
                                                    <td>{order.companyName}</td>
                                                    <td>{order.fullName}</td>
                                                    <td>{order.shipDuration}</td>
                                                    <td>{order.shipFrequency}</td>
                                                    <td>{order.shipStartDate}</td>
                                                    <td>{order.autoShipIsActive.toString()}</td>
                                                    <td>{order.isValid.toString()}</td>
                                                    <td><button className="btn btn-sm btn-info" onClick={e => this.displayAutoShip(order, e)}>Edit</button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.orders.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal id="editAutoShipModal" title="Edit Auto Ship" onOk={this.handleEditAutoShip}>
                    <AutoShip autoShip={this.state.editedAutoShip} onChange={this.handleEditedAutoShipChange}></AutoShip>
                </Modal>
            </div>
        );
    }
}

export default AutoShippedOrderList;
