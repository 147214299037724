import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class AutoShipFrequencySelect extends Component {

    constructor(props) {
        super(props);
        this.state = { items: [] };
        this.getItems = this.getItems.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getItems();
    }

    componentWillUnmount() {

    }

    getItems() {
        this.http.get('/api/Orders/GetAutoShipFrequencies', null).then(data=>{
            this.setState({ items: data });
        });
    }

    handleChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {
        var options = this.state.items.map((item) =>
            <option key={item} value={item}>{item}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {options}
            </select>
        );
    }
}
export default AutoShipFrequencySelect