import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import moment from 'moment'
import Note from './Note';

class NoteList extends Component {

    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleEditedNoteChange = this.handleEditedNoteChange.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.editNote = this.editNote.bind(this);
    }

    handleEditedNoteChange(card) {
        this.editedNote = card;
    }

    deleteNote(note, e) {
        var message = "This note will be deleted. Do you want to continue?"
        this.utils.confirm(message, () => {
            var params = {
                id: note.noteID
            }
            this.http.post('/api/Crm/RemoveNote', params).then(data => {
                if (this.props.onUpdate) {
                    this.props.onUpdate(data);
                }
            });
        })
    }

    editNote() {
        var model = this.editedNote;
        this.http.post("/api/Crm/EditNote", model).then(data => {
            window.$("#editNoteModal" + this.editedNote.noteID).modal("hide");
            if (this.props.onUpdate) {
                this.props.onUpdate(data);
            }
        });
    }

    render() {
        var notes = this.props.notes;
        return (
            <React.Fragment>
                {notes.map(note =>
                    <div key={note.noteID} className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="col pt-1">
                                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: note.message }}>
                                    </p>
                                    <p className="text-muted pull-right mb-0">
                                        {note.enteredBy} {moment(note.timestamp).format('l')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            {this.props.editable &&
                                <React.Fragment>
                                    <button className="btn btn-info btn-border btn-round btn-sm mr-2 pull-right" data-toggle="modal" data-target={"#editNoteModal" + note.noteID}>
                                        <span className="btn-label">
                                            <i className="fas fa-pen"></i>
                                        </span>
                                        Edit
							        </button>
                                    <button className="btn btn-info btn-border btn-round btn-sm mr-2 pull-right" onClick={e => this.deleteNote(note, e)}>
                                        <span className="btn-label">
                                            <i className="fas fa-trash"></i>
                                        </span>
                                        Delete
							        </button>
                                </React.Fragment>
                            }
                        </div>
                        <Modal id={"editNoteModal" + note.noteID} title="Edit Note" onOk={this.editNote}>
                            <Note onChange={this.handleEditedNoteChange} note={note}></Note>
                        </Modal>
                     </div>
                    
                )}
            </React.Fragment>
        )
    }
}

export default NoteList