import React, { Component } from 'react';

class FileInput extends Component {

    constructor(props) {
        super(props);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.ref = React.createRef();
        this.state = {
            fileName: ""
        }
    }

    selectFile() {
        this.ref.current.click();
    }

    handleFileChange(e) {
        this.setState({ fileName: e.target.files[0].name });
        this.props.onChange(e.target.files[0]);
    }


    render() {
        return (
            <React.Fragment>
                {this.state.fileName !== "" &&
                    <span>{this.state.fileName} </span>
                }
                <button className="btn" onClick={this.selectFile}>{this.props.label}</button>
                <input type="file" ref={this.ref} onChange={this.handleFileChange} style={{ display: "none" }}></input>
            </React.Fragment>
        );
    }
}
export default FileInput