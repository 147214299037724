import React, { Component } from 'react';
import AccountTypesSelect from '../../common/AccountTypesSelect';
import CompanySelect from '../../common/CompanySelect';
import CurrencySelect from '../../common/CurrencySelect';
import StandardCommentSelect from '../../common/StandardCommentSelect';
import PaymentTypeSelect from '../../common/PaymentTypeSelect';
import AutoShipFrequencySelect from '../../common/AutoShipFrequencySelect';
import DatePicker from "react-datepicker";
import moment from 'moment'

class OrderInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountTypeId: props.accountType ? props.accountType : 8,
            orderNote: props.orderNote ? props.orderNote: "",
            standardCommentId: props.standardComment ? props.standardComment : -1,
            paymentTypeId: props.paymentType ? props.paymentType : 1,
            customerPO: props.customerPO ? props.customerPO : "",
            companyId: props.company ? props.company : 2,
            currencyId: props.currency ? props.currency : 1,
            autoShipFreq: props.autoShip? props.autoShip.shipFrequency : "NoAutoShip",
            autoShipDuration: props.autoShip? props.autoShip.duration : 0,
            autoShipStartDate: props.autoShip? moment(props.autoShip.shipStartDate).toDate(): new Date()
        }

        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handleOrderNoteChange = this.handleOrderNoteChange.bind(this);
        this.handleStandardCommentChange = this.handleStandardCommentChange.bind(this);
        this.handlePaymentTypeChange = this.handlePaymentTypeChange.bind(this);
        this.handleCustomerPOChange = this.handleCustomerPOChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleAutoShipFreqChange = this.handleAutoShipFreqChange.bind(this);
        this.handleAutoShipDurationChange = this.handleAutoShipDurationChange.bind(this);
        this.handleAutoShipStartDateChange = this.handleAutoShipStartDateChange.bind(this);
        this.notifyChange();
    }

    handleAccountTypeChange(accountType) {
        this.setState({
            accountTypeId: accountType
        }, this.notifyChange);
    }

    handleOrderNoteChange(e) {
        this.setState({
            orderNote: e.target.value
        },this.notifyChange)
    }

    handleStandardCommentChange(standardComment) {
        this.setState({
            standardCommentId: standardComment
        }, this.notifyChange);
    }

    handlePaymentTypeChange(paymentType) {
        this.setState({
            paymentTypeId: paymentType
        }, this.notifyChange);
    }

    handleCustomerPOChange(e) {
        this.setState({
            customerPO: e.target.value
        }, this.notifyChange);
    }

    handleCompanyChange(company) {
        this.setState({
            companyId: company
        }, this.notifyChange);
    }

    handleCurrencyChange(currency) {
        this.setState({
            currencyId: currency
        }, this.notifyChange);
    }

    handleAutoShipFreqChange(freq){
        if(freq === "NoAutoShip"){
            this.setState({
                autoShipFreq: freq,
                autoShipDuration: 0
            }, this.notifyChange);
        }
        else{
            this.setState({
                autoShipFreq: freq
            }, this.notifyChange);
        }        
    }

    handleAutoShipDurationChange(e){
        this.setState({
            autoShipDuration: e.target.value
        }, this.notifyChange);
    }

    handleAutoShipStartDateChange(date){
        this.setState({
            autoShipStartDate: date
        }, this.notifyChange);
    }

    notifyChange(){
        if(this.props.onChange){
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group col-md-12">
                            <label>Account Type</label>
                            <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange}></AccountTypesSelect>
                        </div>
                        <div className="form-group col-md-12">
                            <label>Order Note</label>
                            <textarea rows="4" className="form-control" value={this.state.orderNote} onChange={this.handleOrderNoteChange}></textarea>
                        </div>
                        <div className="form-group col-md-12">
                            <label>Standard Comment</label>
                            <StandardCommentSelect value={this.state.standardCommentId} onChange={this.handleStandardCommentChange}></StandardCommentSelect>
                        </div>
                        <div className="form-group col-md-12">
                            <label>Payment Type</label>
                            <PaymentTypeSelect value={this.state.paymentTypeId} onChange={this.handlePaymentTypeChange}></PaymentTypeSelect>
                        </div>
                        <div className="form-group col-md-12">
                            <label>Customer PO</label>
                            <input type="text" value={this.state.customerPO} onChange={this.handleCustomerPOChange} className="form-control" />
                        </div>
                        <div className="form-group col-md-12">
                            <label>Company</label>
                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange}></CompanySelect>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group col-md-12">
                            <label>Auto Ship Duration</label>
                            <AutoShipFrequencySelect value={this.state.autoShipFreq} onChange={this.handleAutoShipFreqChange}></AutoShipFrequencySelect>
                            <input type="text" disabled={this.state.autoShipFreq === "NoAutoShip"} value={this.state.autoShipDuration} onChange={this.handleAutoShipDurationChange} className="form-control" />
                        </div>
                        <div className="form-group col-md-12">
                            <label>Auto Ship Starting Date</label>
                            <DatePicker className="form-control" selected={this.state.autoShipStartDate} onChange={this.handleAutoShipStartDateChange} />
                        </div>
                        <div className="form-group col-md-12">
                            <label>Currency</label>
                            <CurrencySelect value={this.state.currencyId} onChange={this.handleCurrencyChange}></CurrencySelect>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default OrderInformation