import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class ProductSelect extends Component {

    constructor(props) {
        super(props);
        this.state = { products: [] };
        this.getProducts = this.getProducts.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getProducts();
    }

    componentDidUpdate(prevProps) {
        if (this.props.companyId !== prevProps.companyId || this.props.productTypeId !== prevProps.productTypeId) {
            this.getProducts();
        }
    }

    componentWillUnmount() {
    }

    getProducts() {
        var params = {
            companyId: this.props.companyId,
            productTypeId: this.props.productTypeId
        }
        if (params.companyId > 0 && params.productTypeId > 0) {
            this.http.get('/api/Orders/GetProducts', params).then(data => {
                this.setState({ products: data });
                if(data.length > 0){
                    if(!this.props.value){
                        this.props.onChange(data[0]);
                    }
                    else{
                        var selectedProduct = data.find(p=>p.productID === this.props.value);
                        this.props.onChange(selectedProduct);
                    }
                }
            });
        }
        else if(this.props.allProducts){
            this.http.get('/api/Inventory/GetAllProducts', params).then(data => {
                this.setState({ products: data });
                if(!this.props.value){
                    this.props.onChange(data[0]);
                }
                else{
                    var selectedProduct = data.find(p=>p.productID === this.props.value);
                    this.props.onChange(selectedProduct);
                }
            });
        }
    }

    handleChange(e) {
        var product = this.state.products.find(p => p.productID === parseInt(e.target.value));
        this.props.onChange(product);
    }

    render() {
        var options = this.state.products.map((item) =>
            <option key={item.productID} value={item.productID}>{item.name}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {this.props.hasAllOption === true &&
                    <option value="-1">-- ALL --</option>
                }
                {options}
            </select>
        );
    }
}
export default ProductSelect