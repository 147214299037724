import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import ContactInformation from './ContactInformation';
import { UtilsService } from '../../services/UtilsService';

class AddContact extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            contactInformation: {},
        };
        this.handleContactInfoChange = this.handleContactInfoChange.bind(this);
        this.createContact = this.createContact.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.accountId) {
            this.getAccount();
        }
    }

    getAccount() {
        var params = {
            id: this.props.match.params.accountId
        }
        this.http.get('/api/Crm/GetAccount', params).then(data => {
            this.setState({
                contact: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    accountID: data.accountID,
                    accountName: data.name,
                    userDefinedFields: []
                }
            })
        });
    }

    handleContactInfoChange(value) {
        this.setState({
            contactInformation: value
        })
    }

    createContact() {
        // prepare data for api call
        var userDefinedFields = this.utils.prepareUserDefinedFieldParams(this.state.contactInformation.userDefinedFields);

        var data = {
            firstName: this.state.contactInformation.firstName,
            lastName: this.state.contactInformation.lastName,
            email: this.state.contactInformation.email,
            userDefinedFields: userDefinedFields,
            accountID: this.state.contactInformation.account ? this.state.contactInformation.account.value : 0,
        }
        this.http.post('/api/Crm/SaveContact', data).then(data => {
            this.props.history.push('/crm/contact/' + data);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Add Contact</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-title">General Contact Information</div>
                            </div>
                            <div className="card-body">
                                {this.props.match.params.accountId && this.state.contact &&
                                    <ContactInformation contact={this.state.contact} onChange={this.handleContactInfoChange}></ContactInformation>
                                }
                                {!this.props.match.params.accountId &&
                                    <ContactInformation onChange={this.handleContactInfoChange}></ContactInformation>
                                }
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createContact}>Create Contact</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddContact;
