import React, { Component } from 'react';

class IsResidentialSelect extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {
        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                <option value={1}>Residential</option>
                <option value={0}>Business</option>
            </select>
        );
    }
}
export default IsResidentialSelect