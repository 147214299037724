import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import CompanySelect from '../../common/CompanySelect';
import ProductTypeSelect from '../../common/ProductTypeSelect';

class ProductList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            companyId: -1,
            activeOnly: true,
            inventoryTypeId: -1,
            products: []
        }

        this.paramsKey = props.paramsKey ? props.paramsKey : "params.product-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleInventoryTypeChange = this.handleInventoryTypeChange.bind(this);
        this.handleActiveOnlyChange = this.handleActiveOnlyChange.bind(this);
        this.getProducts = this.getProducts.bind(this);
    }

    componentDidMount() {
        this.getProducts();
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleInventoryTypeChange(inventoryTypeId) {
        this.setState({ inventoryTypeId: inventoryTypeId });
    }

    handleActiveOnlyChange(checked) {
        this.setState({ activeOnly: checked });
    }

    getProducts(e) {
        if (!!e) {
            e.preventDefault();
        }
        var params = {
            companyId: this.state.companyId,
            inventoryTypeId: this.state.inventoryTypeId,
            activeOnly: this.state.activeOnly,
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
        this.http.get('/api/Inventory/SearchProducts', params).then(data => {
            this.setState({ products: data });
        });
    }

    deleteProduct(product) {
        var message = "This product will be deleted. Do you want to continue?"
        this.utils.confirm(message, () => {
            var params = {
                productId: product.productID
            }
            this.http.get('/api/Inventory/DeleteProduct', params).then(data => {
                this.getProducts();
            });
        })
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Inventory List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={e => this.getProducts(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label>Company</label>
                                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange} hasAllOption={true}></CompanySelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Inventory Type</label>
                                            <ProductTypeSelect value={this.state.inventoryTypeId} onChange={this.handleInventoryTypeChange} hasAllOption={true}></ProductTypeSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>&nbsp;</label>
                                            <Checkbox label="Active Only" checked={this.state.activeOnly} onChange={this.handleActiveOnlyChange}></Checkbox>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Inv #</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Wholesale</th>
                                                <th scope="col">MSRP</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.products.map((item) =>
                                                <tr key={item.productID}>
                                                    <td><Link to={"/inventory/product/" + item.productID}>{item.productNumber}</Link></td>
                                                    <td>{item.name}</td>
                                                    <td>{item.typeDescription}</td>
                                                    <td>{this.utils.toCurrency(item.wholesalePrice)}</td>
                                                    <td>{this.utils.toCurrency(item.retailPrice)}</td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={e => this.deleteProduct(item)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductList;
