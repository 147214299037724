export class SharedState {
    static values = {};

    static subscribers = new Map();

    static getValue(key) {
        return SharedState.values[key];
    }

    static setValue(key, value) {
        SharedState.values[key] = value;
        SharedState.notifySubscribers(key, value);
    }

    static subscribe(key, cb) {
        const cbs = SharedState.subscribers.get(key);
        if (cbs) {
            cbs.push(cb);
            SharedState.subscribers.set(key, cbs);
        }
        else {
            SharedState.subscribers.set(key, [cb]);
        }
    }

    static notifySubscribers(key, value) {
        const cbs = SharedState.subscribers.get(key);
        if (cbs) {
            for (const cb of cbs) {
                cb(key, value);
            }
        }
    }
}
