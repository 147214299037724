import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Report from '../../common/Report';
import CompanySelect from '../../common/CompanySelect';

class SalesTaxesReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            beginDate: moment(new Date()).subtract(1, 'month').toDate(),
            endDate: moment(new Date()).toDate(),
            period: "this_month",
            report: null,
            companyId: -1,
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.paramsKey = "params.salex-taxes-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
    }

    componentDidMount() {
        this.getReport();
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handlePeriodChange(e) {
        this.setState({ period: e.target.value });
    }

    getPrintPDFUrl(type, order) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + order.orderID + "&OrderNumber=" + order.orderNumber;
    }

    getReport(e) {
        if (!!e) {
            e.preventDefault();
        }
        var params = {
            companyId: this.state.companyId,
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            period: this.state.period,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Report/GetSalesTaxesReport', params).then(data => {
            this.setState({ report: data });
        });
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Sales Taxes Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={e => this.getReport(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="orderStatus">Company</label>
                                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange} hasAllOption={true}></CompanySelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Select a Period</label>
                                            <select className="form-control" value={this.state.period} onChange={this.handlePeriodChange}>
                                                <option value="this_month">This Month</option>
                                                <option value="last_month">Last Month</option>
                                                <option value="this_year">This Year</option>
                                                <option value="last_year">Last Year</option>
                                                <option value="custom">Custom Date Range</option>
                                            </select>
                                        </div>
                                        {this.state.period === "custom" &&
                                            <React.Fragment>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="beginDate">Invoices From</label>
                                                    <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="endDate">Invoices To</label>
                                                    <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                                </div>
                                            </React.Fragment>
                                        }
                                        <div className="form-group col-md-3">
                                            <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="SalesTaxesReport" hasData={this.state.report.items && this.state.report.items.length > 0} title="Sales Taxes Report" description={this.state.report.description}>
                                <div className="form-row">
                                    <table className="table table-striped mt-1 report-table">
                                        {this.state.report.items.map((group) =>
                                            <React.Fragment key={group.name}>
                                                <thead>
                                                    <tr className="bg-primary reportHeader">
                                                        <th colSpan={11}>{group.name}</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("OrderNumber")}>INV #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("FulfillDate")}>INV DATE</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("CustomerNumber")}>CUST #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("CompanyName")}>CUSTOMER</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("SubTotal")}>SALES</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("ShipCost")}>S/H</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("Total")}>INV TOTAL</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("StateTax")}>TAX</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("StateAbbreviation")}>STATE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {group.items && group.items.map(item =>
                                                        <tr key={item.orderID}>
                                                            <td><a href={this.getPrintPDFUrl("Invoice", item)} target="_blank" rel="noopener noreferrer">{item.orderNumber}</a></td>
                                                            <td>{moment(item.fulfillDate).format('l')}</td>
                                                            <td><Link to={"/customer/" + item.customerID}>{item.customerNumber}</Link></td>
                                                            <td>{item.companyName}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.subTotal)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.shipCost)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.total)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.stateTax)}</td>
                                                            <td>{item.stateAbbreviation}</td>
                                                        </tr>
                                                    )}
                                                    <tr className="bg-light reportFooter">
                                                        <th colSpan={3}>TOTAL # of INVOICES</th>
                                                        <th>{group.items.length}</th>
                                                        <th className="currency">{this.utils.toCurrency(group.sales)}</th>
                                                        <th className="currency">{this.utils.toCurrency(group.shipping)}</th>
                                                        <th className="currency">{this.utils.toCurrency(group.total)}</th>
                                                        <th className="currency">{this.utils.toCurrency(group.tax)}</th>
                                                        <th></th>
                                                    </tr>
                                                </tbody>
                                            </React.Fragment>
                                        )}
                                        <tbody>
                                            <tr className="bg-dark reportFooter">
                                                <th colSpan={3}>TOTAL # of INVOICES</th>
                                                <th>{this.state.report.itemCount}</th>
                                                <th className="currency">{this.utils.toCurrency(this.state.report.sales)}</th>
                                                <th className="currency">{this.utils.toCurrency(this.state.report.shipping)}</th>
                                                <th className="currency">{this.utils.toCurrency(this.state.report.total)}</th>
                                                <th className="currency">{this.utils.toCurrency(this.state.report.tax)}</th>
                                                <th></th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default SalesTaxesReport;
