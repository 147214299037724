import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import CustomerTypeInformation from './CustomerTypeInformation';

class AddCustomerType extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
        this.createCustomerType = this.createCustomerType.bind(this);
    }

    handleCustomerTypeChange(customerType) {
        this.customerType = customerType;
    }

    createCustomerType() {
        var params = this.customerType;
        this.http.post('/api/Admin/CreateCustomerType', params).then(data => {
            this.props.history.push('/admin/customer-type/' + data.customerTypeID);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Create Customer Type</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Customer Type Information</div>
                            </div>
                            <div className="card-body">
                                <CustomerTypeInformation onChange={this.handleCustomerTypeChange}></CustomerTypeInformation>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createCustomerType}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddCustomerType)