import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment'

class DateRange extends Component {

    constructor(props) {
        super(props);

        var beginDate = this.props.value ? moment(this.props.value.beginDate).toDate() : null;
        var endDate = this.props.value ? moment(this.props.value.endDate).toDate() : null;
        this.state = {
            beginDate: beginDate,
            endDate: endDate
        }

        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.notifyChange = this.notifyChange.bind(this);
    }

    handleBeginDateChange(e) {
        this.setState({
            beginDate: e
        }, this.notifyChange);
    }

    handleEndDateChange(e) {
        this.setState({
            endDate: e
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange({
                beginDate: moment(this.state.beginDate).format('L'),
                endDate: moment(this.state.endDate).format('L'),
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6 pr-0 mr-o">
                        <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                    </div>
                    <div className="col-md-6 pl-0 ml-0">
                        <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default DateRange