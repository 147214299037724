import React, { Component } from 'react';

class Scrollable extends Component {

    componentDidMount() {
        window.$("#" + this.props.id).scrollbar();
    }

    render() {
        return (
            <div id={this.props.id} className="scrollbar-inner">
                <div style={{ height: this.props.height }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
export default Scrollable