import React, { Component } from 'react';

class Accordion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: this.props.expanded ? true : false
        }
    }

    componentDidMount() {
        const self = this;
        window.$("#" + this.props.id).on("hidden.bs.collapse", function () {
            self.setState({
                expanded: false,
            }, () => {
                self.onToggle();
            });
        });

        window.$("#" + this.props.id).on("shown.bs.collapse", function () {
            self.setState({
                expanded: true,
            }, () => {
                self.onToggle();
            })
        });
    }

    onToggle(){
        if(this.props.onToggle){
            this.props.onToggle(this.state.expanded);
        }
    }

    getTitle() {
        if (this.state.expanded) {
            if (this.props.expandedTitle) {
                return this.props.expandedTitle;
            }
            else {
                return this.props.title;
            }
        }
        else {
            if (this.props.collapsedTitle) {
                return this.props.collapsedTitle;
            }
            else {
                return this.props.title;
            }
        }
    }

    render() {
        return (
            <div className="accordion accordion-secondary">
                <div className="card ">
                    <div className={"card-header p-1 " + (this.props.expanded === "true" ? "" : "collapsed")} data-toggle="collapse" data-target={"#" + this.props.id} aria-expanded={this.props.expanded ? this.props.expanded : "false"} >
                        <div className="span-icon">
                            <div className={this.props.icon}></div>
                        </div>
                        <div className="span-title">
                            {this.getTitle()}
                        </div>
                        <div className="span-mode"></div>
                    </div>
                    <div className={"collapse " + (this.props.expanded === "true" ? "show" : "")} id={this.props.id}>
                        <div className="card-body p-3">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Accordion