import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import CustomerAddress from './CustomerAddress';

class CustomerAddressList extends Component {

    constructor(props) {
        super(props);
        this.allAddresses = [];
        this.checkedAddress = null;
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            filter: ""
        }

        this.handleOnFilterChange = this.handleOnFilterChange.bind(this);
        this.handleEditedAddressChange = this.handleEditedAddressChange.bind(this);
        this.selectAddress = this.selectAddress.bind(this);
        this.editAddress = this.editAddress.bind(this);
    }

    handleOnFilterChange(e) {
        this.setState({
            filter: e.target.value
        })
    }

    handleEditedAddressChange(address) {
        this.editedAddress = address;
    }

    selectAddress(address, e) {
        address.shipping = this.props.type === "shipping";
        this.props.onSelect(address);
    }

    editAddress(validate) {
        var model = this.editedAddress;
        model.customerId = this.props.customer.customerID;
        var params = {
            validate: validate,
            address: model
        }
        var url = "";
        if (this.props.type === "shipping") {
            url = "/api/Customers/EditShippingAddress";
        }
        else if (this.props.type === "billing") {
            url = "/api/Customers/EditBillingAddress";
        }
        this.http.post(url, params).then(data => {
            if (data.invalid) {
                this.utils.confirm(data.error, () => {
                    this.editAddress(false);
                }, null, "Ignore!", "Cancel");
            }
            else if (data.corrected) {
                const confirmText = `There seems to be a problem with the address, and the address validator suggests a correction.

                Suggested Address:
                ${data.correctedAddress.rawText}

                Original Address:
                ${data.originalAddress.rawText}
                `;
                this.utils.confirm(confirmText, () => {
                    this.editedAddress = {
                        ...this.editedAddress,
                        addressLine1: data.correctedAddress.addressLine1,
                        addressLine2: data.correctedAddress.addressLine2,
                        addressLine3: data.correctedAddress.addressLine3,
                        municipality: data.correctedAddress.municipality,
                        county: data.correctedAddress.county,
                        district: data.correctedAddress.district,
                        city: data.correctedAddress.city,
                        stateId: data.correctedAddress.state.stateID,
                        zipCode: data.correctedAddress.zipCode,
                        countryId: data.correctedAddress.country.countryID,
                        phoneNumber: data.correctedAddress.phoneNumber,
                        addressType: data.correctedAddress.residential ? "Residential" : "Business",
                    }
                    this.editAddress(false);
                }, () => {
                    this.editAddress(false);
                }, "Use Suggestion", "Use Original");
            }
            else {
                window.$("#editAddressModal" + this.editedAddress.addressId).modal("hide");
                if (this.props.onUpdate) {
                    this.props.onUpdate(data);
                }
            }
        });
    }

    deleteAddress(address, e) {
        var message = "This address will be deleted. Do you want to continue?"
        this.utils.confirm(message, () => {
            var params = {
                addressId: address.addressID,
                customerId: this.props.customer.customerID,
                type: this.props.type
            }
            this.http.get('/api/Customers/RemoveAddress', params).then(data => {
                if (this.props.onUpdate) {
                    this.props.onUpdate(data);
                }
            });
        })
    }

    validateAddress(address, e) {
        var params = {
            addressId: address.addressID,
            customerId: this.props.customer.customerID,
            type: this.props.type
        }
        this.http.get('/api/Customers/ValidateAddress', params).then(data => {
        });
    }

    render() {
        var allAddresses = [];
        var selectedAddressId = this.props.selectedAddressId;
        if (this.props.type === "billing") {
            allAddresses = this.utils.dictToArray(this.props.customer.activeBillingAddresses);
        }
        else if (this.props.type === "shipping") {
            allAddresses = this.utils.dictToArray(this.props.customer.activeShippingAddresses);
        }

        if (!selectedAddressId) {
            var defaultAddressIndex = allAddresses.findIndex(a => a.primary);
            if (defaultAddressIndex >= 0) {
                var defaultAddress = allAddresses[defaultAddressIndex];
                selectedAddressId = defaultAddress.addressID;
                // Display default address at the top of the list
                allAddresses.splice(defaultAddressIndex, 1);
                allAddresses.unshift(defaultAddress);
            }
        }

        if (this.state.filter !== "") {
            allAddresses = allAddresses.filter(a => a.formattedAddress.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0)
        }
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <div className="input-icon">
                            <input type="text" className="form-control" placeholder="Filter..." onChange={this.handleOnFilterChange} />
                            <span className="input-icon-addon">
                                <i className="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
                {allAddresses.map(address =>
                    <div key={address.addressID} className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="col pt-1">
                                    <p dangerouslySetInnerHTML={{ __html: address.formattedAddress }}>
                                    </p>
                                    {this.props.type === "shipping" && address.residential &&
                                        <h1 style={{ position: "absolute", top: 0, right: 8 }}>R</h1>
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="card-footer">
                            <button className={"btn btn-info btn-round btn-sm mr-2 " + (selectedAddressId !== address.addressID ? "btn-border" : "")} onClick={e => this.selectAddress(address, e)}>
                                <span className="btn-label">
                                    <i className="fas fa-check"></i>
                                </span>
                                Use this address
                            </button>
                            {this.props.editable &&
                                <React.Fragment>
                                    <button className="btn btn-info btn-border btn-round btn-sm mr-2 pull-right" data-toggle="modal" data-target={"#editAddressModal" + address.addressID}>
                                        <span className="btn-label">
                                            <i className="fas fa-pen"></i>
                                        </span>
                                    </button>
                                    <button className="btn btn-danger btn-border btn-round btn-sm mr-2 pull-right" onClick={e => this.deleteAddress(address, e)}>
                                        <span className="btn-label">
                                            <i className="fas fa-trash"></i>
                                        </span>
                                    </button>
                                    <Modal id={"editAddressModal" + address.addressID} title="Edit Address" onOk={() => this.editAddress(true)}>
                                        <CustomerAddress onChange={this.handleEditedAddressChange} address={address}></CustomerAddress>
                                    </Modal>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default CustomerAddressList
