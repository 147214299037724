import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import CompanySelect from '../../common/CompanySelect';
import ProductTypeSelect from '../../common/ProductTypeSelect';

class ProductOrder extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            companyId: 2,
            activeOnly: true,
            inventoryTypeId: 1,
            products: [],
            hasChanges: false,
        }

        this.paramsKey = props.paramsKey ? props.paramsKey : "params.product-order";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleInventoryTypeChange = this.handleInventoryTypeChange.bind(this);
        this.handleActiveOnlyChange = this.handleActiveOnlyChange.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.applyChanges = this.applyChanges.bind(this);
    }

    componentDidMount() {
        this.getProducts();
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId }, () => {
            this.getProducts();
        });
    }

    handleInventoryTypeChange(inventoryTypeId) {
        this.setState({ inventoryTypeId: inventoryTypeId }, () => {
            this.getProducts();
        });
    }

    handleActiveOnlyChange(checked) {
        this.setState({ activeOnly: checked }, () => {
            this.getProducts();
        });
    }

    getProducts(e) {
        this.setState({
            hasChanges: false
        });

        if (!!e) {
            e.preventDefault();
        }
        var params = {
            companyId: this.state.companyId,
            inventoryTypeId: this.state.inventoryTypeId,
            activeOnly: this.state.activeOnly,
            sortField: "SORTORDER"
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
        this.http.get('/api/Inventory/SearchProducts', params).then(data => {
            this.setState({ products: data });
        });
    }

    moveProductUp(index) {
        if (index > 0) {
            const productsClone = [...this.state.products];
            const item = productsClone[index];
            productsClone.splice(index, 1);
            productsClone.splice(index - 1, 0, item);
            this.setState({
                hasChanges: true,
                products: productsClone
            });
        }
    }

    moveProductDown(index) {
        if (index < this.state.products.length - 1) {
            const productsClone = [...this.state.products];
            const item = productsClone[index];
            productsClone.splice(index, 1);
            productsClone.splice(index + 1, 0, item);
            this.setState({
                hasChanges: true,
                products: productsClone
            });
        }
    }

    applyChanges() {
        var params = this.state.products.map((product, index) => {
            return {
                productID: product.productID,
                sortOrder: index,
            };
        });
        this.http.post('/api/Inventory/UpdateProductOrders', params).then(data => {
            this.getProducts();
        });        
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Inventory List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={e => this.getProducts(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label>Company</label>
                                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange} hasAllOption={false}></CompanySelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Inventory Type</label>
                                            <ProductTypeSelect value={this.state.inventoryTypeId} onChange={this.handleInventoryTypeChange} hasAllOption={false}></ProductTypeSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>&nbsp;</label>
                                            <Checkbox label="Active Only" checked={this.state.activeOnly} onChange={this.handleActiveOnlyChange}></Checkbox>
                                        </div>
                                        <div className="form-group col-md-3 pull-right">
                                            {this.state.hasChanges &&
                                                <button type="button" className="btn btn-secondary mt-4" onClick={this.applyChanges}><i className="fas fa-check"></i> Apply Changes</button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Inv #</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Wholesale</th>
                                                <th scope="col">MSRP</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.products.map((item, index) =>
                                                <tr key={item.productID}>
                                                    <td><Link to={"/inventory/product/" + item.productID}>{item.productNumber}</Link></td>
                                                    <td>{item.name}</td>
                                                    <td>{item.typeDescription}</td>
                                                    <td>{this.utils.toCurrency(item.wholesalePrice)}</td>
                                                    <td>{this.utils.toCurrency(item.retailPrice)}</td>
                                                    <td>
                                                        {index > 0 &&
                                                            <button className="btn btn-xs btn-focus-highlight mr-1" type="button" onClick={(e) => this.moveProductUp(index)}><i className="fas fa-arrow-up"></i></button>
                                                        }
                                                        {index < this.state.products.length - 1 &&
                                                            <button className="btn btn-xs btn-focus-highlight" type="button" onClick={(e) => this.moveProductDown(index)}><i className="fas fa-arrow-down"></i></button>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductOrder;
