import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from '../../common/Pagination'
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';

class CustomerTypeList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            siteId: -1,
            keyword: '',
            activeOnly: true,
            customerTypes: [],
            pageCount: 0,
            pageIndex: 0
        }
        this.paramsKey = "params.customerType-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleActiveOnlyChange = this.handleActiveOnlyChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.pageSize = 100;
        this.getItems = this.getItems.bind(this);
    }

    componentDidMount() {
        this.getItems(this.state.pageIndex);
    }

    handleKeywordChange(e) {
        this.setState({ keyword: e.target.value });
    }

    handleActiveOnlyChange(checked) {
        this.setState({ activeOnly: checked });
    }


    handlePageChange(page) {
        this.getItems(page);
    }

    deleteCustomerType(customerType){
        var message = "This customer type will be deleted. Do you want to continue?";
        this.utils.confirm(message, () => {
            this.http.post('/api/Admin/DeleteCustomerType', {id: customerType.customerTypeID}).then(data => {
                this.getItems(0);
            });
        })
    }

    getItems(page, e) {
        if (!!e) {
            e.preventDefault();
        }
        this.setState({ pageIndex: page });
        var params = {
            activeOnly: this.state.activeOnly,
            keyword: this.state.keyword,
            pageSize: this.pageSize,
            pageIndex: page
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Admin/GetCustomerTypes', params).then(data => {
            this.setState({ customerTypes: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Order List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={(e) => this.getItems(0, e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Keyword</label>
                                            <input type="text" className="form-control" value={this.state.keyword} onChange={this.handleKeywordChange}></input>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>&nbsp;</label>
                                            <Checkbox label="Active Only" checked={this.state.activeOnly} onChange={this.handleActiveOnlyChange}></Checkbox>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-tools">
                                        <Link to="/admin/add-customer-type" className="btn btn-info btn-border btn-round btn-sm mr-2">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add New
							            </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Customer Type</th>
                                                <th scope="col">Category Code</th>
                                                <th scope="col">Is Active</th>
                                                <th scope="col">Is Web</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.customerTypes.map((item) =>
                                                <tr key={item.customerTypeID}>
                                                    <td><Link to={"/admin/customer-type/" + item.customerTypeID}>{item.customerTypeDescription}</Link></td>
                                                    <td>{item.customerCategoryCode}</td>
                                                    <td>{item.isActive ? "yes" : "no"}</td>
                                                    <td>{item.isWeb ? "yes" : "no"}</td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={e => this.deleteCustomerType(item)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.customerTypes.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerTypeList;
