import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';
import UserDefinedFieldEditor from './UserDefinedFieldEditor';
import AccountTypesSelect from './AccountTypesSelect';
import StateSelect from './StateSelect';
import CustomerCategorySelect from './CustomerCategorySelect';
import DateRange from './DateRange';
import ProductTree from './ProductTree';
import IsResidentialSelect from './IsResidentialSelect';

class EntityCriteriaSelect extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.fields = this.props.fields;
        var selectedField = this.props.criteria ? this.fields.find(f => f.name === this.props.criteria.fieldName) : this.fields[0];
        this.state = {
            fields: this.fields,
            userDefinedFields: [],
            selectedField: selectedField,
            selectedOperator: this.props.criteria ? this.props.criteria.operator : selectedField.operators[0],
            value: this.props.criteria ? this.props.criteria.value : ""
        }

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleOperatorChange = this.handleOperatorChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleUserDefinedFieldValueChange = this.handleUserDefinedFieldValueChange.bind(this);
        this.handleStateValueChange = this.handleStateValueChange.bind(this);
        this.notifyChange = this.notifyChange.bind(this);
    }

    componentDidMount() {
        this.getUserDefinedFields();
        this.notifyChange();
    }

    componentDidUpdate(prevProps) {
        var prevCriteria = prevProps.criteria;
        var currentCriteria = this.props.criteria;
        if (prevCriteria.fieldName !== currentCriteria.fieldName || prevCriteria.operator !== currentCriteria.operator || prevCriteria.value !== currentCriteria.value) {
            var selectedField = currentCriteria ? this.fields.find(f => f.name === currentCriteria.fieldName) : this.fields[0];
            this.setState({
                selectedField: selectedField,
                selectedOperator: currentCriteria ? currentCriteria.operator : selectedField.operators[0],
                value: currentCriteria ? currentCriteria.value : ""
            })
        };
    }

    getUserDefinedFields() {
        var params = {
            entity: this.props.entity
        }
        this.http.get("/api/Crm/GetUserDefinedFieldsForEntity", params).then(data => {
            var fields = this.state.fields;
            for (var i = 0; i < data.length; i++) {
                if (!fields.find(f => f.label === data[i].name)) {
                    fields.push({
                        label: data[i].name,
                        name: "udf_" + data[i].userDefinedFieldID.toString(),
                        type: "user_defined",
                        operators: data[i].selection === "Multiple" ? ["includes", "excludes"] : ["equal", "not equal"]
                    })
                };
            }

            this.setState({
                userDefinedFields: data,
                fields: fields
            });
        });
    }



    handleFieldChange(e) {
        var selectedField = this.state.fields.find(f => f.name === e.target.value);
        var value = "";
        var operator = selectedField.operators.find(o => o === "like");
        if (!operator) {
            operator = selectedField.operators[0];
        }

        if (selectedField.type === "user_defined") {
            var selectedUserDefinedField = this.state.userDefinedFields.find(f => f.userDefinedFieldID === parseInt(selectedField.name.replace("udf_", "")));
            if (selectedUserDefinedField.selection === "Multiple") {
                value = [];
            }
        }
        if (selectedField.selector) {
            value = 0;
        }
        this.setState({
            selectedField: selectedField,
            value: value,
            selectedOperator: operator
        }, this.notifyChange)
    }

    handleOperatorChange(e) {
        this.setState({
            selectedOperator: e.target.value
        }, this.notifyChange)
    }

    handleValueChange(e) {
        var value = null;
        try {
            value = e.target.value;
        }
        catch (err) {
            value = e;
        }
        this.setState({
            value: value
        }, this.notifyChange)
    }

    handleStateValueChange(e) {
        var value = null;
        try {
            value = e.target.value;
        }
        catch (err) {
            value = e.stateID;
        }
        this.setState({
            value: value
        }, this.notifyChange)
    }

    handleUserDefinedFieldValueChange(e) {
        this.setState({
            value: e
        }, this.notifyChange)
    }

    notifyChange() {
        var criteria = {
            field: this.state.selectedField,
            operator: this.state.selectedOperator,
            value: this.state.value
        };
        if (this.props.onChange) {
            this.props.onChange(criteria);
        }
    }

    render() {
        var editor = null;
        var selectedField = this.state.selectedField;
        if (selectedField) {
            if (selectedField.type === "user_defined" && this.state.userDefinedFields) {
                var udf = this.state.userDefinedFields.find(f => f.userDefinedFieldID === parseInt(this.state.selectedField.name.replace("udf_", "")));
                if (udf) {
                    editor = <UserDefinedFieldEditor field={udf} value={this.state.value} onChange={this.handleUserDefinedFieldValueChange}></UserDefinedFieldEditor>;
                }
            }
            else {
                if (selectedField.selector === "AccountTypesSelect") {
                    editor = <AccountTypesSelect value={this.state.value} onChange={this.handleValueChange}></AccountTypesSelect>;
                }
                else if (selectedField.selector === "StateSelect") {
                    editor = <StateSelect value={this.state.value} onChange={this.handleStateValueChange}></StateSelect>;
                }
                else if (selectedField.selector === "CustomerCategorySelect") {
                    editor = <CustomerCategorySelect value={this.state.value} onChange={this.handleValueChange}></CustomerCategorySelect>;
                }
                else if (selectedField.selector === "DateRange") {
                    editor = <DateRange value={this.state.value} onChange={this.handleValueChange}></DateRange>;
                }
                else if (selectedField.selector === "ProductTree") {
                    editor = <ProductTree value={this.state.value} onChange={this.handleValueChange}></ProductTree>;
                }
                else if (selectedField.selector === "IsResidentialSelect") {
                    editor = <IsResidentialSelect value={this.state.value} onChange={this.handleValueChange}></IsResidentialSelect>;
                }
                else {
                    editor = <input type="text" className="form-control" value={this.state.value} onChange={this.handleValueChange}></input>;
                }
            }
        }
        else {
            return null;
        }
        return (
            <React.Fragment>
                <div className="form-group col-md-4">
                    <label>Field</label>
                    <select className="form-control" value={this.state.selectedField.name} onChange={this.handleFieldChange}>
                        {this.state.fields.map((field) =>
                            <option key={field.name} value={field.name}>{field.label}</option>
                        )}
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label>Operator</label>
                    <select className="form-control" value={this.state.selectedOperator} onChange={this.handleOperatorChange}>
                        {this.state.selectedField.operators.map((operator) =>
                            <option key={operator} value={operator}>{operator}</option>
                        )}
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label>Value</label>
                    {editor}
                </div>
            </React.Fragment>
        );
    }
}
export default EntityCriteriaSelect