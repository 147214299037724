import React, { Component } from 'react';

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e.target.checked);
        }
    }

    render() {
        return (
            <div style={this.props.style} className="form-check">
                <label className="form-check-label">
                    <input className={"form-check-input " + this.props.className} type="checkbox" checked={this.props.checked} onChange={this.handleChange}></input>
                    <span className="form-check-sign">{this.props.label}</span>
                </label>
            </div>
        );
    }
}

export default Checkbox;