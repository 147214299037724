import React, { Component } from 'react';
import Checkbox from '../../common/Checkbox';

class UserInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: props.user ? props.user.userName : "",
            firstName: props.user ? props.user.firstName : "",
            lastName: props.user ? props.user.lastName : "",
            initials: props.user ? props.user.initials : "",
            isActive: props.user ? props.user.isActive : true,
            email: props.user && props.user.email ? props.user.email : "",
        }

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleInitialsChange = this.handleInitialsChange.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.notifyChange();
    }

    handleUsernameChange(e) {
        this.setState({
            username: e.target.value
        }, this.notifyChange);
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        }, this.notifyChange);
    }

    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        }, this.notifyChange);
    }

    handleLastNameChange(e) {
        this.setState({
            lastName: e.target.value
        }, this.notifyChange);
    }

    handleFirstNameChange(e) {
        this.setState({
            firstName: e.target.value
        }, this.notifyChange);
    }

    handleInitialsChange(e) {
        this.setState({
            initials: e.target.value
        }, this.notifyChange);
    }

    handleIsActiveChange(checked) {
        this.setState({
            isActive: checked
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    {!this.props.updating &&
                        <React.Fragment>
                            <div className="form-group col-md-12">
                                <label>Username</label>
                                <input type="text" value={this.state.username} onChange={this.handleUsernameChange} className="form-control" />
                            </div>

                            <div className="form-group col-md-12">
                                <label>Password</label>
                                <input type="password" value={this.state.password} onChange={this.handlePasswordChange} className="form-control" />
                            </div>
                        </React.Fragment>
                    }
                    {this.props.updating &&
                        <React.Fragment>
                            <div className="form-group col-md-12">
                                <label>Username</label>
                                <input type="text" readOnly={true} value={this.state.username} className="form-control" />
                            </div>
                        </React.Fragment>
                    }
                    <div className="form-group col-md-12">
                        <label>Email</label>
                        <input type="text" value={this.state.email} onChange={this.handleEmailChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-12">
                        <label>First Name</label>
                        <input type="text" value={this.state.firstName} onChange={this.handleFirstNameChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-12">
                        <label>Last Name</label>
                        <input type="text" value={this.state.lastName} onChange={this.handleLastNameChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-12">
                        <label>Initials</label>
                        <input type="text" value={this.state.initials} onChange={this.handleInitialsChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-12">
                        <Checkbox label="Active" checked={this.state.isActive} onChange={this.handleIsActiveChange}></Checkbox>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UserInformation