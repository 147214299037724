import React, { Component } from 'react';
import Checkbox from '../../common/Checkbox';
import DatePicker from "react-datepicker";
import RadioGroup from '../../common/RadioGroup';
import ShipTypeSelect from '../../common/ShipTypeSelect';
import moment from 'moment';

class CouponInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claimCode: props.coupon ? props.coupon.claimCode : "",
            description: props.coupon ? props.coupon.description : "",
            activeFrom: props.coupon ? moment(props.coupon.activeFrom).toDate() : moment(new Date()).toDate(),
            activeTo: props.coupon ? moment(props.coupon.activeTo).toDate() : moment(new Date()).add(1, 'day').toDate(),
            discount: props.coupon ? props.coupon.discount : 0,
            minimumCartValue: props.coupon ? props.coupon.minCartValue : 0, 
            discountType: props.coupon ? props.coupon.type.toString() : "1",
            oneUseOnly: props.coupon ? props.coupon.oneUseOnly : false,
            validForRetail: props.coupon ? props.coupon.validForRetail : false,
            validForWholesale: props.coupon ? props.coupon.validForWholesale : false,
            validForAutoshipOnly: props.coupon ? props.coupon.autoshipOnly : false,
            canBeCombinedWithOtherCoupons: props.coupon ? props.coupon.canBeCombined : false,
            freeShipping: props.coupon && props.coupon.shippingType ? true : false,
            shippingType: props.coupon && props.coupon.shippingType ? props.coupon.shippingType.shipTypeID : 0
        }

        this.handleClaimCodeChange = this.handleClaimCodeChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleActiveFromChange = this.handleActiveFromChange.bind(this);
        this.handleActiveToChange = this.handleActiveToChange.bind(this);
        this.handleDiscountChange = this.handleDiscountChange.bind(this);
        this.handleMinimumCartValueChange = this.handleMinimumCartValueChange.bind(this);
        this.handleDiscountTypeChange = this.handleDiscountTypeChange.bind(this);
        this.handleOneUseOnlyChange = this.handleOneUseOnlyChange.bind(this);
        this.handleValidForRetailChange = this.handleValidForRetailChange.bind(this);
        this.handleValidForWholesaleChange = this.handleValidForWholesaleChange.bind(this);
        this.handleValidForAutoshipOnlyChange = this.handleValidForAutoshipOnlyChange.bind(this);
        this.handleCanBeCombinedWithOtherCoupons = this.handleCanBeCombinedWithOtherCoupons.bind(this);
        this.handleFreeShippingChange = this.handleFreeShippingChange.bind(this);
        this.handleShippingTypeChange = this.handleShippingTypeChange.bind(this);
        this.notifyChange();
    }

    handleClaimCodeChange(e) {
        this.setState({
            claimCode: e.target.value
        }, this.notifyChange);
    }

    handleDescriptionChange(e) {
        this.setState({
            description: e.target.value
        }, this.notifyChange);
    }

    handleActiveFromChange(value) {
        this.setState({
            activeFrom: value
        }, this.notifyChange);
    }

    handleActiveToChange(value) {
        this.setState({
            activeTo: value
        }, this.notifyChange);
    }

    handleDiscountChange(e) {
        this.setState({
            discount: e.target.value
        }, this.notifyChange);
    }

    handleMinimumCartValueChange(e) {
        this.setState({
            minimumCartValue: e.target.value
        }, this.notifyChange);
    }

    handleDiscountTypeChange(value) {
        this.setState({
            discountType: value
        }, this.notifyChange);
    }

    handleOneUseOnlyChange(checked) {
        this.setState({
            oneUseOnly: checked
        }, this.notifyChange);
    }

    handleValidForRetailChange(checked) {
        this.setState({
            validForRetail: checked
        }, this.notifyChange);
    }

    handleValidForWholesaleChange(checked) {
        this.setState({
            validForWholesale: checked
        }, this.notifyChange);
    }

    handleValidForAutoshipOnlyChange(checked) {
        this.setState({
            validForAutoshipOnly: checked
        }, this.notifyChange);
    }

    handleCanBeCombinedWithOtherCoupons(checked) {
        this.setState({
            canBeCombinedWithOtherCoupons: checked
        }, this.notifyChange);
    }

    handleFreeShippingChange(checked) {
        this.setState({
            freeShipping: checked
        }, this.notifyChange);
    }

    handleShippingTypeChange(value) {
        this.setState({
            shippingType: value.shipTypeID
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Claim Code</label>
                        <input type="text" value={this.state.claimCode} onChange={this.handleClaimCodeChange} className="form-control" />
                    </div>

                    <div className="form-group col-md-6">
                        <label>Description</label>
                        <input type="text" value={this.state.description} onChange={this.handleDescriptionChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Active From</label>
                        <DatePicker className="form-control" selected={this.state.activeFrom} onChange={this.handleActiveFromChange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Active To</label>
                        <DatePicker className="form-control" selected={this.state.activeTo} onChange={this.handleActiveToChange} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Discount</label>
                        <input type="text" value={this.state.discount} onChange={this.handleDiscountChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Minimum Cart Value</label>
                        <input type="text" value={this.state.minimumCartValue} onChange={this.handleMinimumCartValueChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Discount Type</label>
                        <RadioGroup groupName="discountType" value={this.state.discountType} items={[{ value: "1", text: "%" }, { value: "0", text: "Actual Amount" }, { value: "2", text: "No Discount" }]} onChange={this.handleDiscountTypeChange}></RadioGroup>
                    </div>
                    <div className="form-group col-md-6">
                        <Checkbox label="One use only (per customer)" checked={this.state.oneUseOnly} onChange={this.handleOneUseOnlyChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-6">
                        <Checkbox label="Valid for retail" checked={this.state.validForRetail} onChange={this.handleValidForRetailChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-6">
                        <Checkbox label="Valid for wholesale" checked={this.state.validForWholesale} onChange={this.handleValidForWholesaleChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-6">
                        <Checkbox label="Valid for auto-ship orders only" checked={this.state.validForAutoshipOnly} onChange={this.handleValidForAutoshipOnlyChange}></Checkbox>
                        <p className="pl-4 text-muted">(will automatically be added to website auto-ship orders)</p>
                    </div>
                    <div className="form-group col-md-6">
                        <Checkbox label="Can be Combined With Other Coupons" checked={this.state.canBeCombinedWithOtherCoupons} onChange={this.handleCanBeCombinedWithOtherCoupons}></Checkbox>
                    </div>
                    <div className="form-group col-md-6">
                        <Checkbox label="Free shipping" checked={this.state.freeShipping} onChange={this.handleFreeShippingChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-6">
                        <ShipTypeSelect disabled={!this.state.freeShipping} value={this.state.shippingType} onChange={this.handleShippingTypeChange}></ShipTypeSelect>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CouponInformation