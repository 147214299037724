import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';

class CustomerComments extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            beginDate: moment(new Date()).subtract(1, 'month').toDate(),
            endDate: moment(new Date()).toDate(),
            comments: [],
        }
        this.paramsKey = "params.customer-comments";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleFollowUpChecked = this.handleFollowUpChecked.bind(this);
        this.getComments = this.getComments.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
    }

    componentDidMount() {
        this.getComments();
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handleFollowUpChecked(comment, checked) {
        var params = {
            commentId: comment.commentId,
            followedUp: checked
        }

        this.http.get('/api/Customers/FollowUpComment', params).then(data => {
            comment.followedUp = checked;
            this.forceUpdate();
        });
        
    }

    getComments() {
        var params = {
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Customers/SearchComments', params).then(data => {
            this.setState({ comments: data });
        });
    }

    deleteComment(comment){
        var params = {
            commentId: comment.commentId,
        }

        this.http.get('/api/Customers/DeleteComment', params).then(data => {
            var index = this.state.comments.findIndex(c=>c.commentId === comment.commentId);
            this.state.comments.splice(index, 1);
            this.forceUpdate();
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Customer Comments</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="beginDate">Begin Date</label>
                                        <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="endDate">End Date</label>
                                        <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <button className="btn btn-primary mt-4" onClick={this.getComments}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Followed Up?</th>
                                                <th scope="col">State</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.comments.map((comment) =>
                                                <React.Fragment key={comment.commentId}>
                                                    <tr>
                                                        <td><Link to={"/customer/" + comment.customerId}>{comment.name}</Link></td>
                                                        <td><a href={"mailto:" + comment.email}>{comment.email}</a></td>
                                                        <td>{comment.site}</td>
                                                        <td>{comment.dateTimeStamp}</td>
                                                        <td><Checkbox checked={comment.followedUp} onChange={(e) => this.handleFollowUpChecked(comment, e)}></Checkbox></td>
                                                        <td>{comment.stateAbbreviation}</td>
                                                        <td><button className="btn btn-sm btn-danger" onClick={(e) => this.deleteComment(comment, e)}><i className="fas fa-trash"></i></button></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={7}><p>{comment.comment}</p></td>
                                                    </tr>
                                                </React.Fragment>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerComments;
