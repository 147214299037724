import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import Callback from './Callback';
import moment from 'moment'

class CallbackList extends Component {

    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleEditedCallbackChange = this.handleEditedCallbackChange.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
        this.editCallback = this.editCallback.bind(this);
    }

    handleEditedCallbackChange(card) {
        this.editedCallback = card;
    }

    deleteCallback(callback, e) {
        var message = "This callback will be deleted. Do you want to continue?"
        this.utils.confirm(message, () => {
            var params = {
                id: callback.callbackID
            }
            this.http.post('/api/Crm/RemoveCallback', params).then(data => {
                if (this.props.onUpdate) {
                    this.props.onUpdate(data);
                }
            });
        })
    }

    editCallback() {
        var model = this.editedCallback;
        this.http.post("/api/Crm/EditCallback", model).then(data => {
            window.$("#editCallbackModal" + this.editedCallback.callbackID).modal("hide");
            if (this.props.onUpdate) {
                this.props.onUpdate(data);
            }
        });
    }

    render() {
        var callbacks = this.props.callbacks;
        return (
            <React.Fragment>
                {callbacks.map(callback =>
                    <div key={callback.callbackID} className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="col pt-1">
                                    <h5>{callback.callbackName}</h5>
                                    <p className="text-muted mb-0">{callback.callbackInfo}</p>                        
                                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: callback.callbackNotes }}>
                                    </p>
                                    <p className="text-muted pull-right mb-0">
                                        {callback.enteredBy} {moment(callback.callbackDateTime).format('l')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            {this.props.editable &&
                                <React.Fragment>
                                    <button className="btn btn-info btn-border btn-round btn-sm mr-2 pull-right" data-toggle="modal" data-target={"#editCallbackModal" + callback.callbackID}>
                                        <span className="btn-label">
                                            <i className="fas fa-pen"></i>
                                        </span>
                                        Edit
							        </button>
                                    <button className="btn btn-info btn-border btn-round btn-sm mr-2 pull-right" onClick={e => this.deleteCallback(callback, e)}>
                                        <span className="btn-label">
                                            <i className="fas fa-trash"></i>
                                        </span>
                                        Delete
							        </button>
                                </React.Fragment>
                            }
                        </div>
                        <Modal id={"editCallbackModal" + callback.callbackID} title="Edit Callback" onOk={this.editCallback}>
                            <Callback onChange={this.handleEditedCallbackChange} callback={callback}></Callback>
                        </Modal>
                     </div>
                    
                )}
            </React.Fragment>
        )
    }
}

export default CallbackList