import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class ProductMultipleSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            filteredProducts: []
        };
        this.getProducts = this.getProducts.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleOptionOnMouseDown = this.handleOptionOnMouseDown.bind(this);
        this.http = new HttpRequestService();
        this.lastMouseDownTime = new Date();
    }

    componentDidMount() {
        this.getProducts();
    }

    componentDidUpdate(prevProps) {
        if (this.props.companyId !== prevProps.companyId || this.props.productTypeId !== prevProps.productTypeId) {
            this.getProducts();
        }
    }

    componentWillUnmount() {
    }

    getProducts() {
        var params = {
            companyId: this.props.companyId,
            productTypeId: this.props.productTypeId
        }
        if (params.companyId > 0 && params.productTypeId > 0) {
            this.http.get('/api/Orders/GetProducts', params).then(data => {
                this.setState({ products: data, filteredProducts: data });
                if (data.length > 0) {
                    if (!this.props.value) {
                        this.props.onChange(data[0]);
                    }
                    else {
                        var selectedProduct = data.find(p => p.productID === this.props.value);
                        this.props.onChange(selectedProduct);
                    }
                }
            });
        }
        else if (this.props.allProducts) {
            this.http.get('/api/Inventory/GetAllProducts', params).then(data => {
                this.setState({ products: data, filteredProducts: data });
                if (!this.props.value) {
                    this.props.onChange(data[0]);
                }
                else {
                    var selectedProduct = data.find(p => p.productID === this.props.value);
                    this.props.onChange(selectedProduct);
                }
            });
        }
    }

    handleKeywordChange(e) {
        const keyword = e.target.value;
        var filteredProducts = this.state.products.filter((p) => {
            return (p.productNumber + " " + p.name).toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
        });
        this.setState({ filteredProducts: filteredProducts });
    }

    handleOptionOnMouseDown(e) {
        e.preventDefault();
        const now = new Date();
        if (now.getTime() - this.lastMouseDownTime.getTime() > 500) {
            const option = e.currentTarget;
            console.log(this.onMouseDownTimeout);
            const select = option.parentElement;
            const originalScrollTop = select.scrollTop;
            option.selected = !option.selected;
            setTimeout(() => {
                select.scrollTop = originalScrollTop;
                this.handleChange(select);
            }, 0);
            select.focus();
            this.lastMouseDownTime = new Date();
        }
        else{
            // double click
            if(this.props.onDoubleClick){
                this.props.onDoubleClick();
            }
        }
        return false;
    }

    handleChange(select) {
        const selectedValues = Array.from(select.selectedOptions, (option) => option.value);
        const products = [];
        selectedValues.forEach((value) => {
            const product = this.state.products.find(p => p.productID === parseInt(value));
            products.push(product);
        });

        this.props.onChange(products);
    }

    render() {
        var options = this.state.filteredProducts.map((item) =>
            <option key={item.productID} value={item.productID} onMouseDown={this.handleOptionOnMouseDown}>{item.productNumber} - {item.name}</option>
        );

        return (
            <>
                <input type="text" placeholder="Search..." className="form-control mb-2" onChange={this.handleKeywordChange} />
                <select multiple size={this.props.size} className="form-control" value={this.props.value} onChange={this.handleChange}>
                    {this.props.hasAllOption === true &&
                        <option value="-1">-- ALL --</option>
                    }
                    {options}
                </select>
            </>
        );
    }
}
export default ProductMultipleSelect
