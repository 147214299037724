import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            password: "",
            newPassword: "",
            confirmNewPassword: ""
        };
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleConfirmNewPasswordChange = this.handleConfirmNewPasswordChange.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }
    
    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        })
    }

    handleNewPasswordChange(e) {
        this.setState({
            newPassword: e.target.value
        })
    }

    handleConfirmNewPasswordChange(e) {
        this.setState({
            confirmNewPassword: e.target.value
        })
    }

    changePassword() {
        var params = {
          password: this.state.password,
          newPassword: this.state.newPassword,
          confirmNewPassword: this.state.confirmNewPassword
        }
        this.http.get('/api/PowerD/ChangePassword', {model: params}).then(data => {
          if(data){
            this.utils.success("");
          }
          else{
              this.utils.alert("Unable to change the password. Please check your password and try again");
          }
        });
      }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Change Password</h4>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label>Password</label>
                                            <input type="password" value={this.state.password} onChange={this.handlePasswordChange} className="form-control" />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>New Password</label>
                                            <input type="password" value={this.state.newPassword} onChange={this.handleNewPasswordChange} className="form-control" />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Confirm New Password</label>
                                            <input type="password" value={this.state.confirmNewPassword} onChange={this.handleConfirmNewPasswordChange} className="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.changePassword}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword