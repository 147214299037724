import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SitesSelect from '../../common/SitesSelect'
import OrderTypesSelect from '../../common/OrderTypesSelect'
import Pagination from '../../common/Pagination'
import { HttpRequestService } from '../../services/HttpRequestService';
import CompanySelect from '../../common/CompanySelect';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';

class InvoiceList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();

        var initialState = {
            siteId: -1,
            companyId: -1,
            orderTypeId: -1,
            customerNumber: '',
            beginDate: moment().subtract(1, 'day').toDate(),
            endDate: moment().toDate(),
            beginNumber: '',
            endNumber: '',
            onlySome: true,
            orders: [],
            pageCount: 0,
            pageIndex: 0
        }
        this.paramsKey = "params.invoice-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleOrderTypeChange = this.handleOrderTypeChange.bind(this);
        this.handleCustomerNumberChange = this.handleCustomerNumberChange.bind(this);
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleBeginNumberChange = this.handleBeginNumberChange.bind(this);
        this.handleEndNumberChange = this.handleEndNumberChange.bind(this);
        this.handleOnlySomeChange = this.handleOnlySomeChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleColCheck = this.handleColCheck.bind(this);
        this.pageSize = 100;
        this.getOrders = this.getOrders.bind(this);
        this.batchPrint = this.batchPrint.bind(this);
    }

    componentDidMount() {
        this.getOrders(this.state.pageIndex);
    }

    handleSiteChange(siteId) {
        this.setState({ siteId: siteId });
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleOrderTypeChange(orderTypeId) {
        this.setState({ orderTypeId: orderTypeId });
    }

    handleCustomerNumberChange(e) {
        this.setState({ customerNumber: e.target.value });
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handleBeginNumberChange(e) {
        this.setState({ beginNumber: e.target.value });
    }

    handleEndNumberChange(e) {
        this.setState({ endNumber: e.target.value });
    }

    handleOnlySomeChange(e) {
        this.setState({ onlySome: e.target.checked });
    }

    handlePageChange(page) {
        this.getOrders(page);
    }

    handleColCheck(checked) {
        var rows = document.getElementsByClassName("row-check");
        for (var i = 0; i < rows.length; i++) {
            rows[i].checked = checked;
            this.state.orders[i].checked = checked;
        }
    }

    handleRowCheck(order, checked) {
        order.checked = checked;
    }

    batchPrint() {
        if (this.state.orders.findIndex(o => o.checked) < 0) {
            this.utils.warning("Please select orders to be printed");
        }
        else {
            var selectedOrders = this.state.orders.filter(o => o.checked);
            for (var i = 0; i < selectedOrders.length; i++) {
                var order = selectedOrders[i];
                window.open(this.getPrintPDFUrl("Invoice", order.orderId, order.orderNumber));
            }
        }
    }

    getPrintPDFUrl(type, orderId, orderNumber) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + orderId + "&OrderNumber=" + orderNumber;
    }

    getOrders(page, e) {
        if (!!e) {
            e.preventDefault();
        }
        this.setState({ pageIndex: page });
        var params = {
            siteId: this.state.siteId,
            companyId: this.state.companyId,
            orderTypeId: this.state.orderTypeId,
            customerNumber: this.state.customerNumber,
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            beginNumber: this.state.beginNumber,
            endNumber: this.state.endNumber,
            onlySome: this.state.onlySome,
            pageSize: this.pageSize,
            pageIndex: page
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
        this.http.get('/api/Orders/SearchInvoices', params).then(data => {
            this.setState({ orders: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Invoice List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={(e) => this.getOrders(0, e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="site">Site</label>
                                            <SitesSelect value={this.state.siteId} onChange={this.handleSiteChange} hasAllOption={true}></SitesSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="orderType">Order Type</label>
                                            <OrderTypesSelect value={this.state.orderTypeId} onChange={this.handleOrderTypeChange} hasAllOption={true}></OrderTypesSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="accountType">Company</label>
                                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange} hasAllOption={true}></CompanySelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="customerNumber">Customer Number</label>
                                            <input type="text" className="form-control" value={this.state.customerNumber} onChange={this.handleCustomerNumberChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Invoice Date Range</label>
                                            <div className="form-group form-inline p-0">
                                                <div className="col-md-5 p-0">
                                                    <DatePicker className="form-control col-md-12" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                                </div>
                                                -
                                            <div className="col-md-6 p-0">
                                                    <DatePicker className="form-control col-md-12" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Invoice # (or range)</label>
                                            <div className="form-group form-inline p-0">
                                                <input type="text" className="form-control col-md-6" value={this.state.beginNumber} onChange={this.handleBeginNumberChange} /> -
                                        <input type="text" className="form-control col-md-5" value={this.state.endNumber} onChange={this.handleEndNumberChange} />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>&nbsp;</label>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" checked={this.state.onlySome} onChange={this.handleOnlySomeChange}></input>
                                                    <span className="form-check-sign">Net 30 / Net 60 / 2% 30 Only</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row pull-right">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="text-right">
                                    <button className="btn btn-default btn-sm" onClick={this.batchPrint}>Print Invoices</button>
                                </div>
                                <div className="form-row">
                                    <table className="table table-striped mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <Checkbox onChange={this.handleColCheck}></Checkbox>
                                                </th>
                                                <th scope="col">Order</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Account Type</th>
                                                <th scope="col">State</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders.map((order) =>
                                                <tr key={order.orderId}>
                                                    <td>
                                                        <Checkbox className="row-check" onChange={e => this.handleRowCheck(order, e)}></Checkbox>
                                                    </td>
                                                    <td><Link to={"/order/" + order.orderId}>{order.orderNumber}</Link></td>
                                                    <td>{order.companyName}</td>
                                                    <td>{order.site}</td>
                                                    <td>{order.accountType}</td>
                                                    <td>{order.state}</td>
                                                    <td>{order.orderType}</td>
                                                    <td>{order.orderStatus}</td>
                                                    <td>{order.dateTimeStamp}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.orders.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default InvoiceList;
