import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class CompanySelect extends Component {

    constructor(props) {
        super(props);
        this.state = { companies: [] };
        this.getCompanies = this.getCompanies.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getCompanies();
    }

    componentWillUnmount() {

    }

    getCompanies() {
        this.http.get('/api/Orders/GetCompanies', null).then(data=>{
            this.setState({ companies: data });
            if(data.length > 0 && this.props.value === 0){
                this.props.onChange(data[0].key);
            }
        });
    }

    handleChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {
        var options = this.state.companies.map((site) =>
            <option key={site.key} value={site.key}>{site.value}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {this.props.hasAllOption === true &&
                    <option value={"-1"}>-- ALL --</option>
                }
                {options}
            </select>
        );
    }
}
export default CompanySelect