import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SitesSelect from '../../common/SitesSelect'
import AccountTypesSelect from '../../common/AccountTypesSelect'
import OrderStatusSelect from '../../common/OrderStatusSelect'
import OrderTypesSelect from '../../common/OrderTypesSelect'
import Pagination from '../../common/Pagination'
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Accordion from '../../common/Accordion';

class OrderList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            siteId: -1,
            accountTypeId: -1,
            orderTypeId: -1,
            orderStatusId: 1,
            orderNumber: '',
            poNumber: '',
            customerNumber: '',
            beginDate: null,
            endDate: null,
            orders: [],
            pageCount: 0,
            pageIndex: 0
        }
        this.paramsKey = "params.order-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handleOrderStatusChange = this.handleOrderStatusChange.bind(this);
        this.handleOrderTypeChange = this.handleOrderTypeChange.bind(this);
        this.handleOrderNumberChange = this.handleOrderNumberChange.bind(this);
        this.handlePoNumberChange = this.handlePoNumberChange.bind(this);
        this.handleCustomerNumberChange = this.handleCustomerNumberChange.bind(this);
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.pageSize = 100;
        this.getOrders = this.getOrders.bind(this);
    }

    componentDidMount() {
        this.getOrders(this.state.pageIndex);
    }

    handleSiteChange(siteId) {
        this.setState({ siteId: siteId });
    }

    handleAccountTypeChange(accountTypeId) {
        this.setState({ accountTypeId: accountTypeId });
    }

    handleOrderStatusChange(orderStatusId) {
        this.setState({ orderStatusId: orderStatusId });
    }

    handleOrderTypeChange(orderTypeId) {
        this.setState({ orderTypeId: orderTypeId });
    }

    handleOrderNumberChange(e) {
        this.setState({ orderNumber: e.target.value });
    }

    handlePoNumberChange(e) {
        this.setState({ poNumber: e.target.value });
    }

    handleCustomerNumberChange(e) {
        this.setState({ customerNumber: e.target.value });
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handlePageChange(page) {
        this.getOrders(page);
    }

    getOrders(page, e) {
        if(!!e){
            e.preventDefault();
        }
        this.setState({ pageIndex: page });
        var params = {
            siteId: this.state.siteId,
            accountTypeId: this.state.accountTypeId,
            orderTypeId: this.state.orderTypeId,
            orderStatusId: this.state.orderStatusId,
            orderNumber: this.state.orderNumber,
            poNumber: this.state.poNumber,
            customerNumber: this.state.customerNumber,
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            pageSize: this.pageSize,
            pageIndex: page
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Orders/SearchOrders', params).then(data => {
            this.setState({ orders: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Order List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Accordion title="Filters" icon="flaticon-search-1" id="filters">
                        <form onSubmit={(e) => this.getOrders(0, e)}>
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <label htmlFor="site">Site</label>
                                    <SitesSelect value={this.state.siteId} onChange={this.handleSiteChange} hasAllOption={true}></SitesSelect>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="accountType">Account Type</label>
                                    <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange} hasAllOption={true}></AccountTypesSelect>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="orderStatus">Order Status</label>
                                    <OrderStatusSelect value={this.state.orderStatusId} onChange={this.handleOrderStatusChange} hasAllOption={true}></OrderStatusSelect>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="orderType">Order Type</label>
                                    <OrderTypesSelect value={this.state.orderTypeId} onChange={this.handleOrderTypeChange} hasAllOption={true}></OrderTypesSelect>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-2">
                                    <label htmlFor="orderNumber">Order Number</label>
                                    <input type="text" className="form-control" value={this.state.orderNumber} onChange={this.handleOrderNumberChange} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="orderNumber">PO Number</label>
                                    <input type="text" className="form-control" value={this.state.poNumber} onChange={this.handlePoNumberChange} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="customerNumber">Customer Number</label>
                                    <input type="text" className="form-control" value={this.state.customerNumber} onChange={this.handleCustomerNumberChange} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="beginDate">Begin Date</label>
                                    <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="endDate">End Date</label>
                                    <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                </div>
                            </div>
                            <div className="form-row justify-content-end">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                            </form>
                        </Accordion>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Order</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Customer Number</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Account Type</th>
                                                <th scope="col">State</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders.map((order) =>
                                                <tr key={order.orderId}>
                                                    <td><Link to={"/order/" + order.orderId}>{order.orderNumber}</Link></td>
                                                    <td><Link to={"/customer/" + order.customerId}>{order.companyName}</Link></td>
                                                    <td><Link to={"/customer/" + order.customerId}>{order.customerNumber}</Link></td>
                                                    <td>{order.site}</td>
                                                    <td>{order.accountType}</td>
                                                    <td>{order.state}</td>
                                                    <td>{order.orderType}</td>
                                                    <td>{order.orderStatus}</td>
                                                    <td>{order.dateTimeStamp}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.orders.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderList;
