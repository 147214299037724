import React, { Component } from 'react';
import { HttpRequestService } from './services/HttpRequestService';
import { SharedState } from './services/SharedState';

class HeaderPinLoginMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {}
    }
    this.http = new HttpRequestService();
    this.getUser = this.getUser.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.getUser()
  }

  getUser() {
    this.http.getSilent('/api/PowerD/GetLoggedInUser', null).then(data => {
      localStorage.setItem("email", data.email);
      localStorage.setItem("username", data.userName);
      this.setState({ user: data });
    });
  }

  logout() {
    this.http.get('/api/PowerD/Logout', null).then(data => {
      this.http.logout();
    });
  }

  render() {
    return (
      <React.Fragment>
        <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
          <li className="nav-item dropdown hidden-caret">
            <a className="nav-link" data-toggle="drop-down" onClick={() => SharedState.setValue("lockedOut", { locked: true, reason: "locked" })} href="#">
              <i className="fa fa-lock" />
            </a>
          </li>
          <li className="nav-item dropdown hidden-caret">
            <a className="nav-link" data-toggle="drop-down" onClick={() => SharedState.setValue("lockedOut", { locked: true, reason: "switch-user" })} href="#">
              <i className="fa fa-people-arrows" />
            </a>
          </li>
          <li className="nav-item-spacer"></li>
          <li className="nav-item dropdown hidden-caret">
            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/" aria-expanded="false">
              <i className="fa fa-user" /> {this.state.user.userName}
            </a>
            <ul className="dropdown-menu dropdown-user animated fadeIn">
              <div className="dropdown-user-scroll scrollbar-outer">
                <li>
                  <div className="user-box">
                    <div className="avatar-lg" style={{ fontSize: '32px' }}><i className="fa fa-user" /></div>
                    <div className="u-text">
                      <h4>{this.state.user.firstName} {this.state.user.lastName}</h4>
                      <p className="text-muted">{this.state.user.userName}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown-divider" />
                  <button className="dropdown-item btn" onClick={this.logout}>Logout</button>
                </li>
              </div>
            </ul>
          </li>
        </ul>
      </React.Fragment>
    )
  }
}
export default HeaderPinLoginMenu
