import React, { Component } from 'react';
import EntityCriteriaSelect from './EntityCriteriaSelect';
import RadioGroup from './RadioGroup';

class CriteriaGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logicalOperator: props.logicalOperator ? props.logicalOperator : "AND",
            criterias: props.criterias ? props.criterias : []
        }
        this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
        this.handleLogicalOperatorChange = this.handleLogicalOperatorChange.bind(this);
        this.addCriteria = this.addCriteria.bind(this);
        this.removeCriteria = this.removeCriteria.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onAdd = this.onAdd.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.queryId !== this.props.queryId) {
            this.setState({
                logicalOperator: this.props.logicalOperator ? this.props.logicalOperator : "AND",
                criterias: this.props.criterias ? this.props.criterias : []
            });
        }
    }

    handleCriteriaChange(index, e) {
        var crts = this.state.criterias;
        var values = [];
        var value = e.value;
        if (Array.isArray(e.value)) {
            values = e.value.filter(el => {
                const num = parseFloat(el);
                return Number.isInteger(num);
            });
        }
        crts[index] = {
            fieldName: e.field.name,
            operator: e.operator,
            value: value,
            values: values
        }
        this.setState({
            criterias: crts
        }, this.onChange);
    }

    handleLogicalOperatorChange(value) {
        this.setState({
            logicalOperator: value
        }, this.onChange);
    }

    addCriteria() {
        var crts = this.state.criterias;
        crts.push({ fieldName: "name", operator: "like", value: "" });
        this.setState({
            criterias: crts
        }, this.onChange);
    }

    removeCriteria(index, e) {
        var crts = this.state.criterias;
        crts.splice(index, 1);
        this.setState({
            criterias: crts
        }, this.onChange);
        this.forceUpdate();
    }

    onChange() {
        if (this.props.onChange) {
            this.props.onChange({
                criterias: this.state.criterias,
                logicalOperator: this.state.logicalOperator
            });
        }
    }

    onRemove() {
        if (this.props.onRemoveClicked) {
            this.props.onRemoveClicked();
        }
    }

    onAdd() {
        if (this.props.onAddClicked) {
            this.props.onAddClicked();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="card col-md-12">
                    <div className="card-header">
                        <div className="card-head-row">
                            <div>
                                Group Logic &nbsp;
                                <RadioGroup
                                    className="p-0"
                                    groupName={this.props.groupName}
                                    value={this.state.logicalOperator} items={[{ value: "AND", text: "AND" }, { value: "OR", text: "OR" }]}
                                    onChange={this.handleLogicalOperatorChange}>
                                </RadioGroup>
                            </div>
                            <div className="card-tools">
                                {this.props.removeButtonVisible &&
                                    <button type="button" className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={this.onRemove}>
                                        <span className="btn-label">
                                            <i className="fas fa-times"></i>
                                        </span>
                                        Remove Group
                                            </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {this.state.criterias.map((criteria, index) =>
                            <React.Fragment key={index}>
                                <div className="form-row">
                                    <div className="form-group col-md-9">
                                        <div className="form-row">
                                            <EntityCriteriaSelect
                                                entity="Account"
                                                fields={this.props.availableCriterias}
                                                criteria={criteria}
                                                onChange={(e) => this.handleCriteriaChange(index, e)}
                                            >
                                            </EntityCriteriaSelect>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-3 mt-2">
                                        {this.state.criterias.length > 1 &&
                                            <React.Fragment>
                                                <button type="button" className="btn btn-sm mt-4" onClick={(e) => this.removeCriteria(index, e)} style={{ marginRight: 4 }}><i className="fas fa-times"></i></button>
                                            </React.Fragment>

                                        }
                                        {index === this.state.criterias.length - 1 &&
                                            <button type="button" className="btn btn-sm mt-4" onClick={this.addCriteria}>Add Criteria</button>
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CriteriaGroup;