import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class PhoneTypeSelect extends Component {

    constructor(props) {
        super(props);
        this.state = { items: [] };
        this.getPhoneTypes = this.getPhoneTypes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getPhoneTypes();
    }

    getPhoneTypes() {
        this.http.get('/api/Crm/GetPhoneTypes', null).then(data => {
            this.setState({ items: data });
            if (data.length > 0 && this.props.value === 0) {
                this.props.onChange(data[0]);
            }
        });
    }

    handleChange(e) {
        var phoneType = this.state.items.find(t => t.phoneTypeID === parseInt(e.target.value));
        if (phoneType) {
            this.props.onChange(phoneType);
        }
        else {
            this.props.onChange({ phoneTypeID: e.target.value });
        }
    }

    render() {
        var options = this.state.items.map((item) =>
            <option key={item.phoneTypeID} value={item.phoneTypeID}>{item.name}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {this.props.hasAllOption === true &&
                    <option value={"-1"}>-- ALL --</option>
                }
                {options}
            </select>
        );
    }
}
export default PhoneTypeSelect