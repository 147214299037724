import React, { Component } from 'react';
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }


    componentDidCatch(error, errorInfo) {
        // Clear sessionStorage as session variables may be the reason of the error
        sessionStorage.clear();
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    reloadPage() {
        window.location.reload();
    }

    render() {
        if (this.state.hasError) {
            // Error fallback UI
            return (
                <div className="page-inner">
                    <div className="page-header">
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h2 style={{ textAlign: "center" }}>
                                        <i className="far fa-frown"></i><br />
                                        Something went wrong.<br /><br />
                                        <button className="btn btn-primary" onClick={this.reloadPage}><i className="fas fa-redo"></i> Reload Page</button>
                                    </h2>
                                    <div>
                                        {this.state.error && this.state.error.toString()}
                                        <br />
                                        {this.state.errorInfo && this.state.errorInfo.componentStack}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary
