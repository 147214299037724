import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class CountrySelect extends Component {

    constructor(props) {
        super(props);
        this.state = { items: [] };
        this.getCountries = this.getCountries.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getCountries();
    }

    getCountries() {
        this.http.get('/api/Customers/GetCountries', null).then(data=>{
            this.setState({ items: data });
            if(data.length > 0 && this.props.value === 0){
                this.props.onChange(data[0].countryID);
            }
        });
    }

    handleChange(e) {
        var country = this.state.items.find(t=>t.countryID === parseInt(e.target.value));
        this.props.onChange(country);
    }

    render() {
        var options = this.state.items.map((item) =>
            <option key={item.countryID} value={item.countryID}>{item.name}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {this.props.hasAllOption === true &&
                    <option value="-1">-- ALL --</option>
                }
                {options}
            </select>
        );
    }
}
export default CountrySelect