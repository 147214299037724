import React, { Component } from 'react';
import { Link } from "react-router-dom";
import AccountTypesSelect from '../../common/AccountTypesSelect'
import Pagination from '../../common/Pagination'
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import CompanySelect from '../../common/CompanySelect';
import ProductTypeSelect from '../../common/ProductTypeSelect';
import ProductSelect from '../../common/ProductSelect';
import Modal from '../../common/Modal';
import CustomerHistoryList from './CustomerHistoryList';

class CustomerProductSearch extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            criteria: "company_name",
            keyword: "",
            activeOnly: true,
            companyId: 0,
            productTypeId: 0,
            selectedProduct: {},
            accountTypeId: -1,
            beginDate: moment(new Date()).subtract(180, 'day').toDate(),
            endDate: moment(new Date()).toDate(),
            customers: [],
            pageCount: 0,
            pageIndex: 0
        }
        this.paramsKey = "params.customer-product-search";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
        this.handleKeywordChange = this.handleKeywordChange.bind(this);
        this.handleActiveOnlyChange = this.handleActiveOnlyChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleProductTypeChange = this.handleProductTypeChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.pageSize = 100;
        this.getCustomers = this.getCustomers.bind(this);
        this.displayCustomerOrders = this.displayCustomerOrders.bind(this);
    }

    handleCriteriaChange(e) {
        this.setState({ criteria: e.target.value });
    }

    handleKeywordChange(e) {
        this.setState({ keyword: e.target.value });
    }

    handleActiveOnlyChange(checked) {
        this.setState({ activeOnly: checked });
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleProductTypeChange(productTypeId) {
        this.setState({ productTypeId: productTypeId });
    }

    handleProductChange(product) {
        this.setState({
            selectedProduct: product,
        });
    }

    handleAccountTypeChange(accountTypeId) {
        this.setState({ accountTypeId: accountTypeId });
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handlePageChange(page) {
        this.getCustomers(page);
    }

    getCustomers(page, e) {
        if(!!e){
            e.preventDefault();
        }
        this.setState({ pageIndex: page });
        var params = {
            criteria: this.state.criteria,
            keyword: this.state.keyword,
            activeOnly: this.state.activeOnly,
            accountTypeId: this.state.accountTypeId,
            productId: this.state.selectedProduct.productID,
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            pageSize: this.pageSize,
            pageIndex: page
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
        this.http.get('/api/Customers/SearchCustomerProducts', params).then(data => {
            this.setState({ customers: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    displayCustomerOrders(customer) {
        this.setState({
            selectedCustomerId: customer.customerID
        }, () => {
            window.$("#customerHistoryModal").modal("show");
        })
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Customer Product Search</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={(e) => this.getCustomers(0, e)}>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Criteria</label>
                                            <select className="form-control" value={this.state.criteria} onChange={this.handleCriteriaChange}>
                                                <option value="company_name">Company</option>
                                                <option value="customer_number">Customer Number</option>
                                                <option value="full_name">Name</option>
                                                <option value="phone_number">Phone Number</option>
                                                <option value="zip_code">Zip Code</option>
                                                <option value="email">Email</option>
                                                <option value="po">Purchase Order #</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Keyword</label>
                                            <input type="text" className="form-control" value={this.state.keyword} onChange={this.handleKeywordChange}></input>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>&nbsp;</label>
                                            <Checkbox label="Active Only" checked={this.state.activeOnly} onChange={this.handleActiveOnlyChange}></Checkbox>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label htmlFor="site">Company</label>
                                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange}></CompanySelect>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="accountType">Product Type</label>
                                            <ProductTypeSelect value={this.state.productTypeId} onChange={this.handleProductTypeChange}></ProductTypeSelect>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="orderStatus">Product</label>
                                            <ProductSelect companyId={this.state.companyId} productTypeId={this.state.productTypeId} onChange={this.handleProductChange}></ProductSelect>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label htmlFor="accountType">Account Type</label>
                                            <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange} hasAllOption={true}></AccountTypesSelect>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="beginDate">Begin Date</label>
                                            <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="endDate">End Date</label>
                                            <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                        </div>
                                    </div>
                                    <div className="form-row justify-content-end">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-0">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Customer #</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Phone Number</th>
                                                <th scope="col">Active</th>
                                                {this.props.placeOrder &&
                                                    <th></th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.customers.map((customer) =>
                                                <tr key={customer.customerID}>
                                                    <td><button className="btn btn-link" onClick={(e) => this.displayCustomerOrders(customer, e)}>Display Orders</button></td>
                                                    <td><Link to={"/customer/" + customer.customerID}>{customer.customerNumber}</Link></td>
                                                    <td>{customer.companyName}</td>
                                                    <td>{customer.fullName}</td>
                                                    <td>{customer.phoneNumber}</td>
                                                    <td>{customer.isActive.toString()}</td>
                                                    {this.props.placeOrder &&
                                                        <td><Link className="btn btn-sm btn-info" to={"/orders/add/" + customer.customerID}>Place Order <i className="fas fa-angle-right"></i></Link></td>
                                                    }
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.customers.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal id="customerHistoryModal" title="Customer Orders" hideButtons={true}>
                            <CustomerHistoryList customerId={this.state.selectedCustomerId} rows={20}></CustomerHistoryList>
                        </Modal>
                    </div>
                </div>
            </div >
        );
    }
}

export default CustomerProductSearch
