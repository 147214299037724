import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import OrderStatusSelect from '../../common/OrderStatusSelect';
import CompanySelect from '../../common/CompanySelect';
import RadioGroup from '../../common/RadioGroup';
import Report from '../../common/Report';

class AmazonOrdersReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            companyId: 2,
            beginDate: moment(new Date()).toDate(),
            endDate: moment(new Date()).toDate(),
            orderStatusId: -1,
            format: "formatted",
            report: null,
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.paramsKey = "params.amazon-orders-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleOrderStatusChange = this.handleOrderStatusChange.bind(this);
        this.handleFormatChange = this.handleFormatChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
    }

    componentDidMount() {
        this.getReport();
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handleOrderStatusChange(statusId) {
        this.setState({ orderStatusId: statusId });
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleFormatChange(format) {
        this.setState({ format: format });
    }

    getPrintPDFUrl(type, order) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + order.orderID + "&OrderNumber=" + order.orderNumber;
    }

    getReport() {
        var params = {
            companyId: this.state.companyId,
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            orderStatusId: this.state.orderStatusId,
            format: this.state.format,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Report/GetAmazonOrdersReport', params).then(data => {
            this.setState({
                report: data,
                displayFormat: this.state.format
            });
        });
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Amazon Orders Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label>Company</label>
                                        <CompanySelect className="form-control" value={this.state.companyId} onChange={this.handleCompanyChange} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Orders From</label>
                                        <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Orders To</label>
                                        <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label>Status</label>
                                        <OrderStatusSelect hasAllOption={true} value={this.state.orderStatusId} onChange={this.handleOrderStatusChange}></OrderStatusSelect>
                                    </div>
                                    <div className="form-group col-md-2 pt-0">
                                        <label>Display</label><br />
                                        <RadioGroup groupName="displayFormat" value={this.state.format} items={[{ value: "formatted", text: "Formatted" }, { value: "raw", text: "Raw" }]} onChange={this.handleFormatChange}></RadioGroup>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <button className="btn btn-primary mt-4" onClick={this.getReport}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="AmazonOrdersReport" hasData={this.state.report.items && this.state.report.items.length > 0} title="Amazon Orders Report" description={this.state.report.description}>
                                <div className="form-row">
                                    <table className="table table-striped mt-1 report-table">
                                        {this.state.report.items.map((group) =>
                                            <React.Fragment key={group.name}>
                                                <thead>
                                                    {this.state.displayFormat === "formatted" &&
                                                        <tr className="bg-primary reportHeader">
                                                            <th colSpan={13}>{group.name}</th>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("AmazonOrderId")}>AMAZON ORDER #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("OrderNumber")}>ORDER #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("FulfillDate")}>DATE</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("CustomerNumber")}>CUST #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("CompanyName")}>CUSTOMER</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("StateAbbreviation")}>STATE</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("ProductCharges")}>PRODUCT CHARGES</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("StateTax")}>SALES TAX</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("ReferralFee")}>REFERRAL FEE</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("FulfillmentFee")}>FBA FEE</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("OtherFees")}>OTHER FEES</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("TotalFees")}>TOTAL FEES</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("TotalCtl")}>TOTAL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {group.items && group.items.map(item =>
                                                        <tr key={item.orderID}>
                                                            <td>{item.amazonOrderNumber}</td>
                                                            <td><a href={this.getPrintPDFUrl("Invoice", item)} target="_blank" rel="noopener noreferrer">{item.orderNumber}</a></td>
                                                            <td>{moment(item.fulfillDate).format('l')}</td>
                                                            <td><Link to={"/customer/" + item.customerID}>{item.customerNumber}</Link></td>
                                                            <td>{item.companyName}</td>
                                                            <td>{item.stateAbbreviation}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.productCharges, item.currencyCode)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.stateTax, item.currencyCode)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.referralFee, item.currencyCode)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.fulfillmentFee, item.currencyCode)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.otherFees, item.currencyCode)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.totalFees, item.currencyCode)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.totalCtl, item.currencyCode)}</td>
                                                        </tr>
                                                    )}
                                                    {this.state.displayFormat === "formatted" &&
                                                        <React.Fragment>
                                                            {group.totalCtl.map((t, i) =>
                                                                <tr className="bg-light reportFooter">
                                                                    <th colSpan={3}>
                                                                        {i === 0 &&
                                                                            <span>
                                                                                TOTAL # of ORDERS
                                                                            </span>
                                                                        }
                                                                    </th>
                                                                    <th>{group.currencyItemCounts[t.currency.toLowerCase()]}</th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th className="currency">{this.utils.toCurrency(group.productCharges[i].amount, group.productCharges[i].currency)}</th>
                                                                    <th className="currency">{this.utils.toCurrency(group.stateTax[i].amount, group.stateTax[i].currency)}</th>
                                                                    <th className="currency">{this.utils.toCurrency(group.referralFee[i].amount, group.referralFee[i].currency)}</th>
                                                                    <th className="currency">{this.utils.toCurrency(group.fulfillmentFee[i].amount, group.fulfillmentFee[i].currency)}</th>
                                                                    <th className="currency">{this.utils.toCurrency(group.otherFees[i].amount, group.otherFees[i].currency)}</th>
                                                                    <th className="currency">{this.utils.toCurrency(group.totalFees[i].amount, group.totalFees[i].currency)}</th>
                                                                    <th className="currency">{this.utils.toCurrency(t.amount, t.currency)}</th>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                    }
                                                </tbody>
                                            </React.Fragment>
                                        )}
                                        {this.state.displayFormat === "formatted" &&
                                            <tbody>
                                                {this.state.report.productCharges.map((c, i) =>
                                                    <tr className="bg-dark reportFooter">
                                                        <th colSpan={3}>
                                                            {i === 0 &&
                                                                <span>
                                                                    TOTAL # of ORDERS
                                                                </span>
                                                            }
                                                        </th>
                                                        <th>{this.state.report.currencyItemCounts[c.currency.toLowerCase()]}</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th className="currency">{this.utils.toCurrency(this.state.report.productCharges[i].amount, this.state.report.productCharges[i].currency)}</th>
                                                        <th className="currency">{this.utils.toCurrency(this.state.report.stateTax[i].amount, this.state.report.stateTax[i].currency)}</th>
                                                        <th className="currency">{this.utils.toCurrency(this.state.report.referralFee[i].amount, this.state.report.referralFee[i].currency)}</th>
                                                        <th className="currency">{this.utils.toCurrency(this.state.report.fulfillmentFee[i].amount, this.state.report.fulfillmentFee[i].currency)}</th>
                                                        <th className="currency">{this.utils.toCurrency(this.state.report.otherFees[i].amount, this.state.report.otherFees[i].currency)}</th>
                                                        <th className="currency">{this.utils.toCurrency(this.state.report.totalFees[i].amount, this.state.report.totalFees[i].currency)}</th>
                                                        <th className="currency">{this.utils.toCurrency(this.state.report.totalCtl[i].amount, this.state.report.totalCtl[i].currency)}</th>
                                                    </tr>
                                                )}
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default AmazonOrdersReport;
