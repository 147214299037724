import React, { Component } from 'react';
import FulfillList from './FulfillList';

class WebFulfillList extends Component {

    render() {
        return <FulfillList title="Web Orders to Fulfill" web={true} paramsKey="params.web-fulfill-list"></FulfillList>
    }
}

export default WebFulfillList