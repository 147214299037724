import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CustomerHistoryList from './CustomerHistoryList';
import { HttpRequestService } from '../../services/HttpRequestService';

class CustomerHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: {}
        }
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getCustomer();
    }

    getCustomer() {
        var params = {
            customerId: this.props.match.params.id
        }
        this.http.get('/api/Customers/GetCustomer', params).then(data => {
            this.setState({
                customer: data
            });
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Customer History</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Customer Information</div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex">
                                    <div className="col pt-1">
                                        <h6 className="fw-bold mb-1">CUSTOMER NUMBER</h6>
                                        <small className="text-muted"><Link to={"/customer/" + this.state.customer.customerID}>{this.state.customer.customerNumber}</Link></small>
                                    </div>
                                    <div className="col pt-1">
                                        <h6 className="fw-bold mb-1">CUSTOMER NAME</h6>
                                        <small className="text-muted">{this.state.customer.companyName}</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <CustomerHistoryList displaySummary={true} printColumns={true} customerId={this.props.match.params.id} all={true}>
                                </CustomerHistoryList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerHistory;
