import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import CouponInformation from './CouponInformation';

class AddCoupon extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.handleCouponInformationChange = this.handleCouponInformationChange.bind(this);
        this.createCoupon = this.createCoupon.bind(this);
    }

    handleCouponInformationChange(couponInformation) {
        this.couponInformation = couponInformation;
    }

    createCoupon() {
        var params = this.couponInformation;
        this.http.post('/api/Admin/CreateCoupon', params).then(data => {
            this.props.history.push('/admin/coupons/' + data.couponID);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Create Coupon</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Coupon Information</div>
                            </div>
                            <div className="card-body">
                                <CouponInformation onChange={this.handleCouponInformationChange}></CouponInformation>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createCoupon}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddCoupon)