import React, { Component } from 'react';
import fileDownload from 'js-file-download'
import Modal from './Modal';
import Select from 'react-select';
import Checkbox from './Checkbox';
import { HttpRequestService } from '../services/HttpRequestService';
import { UtilsService } from '../services/UtilsService';
import moment from 'moment'

class Report extends Component {

    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.exportReportToExcel = this.exportReportToExcel.bind(this);
        this.printReport = this.printReport.bind(this);
        this.selectColumns = this.selectColumns.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.openReportModal = this.openReportModal.bind(this);
        this.loadReport = this.loadReport.bind(this);
        this.handleSelectedColumnsChange = this.handleSelectedColumnsChange.bind(this);
        this.handleSaveAsChange = this.handleSaveAsChange.bind(this);
        this.handleIsPublicChange = this.handleIsPublicChange.bind(this);
        this.cardBody = React.createRef();
        console.log(props);
        this.state = {
            selectedColumns: props.selectedColumns ? props.selectedColumns : [],
            saveAs: props.saveAs ? props.saveAs : "",
            isPublic: false,
            reports: []
        };
    }

    handleSelectedColumnsChange(e) {
        this.setState({
            selectedColumns: e
        })
    }

    handleSaveAsChange(e) {
        this.setState({
            saveAs: e.target.value
        });
    }

    handleIsPublicChange(value) {
        this.setState({
            isPublic: value
        });
    }

    exportReportToExcel(e) {
        if(!this.props.onExportReportToExcel){
            var cardBody = this.cardBody.current;
            fileDownload(this.preparePrintableHtml(cardBody, true), this.props.name + ".xls");
        }
        else{
            this.props.onExportReportToExcel();
        }
    }

    printReport(e) {
        var cardBody = this.cardBody.current;
        var printWindow = window.open('', 'print window', 'height=400,width=600');
        var html = this.preparePrintableHtml(cardBody, false);
        printWindow.document.open();
        printWindow.document.write(html);
        printWindow.document.close();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 3000);
        return true;
    }

    selectColumns() {
        if (this.props.onColumnsSelected) {
            var result = this.state.selectedColumns.map((value) => {
                return { fieldName: value.value }
            })
            this.props.onColumnsSelected(result);
        }

        window.$("#selectColumnsModal").modal("hide");
    }

    saveReport(overwrite = false) {
        var params = {
            reportName: this.state.saveAs,
            reportType: this.props.saveAsType,
            isPublic: this.state.isPublic,
            params: JSON.stringify(this.props.saveParams)
        }
        this.http.get('/api/Report/SaveReportData', { model: JSON.stringify(params), overwrite: overwrite }).then(result => {
            if (result.success) {
                window.$("#saveReportModal").modal("hide");
                this.utils.success("");
            }
            else {
                if (result.reason === "NameIsInUse") {
                    this.utils.confirm("A report with same name exists. You may overwrite it or submit a new name.", () => {
                        this.saveReport(true);
                    }, null, "Overwrite!", "Submit New Name");
                }
            }
        });
    }

    openReportModal() {
        var params = {
            reportType: this.props.saveAsType
        }
        this.http.get('/api/Report/GetReportDataListForType', params).then(data => {
            this.setState({
                reports: data
            });
            window.$("#openReportModal").modal("show");
        });
    }

    loadReport(report) {
        this.setState({
            saveAs: report.reportName,
        })
        if (this.props.onReportDataLoad) {
            this.props.onReportDataLoad(report.params);
        }
        window.$("#openReportModal").modal("hide");
    }

    deleteReport(report) {
        var message = "This report will be deleted. Do you want to continue?";
        this.utils.confirm(message, () => {
            this.http.get('/api/Report/DeleteReportData', { id: report.reportID }).then(data => {
                this.openReportModal();
            });
        })
    }

    preparePrintableHtml(element, noCSS) {
        var html = '<html><head><title></title>';
        if (!noCSS) {
            html += '<link rel="stylesheet" href="' + process.env.PUBLIC_URL + '/assets/css/bootstrap.min.css" media="all">';
            html += '<link rel="stylesheet" href="' + process.env.PUBLIC_URL + '/assets/css/atlantis.min.css" media="all">';
            html += '<link rel="stylesheet" href="' + process.env.PUBLIC_URL + '/assets/css/style.css" media="all">';
        }
        html += '</head><body style="background:#fff !important">';

        var div = '<div class="col-md-12">';
        if (!!this.props.title) {
            div += "<h2>" + this.props.title + "</h2>";
        }
        if (!!this.props.description) {
            div += "<h4>" + this.props.description + "</h4>";
        }
        div += element.innerHTML;
        div += '</div>';

        var divDom = window.$(div).find(".noprint").remove().end();
        
        // Remove hyperlinks but keep text
        divDom.find("a").each(function(){
            var linkText = window.$(this).text();
            window.$(this).before(linkText);
            window.$(this).remove();
        });

        div = divDom[0].outerHTML

        html += div;
        html += '</body></html>';
        
        return html;
    }

    render() {
        var username = localStorage.getItem("username");
        var columnOptions = [];
        if (this.props.availableColumns) {
            columnOptions = this.props.availableColumns.map((value, index) => {
                return { label: value.label, value: value.fieldName }
            });
        }

        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-header">
                        <div className="card-head-row">
                            <div className="card-tools">
                                <button className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={this.printReport}>
                                    <span className="btn-label">
                                        <i className="fas fa-print"></i>
                                    </span>
                                    PRINT
							</button>
                                <button className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={this.exportReportToExcel}>
                                    <span className="btn-label">
                                        <i className="fas fa-file-excel"></i>
                                    </span>
                                    EXPORT TO EXCEL
							</button>
                                {this.props.allowColumnSelection &&
                                    <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#selectColumnsModal">
                                        <span className="btn-label">
                                            <i className="fas fa-columns"></i>
                                        </span>
                                        SELECT COLUMNS
                                </button>
                                }
                                {this.props.allowSave &&
                                    <React.Fragment>
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#saveReportModal">
                                            <span className="btn-label">
                                                <i className="fas fa-save"></i>
                                            </span>
                                            SAVE
                                        </button>
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={this.openReportModal}>
                                            <span className="btn-label">
                                                <i className="fas fa-folder-open"></i>
                                            </span>
                                            OPEN
                                        </button>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card-body" ref={this.cardBody}>
                        {this.props.hasData &&
                            this.props.children
                        }
                        {!this.props.hasData &&
                            <div className="card-sub">
                                DATA NOT FOUND
                        </div>
                        }
                    </div>
                </div>
                <Modal id="selectColumnsModal" title="Select Columns" onOk={this.selectColumns} paddingBottom={250}>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <Select closeMenuOnSelect={false} closeMenuOnScroll={false} maxMenuHeight={200} options={columnOptions} isMulti value={this.state.selectedColumns} onChange={this.handleSelectedColumnsChange}></Select>
                        </div>
                    </div>
                </Modal>
                <Modal id="saveReportModal" title="Save Report" onOk={this.saveReport}>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label>Save As...</label>
                            <input type="text" className="form-control" value={this.state.saveAs} onChange={this.handleSaveAsChange}></input>
                        </div>
                        <div className="form-group col-md-12">
                            <Checkbox label="Is Public" checked={this.state.isPublic} onChange={this.handleIsPublicChange}></Checkbox>
                        </div>
                    </div>
                </Modal>
                <Modal id="openReportModal" title="Open Report">
                    <div className="form-row">
                        <table className="table table-striped mt-1">
                            <thead>
                                <tr>
                                    <th scope="col">Report Name</th>
                                    <th scope="col">Created By</th>
                                    <th scope="col">Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.reports.map((report) =>
                                    <tr key={report.reportID}>
                                        <td><button className="btn btn-link pl-0" type="button" onClick={(e) => this.loadReport(report)}>{report.reportName}</button></td>
                                        <td>{report.createdBy}</td>
                                        <td>{moment(report.timestamp).format('l')}</td>
                                        <td>
                                            {report.createdBy === username &&
                                                <button type="button" className="btn btn-sm btn-danger" onClick={(e) => this.deleteReport(report, e)}><i className="fas fa-trash"></i></button>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
export default Report