import React, { Component } from 'react';
import { Link } from "react-router-dom";
import MonthlySales from './MonthlySales';

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="panel-header bg-primary-gradient">
          <div className="page-inner py-5">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div>
                <h2 className="text-white pb-2 fw-bold">Dashboard</h2>
              </div>
              <div className="ml-md-auto py-2 py-md-0">
                <Link to="/orders/start-new" className="btn btn-secondary btn-round">Add Order</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row">
            <div className="col-md-8">
              <MonthlySales></MonthlySales>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
