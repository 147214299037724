import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class SmtpAccountSelect extends Component {

    constructor(props) {
        super(props);
        this.state = { accounts: [] };
        this.getAccounts = this.getAccounts.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getAccounts();
    }

    componentWillUnmount() {

    }

    getAccounts() {
        this.http.get('/api/Admin/GetSmtpAccounts', null).then(data=>{
            this.setState({ accounts: data });
            if(data.length > 0 && this.props.value === 0){
                this.props.onChange(data[0].key);
            }
        });
    }

    handleChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {
        var options = this.state.accounts.map((account) =>
            <option key={account.accountId} value={account.accountId}>{account.name + " <" + account.username +"> "}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {this.props.hasAllOption === true &&
                    <option value={"-1"}>-- ALL --</option>
                }
                {options}
            </select>
        );
    }
}
export default SmtpAccountSelect