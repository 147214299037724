import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import WebsiteCategorySelect from '../../common/WebsiteCategorySelect';
import ProductSelect from '../../common/ProductSelect';
import CategoryInformation from './CategoryInformation';
import FileInput from '../../common/FileInput';

class EditCategory extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            category: null,
            products: [],
            subCategories: [],
            selectedSubCategory: 0,
            selectedProduct: 0,
        }
        this.handleCategoryInformationChange = this.handleCategoryInformationChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleSubCategoryChange = this.handleSubCategoryChange.bind(this);
        this.handleSmallImageChange = this.handleSmallImageChange.bind(this);
        this.handleLargeImageChange = this.handleLargeImageChange.bind(this);
        this.handleOtherImageChange = this.handleOtherImageChange.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.addSubCategory = this.addSubCategory.bind(this);
        this.removeSubCategory = this.removeSubCategory.bind(this);
    }

    componentDidMount() {
        this.getCategory();
    }

    handleCategoryInformationChange(categoryInformation) {
        this.categoryInformation = categoryInformation;
    }

    handleProductChange(product) {
        this.setState({
            selectedProduct: product.productID
        });
    }

    handleSubCategoryChange(category) {
        this.setState({
            selectedSubCategory: category
        });
    }

    handleSmallImageChange(file) {
        var params = {
          operation: "SaveCategorySmallImage",
          categoryId: this.props.match.params.id
        }
        this.http.upload('/api/FileUpload/UploadFile/', file, params).then(data => {
          this.getCategory();
        });
      }
    
      handleLargeImageChange(file) {
        var params = {
          operation: "SaveCategoryLargeImage",
          categoryId: this.props.match.params.id
        }
        this.http.upload('/api/FileUpload/UploadFile/', file, params).then(data => {
          this.getCategory();
        });
      }
    
      handleOtherImageChange(file) {
        var params = {
          operation: "SaveCategoryOtherImage",
          categoryId: this.props.match.params.id
        }
        this.http.upload('/api/FileUpload/UploadFile/', file, params).then(data => {
          this.getCategory();
        });
      }

    getCategory() {
        var params = {
            id: this.props.match.params.id
        }
        this.http.get('/api/Admin/GetCategory', params).then(data => {
            data.category.userDefinedFields = data.userDefinedFields;
            this.updateState(data);
        })
    }

    updateCategory() {
        var params = {... this.categoryInformation};
        params.userDefinedFields = this.utils.prepareUserDefinedFieldParams(this.categoryInformation.userDefinedFields);
        params.categoryId = this.props.match.params.id;
        this.http.post('/api/Admin/UpdateCategory', params).then(data => {
            this.utils.success("");
        });
    }

    addSubCategory() {
        var params = {
            categoryId: this.props.match.params.id,
            subCategoryId: this.state.selectedSubCategory
        }
        this.http.post('/api/Admin/AddSubCategory', params).then(data => {
            window.$("#addSiteModal").modal("hide");
            this.updateState(data);
        });
    }

    removeSubCategory(category) {
        var params = {
            categoryId: this.props.match.params.id,
            subCategoryId: category.categoryID
        }
        this.http.post('/api/Admin/RemoveSubCategory', params).then(data => {
            this.updateState(data);
        });
    }

    addProduct() {
        var params = {
            categoryId: this.props.match.params.id,
            productId: this.state.selectedProduct
        }
        this.http.post('/api/Admin/AddCategoryProduct', params).then(data => {
            window.$("#addProductModal").modal("hide");
            this.updateState(data);
        });
    }

    removeProduct(product) {
        var params = {
            categoryId: this.props.match.params.id,
            productId: product.productID
        }
        this.http.post('/api/Admin/RemoveCategoryProduct', params).then(data => {
            this.updateState(data);
        });
    }

    updateState(data) {
        this.setState({
            category: data.category,
            products: this.utils.dictToArray(data.products),
            subCategories: this.utils.dictToArray(data.category.categories)
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Edit Category</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Category Information</div>
                            </div>
                            <div className="card-body">
                                {this.state.category &&
                                    <CategoryInformation updating={true} category={this.state.category} onChange={this.handleCategoryInformationChange}></CategoryInformation>
                                }
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateCategory}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Products in this category</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addProductModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
											</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Product</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.products.map(item =>
                                                <tr key={item.productID}>
                                                    <td>{item.name}</td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeProduct(item, e)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Modal id="addProductModal" title="Add Product" onOk={this.addProduct}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="site">Product</label>
                                            <ProductSelect allProducts={true} value={this.state.selectedProduct} onChange={this.handleProductChange}></ProductSelect>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Sub-Categories</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addSubCategoryModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
											</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Category</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.subCategories.map(item =>
                                                <tr key={item.categoryID}>
                                                    <td>{item.name}</td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeSubCategory(item, e)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Modal id="addSubCategoryModal" title="Add Sub-Category" onOk={this.addSubCategory}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="site">Category</label>
                                            <WebsiteCategorySelect value={this.state.selectedSubCategory} onChange={this.handleSubCategoryChange}></WebsiteCategorySelect>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>

                </div>
                {this.state.category &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card full-height">
                                <div className="card-header">
                                    <div className="card-head-row">
                                        <div className="card-title">Category Images</div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="form-row align-items-start">
                                        <div className="form-group col-md-6">
                                            <label>Small Image</label>
                                            <div className="form-control">
                                                {this.state.category.smallImage !== "" &&
                                                    <img className="col-md-12 mb-4 p-0" src={this.http.HOST + "/api/RenderObject?content=img&src=" + this.state.category.smallImage + "&c=" + new Date().getTime()} alt="Small" />
                                                }
                                                <FileInput label="Upload File" onChange={this.handleSmallImageChange}></FileInput>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Large Image</label>
                                            <div className="form-control">
                                                {this.state.category.largeImage !== "" &&
                                                    <img className="col-md-12 mb-4 p-0" src={this.http.HOST + "/api/RenderObject?content=img&src=" + this.state.category.largeImage + "&c=" + new Date().getTime()} alt="Small" />
                                                }
                                                <FileInput label="Upload File" onChange={this.handleLargeImageChange}></FileInput>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Other Category Image</label>
                                            <div className="form-control">
                                                {this.state.category.otherImage !== "" &&
                                                    <img className="col-md-12 mb-4 p-0" src={this.http.HOST + "/api/RenderObject?content=img&src=" + this.state.category.otherImage + "&c=" + new Date().getTime()} alt="Small" />
                                                }
                                                <FileInput label="Upload File" onChange={this.handleOtherImageChange}></FileInput>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default EditCategory