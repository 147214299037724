import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';

class ASINInformation extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            name: props.asin ? props.asin.name : "",
        };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.notifyChange();
    }

    handleNameChange(e) {
        this.setState({
            name: e.target.value
        }, this.notifyChange)
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label>ASIN</label>
                    <input type="text" className="form-control" value={this.state.name} onChange={this.handleNameChange}></input>
                </div>
            </div>
        );
    }
}

export default ASINInformation;
