import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import UserDefinedFieldEditor from '../../common/UserDefinedFieldEditor';

class AccountAssociations extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            customerID: props.account ? props.account.customerID : 0,
            userDefinedFields: [],
        };

        this.getUserDefinedFields = this.getUserDefinedFields.bind(this);
        this.notifyChange();
    }

    componentDidMount() {
        this.getUserDefinedFields();
    }

    getUserDefinedFields() {
        var params = {
            entity: "Account"
        }
        this.http.get("/api/Crm/GetUserDefinedFieldsForEntity", params).then(data => {
            // match values with fields
            for (var i = 0; i < data.length; i++) {
                if (this.props.account && this.props.account.userDefinedFields) {
                    var accountFieldValue = this.props.account.userDefinedFields.find(f => f.userDefinedFieldID === data[i].userDefinedFieldID);
                    if (data[i].selection === "Single") {
                        data[i].value = accountFieldValue ? accountFieldValue.userDefinedFieldOptionID : -1;
                    }
                    else {
                        data[i].value = accountFieldValue ? accountFieldValue.userDefinedFieldOptionIDs : [];
                    }
                }
                else {
                    if (data[i].selection === "Single") {
                        data[i].value = -1;
                    }
                    else {
                        data[i].value = [];
                    }
                }
            }
            this.setState({ userDefinedFields: data }, this.notifyChange);
        });
    }

    handleFieldChange(index, e) {
        var userDefinedFields = this.state.userDefinedFields;
        userDefinedFields[index].value = e;
        this.setState({
            userDefinedFields: userDefinedFields
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <div className="form-row">
                <div className="col-md-12">
                    {this.state.userDefinedFields.map((field, index) =>
                        <div key={field.userDefinedFieldID} className="form-group col-md-12">
                            <UserDefinedFieldEditor field={field} value={field.value} onChange={(e) => this.handleFieldChange(index, e)}></UserDefinedFieldEditor>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default AccountAssociations;
