import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import ProductSelect from '../../common/ProductSelect';
import ASINInformation from './ASINInformation';

class ASINDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asin: null,
      inventoryProducts: [],
      asinProduct: -1,
      asinQuantity: 0,
    };
    this.http = new HttpRequestService();
    this.utils = new UtilsService();
    this.handleASINInfoChange = this.handleASINInfoChange.bind(this);
    this.handleASINProductChange = this.handleASINProductChange.bind(this);
    this.handleASINQuantityChange = this.handleASINQuantityChange.bind(this);

    this.updateASIN = this.updateASIN.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
  }

  componentDidMount() {
    this.getASIN();
  }


  handleASINInfoChange(value) {
    this.asinINFO = value;
  }

  handleASINProductChange(value) {
    this.setState({
      asinProduct: value.productID
    })
  }

  handleASINQuantityChange(e) {
    this.setState({
      asinQuantity: e.target.value
    })
  }

  addProduct() {
    var params = {
      asinId: this.props.match.params.id,
      productId: this.state.asinProduct,
      quantity: this.state.asinQuantity
    }
    this.http.get('/api/Inventory/AddASINProduct', params).then(data => {
      window.$("#addProductModal").modal("hide");
      this.updateState(data);
    });
  }

  removeProduct(product) {
    var params = {
      asinId: this.props.match.params.id,
      productId: product.productID
    }
    this.http.get('/api/Inventory/RemoveASINProduct', params).then(data => {
      this.updateState(data);
    });
  }

  getASIN() {
    var params = {
      asinID: this.props.match.params.id
    }
    this.http.get('/api/Inventory/GetASIN', params).then(data => {
      this.updateState(data);
    });
  }

  updateState(data) {
    this.setState({
      asin: data,
      inventoryProducts: this.utils.dictToArray(data.inventoryProducts),
    });
  }

  updateASIN() {
    var model = this.asinINFO;
    model.asinId = this.props.match.params.id;
    var params = {
      model: JSON.stringify(model)
    }
    this.http.get('/api/Inventory/UpdateASIN', params).then(data => {
      this.utils.success("");
      this.updateState(data);
    });
  }

  render() {
    return (
      <div className="page-inner">
        <div className="page-header">
          <h4 className="page-title">ASIN Detail</h4>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.state.asin &&
              <div className="card full-height">
                <div className="card-header">
                  <div className="card-title">ASIN Information</div>
                </div>
                <div className="card-body">
                  <ASINInformation asin={this.state.asin} onChange={this.handleASINInfoChange}></ASINInformation>
                </div>
                <div className="card-footer">
                  <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateASIN}>Update</button>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card full-height">
              <div className="card-header">
                <div className="card-head-row">
                  <div className="card-title">Products</div>
                  <div className="card-tools">
                    <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addProductModal">
                      <span className="btn-label">
                        <i className="fas fa-plus"></i>
                      </span>
                      Add
											</button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <table className="table table-striped table-bordered mt-3">
                    <thead>
                      <tr>
                        <th scope="col">Product Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.asin && this.state.inventoryProducts.map(item =>
                        <tr key={item.productID}>
                          <td>{item.name}</td>
                          <td>{item.quantity}</td>
                          <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeProduct(item, e)}><i className="fas fa-trash"></i></button></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Modal id="addProductModal" title="Add Product" onOk={this.addProduct}>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>Product</label>
                      <ProductSelect allProducts={true} value={this.state.asinProduct} onChange={this.handleASINProductChange}></ProductSelect>
                    </div>
                    <div className="form-group col-md-12">
                      <label>Quantity</label>
                      <input type="text" className="form-control" value={this.state.asinQuantity} onChange={this.handleASINQuantityChange}></input>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ASINDetail;
