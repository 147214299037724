import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import SmtpAccountInformation from './SmtpAccountInformation';

class AddSmtpAccount extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.handleAccountInformationChange = this.handleAccountInformationChange.bind(this);
        this.createSmtpAccount = this.createSmtpAccount.bind(this);
    }

    handleAccountInformationChange(accountInformation) {
        this.accountInformation = accountInformation;
    }

    createSmtpAccount() {
        var params = this.accountInformation;
        this.http.post('/api/Admin/CreateSmtpAccount', params).then(data => {
            this.props.history.push('/admin/smtp-accounts/');
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Create SMTP Account</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Account Information</div>
                            </div>
                            <div className="card-body">
                                <SmtpAccountInformation onChange={this.handleAccountInformationChange}></SmtpAccountInformation>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createSmtpAccount}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddSmtpAccount)