import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import UserDefinedField from './UserDefinedField';
import Modal from '../../common/Modal';

class EditUserDefinedField extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            field: null,
            optionName: "",
            optionOrder: 0
        }
        this.handleFieldInformationChange = this.handleFieldInformationChange.bind(this);
        this.handleOptionNameChange = this.handleOptionNameChange.bind(this);
        this.handleOptionOrderChange = this.handleOptionOrderChange.bind(this);
        this.addOption = this.addOption.bind(this);
        this.editOption = this.editOption.bind(this);
        this.saveOption = this.saveOption.bind(this);
        this.updateField = this.updateField.bind(this);
    }

    componentDidMount() {
        this.getField();
    }

    handleOptionNameChange(e) {
        this.setState({
            optionName: e.target.value
        })
    }

    handleOptionOrderChange(e) {
        this.setState({
            optionOrder: e.target.value
        })
    }

    handleFieldInformationChange(fieldInformation) {
        this.fieldInformation = fieldInformation;
    }

    getField() {
        var params = {
            id: this.props.match.params.id
        }
        this.http.get('/api/Crm/GetUserDefinedField', params).then(data => {
            this.updateState(data);
        })
    }

    updateField() {
        var entities = [];
        if(this.fieldInformation.entities){
            entities = this.fieldInformation.entities.map((value, index) => {
                return { entity: value.value }
            });
        }
        var params = {
            userDefinedFieldID: this.props.match.params.id,
            name: this.fieldInformation.name,
            position: this.fieldInformation.position,
            selection: this.fieldInformation.selection,
            filterCriteria: this.fieldInformation.filterCriteria,
            entities: entities
        }
        this.http.post('/api/Crm/SaveUserDefinedField', params).then(data => {
            this.utils.success("");
        });
    }

    handleOptionChange(phoneNumber) {
        this.newPhoneNumber = phoneNumber;
    }

    editOption(option){
        this.editedOptionId = option.userDefinedFieldOptionID;
        this.setState({
            optionName: option.name,
            optionOrder: option.displayOrder
        }, () => {
            window.$("#addOptionModal").modal("show");
        });
    }

    addOption(){
        this.editedOptionId = 0;
        this.setState({
            optionName: "",
            optionOrder: 0
        }, () => {
            window.$("#addOptionModal").modal("show");
        });
    }

    saveOption() {
        var data = {
            userDefinedFieldOptionID: this.editedOptionId,
            userDefinedFieldID: this.props.match.params.id,
            name: this.state.optionName,
            displayOrder: this.state.optionOrder
        }
        this.http.post('/api/Crm/SaveUserDefinedFieldOption', data).then(data => {
            window.$("#addOptionModal").modal("hide");
            this.getField();
        });
    }

    removeOption(option) {
        var params = {
            id: option.userDefinedFieldOptionID
        }
        this.http.post('/api/Crm/RemoveUserDefinedFieldOption', params).then(data => {
            this.getField();
        });
    }

    updateState(data) {
        this.setState({
            field: data,
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Edit Field</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Field Information</div>
                            </div>
                            <div className="card-body">
                                {this.state.field &&
                                    <UserDefinedField field={this.state.field} onChange={this.handleFieldInformationChange}></UserDefinedField>
                                }
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateField}>Update</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Options</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={this.addOption}>
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
											</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card-category">
                                    <p><small className="text-muted"><i className="fas fa-asterisk"></i> Options are sorted first by order then by name.</small></p>
                                </div>
                                <div className="form-row">
                                    <table className="table table-striped table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Option</th>
                                                <th scope="col">Order</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.field && this.state.field.options.map(item =>
                                                <tr key={item.userDefinedFieldOptionID}>
                                                    <td>{item.name}</td>
                                                    <td>{item.displayOrder}</td>
                                                    <td>
                                                        <button className="btn btn-sm" onClick={(e) => this.editOption(item, e)}><i className="fas fa-pen"></i></button>
                                                        &nbsp;
                                                        <button className="btn btn-sm btn-danger" onClick={(e) => this.removeOption(item, e)}><i className="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Modal id="addOptionModal" title={this.editedOptionId === 0 ? "Add Option" : "Edit Option"} onOk={this.saveOption}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label>Name</label>
                                            <input type="text" value={this.state.optionName} onChange={this.handleOptionNameChange} className="form-control" />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Display Order</label>
                                            <input type="text" value={this.state.optionOrder} onChange={this.handleOptionOrderChange} className="form-control" />
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditUserDefinedField