import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import ContactInformation from './ContactInformation';
import PhoneNumber from '../../common/PhoneNumber';
import Scrollable from '../../common/Scrollable';
import NoteList from './NoteList';
import Note from './Note';

class ContactDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: null,
        };
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleContactInfoChange = this.handleContactInfoChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.addPhoneNumber = this.addPhoneNumber.bind(this);
        this.removePhoneNumber = this.removePhoneNumber.bind(this);
        this.updateContact = this.updateContact.bind(this);
        this.addNote = this.addNote.bind(this);
        this.handleNewNoteChange = this.handleNewNoteChange.bind(this);
        this.handleNoteListUpdate = this.handleNoteListUpdate.bind(this);
        this.openEditPhoneNumberModal = this.openEditPhoneNumberModal.bind(this);
        this.editPhoneNumber = this.editPhoneNumber.bind(this);
        this.handleEditedPhoneNumberChange = this.handleEditedPhoneNumberChange.bind(this);
    }

    componentDidMount() {
        this.getContact();
        window.$(".card-body.scroll > .scrollbar-inner").scrollbar();
    }

    componentWillUnmount() {
    }

    handleContactInfoChange(value) {
        this.contactInfo = value;
    }

    handlePhoneNumberChange(phoneNumber) {
        this.newPhoneNumber = phoneNumber;
    }

    addPhoneNumber() {
        var model = this.newPhoneNumber;
        model.ownerID = this.props.match.params.id;
        model.ownerTypeID = 1
        this.http.post('/api/Crm/AddPhoneNumber', model).then(data => {
            window.$("#addPhoneNumberModal").modal("hide");
            this.getContact();
        });
    }

    removePhoneNumber(phone) {
        var params = {
            id: phone.phoneNumberID
        }
        this.http.post('/api/Crm/RemovePhoneNumber', params).then(data => {
            this.getContact();
        });
    }

    getContact() {
        var params = {
            contactId: this.props.match.params.id
        }
        this.http.get('/api/Crm/GetContact', params).then(data => {
            this.updateState(data);
        });
    }

    openEditPhoneNumberModal(phoneNumber) {
        this.setState({
            editedPhoneNumber: phoneNumber
        });
        window.$("#editPhoneNumberModal").modal("show");
    }

    handleEditedPhoneNumberChange(value) {
        this.setState({
            editedPhoneNumber: value
        })
    }

    editPhoneNumber() {
        var model = this.state.editedPhoneNumber;
        model.ownerID = this.props.match.params.id;
        model.ownerTypeID = 1;
        this.http.post('/api/Crm/UpdatePhoneNumber', model).then(data => {
            window.$("#editPhoneNumberModal").modal("hide");
            this.getContact();
        });
    }

    updateContact() {
        // prepare data for api call
        var userDefinedFields = this.utils.prepareUserDefinedFieldParams(this.contactInfo.userDefinedFields);
        var data = {
            firstName: this.contactInfo.firstName,
            lastName: this.contactInfo.lastName,
            email: this.contactInfo.email,
            isPrimary: this.contactInfo.isPrimary,
            accountID: this.contactInfo.account ? this.contactInfo.account.value : 0,
            userDefinedFields: userDefinedFields,
            contactID: this.props.match.params.id
        }
        this.http.post('/api/Crm/SaveContact', data).then(data => {
            this.utils.success("");
        });
    }

    handleNewNoteChange(note) {
        this.newNote = note;
    }

    handleNoteListUpdate() {
        this.getContact();
    }

    addNote() {
        var model = this.newNote;
        model.ownerId = this.props.match.params.id;
        model.ownerTypeId = 1;
        this.http.post("/api/Crm/AddNote", model).then(data => {
            window.$("#addNoteModal").modal("hide");
            this.getContact();
        });
    }

    updateState(data) {
        this.setState({
            contact: data
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Contact Detail</h4>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-title">General Contact Information</div>
                            </div>
                            <div className="card-body">
                                {this.state.contact &&
                                    <ContactInformation contact={this.state.contact} accountEditable={false} onChange={this.handleContactInfoChange}></ContactInformation>
                                }
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateContact}>Update</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Phone Numbers</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addPhoneNumberModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
											</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Type</th>
                                                <th scope="col">Number</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.contact && this.state.contact.phoneNumbers.map(item =>
                                                <tr key={item.phoneNumberID}>
                                                    <td>{item.type.name}</td>
                                                    <td>{item.number}</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-default p-1" onClick={(e) => this.openEditPhoneNumberModal(item, e)}><i className="fas fa-pen"></i></button>&nbsp;
                                                        <button className="btn btn-sm btn-danger p-1" onClick={(e) => this.removePhoneNumber(item, e)}><i className="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Modal id="addPhoneNumberModal" title="Add Phone Number" onOk={this.addPhoneNumber}>
                                    <div className="form-row">
                                        <PhoneNumber onChange={this.handlePhoneNumberChange}></PhoneNumber>
                                    </div>
                                </Modal>
                                <Modal id="editPhoneNumberModal" title="Edit Phone Number" onOk={this.editPhoneNumber}>
                                    <div className="form-row">
                                        <PhoneNumber value={this.state.editedPhoneNumber} onChange={this.handleEditedPhoneNumberChange}></PhoneNumber>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Notes</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addNoteModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
											</button>
                                    </div>
                                </div>
                            </div>
                            <Scrollable id="contactNotes" height={350}>
                                <div className="card-body">
                                    {this.state.contact &&
                                        <NoteList editable={true} notes={this.state.contact.notes} onUpdate={this.handleNoteListUpdate}></NoteList>
                                    }
                                </div>
                            </Scrollable>
                            <Modal id="addNoteModal" title="Add Note" onOk={this.addNote}>
                                <Note onChange={this.handleNewNoteChange}></Note>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactDetail;
