import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class StateSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
        this.getStates = this.getStates.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getStates();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.countryID !== this.props.countryID) {
            this.getStates();
        }
    }

    getStates() {
        this.http.get('/api/Customers/GetStates', { countryID: this.props.countryID ? this.props.countryID : 211 }).then(data => {
            this.setState({ items: data });
            if (data.length > 0 && this.props.value === 0) {
                this.props.onChange(data[0]);
            }
        });
    }

    handleChange(e) {
        var state = this.state.items.find(t => t.stateID === parseInt(e.target.value));
        if (state) {
            this.props.onChange(state);
        }
        else {
            this.props.onChange({ stateID: e.target.value });
        }
    }

    render() {
        var options = this.state.items.map((item) =>
            <option key={item.stateID} value={item.stateID}>{item.name}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {this.props.hasAllOption === true &&
                    <option value={"-1"}>-- ALL --</option>
                }
                {options}
            </select>
        );
    }
}
export default StateSelect