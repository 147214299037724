import axios from 'axios';
import { UtilsService } from './UtilsService';


export class HttpRequestService {

  HOST = process.env.REACT_APP_BACKEND_HOST;

  BASE_URL = this.HOST;

  constructor() {
    this.utils = new UtilsService();
    if (window.loaderState === undefined) {
      window.loaderState = 0;
    }
  }

  cancel() {
  }

  get(url, params, silent = false, responseType = null) {
    var self = this;    
    if (url.indexOf("Login.aspx") > 0) {
      window.location.href = this.HOST + "/" + process.env.PUBLIC_URL;
      return Promise.reject("");
    }
    if (!silent) {
      this.setLoading(true);
    }

    var config = {
      responseType: responseType,
      params: params, cancelToken: new axios.CancelToken(function executor(c) {
        self.cancel = c;
      })
    }

    var finalUrl = this.BASE_URL + url;
    if (localStorage.getItem("auth-token")) {
      config.headers = { 'Authorization': 'Bearer ' + localStorage.getItem("auth-token") }
      if (!silent) {
        localStorage.setItem("lastRequestTime", new Date().getTime());
      }
    }

    return axios.get(finalUrl, config)
      .then(response => {
        if (!silent) {
          this.setLoading(false);
        }
        return response.data;
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          this.setLoading(false);
          return Promise.reject(error);
        }
        if (!silent && error.response && error.response.status === 401) {
          this.logout();
          return Promise.reject(error);
        }
        if (!silent) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 400) {
              this.utils.warning(error.response.data);
            }
            else if (error.response.status === 500) {
              this.utils.alert(error.response.data);
            }
          } else if (error.request) {
            this.utils.alert("An error occured while sending a request to server");
          } else {
            this.utils.alert("An error occured");
          }

          this.setLoading(false);
        }
        return Promise.reject(error);
      })
  }

  getSilent(url, params) {
    return this.get(url, params, true);
  }

  post(url, data) {
    var finalUrl = this.BASE_URL + url;
    var config = {
    }

    if (localStorage.getItem("auth-token")) {
      config.headers = { 'Authorization': 'Bearer ' + localStorage.getItem("auth-token") }
      localStorage.setItem("lastRequestTime", new Date().getTime());
    }

    this.setLoading(true);
    return axios.post(finalUrl, data, config)
      .then(response => {
        this.setLoading(false);
        return response.data;
      })
      .catch(error => {
        this.setLoading(false);

        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }
        if (error.response && error.response.status === 401) {
          this.logout();
          return Promise.reject(error);
        }

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            this.utils.warning(error.response.data);
          }
          else if (error.response.status === 500) {
            this.utils.alert(error.response.data);
          }
        } else if (error.request) {
          this.utils.alert("An error occured while sending a request to server");
        } else {
          this.utils.alert("An error occured");
        }

        return Promise.reject(error);
      })
  }

  upload(url, file, params) {
    this.setLoading(true);

    var config = {
      headers: { "Content-Type": "multipart/form-data" }
    }
    if (localStorage.getItem("auth-token")) {
      config.headers = { "Content-Type": "multipart/form-data", 'Authorization': 'Bearer ' + localStorage.getItem("auth-token") }
    }

    var formData = new FormData();
    formData.append("image", file);
    if (!!params) {
      var props = Object.keys(params);
      for (var i = 0; i < props.length; i++) {
        formData.append(props[i], params[props[i]]);
      }
    }

    return axios.post(this.BASE_URL + url, formData, config)
      .then(response => {
        this.setLoading(false);
        return response.data;
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          this.logout();
          return Promise.reject(error);
        }
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            this.utils.warning(error.response.data);
          }
          else if (error.response.status === 500) {
            this.utils.alert(error.response.data);
          }
        } else if (error.request) {
          this.utils.alert("An error occured while sending a request to server");
        } else {
          this.utils.alert("An error occured");
        }

        this.setLoading(false);
        return Promise.reject(error);
      })
  }

  logout(){
    localStorage.removeItem("auth-token");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  setLoading(visible) {
    if (visible) {
      if (window.loaderState === 0) {
        document.getElementById("loading-spinner").style.display = "";
      }
      window.loaderState++;
    }
    else {
      window.loaderState--;
      if (window.loaderState <= 0) {
        document.getElementById("loading-spinner").style.display = "none";
      }
    }
  }
}
