import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import Checkbox from '../../common/Checkbox';
import ProductTypeSelect from '../../common/ProductTypeSelect';
import UnitMeasureSelect from '../../common/UnitMeasureSelect';
import CompanySelect from '../../common/CompanySelect';

class ProductInformation extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            name: props.product ? props.product.name : "",
            type: props.product ? props.product.type : 0,
            productNumber: props.product ? props.product.productNumber : "",
            upcBarCode: props.product ? props.product.upc : "",
            unitMeasure: props.product ? props.product.unitMeasure : 0,
            companyId: props.product ? props.product.company.key : 0,
            wholesalePrice: props.product ? props.product.wholesalePrice : 0,
            retailPrice: props.product ? props.product.retailPrice : 0,
            salePrice: props.product ? props.product.salePrice : 0,
            cost: props.product ? props.product.cost : 0,
            reOrderLevel: props.product ? props.product.reorderLevel : 0,
            casePackSize: props.product ? props.product.casePackSize : 0,
            lotNumber: props.product ? props.product.lotNumExpDateComment : "",
            active: props.product ? props.product.active : true,
            hidden: props.product ? props.product.hidden : false,
            commission: props.product ? props.product.commission : false,
            isWebBackOrdered: props.product ? props.product.isWebBackOrdered : false,
            isPowerDBackOrdered: props.product ? props.product.isPowerDBackOrdered : false,
            keepInventory: props.product ? props.product.keepInventory : false,
            allowDiscount: props.product ? props.product.allowDiscount : false,
            featured: props.product ? props.product.featured : false,
            caProp65: props.product ? props.product.caProp65 : false,
        };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleProductNumberChange = this.handleProductNumberChange.bind(this);
        this.handleBarcodeChange = this.handleBarcodeChange.bind(this);
        this.handleUnitMeasureChange = this.handleUnitMeasureChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleWholesalePriceChange = this.handleWholesalePriceChange.bind(this);
        this.handleRetailPriceChange = this.handleRetailPriceChange.bind(this);
        this.handleSalePriceChange = this.handleSalePriceChange.bind(this);
        this.handleCostChange = this.handleCostChange.bind(this);
        this.handleReOrderLevelChange = this.handleReOrderLevelChange.bind(this);
        this.handleCasePackSizeChange = this.handleCasePackSizeChange.bind(this);
        this.handleLotNumberChange = this.handleLotNumberChange.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        this.handleHiddenChange = this.handleHiddenChange.bind(this);
        this.handleCommissionChange = this.handleCommissionChange.bind(this);
        this.handleWebBackOrderedChange = this.handleWebBackOrderedChange.bind(this);
        this.handlePowerDBackOrderedChange = this.handlePowerDBackOrderedChange.bind(this);
        this.handleKeepInventoryChange = this.handleKeepInventoryChange.bind(this);
        this.handleAllowDiscountChange = this.handleAllowDiscountChange.bind(this);
        this.handleFeaturedChange = this.handleFeaturedChange.bind(this);
        this.handlaCAProp65Change = this.handlaCAProp65Change.bind(this);
        this.notifyChange();
    }
    
    handleNameChange(e) {
        this.setState({
            name: e.target.value
        }, this.notifyChange)
    }

    handleTypeChange(typeId) {
        this.setState({
            type: typeId
        }, this.notifyChange)
    }

    handleProductNumberChange(e) {
        this.setState({
            productNumber: e.target.value
        }, this.notifyChange)
    }

    handleBarcodeChange(e) {
        this.setState({
            upcBarCode: e.target.value
        }, this.notifyChange)
    }

    handleUnitMeasureChange(value) {
        this.setState({
            unitMeasure: value
        }, this.notifyChange)
    }

    handleCompanyChange(value) {
        this.setState({
            companyId: value
        }, this.notifyChange)
    }

    handleWholesalePriceChange(e) {
        this.setState({
            wholesalePrice: e.target.value
        }, this.notifyChange)
    }

    handleRetailPriceChange(e) {
        this.setState({
            retailPrice: e.target.value
        }, this.notifyChange)
    }

    handleSalePriceChange(e) {
        this.setState({
            salePrice: e.target.value
        }, this.notifyChange)
    }

    handleCostChange(e) {
        this.setState({
            cost: e.target.value
        }, this.notifyChange)
    }

    handleReOrderLevelChange(e) {
        this.setState({
            reOrderLevel: e.target.value
        }, this.notifyChange)
    }

    handleCasePackSizeChange(e) {
        this.setState({
            casePackSize: e.target.value
        }, this.notifyChange)
    }

    handleLotNumberChange(e) {
        this.setState({
            lotNumber: e.target.value
        }, this.notifyChange)
    }

    handleActiveChange(checked){
        this.setState({
            active: checked
        }, this.notifyChange)
    }

    handleHiddenChange(checked){
        this.setState({
            hidden: checked
        }, this.notifyChange)
    }

    handleCommissionChange(checked) {
        this.setState({
            commission: checked
        }, this.notifyChange)
    }

    handleWebBackOrderedChange(checked) {
        this.setState({
            isWebBackOrdered: checked
        }, this.notifyChange)
    }

    handlePowerDBackOrderedChange(checked) {
        this.setState({
            isPowerDBackOrdered: checked
        }, this.notifyChange)
    }

    handleKeepInventoryChange(checked) {
        this.setState({
            keepInventory: checked
        }, this.notifyChange)
    }

    handleAllowDiscountChange(checked) {
        this.setState({
            allowDiscount: checked
        }, this.notifyChange)
    }

    handleFeaturedChange(checked) {
        this.setState({
            featured: checked
        }, this.notifyChange)
    }

    handlaCAProp65Change(checked) {
        this.setState({
            caProp65: checked
        }, this.notifyChange)
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label>Item Name</label>
                        <input type="text" className="form-control" value={this.state.name} onChange={this.handleNameChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Item Type</label>
                        <ProductTypeSelect value={this.state.type} onChange={this.handleTypeChange}></ProductTypeSelect>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Item Number</label>
                        <input type="text" className="form-control" value={this.state.productNumber} onChange={this.handleProductNumberChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>UPC Bar Code</label>
                        <input type="text" className="form-control" value={this.state.upcBarCode} onChange={this.handleBarcodeChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>U/M</label>
                        <UnitMeasureSelect value={this.state.unitMeasure} onChange={this.handleUnitMeasureChange}></UnitMeasureSelect>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Company</label>
                        <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange}></CompanySelect>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Wholesale $</label>
                        <input type="text" className="form-control" value={this.state.wholesalePrice} onChange={this.handleWholesalePriceChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>MSRP $</label>
                        <input type="text" className="form-control" value={this.state.retailPrice} onChange={this.handleRetailPriceChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Sale $</label>
                        <input type="text" className="form-control" value={this.state.salePrice} onChange={this.handleSalePriceChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Cost $</label>
                        <input type="text" className="form-control" value={this.state.cost} onChange={this.handleCostChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Re-order Level</label>
                        <input type="text" className="form-control" value={this.state.reOrderLevel} onChange={this.handleReOrderLevelChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Case Pack Size</label>
                        <input type="text" className="form-control" value={this.state.casePackSize} onChange={this.handleCasePackSizeChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Lot # &amp; Exp. Date / Comment</label>
                        <input type="text" className="form-control" value={this.state.lotNumber} onChange={this.handleLotNumberChange}></input>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <Checkbox label="Active" checked={this.state.active} onChange={this.handleActiveChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-4">
                        <Checkbox label="Hidden" checked={this.state.hidden} onChange={this.handleHiddenChange}></Checkbox>
                    </div>

                    <div className="form-group col-md-4">
                        <Checkbox label="Commission" checked={this.state.commission} onChange={this.handleCommissionChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-4">
                        <Checkbox label="Web Back Ordered" checked={this.state.isWebBackOrdered} onChange={this.handleWebBackOrderedChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-4">
                        <Checkbox label="PowerD Back Ordered" checked={this.state.isPowerDBackOrdered} onChange={this.handlePowerDBackOrderedChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-4">
                        <Checkbox label="Keep Inventory" checked={this.state.keepInventory} onChange={this.handleKeepInventoryChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-4">
                        <Checkbox label="Allow Discount" checked={this.state.allowDiscount} onChange={this.handleAllowDiscountChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-4">
                        <Checkbox label="Featured Product" checked={this.state.featured} onChange={this.handleFeaturedChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-4">
                        <Checkbox label="CA Prop 65" checked={this.state.caProp65} onChange={this.handlaCAProp65Change}></Checkbox>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ProductInformation;
