import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SitesSelect from '../../common/SitesSelect'
import AccountTypesSelect from '../../common/AccountTypesSelect'
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import CompanySelect from '../../common/CompanySelect';
import Report from '../../common/Report';

class LotReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            beginDate: moment(new Date()).subtract(1, 'month').toDate(),
            endDate: moment(new Date()).toDate(),
            companyId: -1,
            accountTypeId: -1,
            siteId: -1,
            lot: "",
            report: null,
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.paramsKey = "params.lot-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);    
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleLotChange = this.handleLotChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
    }

    componentDidMount() {
        this.getReport();
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleAccountTypeChange(accountTypeId) {
        this.setState({ accountTypeId: accountTypeId });
    }

    handleSiteChange(siteId) {
        this.setState({ siteId: siteId });
    }

    handleLotChange(e) {
        this.setState({ lot: e.target.value });
    }

    getPrintPDFUrl(type, order) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + order.orderID + "&OrderNumber=" + order.orderNumber;
    }

    getReport(e) {
        if (!!e) {
            e.preventDefault();
        }
        var params = {
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            companyId: this.state.companyId,
            accountTypeId: this.state.accountTypeId,
            siteId: this.state.siteId,
            lot: this.state.lot,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Report/GetLotReport', params).then(data => {
            this.setState({ report: data });
        });
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Lot Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.getReport}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="beginDate">Orders From</label>
                                            <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="endDate">Orders To</label>
                                            <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="orderStatus">Company</label>
                                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange} hasAllOption={true}></CompanySelect>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label>Account Type</label>
                                            <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange} hasAllOption={true}></AccountTypesSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Site</label>
                                            <SitesSelect value={this.state.siteId} onChange={this.handleSiteChange} hasAllOption={true}></SitesSelect>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                    <div className="form-group col-md-3">
                                            <label>Specific Lot</label>
                                            <input type="text" className="form-control" value={this.state.lot} onChange={this.handleLotChange} />
                                        </div>
                                    </div>
                                    <div className="form-row justify-content-end">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="LotReport" hasData={this.state.report.items && this.state.report.items.length > 0} title="Lot Report" description={this.state.report.description}>
                                <div className="form-row">
                                    <table className="table table-striped mt-1 report-table">
                                        {this.state.report.items.map((group) =>
                                            <React.Fragment key={group.name}>
                                                <thead>
                                                    <tr className="bg-primary reportHeader">
                                                        <th colSpan={11}>{group.name}</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("OrderNumber")}>ORDER #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("CustPO")}>PO #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("FulfillDate")}>ORDER DATE</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("CustomerNumber")}>CUST #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("CustomerName")}>CUSTOMER</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("TypeAbbr")}>TYPE</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("SubTotal")}>SALES</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("ShipCost")}>S/H</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("Total")}>INV TOTAL</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("OrderStatusDesc")}>STATUS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {group.items && group.items.map(item =>
                                                        <tr key={item.orderID}>
                                                            <td><a href={this.getPrintPDFUrl("Invoice", item)} target="_blank" rel="noopener noreferrer">{item.orderNumber}</a></td>
                                                            <td>{item.custPO}</td>
                                                            <td>{moment(item.fulfillDate).format('l')}</td>
                                                            <td><Link to={"/customer/" + item.customerID}>{item.customerNumber}</Link></td>
                                                            <td>{item.customerName}</td>
                                                            <td>{item.typeAbbr}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.subTotal, item.currencyCode)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.shipCost, item.currencyCode)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.total, item.currencyCode)}</td>
                                                            <td>{item.orderStatusDesc}</td>
                                                        </tr>
                                                    )}
                                                    <tr className="bg-light reportFooter">
                                                        <th colSpan={3}>TOTAL # of ORDERS</th>
                                                        <th>{group.items.length}</th>
                                                        <th colSpan={6}></th>
                                                    </tr>
                                                </tbody>
                                            </React.Fragment>
                                        )}
                                        <tbody>
                                            <tr className="bg-dark reportFooter">
                                                <th colSpan={3}>TOTAL # of ORDERS</th>
                                                <th>{this.state.report.itemCount}</th>
                                                <th colSpan={6}></th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default LotReport;
