import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';

class BWExport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            beginDate: moment().toDate(),
        }

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.export = this.export.bind(this);
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    export(e) {
        e.preventDefault();
        var params = {
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
        }
        this.http.get('/api/Orders/ExportBWData', params).then(data => {
            this.utils.success("");
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Export Business Works Data</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.export}>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="beginDate">Begin Date</label>
                                            <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                        </div>
                                        <div className="form-group col-md-3 mt-4">
                                            <button type="submit" className="btn btn-primary">Export</button>
                                        </div>
                                    </div>
                                    <div className="card-sub">
                                        Exported files are available at <a href="https://powerd.nutraceutics.com/BWData" target="_blank" rel="noopener noreferrer">https://powerd.nutraceutics.com/BWData</a>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BWExport;
