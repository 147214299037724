import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';

class Note extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noteID: props.note ? props.note.noteID : 0,
            message: props.note ? props.note.message : "",
            enteredBy: props.note ? props.note.enteredBy : "", 
            ownerID: props.note ? props.note.ownerID: 0       
        }

        this.http = new HttpRequestService();
        this.handleNoteChange = this.handleNoteChange.bind(this);
        this.handleEnteredByChange = this.handleEnteredByChange.bind(this);
        this.getInitials = this.getInitials.bind(this);
        this.notifyChange();
    }

    componentDidMount(){
        if(!this.props.note){
            this.getInitials();
        }
    }

    getInitials(){
        this.http.get("/api/PowerD/GetInitials", null).then(data => {
            this.setState({
                enteredBy: data 
            })
          });
    }

    handleNoteChange(e) {
        this.setState({
            message: e.target.value
        }, this.notifyChange);
    }

    handleEnteredByChange(e) {
        this.setState({
            enteredBy: e.target.value
        }, this.notifyChange);
    }    

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label>Entered By</label>
                        <input type="text" className="form-control" value={this.state.enteredBy} onChange={this.handleEnteredByChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Note</label>
                        <textarea className="form-control" maxLength={500} rows={10} value={this.state.message} onChange={this.handleNoteChange}></textarea>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Note