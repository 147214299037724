import React, { Component } from 'react';
import Select from 'react-select';

class UserDefinedFieldEditor extends Component {

    constructor(props) {
        super(props);
        var value = null;

        var options = this.props.field.options.map((value, index) => {
            return { label: value.name, value: value.userDefinedFieldOptionID };
        })

        if(this.props.field.selection === "Multiple"){
            value = options.filter(o => this.props.value.find(v => v === o.value) !== undefined);
        }
        else{
            value = options.find(o => o.value === this.props.value);
        }
        
        this.state = {
            value: value
        }

        this.handleChange = this.handleChange.bind(this);
        this.notifyChange = this.notifyChange.bind(this);
    }
    

    handleChange(e) {
        this.setState({
            value: e
        }, this.notifyChange)
    }

    notifyChange() {
        if (this.props.onChange) {
            if(this.props.field.selection === "Multiple"){
                if(this.state.value){
                    this.props.onChange(this.state.value.map((value, index) => {
                        return value.value;
                    }));
                }
                else{
                    this.props.onChange([]);
                }
            }
            else{
                if(this.state.value){
                    this.props.onChange(this.state.value.value);
                }
                else{
                    this.props.onChange(-1);
                }
            }
        }
    }

    render() {
        var options = this.props.field.options.map((value, index) => {
            return { label: value.name, value: value.userDefinedFieldOptionID };
        })
        return (
            <React.Fragment>
                <label>{this.props.field.name}</label>
                <Select isClearable={true} options={options} isMulti={this.props.field.selection === "Multiple"} value={this.state.value} onChange={this.handleChange}></Select>
            </React.Fragment>
        );
    }
}
export default UserDefinedFieldEditor