import React, { Component } from 'react';
import SmtpAccountSelect from './SmtpAccountSelect';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class ComposeEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smtpAccountId: props.smtpAccountId ? props.smtpAccountId : "",
            to: props.to ? props.to : "",
            cc: props.cc ? props.cc : "",
            subject: props.subject ? props.subject : "",
            body: props.body ? props.body : "",
        }

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleFromDisplayNameChange = this.handleFromDisplayNameChange.bind(this);
        this.handleSmtpAccountChange = this.handleSmtpAccountChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handleCCChange = this.handleCCChange.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleBodyChange = this.handleBodyChange.bind(this);
        this.notifyChange();
    }

    handleFromChange(e) {
        this.setState({
            from: e.target.value
        }, this.notifyChange);
    }

    handleFromDisplayNameChange(e) {
        this.setState({
            fromDisplayName: e.target.value
        }, this.notifyChange);
    }

    handleToChange(e) {
        this.setState({
            to: e.target.value
        }, this.notifyChange);
    }

    handleCCChange(e) {
        this.setState({
            cc: e.target.value
        }, this.notifyChange);
    }

    handleSubjectChange(e) {
        this.setState({
            subject: e.target.value
        }, this.notifyChange);
    }

    handleBodyChange(e) {
        this.setState({
            body: e.target.value
        }, this.notifyChange);
    }

    handleSmtpAccountChange(e) {
        this.setState({
            smtpAccountId: e
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <div className="form-row">
                <div className="col-md-12">
                    {/* <div className="form-group col-md-12">
                        <label>From</label>
                        <input type="text" value={this.state.from} onChange={this.handleFromChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-12">
                        <label>From Name</label>
                        <input type="text" value={this.state.fromDisplayName} onChange={this.handleFromDisplayNameChange} className="form-control" />
                    </div> */}
                    <div className="form-group col-md-12">
                        <label>From</label>
                        <SmtpAccountSelect value={this.state.smtpAccountId} onChange={this.handleSmtpAccountChange}></SmtpAccountSelect>
                    </div>
                    <div className="form-group col-md-12">
                        <label>To</label>
                        <input type="text" value={this.state.to} onChange={this.handleToChange} className="form-control" />
                        <small>Separate multiple email addresses using semicolon(;) or comma(,)</small>
                    </div>
                    <div className="form-group col-md-12">
                        <label>CC</label>
                        <input type="text" value={this.state.cc} onChange={this.handleCCChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-12">
                        <label>Subject</label>
                        <input type="text" value={this.state.subject} onChange={this.handleSubjectChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-12">
                        <label>Body</label>
                        {/* <textarea rows="4" className="form-control" value={this.state.body} onChange={this.handleBodyChange}></textarea> */}
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.body}
                            config={{
                                toolbar: [
                                    'heading', '|',
                                    'alignment', '|',
                                    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
                                    'link', '|',
                                    'bulletedList', 'numberedList',
                                    'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
                                    'insertTable', '|',
                                    'outdent', 'indent', '|',
                                    'blockQuote', '|',
                                    'undo', 'redo'
                                ],
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData(); this.setState({
                                    body: data
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ComposeEmail