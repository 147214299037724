import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import Checkbox from '../../common/Checkbox';

class ShipmentDetails extends Component {
    constructor(props) {
        super(props);
        this.products = this.getCartItems(this.props.order.shoppingCart);
        var defaultPackageItems = this.getDefaultPackageItems();
        this.state = {
            trackingNumber: "",
            totalWeight: 0,
            numberOfPackages: 1,
            packages: [
                {
                    items: defaultPackageItems
                }
            ],
            printUCCLabel: true
        };

        this.http = new HttpRequestService();
        this.handleTotalWeightChange = this.handleTotalWeightChange.bind(this);
        this.handleTrackingNumberChange = this.handleTrackingNumberChange.bind(this);
        this.handleNumberOfPackagesChange = this.handleNumberOfPackagesChange.bind(this);
        this.handlePrintUCCLabelChange = this.handlePrintUCCLabelChange.bind(this);
        this.notifyChange();
    }

    getDefaultPackageItems() {
        var items = [];
        for (var i = 0; i < this.products.length; i++) {
            items.push({
                cartItemId: this.products[i].id,
                quantity: this.getDefaultQuantity(this.products[i])
            });
        }
        return items;
    }

    handleTotalWeightChange(e) {
        this.setState({
            totalWeight: e.target.value
        }, this.notifyChange);
    }

    handleTrackingNumberChange(e) {
        this.setState({
            trackingNumber: e.target.value
        }, this.notifyChange);
    }

    handlePrintUCCLabelChange(value){
        this.setState({
            printUCCLabel: value
        }, this.notifyChange);
    }

    handleNumberOfPackagesChange(e) {
        var num = parseInt(e.target.value);
        if (num <= 0) {
            return;
        }
        var pkgs = this.state.packages;
        var numOfPkgs = pkgs.length;
        if (num > numOfPkgs) {
            for (var i = 0; i < num - numOfPkgs; i++) {
                pkgs.push({
                    items: [
                        {
                            cartItemId: 0,
                            quantity: 0
                        }
                    ]
                });
            }
        }
        else {
            pkgs.splice(numOfPkgs - num, numOfPkgs - num);
        }

        if(num === 1){
            var defaultPackageItems = this.getDefaultPackageItems();
            pkgs = [{
                items: defaultPackageItems
            }];
        }
        
        this.setState({
            numberOfPackages: e.target.value,
            packages: pkgs
        }, this.notifyChange);
    }

    handleProductSelect(e, itemIndex, pkgIndex) {
        var pkgs = this.state.packages;
        pkgs[pkgIndex].items[itemIndex].cartItemId = parseInt(e.target.value);
        var cartItem = this.products.find(p => p.id === parseInt(e.target.value));
        if (cartItem) {
            pkgs[pkgIndex].items[itemIndex].quantity = this.getDefaultQuantity(cartItem);
        }
        else {
            pkgs[pkgIndex].items[itemIndex].quantity = 0;
        }
        this.setState({
            packages: pkgs
        });
    }

    getDefaultQuantity(cartItem) {
        return parseInt(cartItem.quantityShipped) === 0 ? cartItem.quantity : parseInt(cartItem.quantityShipped);
    }

    handleQuantityChange(e, itemIndex, pkgIndex) {
        if (e.target.value > 0) {
            var pkgs = this.state.packages;
            var cartItem = this.products.find(p => p.id === pkgs[pkgIndex].items[itemIndex].cartItemId);
            var enteredQuantity = parseInt(e.target.value);
            pkgs[pkgIndex].items[itemIndex].quantity = enteredQuantity;
            if (cartItem !== null) {
                var defaultQuantity = this.getDefaultQuantity(cartItem);
                var totalEnteredQuantity = 0;
                // Calculate entered quantity by looking at previous packages
                for (let i = 0; i <= pkgIndex; i++) {
                    var item = pkgs[i].items.find(i => i.cartItemId === cartItem.id);
                    if (item) {
                        totalEnteredQuantity += item.quantity;
                    }
                }

                if (defaultQuantity > totalEnteredQuantity && pkgs.length > 1) {
                    // distribute items evenly to the next packages
                    var remainingQuantity = defaultQuantity - totalEnteredQuantity;
                    for (let i = pkgIndex + 1; i < pkgs.length && remainingQuantity >= 0; i++) {
                        var distributedQuantity = remainingQuantity > enteredQuantity ? enteredQuantity : remainingQuantity;
                        let item = pkgs[i].items.find(i => i.cartItemId === cartItem.id);
                        if (item) {
                            item.quantity = distributedQuantity;
                        }
                        else {
                            if (pkgs[i].items[0].cartItemId === 0) {
                                pkgs[i].items.splice(0, 1);
                            }
                            pkgs[i].items.push({
                                cartItemId: cartItem.id,
                                quantity: distributedQuantity
                            });
                        }

                        remainingQuantity -= distributedQuantity;
                    }
                }
            }

            this.setState({
                packages: pkgs
            });
        }
    }

    addPackItem(pkgIndex, e) {
        e.preventDefault();
        e.stopPropagation();
        var pkgs = this.state.packages;
        pkgs[pkgIndex].items.push({
            cartItemId: 0,
            quantity: 0
        });
        this.setState({
            packages: pkgs
        });
    }

    removePackItem(itemIndex, pkgIndex, e) {
        e.preventDefault();
        e.stopPropagation();
        var pkgs = this.state.packages;
        pkgs[pkgIndex].items.splice(itemIndex, 1);
        this.setState({
            packages: pkgs
        });
    }

    getCartItems(shoppingCart) {
        if (!!shoppingCart) {
            var sortedProductItems = shoppingCart.sortedNutraCartProductItems;
            var cartItems = [];
            for (var key in sortedProductItems) {
                var item = sortedProductItems[key];
                item.id = item.productID;
                cartItems.push(item);
            }
            return cartItems;
        }
        else {
            return [];
        }
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label>Total Weight</label>
                        <input type="text" className="form-control" value={this.state.totalWeight} onChange={this.handleTotalWeightChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Tracking Number</label>
                        <input className="form-control" value={this.state.trackingNumber} onChange={this.handleTrackingNumberChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Number of Packages</label>
                        <input type="numeric" className="form-control" value={this.state.numberOfPackages} onChange={this.handleNumberOfPackagesChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                    <Checkbox label="Print UCC Label upon fulfillment" checked={this.state.printUCCLabel} onChange={this.handlePrintUCCLabelChange}></Checkbox>
                    </div>
                </div>
                {this.state.numberOfPackages > 1 &&
                    <React.Fragment>
                        <h4 className="mt-2 p-2">Packages</h4>
                        <div className="col-md-12">
                            {this.state.packages.map((pkg, pkgIndex) =>
                                <React.Fragment key={pkgIndex}>
                                    <table className="table table-striped table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" colSpan={3} className="tableHeading">
                                                    PACKAGE {pkgIndex + 1}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th scope="col">PRODUCT</th>
                                                <th scope="col">QUANTITY</th>
                                                <th scope="col">
                                                    <button className="btn btn-xs btn-default" onClick={(e) => this.addPackItem(pkgIndex, e)}><i className="fas fa-plus"></i></button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pkg.items.map((item, itemIndex) =>
                                                <tr key={itemIndex}>
                                                    <td>
                                                        <select className="form-control" value={item.cartItemId} onChange={(e) => this.handleProductSelect(e, itemIndex, pkgIndex)}>
                                                            <option value="0">--SELECT--</option>
                                                            {this.products.map(product =>
                                                                <option key={product.id} value={product.id}>{product.name}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input className="form-control" type="text" value={item.quantity} onChange={(e) => this.handleQuantityChange(e, itemIndex, pkgIndex)}></input>
                                                    </td>
                                                    <td>
                                                        {pkg.items.length > 1 &&
                                                            <button className="btn btn-xs btn-danger" onClick={(e) => this.removePackItem(itemIndex, pkgIndex, e)}><i className="fas fa-minus"></i></button>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                }
            </React.Fragment >
        );
    }
}

export default ShipmentDetails