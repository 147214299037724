import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import FileInput from '../../common/FileInput';
import Report from '../../common/Report';
import CompanySelect from '../../common/CompanySelect';

class AmazonReconciliation extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            paymentFile: null,
            mismatchedOnly: false,
            companyId: 2,
            fulfillmentChannel: "All",
            report: null
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handlePaymentFileChange = this.handlePaymentFileChange.bind(this);
        this.handleFulfillmentChannelChange = this.handleFulfillmentChannelChange.bind(this);
        this.handleMismatchedOnlyChange = this.handleMismatchedOnlyChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.importMissingOrders = this.importMissingOrders.bind(this);
        this.importMismatchedFees = this.importMismatchedFees.bind(this);
    }

    componentDidMount() {
    }

    handlePaymentFileChange(file) {
        this.setState({
            paymentFile: file
        });
    }


    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleFulfillmentChannelChange(e) {
        this.setState({ fulfillmentChannel: e.target.value });
    }

    handleMismatchedOnlyChange(checked) {
        this.setState({ mismatchedOnly: checked });
    }

    getReport() {
        var params = {
            fulfillmentChannel: this.state.fulfillmentChannel,
            companyId: this.state.companyId,
            mismatchedOnly: this.state.mismatchedOnly,
            operation: "ReconcileAmazonOrders",
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }

        this.http.upload('/api/FileUpload/UploadFile/', this.state.paymentFile, params).then(data => {
            this.setState({
                report: data
            })
        });
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    importMissingOrders() {
        var params = {
            fulfillmentChannel: this.state.fulfillmentChannel,
            mismatchedOnly: this.state.mismatchedOnly,
            companyId: this.state.companyId,
            operation: "ImportMissingOrders",
        }

        this.http.upload('/api/FileUpload/UploadFile/', this.state.paymentFile, params).then(data => {
            this.getReport();
        });
    }

    importMismatchedFees() {
        var params = {
            fulfillmentChannel: this.state.fulfillmentChannel,
            mismatchedOnly: this.state.mismatchedOnly,
            companyId: this.state.companyId,
            operation: "ImportMismatchedFees",
        }

        this.http.upload('/api/FileUpload/UploadFile/', this.state.paymentFile, params).then(data => {
            this.getReport();
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Reconcile Amazon Orders</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>Payment File</label>
                                        <br />
                                        <FileInput label="Select File" onChange={this.handlePaymentFileChange}></FileInput>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>Company</label>
                                        <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange}>
                                        </CompanySelect>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>FBA/FBM</label>
                                        <select className="form-control" value={this.state.fulfillmentChannel} onChange={this.handleFulfillmentChannelChange}>
                                            <option>All</option>
                                            <option>FBA</option>
                                            <option>FBM</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label>&nbsp;</label>
                                        <Checkbox label="Display only mismatched orders" checked={this.state.mismatchedOnly} onChange={this.handleMismatchedOnlyChange}></Checkbox>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <button className="btn btn-primary mt-4" onClick={this.getReport}>Submit</button>
                                    </div>
                                </div>
                                {this.state.report && this.state.report.items.length > 0 &&
                                    <React.Fragment>
                                        <div className="form-row">
                                            <div className="form-group col-md-3 offset-9">
                                                <button className="btn btn-default btn-sm" onClick={this.importMissingOrders}>Import Missing Orders</button>
                                            </div>
                                        </div>
                                        {/* <div className="form-row">
                                            <div className="form-group col-md-3 offset-9">
                                                <button className="btn btn-default btn-sm" onClick={this.importMismatchedFees}>Re-Import Mismatched Fees</button>
                                            </div>
                                        </div> */}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="AmazonReconciliationReport" hasData={this.state.report.items && this.state.report.items.length > 0}>
                                <div className="form-row">
                                    <table className="table table-bordered table-striped mt-1 report-table">
                                        <thead>
                                            <tr>
                                                <th colSpan={6}></th>
                                                <th className="separator"></th>
                                                <th colSpan={7}><h3>PowerD</h3></th>
                                                <th className="separator"></th>
                                                <th colSpan={7}><h3>Amazon</h3></th>
                                            </tr>
                                            <tr>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("AmazonOrderId")}>AMAZON ORDER #</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("OrderId")}>ORDER #</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("Date")}>DATE</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("Customer")}>CUSTOMER</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("State")}>STATE</th>
                                                <th className="sortable" scope="col" onClick={(e) => this.sortReport("FulfillmentChannel")}>FBA/FBM</th>
                                                <th className="separator"></th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("PowerDProductCharges")}>PRODUCT CHARGES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("PowerDSalesTax")}>SALES TAX</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("PowerDReferralFee")}>REFERRAL FEE</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("PowerDFbaFee")}>FBA FEE</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("PowerDOtherFees")}>OTHER FEES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("PowerDTotalFees")}>TOTAL FEES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("PowerDTotal")}>TOTAL</th>
                                                <th className="separator"></th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("AmazonOrder.ProductCharges")}>PRODUCT CHARGES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("AmazonOrder.SalesTax")}>SALES TAX</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("AmazonOrder.ReferralFee")}>REFERRAL FEE</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("AmazonOrder.FbaFee")}>FBA FEE</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("AmazonOrder.OtherFees")}>OTHER FEES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("AmazonOrder.TotalFees")}>TOTAL FEES</th>
                                                <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("AmazonOrder.Total")}>TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.report.items && this.state.report.items.map(item =>
                                                <tr key={item.orderID}>
                                                    <td>{item.amazonOrderId}</td>
                                                    <td><Link to={"/order/" + item.orderId} target="_blank" rel="noopener noreferrer">{item.orderNumber}</Link></td>
                                                    <td>{item.date}</td>
                                                    <td><Link to={"/customer/" + item.customerId} target="_blank">{item.customer}</Link></td>
                                                    <td>{item.state}</td>
                                                    <td>{item.fulfillmentChannel}</td>
                                                    <td className="separator"></td>
                                                    <td className={"currency " + (item.powerDProductCharges !== item.amazonOrder.productCharges ? "bg-danger" : "")}>{this.utils.toCurrency(item.powerDProductCharges, item.powerDCurrency)}</td>
                                                    <td className={"currency " + (item.powerDSalesTax !== item.amazonOrder.salesTax ? "bg-danger" : "")}>{this.utils.toCurrency(item.powerDSalesTax, item.powerDCurrency)}</td>
                                                    <td className={"currency " + (item.powerDReferralFee !== item.amazonOrder.referralFee ? "bg-danger" : "")}>{this.utils.toCurrency(item.powerDReferralFee, item.powerDCurrency)}</td>
                                                    <td className={"currency " + (item.powerDFBAFee !== item.amazonOrder.fbaFee ? "bg-danger" : "")}>{this.utils.toCurrency(item.powerDFBAFee, item.powerDCurrency)}</td>
                                                    <td className={"currency " + (item.powerDOtherFees !== item.amazonOrder.otherFees ? "bg-danger" : "")}>{this.utils.toCurrency(item.powerDOtherFees, item.powerDCurrency)}</td>
                                                    <td className={"currency " + (item.powerDTotalFees !== item.amazonOrder.totalFees ? "bg-danger" : "")}>{this.utils.toCurrency(item.powerDTotalFees, item.powerDCurrency)}</td>
                                                    <td className={"currency " + (item.powerDTotal !== item.amazonOrder.total ? "bg-danger" : "")}>{this.utils.toCurrency(item.powerDTotal, item.powerDCurrency)}</td>
                                                    <td className="separator"></td>
                                                    <td className="currency">{this.utils.toCurrency(item.amazonOrder.productCharges, item.amazonOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.amazonOrder.salesTax, item.amazonOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.amazonOrder.referralFee, item.amazonOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.amazonOrder.fbaFee, item.amazonOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.amazonOrder.otherFees, item.amazonOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.amazonOrder.totalFees, item.amazonOrder.currency)}</td>
                                                    <td className="currency">{this.utils.toCurrency(item.amazonOrder.total, item.amazonOrder.currency)}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tbody>
                                            <tr className="bg-dark reportFooter">
                                                <th colSpan={6}></th>
                                                <td className="separator"></td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.powerDProductCharges, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.powerDSalesTax, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.powerDReferralFee, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.powerDFbaFee, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.powerDOtherFees, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.powerDTotalFees, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.powerDTotal, this.state.report.currency)}</td>
                                                <td className="separator"></td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.amazonProductCharges, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.amazonSalesTax, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.amazonReferralFee, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.amazonFbaFee, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.amazonOtherFees, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.amazonTotalFees, this.state.report.currency)}</td>
                                                <td className="currency">{this.utils.toCurrency(this.state.report.amazonTotal, this.state.report.currency)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default AmazonReconciliation;
