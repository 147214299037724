import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';

class ASINList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            asins: []
        }

        this.paramsKey = props.paramsKey ? props.paramsKey : "params.asin-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getASINs = this.getASINs.bind(this);
    }

    componentDidMount() {
        this.getASINs();
    }  

    getASINs() {
        this.http.get('/api/Inventory/GetASINList', null).then(data => {
            this.setState({ asins: data });
        });
    }

    deleteASIN(asin){
      var message = "This ASIN will be deleted. Do you want to continue?"
       this.utils.confirm(message, () => {
            var params = {
                asinId: asin.asinId
            }
        this.http.get('/api/Inventory/DeleteASIN', params).then(data => {
            this.getASINs();
        });
       })
    }
    
    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">ASIN List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">ASIN</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.asins.map((item) =>
                                                <tr key={item.asinId}>
                                                    <td><Link to={"/inventory/asin/" + item.asinId}>{item.name}</Link></td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={e => this.deleteASIN(item)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ASINList;
