import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class ShipTypeSelect extends Component {

    constructor(props) {
        super(props);
        this.state = { shipTypes: [] };
        this.getShipTypes = this.getShipTypes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        if(!this.props.order){
            this.getDistinctShipTypes();
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.order){
            if (this.props.order !== prevProps.order) {
                this.getShipTypes();
            }
        }
    }

    componentWillUnmount() {
    }

    getShipTypes() {
        var params ={
            companyId: this.props.order.company.key,
            // siteId: this.props.order.site.key
            siteId: 3 //default value
        }
        this.http.get('/api/Orders/GetShipTypes', params).then(data=>{
            this.setState({ shipTypes: data });
            if(data.length > 0 && this.props.value === 0){
                this.props.onChange(data[0].key);
            }
        });
    }

    getDistinctShipTypes() {
        this.http.get('/api/Customers/GetDistinctShippingTypes', null).then(data=>{
            this.setState({ shipTypes: data });
            if(data.length > 0 && this.props.value === 0){
                this.props.onChange(data[0]);
            }
        });
    }

    handleChange(e) {
        var shipType = this.state.shipTypes.find(t=>t.shipTypeID === parseInt(e.target.value));
        this.props.onChange(shipType);
    }

    render() {
        var options = this.state.shipTypes.map((item) =>
            <option key={item.shipTypeID} value={item.shipTypeID}>{item.description}</option>
        );

        return (
            <select disabled={this.props.disabled} className="form-control" value={this.props.value} onChange={this.handleChange}>
                {this.props.hasAllOption === true &&
                    <option value="-1">-- ALL --</option>
                }
                {this.props.placeholder &&
                    <option value="-1">{this.props.placeholder}</option>
                }
                {options}
            </select>
        );
    }
}
export default ShipTypeSelect