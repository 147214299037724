import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import moment from 'moment';
import { withRouter } from "react-router-dom";

class ReportDataList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            reports: [],
        }

        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getReportDataList = this.getReportDataList.bind(this);
    }

    componentDidMount() {
        this.getReportDataList();
    }

    getReportDataList(page) {
        this.http.get('/api/Report/GetReportDataList', null).then(data => {
            this.setState({ reports: data });
        });
    }

    deleteReport(report){
        var message = "This report will be deleted. Do you want to continue?";
        this.utils.confirm(message, () => {
            this.http.get('/api/Report/DeleteReportData', {id: report.reportID}).then(data => {
                this.getReportDataList();
            });
        })
    }

    loadReport(report){
        if(report.reportType === "ContactList"){
            this.props.history.push('/crm/contact-list/' + report.reportID);
        }
        else if(report.reportType === "AccountList"){
            this.props.history.push('/crm/account-list/' + report.reportID);
        }
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Report List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Report Type</th>
                                                <th scope="col">Created By</th>
                                                <th scope="col">Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.reports.map((report) =>
                                                <tr key={report.reportID}>
                                                    <td><button type="button" className="btn btn-link" onClick={e => this.loadReport(report)}>{report.reportName}</button></td>
                                                    <td>{report.reportType}</td>
                                                    <td>{report.createdBy}</td>
                                                    <td>{moment(report.timestamp).format('l')}</td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={e => this.deleteReport(report)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ReportDataList);
