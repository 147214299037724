import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import { Link } from "react-router-dom";

class UserDefinedFieldList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            fields: [],
        }

        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getFields = this.getFields.bind(this);
    }

    componentDidMount() {
        this.getFields();
    }

    getFields(page) {
        this.http.get('/api/Crm/GetUserDefinedFields', null).then(data => {
            this.setState({ fields: data });
        });
    }

    deleteField(field) {
        var message = "This field will be deleted. Do you want to continue?";
        this.utils.confirm(message, () => {
            this.http.post('/api/Crm/DeleteUserDefinedField', { id: field.userDefinedFieldID }).then(data => {
                this.getFields();
            });
        })
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">User Defined Field List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-tools">
                                        <Link to="/crm/add-user-defined-field" className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={this.printReport}>
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add New
							            </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Field Name</th>
                                                <th scope="col">Applies To</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.fields.map((field) =>
                                                <tr key={field.userDefinedFieldID}>
                                                    <td><Link to={"/crm/user-defined-field/" + field.userDefinedFieldID}>{field.name}</Link></td>
                                                    <td>{field.entities.map((entity) =>
                                                        <span key={entity.userDefinedFieldEntityID} className="separated">{entity.entity}</span>
                                                    )}
                                                    </td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={e => this.deleteField(field)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserDefinedFieldList;
