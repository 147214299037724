import React, { Component } from 'react';
import { Link } from "react-router-dom";
import OrderStatusSelect from '../../common/OrderStatusSelect'
import Pagination from '../../common/Pagination'
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import CompanySelect from '../../common/CompanySelect';

class AmazonOrderList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            orderStatusId: 1,
            companyId: -1,
            orderNumber: '',
            amazonOrderNumber: '',
            customerNumber: '',
            customerName: '',
            beginDate: null,
            endDate: null,
            orders: [],
            pageCount: 0,
            pageIndex: 0
        }
        this.paramsKey = "params.amazon-order-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        this.http = new HttpRequestService();
        this.handleOrderStatusChange = this.handleOrderStatusChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleOrderNumberChange = this.handleOrderNumberChange.bind(this);
        this.handleAmazonOrderNumberChange = this.handleAmazonOrderNumberChange.bind(this);
        this.handleCustomerNumberChange = this.handleCustomerNumberChange.bind(this);
        this.handleCustomerNameChange = this.handleCustomerNameChange.bind(this);
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.pageSize = 50;
        this.getOrders = this.getOrders.bind(this);
    }

    componentDidMount() {
        this.getOrders(this.state.pageIndex);
    }

    handleOrderStatusChange(orderStatusId) {
        this.setState({ orderStatusId: orderStatusId });
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleOrderNumberChange(e) {
        this.setState({ orderNumber: e.target.value });
    }

    handleAmazonOrderNumberChange(e) {
        this.setState({ amazonOrderNumber: e.target.value });
    }

    handleCustomerNumberChange(e) {
        this.setState({ customerNumber: e.target.value });
    }

    handleCustomerNameChange(e) {
        this.setState({ customerName: e.target.value });
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handlePageChange(page) {
        this.getOrders(page);
    }

    getOrders(page, e) {
        if (!!e) {
            e.preventDefault();
        }
        this.setState({ pageIndex: page });
        var params = {
            orderStatusId: this.state.orderStatusId,
            companyId: this.state.companyId,
            orderNumber: this.state.orderNumber,
            amazonOrderNumber: this.state.amazonOrderNumber,
            customerNumber: this.state.customerNumber,
            customerName: this.state.customerName,
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            pageSize: this.pageSize,
            pageIndex: page
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
        this.http.get('/api/Orders/SearchAmazonOrders', params).then(data => {
            this.setState({ orders: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Amazon Order List</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <form onSubmit={(e) => this.getOrders(0, e)}>
                                <div className="card-body">
                                    <div className="form-row">
                                    <div className="form-group col-md-3">
                                            <label htmlFor="orderStatus">Company</label>
                                            <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange} hasAllOption={true}></CompanySelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="orderStatus">Order Status</label>
                                            <OrderStatusSelect value={this.state.orderStatusId} onChange={this.handleOrderStatusChange} hasAllOption={true}></OrderStatusSelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="orderNumber">Order Number</label>
                                            <input type="text" className="form-control" value={this.state.orderNumber} onChange={this.handleOrderNumberChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="orderNumber">Amazon Order Number</label>
                                            <input type="text" className="form-control" value={this.state.amazonOrderNumber} onChange={this.handleAmazonOrderNumberChange} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="customerNumber">Customer Name</label>
                                            <input type="text" className="form-control" value={this.state.customerName} onChange={this.handleCustomerNameChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="customerNumber">Customer Number</label>
                                            <input type="text" className="form-control" value={this.state.customerNumber} onChange={this.handleCustomerNumberChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="beginDate">Begin Date</label>
                                            <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="endDate">End Date</label>
                                            <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                        </div>
                                    </div>
                                    <div className="form-row pull-right">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Order</th>
                                                <th scope="col">Amazon Order Id</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Product Charges</th>
                                                <th scope="col">Sales Tax</th>
                                                <th scope="col">Referral Fee</th>
                                                <th scope="col">FBA Pick &amp; Pack Fee</th>
                                                <th scope="col">Other Fees</th>
                                                <th scope="col">Total Fees</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">State</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders.map((order) =>
                                                <tr key={order.orderId}>
                                                    <td><Link to={"/order/" + order.orderId}>{order.orderNumber}</Link></td>
                                                    <td>{order.marketplaceOrderId}</td>
                                                    <td>{order.companyName}</td>
                                                    <td>{this.utils.toCurrency(order.productCharges, order.currency)}</td>
                                                    <td>{this.utils.toCurrency(order.salesTax, order.currency)}</td>
                                                    <td>{this.utils.toCurrency(order.referralFee, order.currency)}</td>
                                                    <td>{this.utils.toCurrency(order.fulfillmentFee, order.currency)}</td>
                                                    <td>{this.utils.toCurrency(order.otherFees, order.currency)}</td>
                                                    <td>{this.utils.toCurrency(order.referralFee + order.fulfillmentFee + order.otherFees, order.currency)}</td>
                                                    <td>{this.utils.toCurrency(order.productCharges - order.referralFee - order.fulfillmentFee - order.otherFees, order.currency)}</td>
                                                    <td>{order.state}</td>
                                                    <td>{order.orderStatus}</td>
                                                    <td>{order.dateTimeStamp}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.orders.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AmazonOrderList;
