import React, { Component } from 'react';
import CriteriaGroup from './CriteriaGroup';
import RadioGroup from './RadioGroup';

class CriteriaBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logicalOperator: props.criteriaBuilder ? props.criteriaBuilder.logicalOperator : "AND",
            groups: props.criteriaBuilder ? props.criteriaBuilder.groups : [],
            queryId: props.queryId ? props.queryId : 0
        }
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleLogicalOperatorChange = this.handleLogicalOperatorChange.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.removeGroup = this.removeGroup.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.queryId !== this.props.queryId) {
            this.setState({
                logicalOperator: this.props.criteriaBuilder ? this.props.criteriaBuilder.logicalOperator : "AND",
                groups: this.props.criteriaBuilder ? this.props.criteriaBuilder.groups : [],
                queryId: this.props.queryId
            });
        }
    }

    handleLogicalOperatorChange(value) {
        this.setState({
            logicalOperator: value
        }, this.onChange);
    }

    handleGroupChange(index, value) {
        var groups = this.state.groups;
        groups[index] = value
        this.setState({
            groups: groups
        }, this.onChange);
    }

    addGroup() {
        var groups = this.state.groups;
        groups.push({ logicalOperator: "AND", criterias: [{fieldName: "name", operator: "like", value: "" }]});
        this.setState({
            groups: groups
        }, this.onChange);
    }

    removeGroup(index, e) {
        var groups = this.state.groups;
        groups.splice(index, 1);
        this.setState({
            groups: groups
        }, this.onChange);
    }



    onChange() {
        if (this.props.onChange) {
            this.props.onChange({
                groups: this.state.groups,
                logicalOperator: this.state.logicalOperator
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="card col-md-12">
                    <div className="card-header">
                        <div className="card-head-row">
                            <div>
                                Filter Logic &nbsp;
                                <RadioGroup
                                    className="p-0"
                                    groupName="builderLogic"
                                    value={this.state.logicalOperator} items={[{ value: "AND", text: "AND" }, { value: "OR", text: "OR" }]}
                                    onChange={this.handleLogicalOperatorChange}>
                                </RadioGroup>
                            </div>
                            <div className="card-tools">
                                <button type="button" className="btn btn-info btn-border btn-round btn-sm mr-2" onClick={this.addGroup}>
                                    <span className="btn-label">
                                        <i className="fas fa-plus"></i>
                                    </span>
                                    Add Group
                                        </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    {this.state.groups.map((group, index) =>
                        <CriteriaGroup
                            availableCriterias={this.props.availableCriterias}
                            logicalOperator={group.logicalOperator}
                            criterias={group.criterias}
                            defaultCriteria={this.props.defaultCriteria}
                            onChange={e => this.handleGroupChange(index, e)}
                            onRemoveClicked={e => this.removeGroup(index)}
                            onAddClicked={e => this.addGroup(index)}
                            removeButtonVisible={this.state.groups.length > 1}
                            groupName={"group" + index}
                            key={index}
                            queryId={this.state.queryId}
                        >
                        </CriteriaGroup>

                    )
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default CriteriaBuilder;