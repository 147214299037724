import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import Pagination from '../../common/Pagination';

class ErrorLog extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            beginDate: moment().subtract(5, 'day').toDate(),
            endDate: moment().toDate(),
            logs: [],
            logDetail: null,
            pageCount: 0,
            pageIndex: 0
        }
        this.paramsKey = "params.error-log";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.getLogs = this.getLogs.bind(this);
        this.pageSize = 50;
    }

    componentDidMount() {
        this.getLogs(this.state.pageIndex);
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleEndDateChange(e) {
        this.setState({ endDate: e });
    }

    handlePageChange(page) {
        this.getLogs(page);
    }

    getLogs(page) {
        this.setState({ pageIndex: page });
        var params = {
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            endDate: this.state.endDate != null ? moment(this.state.endDate).format('L') : "",
            pageSize: this.pageSize,
            pageIndex: page
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Admin/GetErrorLogs', params).then(data => {
            this.setState({ logs: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    displayLogDetails(log) {
        var params = {
            id: log.errorId
        }
        this.http.get('/api/Admin/GetErrorLog', params).then(data => {
            this.setState({ logDetail: data },
                () => {
                    window.$("#logDetail").modal("show");
                });
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Error Logs</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label htmlFor="beginDate">Begin Date</label>
                                        <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="endDate">End Date</label>
                                        <DatePicker className="form-control" selected={this.state.endDate} onChange={this.handleEndDateChange} />
                                    </div>
                                    <div className="form-group col-md-3 mt-4">
                                        <button className="btn btn-primary" onClick={(e) => this.getLogs(0, e)}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Source</th>
                                                <th scope="col">Message</th>
                                                <th scope="col">Error Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.logs.map((log) =>
                                                <tr key={log.errorId}>
                                                    <td><button className="btn btn-link" onClick={e => this.displayLogDetails(log)}>{log.errorId}</button></td>
                                                    <td>{log.site}</td>
                                                    <td>{log.source}</td>
                                                    <td>{log.message}</td>
                                                    <td>{log.dateTimeStamp}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.logs.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                                <Modal id="logDetail" title="Error Detail">
                                    {this.state.logDetail &&
                                        <React.Fragment>
                                            <div className="form-group col-md-12">
                                                <label>Description</label>
                                                <textarea rows={16} className="form-control" readOnly={true} value={this.state.logDetail.description}></textarea>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Query String</label>
                                                <input className="form-control" type="text" readOnly={true} value={this.state.logDetail.queryString}></input>
                                            </div>
                                        </React.Fragment>
                                    }
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorLog;
