import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import UserDefinedField from './UserDefinedField';

class AddUserDefinedField extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.handleFieldInformationChange = this.handleFieldInformationChange.bind(this);
        this.createField = this.createField.bind(this);
    }

    handleFieldInformationChange(fieldInformation) {
        this.fieldInformation = fieldInformation;
    }

    createField() {
        var params = {
            name: this.fieldInformation.name,
            position: this.fieldInformation.position,
            selection: this.fieldInformation.selection,
            filterCriteria: this.fieldInformation.filterCriteria,
            entities: this.fieldInformation.entities.map((value, index) => {
                return { entity: value.value }
            })
        }
        this.http.post('/api/Crm/SaveUserDefinedField', params).then(data => {
            this.props.history.push('/crm/user-defined-field/' + data);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Create Field</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Field Information</div>
                            </div>
                            <div className="card-body">
                                <UserDefinedField onChange={this.handleFieldInformationChange}></UserDefinedField>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createField}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddUserDefinedField)