import React, { Component } from 'react';
import HeaderNotifications from './HeaderNotifications';
import HeaderUserMenu from './HeaderUserMenu';
import HeaderPinLoginMenu from './HeaderPinLoginMenu';

class HeaderNav extends Component {

  render() {
    return (
      <nav className="navbar navbar-header navbar-expand-lg" >
        <div className="container-fluid">
          {localStorage.getItem("loginType") !== "pin" &&
            <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
              <HeaderNotifications></HeaderNotifications>
              <HeaderUserMenu></HeaderUserMenu>
            </ul>
          }
          {localStorage.getItem("loginType") === "pin" &&
              <HeaderPinLoginMenu></HeaderPinLoginMenu>
          }
        </div>
      </nav>

    )
  }
}
export default HeaderNav
