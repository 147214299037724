import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from '../../common/Pagination'
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';

class RecentlyFulfilledOrders extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            orders: [],
            pageCount: 0,
            pageIndex: 0
        }
        this.paramsKey = "params.recently-fulfilled";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();        
        this.handlePageChange = this.handlePageChange.bind(this);
        this.pageSize = 100;
        this.getOrders = this.getOrders.bind(this);
    }

    componentDidMount() {
        this.getOrders(this.state.pageIndex);
    }   

    getOrders(page, e) {
        if(!!e){
            e.preventDefault();
        }
        this.setState({ pageIndex: page });
        var params = {
            pageSize: this.pageSize,
            pageIndex: page
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Orders/GetRecentlyFulfilledOrders', params).then(data => {
            this.setState({ orders: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    handlePageChange(page) {
        this.getOrders(page);
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Recently Fulfilled Orders</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Order</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Customer Number</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Account Type</th>
                                                <th scope="col">State</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Fulfill Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders.map((order) =>
                                                <tr key={order.orderId}>
                                                    <td><Link to={"/order/" + order.orderId}>{order.orderNumber}</Link></td>
                                                    <td><Link to={"/customer/" + order.customerId}>{order.companyName}</Link></td>
                                                    <td><Link to={"/customer/" + order.customerId}>{order.customerNumber}</Link></td>
                                                    <td>{order.site}</td>
                                                    <td>{order.accountType}</td>
                                                    <td>{order.state}</td>
                                                    <td>{order.orderType}</td>
                                                    <td>{order.orderStatus}</td>
                                                    <td>{order.fulfillDate}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.orders.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RecentlyFulfilledOrders;
