import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import CustomerCreditCard from './CustomerCreditCard';

class CustomerCreditCardList extends Component {

    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleCardSelect = this.handleCardSelect.bind(this);
        this.handleEditedCreditCardChange = this.handleEditedCreditCardChange.bind(this);
        this.deleteCreditCard = this.deleteCreditCard.bind(this);
        this.editCreditCard = this.editCreditCard.bind(this);
    }

    handleCardSelect(card, e) {
        if (this.props.onSelect) {
            this.props.onSelect(card);
        }
    }

    handleEditedCreditCardChange(card) {
        this.editedCreditCard = card;
    }

    deleteCreditCard(card, e) {
        var message = "This credit card will be deleted. Do you want to continue?"
        this.utils.confirm(message, () => {
            var params = {
                creditCardId: card.creditCardID,
                customerId: this.props.customer.customerID
            }
            this.http.get('/api/Customers/RemoveCreditCard', params).then(data => {
                if (this.props.onUpdate) {
                    this.props.onUpdate(data);
                }
            });
        })
    }

    editCreditCard() {
        var model = this.editedCreditCard;
        model.customerId = this.props.customer.customerID;
        var params = {
            model: JSON.stringify(model)
        }
        this.http.get("/api/Customers/EditCreditCard", params).then(data => {
            window.$("#editCreditCardModal" + this.editedCreditCard.creditCardId).modal("hide");
            if (this.props.onUpdate) {
                this.props.onUpdate(data);
            }
        });
    }

    render() {
        var cards = this.utils.dictToArray(this.props.customer.activeCreditCards);
        var selectedCardId = this.props.selectedCardId;
        if (!selectedCardId) {
            var defaultCardIndex = cards.findIndex(c => c.primary);
            if (defaultCardIndex >= 0) {
                var defaultCard = cards[defaultCardIndex];
                selectedCardId = defaultCard.creditCardID;
                // Display default card at the top of the list
                cards.splice(defaultCardIndex, 1);
                cards.unshift(defaultCard);
            }
        }
        return (
            <React.Fragment>
                {cards.map(card =>
                    <div key={card.creditCardID} className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="col pt-1">
                                    <p dangerouslySetInnerHTML={{ __html: card.formattedCreditCard }}>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button className={"btn btn-info btn-round btn-sm mr-2 " + (selectedCardId !== card.creditCardID ? "btn-border" : "")} onClick={e => this.handleCardSelect(card, e)}>
                                <span className="btn-label">
                                    <i className="fas fa-check"></i>
                                </span>
                                Use this card
							</button>
                            {this.props.editable &&
                                <React.Fragment>
                                    <button className="btn btn-info btn-border btn-round btn-sm mr-2 pull-right" data-toggle="modal" data-target={"#editCreditCardModal" + card.creditCardID}>
                                        <span className="btn-label">
                                            <i className="fas fa-pen"></i>
                                        </span>
                                        Edit
							        </button>
                                    <button className="btn btn-info btn-border btn-round btn-sm mr-2 pull-right" onClick={e => this.deleteCreditCard(card, e)}>
                                        <span className="btn-label">
                                            <i className="fas fa-trash"></i>
                                        </span>
                                        Delete
							        </button>
                                    <Modal id={"editCreditCardModal" + card.creditCardID} title="Edit Credit Card" onOk={this.editCreditCard}>
                                        <CustomerCreditCard onChange={this.handleEditedCreditCardChange} creditCard={card}></CustomerCreditCard>
                                    </Modal>
                                </React.Fragment>
                            }
                        </div>
                    </div>

                )}
            </React.Fragment>
        )
    }
}

export default CustomerCreditCardList