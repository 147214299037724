import React, { Component } from 'react';

class SmtpAccountInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            host: props.account ? props.account.host : "",
            port: props.account ? props.account.port : "",
            username: props.account ? props.account.username : "",
            password: props.account ? props.account.password : "",
            name: props.account ? props.account.name : ""         
        }

        this.handleHostChange = this.handleHostChange.bind(this);
        this.handlePortChange = this.handlePortChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);      
        this.handleNameChange = this.handleNameChange.bind(this);        
        this.notifyChange();
    }

    handleHostChange(e) {
        this.setState({
            host: e.target.value
        }, this.notifyChange);
    }

    handlePortChange(e) {
        this.setState({
            port: e.target.value
        }, this.notifyChange);
    }

    handleUsernameChange(e) {
        this.setState({
            username: e.target.value
        }, this.notifyChange);
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        }, this.notifyChange);
    }

    handleNameChange(e) {
        this.setState({
            name: e.target.value
        }, this.notifyChange);
    }
   
    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Host</label>
                        <input type="text" value={this.state.host} onChange={this.handleHostChange} className="form-control" />
                    </div>

                    <div className="form-group col-md-6">
                        <label>Port</label>
                        <input type="text" value={this.state.port} onChange={this.handlePortChange} className="form-control" />
                    </div>                    
                    <div className="form-group col-md-6">
                        <label>Username</label>
                        <input type="text" value={this.state.username} onChange={this.handleUsernameChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Password</label>
                        <input type="text" value={this.state.password} onChange={this.handlePasswordChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Name</label>
                        <input type="text" value={this.state.name} onChange={this.handleNameChange} className="form-control" />
                    </div>                    
                </div>
            </React.Fragment>
        );
    }
}

export default SmtpAccountInformation