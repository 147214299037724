import React, { Component } from 'react';

class RadioGroup extends Component {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    handleChange(e) {
        this.setState({
            value: e.target.value
        });
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        return (
            <div className={"form-group selectgroup " + this.props.className} style={this.props.style}>
                {this.props.items.map(item =>
                    <label key={item.value} className="selectgroup-item">
                        <input type="radio" name={this.props.groupName} value={item.value} className="selectgroup-input" onChange={this.handleChange} checked={this.state.value === item.value} />
                        <span className="selectgroup-button">{item.text}</span>
                    </label>
                )}
            </div>
        );
    }
}

export default RadioGroup;