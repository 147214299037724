import React, { Component } from 'react';
import CustomerSearch from '../customers/CustomerSearch';

class StartNewOrder extends Component {
    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Add New Order</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <CustomerSearch placeOrder={true} paramsKey="params.start-new-order">
                        </CustomerSearch>
                    </div>
                </div>
            </div>
        );
    }
}

export default StartNewOrder;
