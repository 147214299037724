import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import { Link } from "react-router-dom";
import Checkbox from '../../common/Checkbox';
import { relativeTimeThreshold } from 'moment';

class CategorySpecialtyGrid extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            categoryDetails: [],
            specialties: []
        }

        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getCategories = this.getCategories.bind(this);
        this.getSpecialties = this.getSpecialties.bind(this);
        this.handleCheckedChange = this.handleCheckedChange.bind(this);
        this.getChecked = this.getChecked.bind(this);
    }

    componentDidMount() {
        this.getCategories();
        this.getSpecialties();
    }

    getCategories() {
        this.http.get('/api/Admin/GetCategoriesWithDetails', { site: "Everidis-HCP" }).then(data => {
            this.setState({ categoryDetails: data });
        });
    }

    getSpecialties() {
        this.http.get('/api/Crm/GetUserDefinedField', { id: 8 }).then(data => {
            this.setState({ specialties: data.options });
        })
    }

    handleCheckedChange(checked, categoryDetail, specialty) {
        var selectedOptions = categoryDetail.userDefinedFields.find(u => u.userDefinedFieldID === 8).userDefinedFieldOptionIDs;
        var optionIndex = selectedOptions.findIndex(i => i === specialty.userDefinedFieldOptionID);
        if (!checked) {
            if (optionIndex >= 0) {
                this.http.post('/api/Admin/AddRemoveCategorySpecialty', {
                    specialtyID: specialty.userDefinedFieldOptionID,
                    categoryID: categoryDetail.category.categoryID,
                    add: false
                }).then(() => {
                    selectedOptions.splice(optionIndex, 1);
                    this.setState({ categoryDetails: this.state.categoryDetails });
                });
            }
        }
        else {
            if (optionIndex < 0) {
                this.http.post('/api/Admin/AddRemoveCategorySpecialty', {
                    specialtyID: specialty.userDefinedFieldOptionID,
                    categoryID: categoryDetail.category.categoryID,
                    add: true
                }).then(() => {
                    selectedOptions.push(specialty.userDefinedFieldOptionID);
                    this.setState({ categoryDetails: this.state.categoryDetails });
                });
            }
        }
    }

    getChecked(specialty, categoryDetail) {
        const udf = categoryDetail.userDefinedFields.find(udf => udf.userDefinedFieldID === 8);
        console.log(udf);
        if (udf) {
            return udf.userDefinedFieldOptionIDs.findIndex(i => i === specialty.userDefinedFieldOptionID) >= 0;
        }
        else {
            return false;
        }
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Categories & Specialties</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                {this.state.categoryDetails.map((categoryDetail) =>
                                                    <th key={categoryDetail.category.categoryID}>{categoryDetail.category.name}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.specialties.map((specialty) =>
                                                <tr key={specialty.userDefinedFieldOptionID}>
                                                    <td>{specialty.name}</td>
                                                    {this.state.categoryDetails.map((categoryDetail) =>
                                                        <td key={categoryDetail.category.categoryID}>
                                                            <div className="form-group col-md-12">
                                                                <Checkbox
                                                                    checked={this.getChecked(specialty, categoryDetail)}
                                                                    onChange={(e) => this.handleCheckedChange(e, categoryDetail, specialty)}
                                                                >
                                                                </Checkbox>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CategorySpecialtyGrid;
