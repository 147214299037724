import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import ShippingCost from './ShippingCost';

class ShippingCostList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            costs: [],
            editedCost: {}
        }

        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getCosts = this.getCosts.bind(this);
        this.handleEditedCostChange = this.handleEditedCostChange.bind(this);
        this.handleEditCost = this.handleEditCost.bind(this);
    }

    componentDidMount() {
        this.getCosts();
    }

    getCosts() {
        this.http.get('/api/Admin/GetShippingCosts', null).then(data => {
            this.setState({ costs: data });
        });
    }

    editCost(cost) {
        this.setState({
            editedCost: cost
        }, () => {
            window.$("#editCostModal").modal("show");
        })
    }

    handleEditedCostChange(cost){
        this.setState({
            editedCost: cost
        });
    }

    handleEditCost(){
        this.http.post('/api/Admin/UpdateShippingCost', this.state.editedCost).then(data => {
            window.$("#editCostModal").modal("hide");
            this.getCosts();
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Shipping Costs</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Company</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Ship Cost</th>
                                                <th scope="col">Wholesale Cost</th>
                                                <th scope="col">Visible</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.costs.map((cost) =>
                                                <tr key={cost.shipTypeId + "-" + cost.siteId + "-" + cost.companyId}>
                                                    <td>{cost.company}</td>
                                                    <td>{cost.site}</td>
                                                    <td>{cost.shipTypeDesc}</td>
                                                    <td>{this.utils.toCurrency(cost.shippingCost)}</td>
                                                    <td>{this.utils.toCurrency(cost.wholesaleCost)}</td>
                                                    <td>{cost.visible ? "yes" : "no"}</td>
                                                    <td><button className="btn btn-sm btn-info" onClick={e => this.editCost(cost)}><i className="fas fa-pen"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Modal id="editCostModal" title="Edit Shipping Cost" onOk={this.handleEditCost}>
                                <ShippingCost cost={this.state.editedCost} onChange={this.handleEditedCostChange}></ShippingCost>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShippingCostList;
