import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';

class MonthlyInventoryReports extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            reports: null,
        }

        this.http = new HttpRequestService();
        this.getReports = this.getReports.bind(this);
    }

    componentDidMount() {
        this.getReports();
    }

    getReports(e) {
        if (!!e) {
            e.preventDefault();
        }

        this.http.get('/api/Report/GetMonthlyInventoryReports').then(data => {
            this.setState({ reports: data });
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Monthly Inventory Reports</h4>
                </div>
                <div className="row">
                    <div class="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {this.state.reports != null &&
                                    <div className="form-row">
                                        <table className="table table-striped mt-1">
                                            {this.state.reports.map((group) =>
                                                <React.Fragment key={group.year}>
                                                    <thead>
                                                        <tr className="bg-primary reportHeader">
                                                            <th colSpan={11}>{group.year}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {group.items && group.items.map(item =>
                                                            <tr key={item.month}>
                                                                <td>
                                                                        <a href={"https://powerd.nutraceutics.com/SavedReports/MonthlyInventoryReport/" + item.fileName} target="_blank">{item.monthName} {item.extension.replace(".", "").toUpperCase()}</a>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </React.Fragment>
                                            )}
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default MonthlyInventoryReports;
