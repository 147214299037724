import React, { Component } from 'react';
import moment from 'moment'
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import PartialLoading from '../../common/PartialLoading';

class MonthlySales extends Component {
    constructor(props) {
        super(props);
        var now = moment();
        this.monthsToShow = 12;
        var startDate = moment(now.subtract('month', this.monthsToShow));
        this.state = {
            beginDate: moment(new Date(startDate.year(), startDate.month(), 1)).toDate(),
            endDate: (moment().add('day', 1)).toDate(),
            total: 0,
            transactions: 0,
            loading: false
        }
        this.chart = null;
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        var params = {
            beginDate: moment(this.state.beginDate).format('L'),
            endDate: moment(this.state.endDate).format('L')
        }
        this.setState({ loading: true });
        this.http.getSilent("/api/Report/GetMonthlySales", params).then(data => {
            this.renderChart(data);
            this.setState({
                loading: false,
            });
        });
    }

    renderChart(data) {
        var self = this;
        if (this.chart !== null) {
            this.chart.destroy();
        }
        if (data.length === 0) {
            return;
        }

        var colors = [];
        var datasetColors = ["#527DA0","#6861ce", "#ffc83d", "#e83e8c"]
        for (var i = 0; i < 2; i++) {
            colors[i] = Array(this.monthsToShow).fill(datasetColors[i]);
            colors[i][this.monthsToShow] = getComputedStyle(document.documentElement).getPropertyValue('--success');
        }

        // prepare datasets
        var datasets = [];
        var colorIndex = 0;
        for (let key in data.data) {
            datasets.push({
                label: key.toUpperCase(),
                fill: false,
                backgroundColor: colors[colorIndex],
                borderColor: "#fff",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0,
                pointBorderColor: "#fff",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#fff",
                pointHoverBorderWidth: 1,
                pointRadius: 1,
                pointHitRadius: 5,
                lineTension: 0,
                data: data.data[key],
                hidden: colorIndex !== 0,
            }
            );
            colorIndex++;
        }

        var ctx = document.getElementById('monthlySalesChart').getContext('2d');
        this.chart = new window.Chart(ctx, {
            type: 'bar',
            data: {
                labels: data.labels,
                datasets: datasets
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: datasets.length > 1 ? true : false
                },
                animation: {
                    easing: "easeInOutBack"
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function (value, index, values) {
                                var legendItems = this.chart.legend.legendItems;
                                var currencySymbol = "";
                                if (legendItems) {
                                    var visibleLegendItems = legendItems.filter(l => !l.hidden);
                                    if (visibleLegendItems.length == 1) {
                                        currencySymbol = self.utils.getCurrencySymbol(visibleLegendItems[0].text);
                                    }
                                }
                                else {
                                    currencySymbol = self.utils.getCurrencySymbol(datasets[0].label);
                                }

                                if (parseInt(value) >= 1000) {
                                    return currencySymbol + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                    return currencySymbol + value;
                                }
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            var dataLabel = data.labels[tooltipItem.index];
                            var value = `: ${self.utils.getCurrencySymbol(data.datasets[tooltipItem.datasetIndex].label)} ` + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString();
                            if (window.Chart.helpers.isArray(dataLabel)) {
                                dataLabel = dataLabel.slice();
                                dataLabel[0] += value;
                            } else {
                                dataLabel += value;
                            }
                            return dataLabel;
                        }
                    }
                }
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <PartialLoading visible={this.state.loading}></PartialLoading>
                <div className="card">
                    <div className="card-header">
                        <div className="card-head-row">
                            <div className="card-title">BioGaia USA Monthly Sales</div>
                            <div className="card-tools">
                                <button className="btn btn-icon btn-link mr-2" onClick={this.getData}>
                                    <i className="fas fa-sync"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-category">{moment(this.state.beginDate).format("MMMM D, YYYY")} - {moment(this.state.endDate).format("MMMM D, YYYY")}</div>
                    </div>
                    <div className="card-body pb-0">
                        <canvas id="monthlySalesChart" style={{ minHeight: 375 }}></canvas>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default MonthlySales