import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import AutoComplete from '../../common/AutoComplete';
import UserDefinedFieldEditor from '../../common/UserDefinedFieldEditor';
import Checkbox from '../../common/Checkbox';


class ContactInformation extends Component {

    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            firstName: props.contact ? props.contact.firstName : "",
            lastName: props.contact ? props.contact.lastName : "",
            email: props.contact ? props.contact.email : "",
            account: props.contact ? { label: props.contact.accountName, value: props.contact.accountID } : null,
            userDefinedFields: [],
            isPrimary: props.contact ? props.contact.isPrimary : false,
        };

        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleIsPrimaryChange = this.handleIsPrimaryChange.bind(this);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.getUserDefinedFields = this.getUserDefinedFields.bind(this);
        this.notifyChange();
    }

    componentDidMount() {
        this.getUserDefinedFields();
    }

    getUserDefinedFields() {
        var params = {
            entity: "Contact"
        }
        this.http.get("/api/Crm/GetUserDefinedFieldsForEntity", params).then(data => {
            // match values with fields
            for (var i = 0; i < data.length; i++) {
                if (this.props.contact && this.props.contact.userDefinedFields) {
                    var contactFieldValue = this.props.contact.userDefinedFields.find(f => f.userDefinedFieldID === data[i].userDefinedFieldID);
                    if (data[i].selection === "Single") {
                        data[i].value = contactFieldValue ? contactFieldValue.userDefinedFieldOptionID : -1;
                    }
                    else {
                        data[i].value = contactFieldValue ? contactFieldValue.userDefinedFieldOptionIDs : [];
                    }
                }
                else {
                    if (data[i].selection === "Single") {
                        data[i].value = -1;
                    }
                    else {
                        data[i].value = [];
                    }
                }
            }
            this.setState({ userDefinedFields: data }, this.notifyChange);
        });
    }

    handleAccountChange(e) {
        this.setState({
            account: e
        }, this.notifyChange)
    }

    handleFirstNameChange(e) {
        this.setState({
            firstName: e.target.value
        }, this.notifyChange)
    }

    handleLastNameChange(e) {
        this.setState({
            lastName: e.target.value
        }, this.notifyChange)
    }

    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        }, this.notifyChange)
    }

    handleIsPrimaryChange(checked) {
        this.setState({
            isPrimary: checked
        }, this.notifyChange)
    }

    handleFieldChange(index, e) {
        var userDefinedFields = this.state.userDefinedFields;
        userDefinedFields[index].value = e;
        this.setState({
            userDefinedFields: userDefinedFields
        }, this.notifyChange);
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-group col-md-12">
                        <label>Account</label>
                        {this.props.accountEditable !== false &&
                            <AutoComplete endpoint="/api/Crm/SearchAccountsByKeyword" value={this.state.account} onChange={this.handleAccountChange}></AutoComplete>
                        }
                        {this.props.accountEditable === false &&
                            <div>
                                <Link to={"/crm/account/" + this.state.account.value}>{this.state.account.label}</Link>
                            </div>                            
                        }
                    </div>
                    <div className="form-group col-md-12">
                        <label>First Name</label>
                        <input type="text" className="form-control" value={this.state.firstName} onChange={this.handleFirstNameChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Last Name</label>
                        <input type="text" className="form-control" value={this.state.lastName} onChange={this.handleLastNameChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Email</label>
                        <input type="text" className="form-control" value={this.state.email} onChange={this.handleEmailChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <Checkbox label="Primary Contact" checked={this.state.isPrimary} onChange={this.handleIsPrimaryChange}></Checkbox>
                    </div>
                    {this.state.userDefinedFields.map((field, index) =>
                        <div key={field.userDefinedFieldID} className="form-group col-md-12">
                            <UserDefinedFieldEditor field={field} value={field.value} onChange={(e) => this.handleFieldChange(index, e)}></UserDefinedFieldEditor>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default ContactInformation;
