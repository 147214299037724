import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';

class Callback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callbackID: props.callback ? props.callback.callbackID : 0,
            callbackNotes: props.callback ? props.callback.callbackNotes : "",
            callbackName: props.callback ? props.callback.callbackName : "",
            callbackInfo: props.callback ? props.callback.callbackInfo : "",
            enteredBy: props.callback ? props.callback.enteredBy : "",        
        }

        this.http = new HttpRequestService();
        this.handleNoteChange = this.handleNoteChange.bind(this);
        this.handleCallbackNameChange = this.handleCallbackNameChange.bind(this);
        this.handleCallbackInfoChange = this.handleCallbackInfoChange.bind(this);
        this.handleEnteredByChange = this.handleEnteredByChange.bind(this);
        this.getInitials = this.getInitials.bind(this);
        this.notifyChange();
    }

    componentDidMount(){
        if(!this.props.callback){
            this.getInitials();
        }
    }

    getInitials(){
        this.http.get("/api/PowerD/GetInitials", null).then(data => {
            this.setState({
                enteredBy: data 
            })
          });
    }

    handleNoteChange(e) {
        this.setState({
            callbackNotes: e.target.value
        }, this.notifyChange);
    }

    handleEnteredByChange(e) {
        this.setState({
            enteredBy: e.target.value
        }, this.notifyChange);
    }  
    
    handleCallbackNameChange(e) {
        this.setState({
            callbackName: e.target.value
        }, this.notifyChange);
    }  

    handleCallbackInfoChange(e) {
        this.setState({
            callbackInfo: e.target.value
        }, this.notifyChange);
    }  

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label>Entered By</label>
                        <input type="text" className="form-control" value={this.state.enteredBy} onChange={this.handleEnteredByChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Callback Name</label>
                        <input type="text" className="form-control" value={this.state.callbackName} onChange={this.handleCallbackNameChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Callback Info</label>
                        <input type="text" className="form-control" value={this.state.callbackInfo} onChange={this.handleCallbackInfoChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Notes</label>
                        <textarea className="form-control" maxLength={500} rows={10} value={this.state.callbackNotes} onChange={this.handleNoteChange}></textarea>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Callback