import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import CustomerInfoCard from '../customers/CustomerInfoCard';
import OrderInformation from './OrderInformation';
import { HttpRequestService } from '../../services/HttpRequestService';

class AddOrder extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            customer: null,
            orderInformation: {}
        };
        this.handleOrderInformationChange = this.handleOrderInformationChange.bind(this);
        this.createOrder = this.createOrder.bind(this);
    }

    componentDidMount() {
        this.getCustomer();
    }

    handleOrderInformationChange(orderInformation) {
        this.setState({
            orderInformation: orderInformation
        })
    }

    getCustomer() {
        var params = {
            customerId: this.props.match.params.customerId
        }
        this.http.get('/api/Customers/GetCustomer', params).then(data => {
            this.setState({
                customer: data
            })
        })
    }

    createOrder() {
        var params = this.state.orderInformation;
        params.customerId = this.state.customer.customerID

        this.http.get('/api/Orders/CreateOrder', params).then(data => {
            this.props.history.push('/order/' + data);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Create Order</h4>
                </div>
                {this.state.customer &&
                    <div className="row">
                        <div className="col-md-3">
                            <CustomerInfoCard customer={this.state.customer}></CustomerInfoCard>
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">Order Information</div>
                                </div>
                                <div className="card-body">
                                    <OrderInformation paymentType={this.state.customer.defaultTerms ? this.state.customer.defaultTerms.key : 1} accountType={this.state.customer.salesman ? this.state.customer.salesman.key : 8} onChange={this.handleOrderInformationChange}></OrderInformation>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="card full-height">
                            <div className="card-body pull-right">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.createOrder}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddOrder)