import React, { Component } from 'react';
import Checkbox from '../../common/Checkbox';

class CustomerTypeInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerTypeDescription: props.customerType ? props.customerType.customerTypeDescription : "",
            customerCategoryCode: props.customerType ? props.customerType.customerCategoryCode : "",
            isActive: props.customerType ? props.customerType.isActive : true,
            isWeb: props.customerType ? props.customerType.isWeb : false        
        }

        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleCategoryCodeChange = this.handleCategoryCodeChange.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.handleIsWebChange = this.handleIsWebChange.bind(this);
        this.notifyChange();
    }

    handleDescriptionChange(e) {
        this.setState({
            customerTypeDescription: e.target.value
        }, this.notifyChange);
    }

    handleCategoryCodeChange(e) {
        this.setState({
            customerCategoryCode: e.target.value
        }, this.notifyChange);
    }
    
    handleIsActiveChange(checked) {
        this.setState({
            isActive: checked
        }, this.notifyChange);
    }

    handleIsWebChange(checked) {
        this.setState({
            isWeb: checked
        }, this.notifyChange);
    }    

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label>Description</label>
                        <input type="text" value={this.state.customerTypeDescription} onChange={this.handleDescriptionChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-12">
                        <label>Category Code</label>
                        <input type="text" value={this.state.customerCategoryCode} onChange={this.handleCategoryCodeChange} className="form-control" />
                    </div>
                    <div className="form-group col-md-12">
                        <Checkbox label="Is Active" checked={this.state.isActive} onChange={this.handleIsActiveChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-12">
                        <Checkbox label="Is Web" checked={this.state.isWeb} onChange={this.handleIsWebChange}></Checkbox>
                    </div>                    
                </div>
            </React.Fragment>
        );
    }
}

export default CustomerTypeInformation