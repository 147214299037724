import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
        this.handleCancelClicked = this.handleCancelClicked.bind(this);
        this.handleOkClicked = this.handleOkClicked.bind(this);
        this.props.history.listen(location => {
            // modal backdrop does not disappear when we navigate to a new page from a modal
            window.$(".modal-backdrop").remove();
        });
    }

    componentDidMount() {
        window.$("#" + this.props.id).on("show.bs.modal", (e) => {
            this.setState({
                visible: true
            })
        });

        window.$("#" + this.props.id).on("hide.bs.modal", (e) => {
            this.setState({
                visible: false
            })
        });
    }

    handleCancelClicked() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    handleOkClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onOk) {
            this.props.onOk();
        }
        else {
            window.$("#" + this.props.id).modal("hide");
        }
    }

    render() {
        return (
            <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ maxWidth: this.props.width ? this.props.width : "" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.title}</h5>
                            {!this.props.hideCloseButton &&
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            }
                        </div>
                        <form onSubmit={(e) => this.handleOkClicked(e)}>
                            <div className="modal-body" style={{ height: this.props.height ? this.props.height : "", paddingTop: 0, paddingBottom: this.props.paddingBottom ? this.props.paddingBottom : "" }}>
                                {this.state.visible &&
                                    this.props.children
                                }
                            </div>
                            {!this.props.hideButtons &&
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary btn-focus-highlight" data-dismiss="modal" onClick={this.handleCancelClicked}>Cancel</button>
                                    <button type="submit" className="btn btn-primary btn-focus-highlight">{this.props.okText ? this.props.okText : "Ok"}</button>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Modal)