import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import PaymentTypeSelect from '../../common/PaymentTypeSelect';
import Checkbox from '../../common/Checkbox';
import AccountTypesSelect from '../../common/AccountTypesSelect';
import ShipTypeSelect from '../../common/ShipTypeSelect';

class CustomerAccountInformation extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            webUserName: props.customer ? props.customer.userName : "",
            emailAddress: props.customer ? props.customer.email : "",
            companyName: props.customer ? props.customer.companyName : "",
            firstName: props.customer ? props.customer.firstName : "",
            lastName: props.customer ? props.customer.lastName : "",
            customerNumber: props.customer ? props.customer.customerNumber : "",
            phoneNumber: props.customer ? props.customer.phoneNumber : "",
            faxNumber: props.customer ? props.customer.faxNumber : "",
            defaultShipTypeId: props.customer ? props.customer.defaultShipTypeID : 0,
            discount: props.customer ? props.customer.discount * 100 : 0,
            defaultTermsId: props.customer ? props.customer.defaultTerms.key : 1,
            salesmanId: props.customer ? props.customer.salesman.key : 8,
            addToMailingList: props.customer ? props.customer.mailingList : true,
            chargeSalesTax: props.customer ? props.customer.chargeSalesTax : true,
            prop65: props.customer ? props.customer.prop65 : false,
            isActive: props.customer ? props.customer.isActive : true,
            autoSendInvoices: props.customer ? props.customer.autoSendInvoices : false,
            autoPrintInvoices: props.customer ? props.customer.autoPrintInvoices : false,
            invoiceEmailAddresses: props.customer ? props.customer.invoiceEmailAddresses : "",
        };

        this.handleWebUserNameChange = this.handleWebUserNameChange.bind(this);
        this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
        this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleCustomerNumberChange = this.handleCustomerNumberChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleFaxNumberChange = this.handleFaxNumberChange.bind(this);

        this.handleDefaultShipTypeChange = this.handleDefaultShipTypeChange.bind(this);
        this.handleDiscountChange = this.handleDiscountChange.bind(this);
        this.handleDefaultTermsChange = this.handleDefaultTermsChange.bind(this);
        this.handleSalesmanChange = this.handleSalesmanChange.bind(this);
        this.handleMailingListChange = this.handleMailingListChange.bind(this);
        this.handleChargeSalesTaxChange = this.handleChargeSalesTaxChange.bind(this);
        this.handleProp65Change = this.handleProp65Change.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.handleAutoSendInvoicesChange = this.handleAutoSendInvoicesChange.bind(this);
        this.handleAutoPrintInvoicesChange = this.handleAutoPrintInvoicesChange.bind(this);
        this.handleInvoiceEmailAddressesChange = this.handleInvoiceEmailAddressesChange.bind(this);
        this.notifyChange();
    }

    handleWebUserNameChange(e) {
        this.setState({
            webUserName: e.target.value
        }, this.notifyChange)
    }

    handleEmailAddressChange(e) {
        this.setState({
            emailAddress: e.target.value
        }, this.notifyChange)
    }

    handleCompanyNameChange(e) {
        this.setState({
            companyName: e.target.value
        }, this.notifyChange)
    }

    handleFirstNameChange(e) {
        this.setState({
            firstName: e.target.value
        }, this.notifyChange)
    }

    handleLastNameChange(e) {
        this.setState({
            lastName: e.target.value
        }, this.notifyChange)
    }

    handleCustomerNumberChange(e) {
        this.setState({
            customerNumber: e.target.value
        }, this.notifyChange)
    }

    handlePhoneNumberChange(e) {
        this.setState({
            phoneNumber: e.target.value
        }, this.notifyChange)
    }

    handleFaxNumberChange(e) {
        this.setState({
            faxNumber: e.target.value
        }, this.notifyChange)
    }

    handleDefaultShipTypeChange(shipType) {
        this.setState({
            defaultShipTypeId: shipType.shipTypeID
        }, this.notifyChange)
    }

    handleDiscountChange(e) {
        this.setState({
            discount: e.target.value
        }, this.notifyChange)
    }

    handleDefaultTermsChange(value) {
        this.setState({
            defaultTermsId: value
        }, this.notifyChange)
    }

    handleSalesmanChange(value) {
        this.setState({
            salesmanId: value
        }, this.notifyChange)
    }

    handleMailingListChange(checked) {
        this.setState({
            addToMailingList: checked
        }, this.notifyChange)
    }

    handleChargeSalesTaxChange(checked) {
        this.setState({
            chargeSalesTax: checked
        }, this.notifyChange)
    }

    handleProp65Change(checked) {
        this.setState({
            prop65: checked
        }, this.notifyChange)
    }

    handleAutoSendInvoicesChange(checked) {
        this.setState({
            autoSendInvoices: checked
        }, this.notifyChange)
    }

    handleAutoPrintInvoicesChange(checked) {
        this.setState({
            autoPrintInvoices: checked
        }, this.notifyChange)
    }

    handleInvoiceEmailAddressesChange(e) {
        this.setState({
            invoiceEmailAddresses: e.target.value
        }, this.notifyChange)
    }


    handleIsActiveChange(checked) {
        this.setState({
            isActive: checked
        }, this.notifyChange)
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <div className="form-row">
                {this.props.customer && this.props.customer.onHold &&
                    <div className="col-md-12">
                        <div className="form-group col-md-12">
                            <label className="text-danger">** CUSTOMER ON HOLD **</label>
                        </div>
                    </div>
                }
                {this.props.mode === "addCustomer" &&
                    <React.Fragment>
                        <div className="col-md-6">
                            <div className="form-group col-md-12">
                                <label>Web User Name</label>
                                <input type="text" className="form-control" value={this.state.webUserName} onChange={this.handleWebUserNameChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>E-mail Address</label>
                                <input type="text" className="form-control" value={this.state.emailAddress} onChange={this.handleEmailAddressChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Account Name</label>
                                <input type="text" className="form-control" value={this.state.companyName} onChange={this.handleCompanyNameChange}></input>
                            </div>

                            <div className="form-group col-md-12">
                                <label>Account Number</label>
                                <input type="text" className="form-control" value={this.state.customerNumber} onChange={this.handleCustomerNumberChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Phone Number</label>
                                <input type="text" className="form-control" value={this.state.phoneNumber} onChange={this.handlePhoneNumberChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Fax Number</label>
                                <input type="text" className="form-control" value={this.state.faxNumber} onChange={this.handleFaxNumberChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Contact First Name</label>
                                <input type="text" className="form-control" value={this.state.firstName} onChange={this.handleFirstNameChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Contact Last Name</label>
                                <input type="text" className="form-control" value={this.state.lastName} onChange={this.handleLastNameChange}></input>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label>Default Shipping Method</label>
                                    <ShipTypeSelect value={this.state.defaultShipTypeId} onChange={this.handleDefaultShipTypeChange}></ShipTypeSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Discount (%)</label>
                                    <input type="number" className="form-control" value={this.state.discount} onChange={this.handleDiscountChange}></input>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Default Terms</label>
                                    <PaymentTypeSelect value={this.state.defaultTermsId} onChange={this.handleDefaultTermsChange}></PaymentTypeSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Account Type</label>
                                    <AccountTypesSelect value={this.state.salesmanId} onChange={this.handleSalesmanChange}></AccountTypesSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Send Invoices To:</label>
                                    <input type="text" className="form-control" value={this.state.invoiceEmailAddresses} onChange={this.handleInvoiceEmailAddressesChange}></input>
                                    <small>Separate multiple email addresses using semicolon(;) or comma(,)</small>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Add Customer to Mailing List" checked={this.state.addToMailingList} onChange={this.handleMailingListChange}></Checkbox>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Charge this Customer Sales Tax?" checked={this.state.chargeSalesTax} onChange={this.handleChargeSalesTaxChange}></Checkbox>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Prop 65 Labeled Customer" checked={this.state.prop65} onChange={this.handleProp65Change}></Checkbox>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Auto Send Invoices" checked={this.state.autoSendInvoices} onChange={this.handleAutoSendInvoicesChange}></Checkbox>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Auto Print Invoices" checked={this.state.autoPrintInvoices} onChange={this.handleAutoPrintInvoicesChange}></Checkbox>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {this.props.mode === "editCustomer" &&
                    <React.Fragment>
                        <div className="col-md-6">
                            <div className="form-group col-md-12">
                                <label>Web User Name</label>
                                <input type="text" className="form-control" value={this.state.webUserName} onChange={this.handleWebUserNameChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>E-mail Address</label>
                                <input type="text" className="form-control" value={this.state.emailAddress} onChange={this.handleEmailAddressChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Default Shipping Method</label>
                                <ShipTypeSelect value={this.state.defaultShipTypeId} onChange={this.handleDefaultShipTypeChange}></ShipTypeSelect>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Discount (%)</label>
                                <input type="number" className="form-control" value={this.state.discount} onChange={this.handleDiscountChange}></input>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Default Terms</label>
                                <PaymentTypeSelect value={this.state.defaultTermsId} onChange={this.handleDefaultTermsChange}></PaymentTypeSelect>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label>Send Invoices To:</label>
                                    <input type="text" className="form-control" value={this.state.invoiceEmailAddresses} onChange={this.handleInvoiceEmailAddressesChange}></input>
                                    <small>Separate multiple email addresses using semicolon(;) or comma(,)</small>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Add Customer to Mailing List" checked={this.state.addToMailingList} onChange={this.handleMailingListChange}></Checkbox>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Charge this Customer Sales Tax?" checked={this.state.chargeSalesTax} onChange={this.handleChargeSalesTaxChange}></Checkbox>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Prop 65 Labeled Customer" checked={this.state.prop65} onChange={this.handleProp65Change}></Checkbox>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Auto Send Invoices" checked={this.state.autoSendInvoices} onChange={this.handleAutoSendInvoicesChange}></Checkbox>
                                </div>
                                <div className="form-group col-md-12">
                                    <Checkbox label="Auto Print Invoices" checked={this.state.autoPrintInvoices} onChange={this.handleAutoPrintInvoicesChange}></Checkbox>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default CustomerAccountInformation;
