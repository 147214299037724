import React, { Component } from 'react';

class PartialLoading extends Component {
    render() {
        var display = this.props.visible ? "" : "none";
        return (
            <div className="lds-ring" style={{ display: display }}><div></div><div></div><div></div><div></div></div>
        );
    }
}
export default PartialLoading