import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import CompanySelect from '../../common/CompanySelect';
import Checkbox from '../../common/Checkbox';
import Report from '../../common/Report';

class InventoryReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            activeOnly: true,
            inventoryKeptOnly: false,
            companyId: -1,
            report: null,
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.paramsKey = "params.inventory-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleActiveOnlyChange = this.handleActiveOnlyChange.bind(this);
        this.handleInventoryKeptOnlyChange = this.handleInventoryKeptOnlyChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
    }

    componentDidMount() {
        this.getReport();
    }

    handleCompanyChange(value) {
        this.setState({ companyId: value });
    }

    handleActiveOnlyChange(checked) {
        this.setState({ activeOnly: checked });
    }

    handleInventoryKeptOnlyChange(checked) {
        this.setState({ inventoryKeptOnly: checked });
    }

    getReport(e) {
        if (!!e) {
            e.preventDefault();
        }
        var params = {
            activeOnly: this.state.activeOnly,
            inventoryKeptOnly: this.state.inventoryKeptOnly,
            companyId: this.state.companyId,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Report/GetInventoryReport', params).then(data => {
            this.setState({ report: data });
        });
    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Inventory Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <form onSubmit={e => this.getReport(e)}>
                                <div className="card-body">
                                    <div className="form-row">
                                        <div className="form-group col-md-2">
                                            <label>&nbsp;</label>
                                            <Checkbox label="Show Active Only" checked={this.state.activeOnly} onChange={this.handleActiveOnlyChange}></Checkbox>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>&nbsp;</label>
                                            <Checkbox label="Show Only Products Where Inventory Is Kept" checked={this.state.inventoryKeptOnly} onChange={this.handleInventoryKeptOnlyChange}></Checkbox>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Company</label>
                                            <CompanySelect hasAllOption={true} value={this.state.companyId} onChange={this.handleCompanyChange}></CompanySelect>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {this.state.report != null &&
                            <Report name="InventoryReport" hasData={this.state.report.items && this.state.report.items.length > 0} title="Inventory Report" description={this.state.report.description}>
                                <div className="form-row">
                                    <table className="table table-striped mt-1 report-table">
                                        {this.state.report.items.map((group) =>
                                            <React.Fragment key={group.name}>
                                                <thead>
                                                    <tr className="bg-primary reportHeader">
                                                        <th colSpan={11}>{group.name}</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("ProductNumber")}>PROD #</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("Name")}>PROD NAME</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("BarCodeUPC")}>UPC</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("AmountInStock")}>IN STOCK</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("KeepInventory")}>INV?</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("Cost")}>COST</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("RetailPrice")}>RETAIL</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("WholesalePrice")}>WHLSL</th>
                                                        <th className="sortable currency" scope="col" onClick={(e) => this.sortReport("SalePrice")}>SALE</th>
                                                        <th className="sortable" scope="col" onClick={(e) => this.sortReport("IsActive")}>ACTV?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {group.items && group.items.map(item =>
                                                        <tr key={item.productID} className={item.reorderLevel > item.amountInStock ? "highlight" : ""}>
                                                            <td><Link to={"/inventory/product/" + item.productID}>{item.productNumber}</Link></td>
                                                            <td>{item.name}</td>
                                                            <td>{item.barCodeUPC}</td>
                                                            <td>{item.amountInStock}</td>
                                                            <td>{item.keepInventory ? "yes" : "no"}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.cost)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.retailPrice)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.wholesalePrice)}</td>
                                                            <td className="currency">{this.utils.toCurrency(item.salePrice)}</td>
                                                            <td>{item.isActive ? "yes" : "no"}</td>
                                                        </tr>
                                                    )}
                                                    <tr className="bg-light reportFooter">
                                                        <th colSpan={3}>TOTAL # of PRODUCTS</th>
                                                        <th colSpan={7}>{group.items.length}</th>
                                                    </tr>
                                                </tbody>
                                            </React.Fragment>
                                        )}
                                        <tbody>
                                            <tr className="bg-dark reportFooter">
                                                <th colSpan={3}>TOTAL # of PRODUCTS</th>
                                                <th colSpan={7}>{this.state.report.itemCount}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default InventoryReport;
