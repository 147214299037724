import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SitesSelect from '../../common/SitesSelect'
import AccountTypesSelect from '../../common/AccountTypesSelect'
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Accordion from '../../common/Accordion';
import Modal from '../../common/Modal';
import OrderShipmentDetails from './OrderShipmentDetails';
import Checkbox from '../../common/Checkbox';
import ShipTypeSelect from '../../common/ShipTypeSelect';
import * as signalR from "signalr";
import PlaceOrderOnHold from '../../common/PlaceOrderOnHold';
import printJS from 'print-js';

class PaperlessFulfillList extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();

        var initialState = {
            siteId: -1,
            accountTypeId: -1,
            pricingType: '-- All --',
            shipTypeId: -1,
            timeInTransit: -1,
            orders: [],
            ordersToFulfill: [],
            summaryItems: [],
            ordersToShip: null,
            activeTab: "readyToPack",
            fulfillmentGroups: [],
            groupOrders: false,
            sortBy: "",
            sortDirection: "ASC",
            ordersToPrint: []
        }

        this.timeInTransitOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        this.paramsKey = props.paramsKey ? props.paramsKey : "params.fulfill-list";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handleShipTypeChange = this.handleShipTypeChange.bind(this);
        this.handlePricingTypeChange = this.handlePricingTypeChange.bind(this);
        this.handleTimeInTransitChange = this.handleTimeInTransitChange.bind(this);
        this.handleShipmentDetailsChange = this.handleShipmentDetailsChange.bind(this);
        this.handleGroupOrdersChange = this.handleGroupOrdersChange.bind(this);
        this.startPackingOrder = this.startPackingOrder.bind(this);
        this.submitShipmentDetails = this.submitShipmentDetails.bind(this);
        this.printShippingLabels = this.printShippingLabels.bind(this);
        this.handleColCheck = this.handleColCheck.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.autoRefresh = this.autoRefresh.bind(this);
        this.batchFulfill = this.batchFulfill.bind(this);
        this.pageSize = 150;
        this.orderHold = null;
        this.orderToBePlacedOnHold = null;
        this.handleOrderHoldChange = this.handleOrderHoldChange.bind(this);
        this.placeOrderOnHold = this.placeOrderOnHold.bind(this);
    }

    componentDidMount() {
        this.getOrders();
        this.getSummary();
        this.connectToFulfillmentHub();
        let accordionToggleSessionStr = sessionStorage.getItem("fulfillment.groupAccordions");
        let accordionToggleSession = [];
        if (accordionToggleSessionStr !== null) {
            accordionToggleSession = JSON.parse(accordionToggleSessionStr);
        }
        this.accordionToggles = accordionToggleSession;
        this.timer = setInterval(this.autoRefresh, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    connectToFulfillmentHub() {
        const connection = window.$.hubConnection();
        const hubProxy = connection.createHubProxy("PowerDHub");

        function start() {
            connection.start().done(() => {
                console.log("Connection to fulfillment hub established");

            }).fail(() => {
                console.log("Connection to fulfillment hub failed!");
            });
        }

        connection.disconnected(() => {
            // reconnect
            start();
        });

        hubProxy.on('receiveMessage', (message) => {
            this.getOrders(null, true);
        });

        start();
    }

    handleSiteChange(siteId) {
        this.setState({
            siteId: siteId
        }, () => {
            this.getOrders();
        });
    }

    handleAccountTypeChange(accountTypeId) {
        this.setState({
            accountTypeId: accountTypeId
        }, () => {
            this.getOrders();
        });
    }

    handleShipTypeChange(shipType) {
        this.setState({
            shipTypeId: shipType && shipType.shipTypeID ? shipType.shipTypeID : -1
        }, () => {
            this.getOrders();
        });
    }

    handleTimeInTransitChange(e) {
        this.setState({ timeInTransit: e.target.value });
    }


    handlePricingTypeChange(e) {
        this.setState({
            pricingType: e.target.value
        }, () => {
            this.getOrders();
        });
    }

    handleGroupOrdersChange(checked) {
        this.setState({
            groupOrders: checked,
            orders: [],
            ordersToFulfill: []
        }, () => {
            this.getOrders();
        });
    }

    handleColCheck(checked) {
        var rows = document.getElementsByClassName("row-check");
        for (var i = 0; i < rows.length; i++) {
            rows[i].checked = checked;
            this.state.orders[i].checked = checked;
        }
    }

    handleRowCheck(order, checked) {
        order.checked = checked;
    }

    handleTabClicked(tab) {
        this.setState({
            activeTab: tab,
            fulfillmentGroups: [],
            orders: [],
        }, () => this.getOrders());
    }

    sortOrders(sortBy) {
        this.setState({
            sortBy: sortBy,
            sortDirection: this.state.sortDirection === "ASC" ? "DESC" : "ASC"
        }, () => {
            this.getOrders()
        });
    }

    getOrders(e, silent) {
        if (!!e) {
            e.preventDefault();
        }
        var params = {
            siteId: this.state.siteId,
            accountTypeId: this.state.accountTypeId,
            shipTypeId: this.state.shipTypeId,
            timeInTransit: this.state.timeInTransit,
            pricingType: this.state.pricingType,
            status: this.state.activeTab,
            groupOrders: this.state.groupOrders,
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        if (this.state.groupOrders) {
            this.http.get('/api/Orders/SearchFulfillmentGroup', params, silent).then(data => {
                this.setState({ fulfillmentGroups: data });
            });
        }
        else {
            this.http.get('/api/Orders/SearchFulfillment', params, silent).then(data => {
                this.setState({ orders: data.data });
            });
        }
    }

    getSummary(silent) {
        this.http.get('/api/Orders/GetFulfillmentSummary', { samplesOnly: true }, silent).then(data => {
            this.setState({ summaryItems: data });
        });
    }

    autoRefresh() {
        // refresh only if there is no modal window open
        if (this.state.ordersToShip === null) {
            this.getOrders(null, true);
            this.getSummary(true);
        }
    }

    batchFulfill() {
        if (this.state.orders.findIndex(o => o.checked) < 0) {
            this.utils.warning("Please select orders to be fulfilled");
        }
        else {
            this.utils.confirm("Are you sure to fulfill selected orders?", () => {
                var selectedOrders = this.state.orders.filter(o => o.checked);
                var orderIds = [];
                for (var i = 0; i < selectedOrders.length; i++) {
                    orderIds.push(selectedOrders[i].orderId);
                }
                var params = {
                    orders: orderIds.join(",")
                }
                this.http.get('/api/Orders/FulfillOrders', params).then(data => {
                    this.getOrders();
                    this.getSummary();
                });
            });
        }
    }

    startPackingOrder(orders) {
        var shippingMethod = orders[0].shippingMethod.toLowerCase();
        if (shippingMethod.indexOf("fedex") >= 0 || shippingMethod.indexOf("ups") >= 0 || shippingMethod.indexOf("usps") >= 0 || shippingMethod === "standard shipping") {
            var params = {
                ids: orders.map((o => { return o.orderId; }))
            }
            this.http.post('/api/Orders/SetOrdersAsBeingPacked', params).then(data => {
                this.openShippingDetailsModal(orders);
                this.getOrders();
            });
        }
        else {
            this.openTraditionalShippingModal(orders);
        }
    }

    revertToReadyToPack(orders, force) {
        if (!force) {
            var confirmText = "Are you sure to revert the orders to 'Ready To Pack' status?";
            if (orders.length === 1) {
                confirmText = "Are you sure to revert the order to 'Ready To Pack' status?";
            }
            this.utils.confirm(confirmText, () => {
                var params = {
                    ids: orders.map((o => { return o.orderId; }))
                }
                this.http.post('/api/Orders/SetOrdersAsReadyToPack', params).then(data => {
                    this.getOrders();
                });
            });
        }
        else {
            var params = {
                ids: orders.map((o => { return o.orderId; }))
            }
            this.http.post('/api/Orders/SetOrdersAsReadyToPack', params).then(data => {
                this.getOrders();
            });
        }
    }

    openShippingDetailsModal(orders) {
        if (orders.length === 1) {
            this.http.get('/api/Orders/GetOrder', { orderId: orders[0].orderId }).then(data => {
                orders[0].cartItems = this.getCartItems(data.shoppingCart);
                orders[0].standardComment = data.standardComment;
                orders[0].customer = data.customer;
                orders[0].printInvoice = data.customer.autoPrintInvoices || (orders[0].standardComment && orders[0].standardComment.value === "Print Invoice Only");
                orders[0].printPackingSlip = (orders[0].standardComment && orders[0].standardComment.value === "Print Packing Slip Only");
                this.getOrders();
                this.setState({
                    ordersToShip: orders
                }, () => {
                    window.$("#shipmentDetailsModal").modal({
                        show: true,
                        backdrop: "static"
                    });
                });
            });
        }
        else if (orders[0].cartItems) {
            this.getOrders();
            this.setState({
                ordersToShip: orders
            }, () => {
                window.$("#shipmentDetailsModal").modal({
                    show: true,
                    backdrop: "static"
                });
            });
        }
    }

    openTraditionalShippingModal(orders) {
        this.setState({
            ordersToShip: orders
        }, () => {
            window.$("#traditionalShippingModal").modal({
                show: true,
                backdrop: "static"
            });
        });
    }

    getCartItems(shoppingCart) {
        if (!!shoppingCart) {
            var sortedProductItems = shoppingCart.sortedNutraCartProductItems;
            var cartItems = [];
            for (var key in sortedProductItems) {
                var item = sortedProductItems[key];
                item.id = item.productID;
                cartItems.push(item);
            }
            return cartItems;
        }
        else {
            return [];
        }
    }

    handleShipmentDetailsChange(value) {
        this.shipmentDetails = value;
    }

    submitShipmentDetails() {
        var params = {
            orderIds: this.state.ordersToShip.map(o => { return o.orderId }),
            cartItems: this.state.ordersToShip[0].cartItems,
            shipmentDetails: this.shipmentDetails
        }

        this.http.post('/api/Orders/SetShipment', params).then(data => {
            window.$("#shipmentDetailsModal").modal("hide").on("hidden.bs.modal", () => {
                this.setState({
                    ordersToPrint: [...this.state.ordersToShip]
                }, () => {
                    this.setState({
                        ordersToShip: null
                    });
                    this.batchPrintShippingLabels(this.state.ordersToPrint);
                });
            });
        });
    }

    batchPrintShippingLabels(orders, existing) {
        this.printShippingLabels(orders, existing);
    }

    printShippingLabels(orders, existing) {
        const orderIds = orders.map(o => { return o.orderId; });
        var params = {
            orders: orderIds.join(",")
        }
        if (existing) {
            printJS({
                printable: this.http.HOST + "/Print/FedExShippingLabels?orders=" + params.orders,
                type: 'pdf',
                showModal: true,
                modalMessage: orders.length > 1 ? "Retrieving Labels" : "Retrieving Label",
            });
        }
        else {
            this.http.get('/api/Orders/PrintShippingLabels', params).then(data => {
                const ordersToPrint = [];
                for (const order of this.state.ordersToPrint) {
                    if (data.printedOrders.findIndex(o => o === order.orderId) < 0) {
                        ordersToPrint.push(order);
                    }
                }

                if (data.errors.length > 0) {
                    this.utils.warning(data.errors.join("\n"));
                }

                this.setState({
                    ordersToPrint: [...ordersToPrint]
                });

                if (data.printedOrders.length > 0) {
                    printJS({
                        printable: this.http.HOST + "/Print/FedExShippingLabels?orders=" + data.printedOrders.join(","),
                        type: 'pdf',
                        showModal: true,
                        modalMessage: data.printedOrders.length > 1 ? "Retrieving Labels" : "Retrieving Label",
                    });
                }

                this.getOrders();
            });
        }
    }

    getShipmentDetailsTitle(orders) {
        let title = "Shipment Details ";
        for (let i = 0; i < orders.length; i++) {
            title += "#" + orders[i].orderNumber;
            if (i !== orders.length - 1) {
                title += ", ";
            }
        }
        return title;
    }

    getPrintPDFUrl(order, type) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + order.orderId + "&OrderNumber=" + order.orderNumber;
    }

    onGroupAccordionToggled(status, groupName, expanded) {
        let accordionToggleSessionStr = sessionStorage.getItem("fulfillment.groupAccordions");
        let accordionToggleSession = [];
        if (accordionToggleSessionStr !== null) {
            accordionToggleSession = JSON.parse(accordionToggleSessionStr);
        }

        const accordionIndex = accordionToggleSession.findIndex(i => i.status === status && i.groupName === groupName);
        if (accordionIndex < 0) {
            accordionToggleSession.push({
                status: status,
                groupName: groupName,
                expanded: expanded
            })
        }
        else {
            accordionToggleSession[accordionIndex].expanded = expanded;
        }

        sessionStorage.setItem("fulfillment.groupAccordions", JSON.stringify(accordionToggleSession));
        this.accordionToggles = accordionToggleSession;
    }

    getAccordionToggle(status, groupName) {
        const accordionToggle = this.accordionToggles.find(t => t.status === status && t.groupName === groupName);
        if (accordionToggle) {
            return accordionToggle.expanded.toString();
        }
        else {
            return "true";
        }
    }

    openOrderOnHoldModal(order) {
        this.orderToBePlacedOnHold = order;
        window.$("#placeOrderOnHoldModal").modal("show");
    }

    handleOrderHoldChange(val) {
        this.orderHold = val;
    }

    placeOrderOnHold() {
        var params = {
            orderId: this.orderToBePlacedOnHold.orderId,
            reason: this.orderHold.holdReason,
            releaseDate: this.orderHold.holdReleaseDate
        }
        this.http.post('/api/Orders/PlaceOnHold', params).then(data => {
            window.$("#placeOrderOnHoldModal").modal("hide");
            this.getOrders();
        })
    }

    render() {
        var grandTotals = [];
        for (var i = 0; i < this.state.orders.length; i++) {
            if (grandTotals[this.state.orders[i].currency]) {
                grandTotals[this.state.orders[i].currency] += this.state.orders[i].total;
            }
            else {
                grandTotals[this.state.orders[i].currency] = this.state.orders[i].total;
            }
        }
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">{this.props.title ? this.props.title : "Orders to Fulfill"}</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-2 col-sm-3 col-xs-6">
                                            <label htmlFor="site">Site</label>
                                            <SitesSelect value={this.state.siteId} onChange={this.handleSiteChange} hasAllOption={true}></SitesSelect>
                                        </div>
                                        <div className="form-group col-md-2 col-sm-3 col-xs-6">
                                            <label htmlFor="accountType">Account Type</label>
                                            <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange} hasAllOption={true}></AccountTypesSelect>
                                        </div>
                                        <div className="form-group col-md-2 col-sm-3 col-xs-6">
                                            <label htmlFor="accountType">Ship Type</label>
                                            <ShipTypeSelect value={this.state.shipTypeId} onChange={this.handleShipTypeChange} hasAllOption={true}></ShipTypeSelect>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label htmlFor="accountType">Time In Transit</label>
                                            <select className="form-control" value={this.state.timeInTransit} onChange={this.handleTimeInTransitChange}>
                                                <option value={-1}>--All--</option>
                                                {this.timeInTransitOptions.map(t =>
                                                    <option key={t} value={t}>{t}</option>
                                                )}
                                            </select>
                                        </div>
                                        {!this.props.web &&
                                            <div className="form-group col-md-2 col-sm-3 col-xs-6">
                                                <label htmlFor="pricingType">Pricing Type</label>
                                                <select className="form-control" value={this.state.pricingType} onChange={this.handlePricingTypeChange}>
                                                    <option value='-- All --'>--All --</option>
                                                    <option value='Sample'>Samples</option>
                                                    <option value='Paid'>Paid</option>
                                                </select>
                                            </div>
                                        }
                                        <div className="form-group col-md-2 col-sm-3 col-xs-6">
                                            <label className="mb-4"></label>
                                            <Checkbox label="Group Orders" checked={this.state.groupOrders} onChange={this.handleGroupOrdersChange}></Checkbox>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <Accordion title="Sample/Promo Items Summary" icon="flaticon-list" id="samples">
                            <div className="form-row">
                                <table className="table table-striped mt-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Product</th>
                                            <th scope="col">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.summaryItems.map((item) =>
                                            <tr key={item.name}>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item.quantity}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>
                        <div className="card">
                            <div className="card-body">
                                <div>
                                    <ul className="col-md-9 nav nav-pills nav-secondary" id="pills-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="pills-ready-to-pack-tab" data-toggle="pill" onClick={(e) => this.handleTabClicked("readyToPack")} href="#pills-ready-to-pack" role="tab" aria-controls="pills-ready-to-packed" aria-selected="true">Ready To Pack</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="pills-being-packed-tab" data-toggle="pill" onClick={(e) => this.handleTabClicked("beingPacked")} href="#pills-being-packed" role="tab" aria-controls="pills-being-backed" aria-selected="false">Being Packed</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="pills-packed-tab" data-toggle="pill" onClick={(e) => this.handleTabClicked("packed")} href="#pills-packed" role="tab" aria-controls="pills-packed" aria-selected="false">Packed</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="recently-fulfilled-tab" data-toggle="pill" onClick={(e) => this.handleTabClicked("recentlyFulfilled")} href="#recentlty-fulfilled" role="tab" aria-controls="recently-fulfilled" aria-selected="false">Recently Fulfilled</a>
                                        </li>
                                    </ul>
                                </div>
                                {this.state.groupOrders &&
                                    <div className="tab-content mt-2 mb-3" id="pills-tabContent">
                                        <div className={"tab-pane fade" + this.state.activeTab === "readyToPack" ? " show active" : ""} id="pills-ready-to-pack" role="tabpanel" aria-labelledby="pills-read-to-pack-tab">
                                            {this.state.activeTab === "readyToPack" && this.state.fulfillmentGroups.map((fulfillmentGroup, index) =>
                                                <React.Fragment key={index}>
                                                    <div className="card fulfillment-group">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="pull-left col-md-6" style={{}}>
                                                                    {fulfillmentGroup.name.split(',').map((item, j) =>
                                                                        <React.Fragment key={j}>
                                                                            <i>{item}</i><br />
                                                                        </React.Fragment>
                                                                    )}
                                                                    <strong><i>NUMBER of ORDERS: {fulfillmentGroup.orders.length}</i></strong>
                                                                </div>
                                                                <div className="pull-right col-md-6">
                                                                    <button className="btn btn-default btn-sm pull-right" type="button" onClick={(e) => this.startPackingOrder(fulfillmentGroup.orders)}>Batch Pack This Group</button>
                                                                </div>
                                                            </div>
                                                            <Accordion expanded={this.getAccordionToggle("readyToPack", fulfillmentGroup.name)} onToggle={(expanded) => { this.onGroupAccordionToggled("readyToPack", fulfillmentGroup.name, expanded) }} id={"readyToPack-" + index} expandedTitle="Hide Orders" collapsedTitle="Show Orders" icon="flaticon-list">
                                                                <div className="form-row">
                                                                    <table className="table table-striped mt-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Order</th>
                                                                                <th scope="col">Customer</th>
                                                                                <th scope="col">Account Type</th>
                                                                                <th scope="col">Shipping Method</th>
                                                                                <th scope="col">Time In Transit</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {fulfillmentGroup.orders.map((order) =>
                                                                                <tr key={order.orderId}>
                                                                                    <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                                                                    <td>{order.customerName}</td>
                                                                                    <td>{order.salesman}</td>
                                                                                    <td>{order.shippingMethod}</td>
                                                                                    <td>{order.timeInTransit}</td>
                                                                                    <td>{order.status}</td>
                                                                                    <td>{order.orderDate}</td>
                                                                                    <td>
                                                                                        <button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.startPackingOrder([order])}>Pack This Order</button>
                                                                                        <div className="btn-group dropdown ml-2">
                                                                                            <button type="button" className="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                                                <span className="btn-label"><i className="fas fa-ellipsis-h"></i></span>
                                                                                            </button>
                                                                                            <ul className="dropdown-menu" role="menu" x-placement="bottom-start">
                                                                                                <li>
                                                                                                    <button className="dropdown-item" onClick={(e) => this.openOrderOnHoldModal(order)}>Place On Hold</button>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className={"tab-pane fade" + this.state.activeTab === "beingPacked" ? " show active" : ""} id="pills-being-packed" role="tabpanel" aria-labelledby="pills-being-packed-tab">
                                            {this.state.activeTab === "beingPacked" && this.state.fulfillmentGroups.map((fulfillmentGroup, index) =>
                                                <React.Fragment key={index}>
                                                    <div className="card fulfillment-group">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="pull-left col-md-6" style={{}}>
                                                                    {fulfillmentGroup.name.split(',').map((item, j) =>
                                                                        <React.Fragment key={j}>
                                                                            <i>{item}</i><br />
                                                                        </React.Fragment>
                                                                    )}
                                                                    <strong><i>NUMBER of ORDERS: {fulfillmentGroup.orders.length}</i></strong>
                                                                </div>
                                                                <div className="pull-right col-md-6">
                                                                    <button className="btn btn-default btn-sm pull-right" type="button" onClick={(e) => this.openShippingDetailsModal(fulfillmentGroup.orders)}>Batch Enter Packing Details</button>
                                                                    <button type="button" className="btn btn-sm btn-danger pull-right mr-2" onClick={(e) => this.revertToReadyToPack(fulfillmentGroup.orders)}><i className="fas fa-undo"></i> Batch Revert</button>
                                                                </div>
                                                            </div>
                                                            <Accordion expanded={this.getAccordionToggle("beingPacked", fulfillmentGroup.name)} onToggle={(expanded) => { this.onGroupAccordionToggled("beingPacked", fulfillmentGroup.name, expanded) }} id={"beingPacked-" + index} expandedTitle="Hide Orders" collapsedTitle="Show Orders" icon="flaticon-list">
                                                                <div className="form-row">
                                                                    <table className="table table-striped mt-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Order</th>
                                                                                <th scope="col">Customer</th>
                                                                                <th scope="col">Account Type</th>
                                                                                <th scope="col">Shipping Method</th>
                                                                                <th scope="col">Time In Transit</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {fulfillmentGroup.orders.map((order) =>
                                                                                <tr key={order.orderId}>
                                                                                    <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                                                                    <td>{order.customerName}</td>
                                                                                    <td>{order.salesman}</td>
                                                                                    <td>{order.shippingMethod}</td>
                                                                                    <td>{order.timeInTransit}</td>
                                                                                    <td>{order.status}</td>
                                                                                    <td>{order.orderDate}</td>
                                                                                    <td>
                                                                                        <button type="button" className="btn btn-sm btn-danger mr-2" onClick={(e) => this.revertToReadyToPack([order])}><i className="fas fa-undo"></i> Revert</button>
                                                                                        <button className="btn btn-sm btn-primary" type="button" onClick={(e) => this.openShippingDetailsModal([order])}>Enter Packing Details</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className={"tab-pane fade" + this.state.activeTab === "packed" ? " show active" : ""} id="pills-packed" role="tabpanel" aria-labelledby="pills-packed-tab">
                                            {this.state.activeTab === "packed" && this.state.fulfillmentGroups.map((fulfillmentGroup, index) =>
                                                <React.Fragment key={index}>
                                                    <div className="card fulfillment-group">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="pull-left col-md-6" style={{}}>
                                                                    {fulfillmentGroup.name.split(',').map((item, j) =>
                                                                        <React.Fragment key={j}>
                                                                            <i>{item}</i><br />
                                                                        </React.Fragment>
                                                                    )}
                                                                    <strong><i>NUMBER of ORDERS: {fulfillmentGroup.orders.length}</i></strong>
                                                                </div>
                                                                <div className="pull-right col-md-6">
                                                                    <button className="btn btn-default btn-sm pull-right" type="button" onClick={(e) => this.batchPrintShippingLabels(fulfillmentGroup.orders)}>Batch Print Shipping Labels</button>
                                                                </div>
                                                            </div>
                                                            <Accordion expanded={this.getAccordionToggle("packed", fulfillmentGroup.name)} onToggle={(expanded) => { this.onGroupAccordionToggled("packed", fulfillmentGroup.name, expanded) }} id={"packed-" + index} expandedTitle="Hide Orders" collapsedTitle="Show Orders" icon="flaticon-list">
                                                                <div className="form-row">
                                                                    <table className="table table-striped mt-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Order</th>
                                                                                <th scope="col">Customer</th>
                                                                                <th scope="col">Account Type</th>
                                                                                <th scope="col">Shipping Method</th>
                                                                                <th scope="col">Time In Transit</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {fulfillmentGroup.orders.map((order) =>
                                                                                <tr key={order.orderId}>
                                                                                    <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                                                                    <td>{order.customerName}</td>
                                                                                    <td>{order.salesman}</td>
                                                                                    <td>{order.shippingMethod}</td>
                                                                                    <td>{order.timeInTransit}</td>
                                                                                    <td>{order.status}</td>
                                                                                    <td>{order.orderDate}</td>
                                                                                    <td><button className="btn btn-sm btn-primary" type="button" onClick={(e) => this.printShippingLabels([order])}>Print Shipping Label</button></td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className={"tab-pane fade" + this.state.activeTab === "recentlyFulfilled" ? " show active" : ""} id="recently-fulfilled" role="tabpanel" aria-labelledby="pills-recently-fulfilled-tab">
                                            {this.state.activeTab === "recentlyFulfilled" && this.state.fulfillmentGroups.map((fulfillmentGroup, index) =>
                                                <React.Fragment key={index}>
                                                    <div className="card fulfillment-group">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="pull-left col-md-6" style={{}}>
                                                                    {fulfillmentGroup.name.split(',').map((item, j) =>
                                                                        <React.Fragment key={j}>
                                                                            <i>{item}</i><br />
                                                                        </React.Fragment>
                                                                    )}
                                                                    <strong><i>NUMBER of ORDERS: {fulfillmentGroup.orders.length}</i></strong>
                                                                </div>
                                                                <div className="pull-right col-md-6">
                                                                    <button className="btn btn-default btn-sm pull-right" type="button" onClick={(e) => this.batchPrintShippingLabels(fulfillmentGroup.orders, true)}>Batch Print Shipping Labels</button>
                                                                </div>
                                                            </div>
                                                            <Accordion expanded={this.getAccordionToggle("packed", fulfillmentGroup.name)} onToggle={(expanded) => { this.onGroupAccordionToggled("packed", fulfillmentGroup.name, expanded) }} id={"packed-" + index} expandedTitle="Hide Orders" collapsedTitle="Show Orders" icon="flaticon-list">
                                                                <div className="form-row">
                                                                    <table className="table table-striped mt-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Order</th>
                                                                                <th scope="col">Customer</th>
                                                                                <th scope="col">Account Type</th>
                                                                                <th scope="col">Shipping Method</th>
                                                                                <th scope="col">Time In Transit</th>
                                                                                <th scope="col">Status</th>
                                                                                <th scope="col">Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {fulfillmentGroup.orders.map((order) =>
                                                                                <tr key={order.orderId}>
                                                                                    <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                                                                    <td>{order.customerName}</td>
                                                                                    <td>{order.salesman}</td>
                                                                                    <td>{order.shippingMethod}</td>
                                                                                    <td>{order.timeInTransit}</td>
                                                                                    <td>{order.status}</td>
                                                                                    <td>{order.orderDate}</td>
                                                                                    <td><button className="btn btn-sm btn-primary" type="button" onClick={(e) => this.printShippingLabels([order], true)}>Print Shipping Label</button></td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                }
                                {this.state.groupOrders === false &&
                                    <div className="tab-content mt-2 mb-3" id="pills-tabContent">
                                        <div className={"tab-pane fade" + this.state.activeTab === "readyToPack" ? " show active" : ""} id="pills-ready-to-pack" role="tabpanel" aria-labelledby="pills-read-to-pack-tab">
                                            {this.state.activeTab === "readyToPack" &&
                                                <table className="table table-striped mt-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="sortable" onClick={() => this.sortOrders("OrderID")}>Order</th>
                                                            <th scope="col">Customer</th>
                                                            <th className="currency" scope="col">Total</th>
                                                            <th scope="col">Account Type</th>
                                                            <th scope="col">Shipping Method</th>
                                                            <th scope="col" className="sortable" onClick={() => this.sortOrders("TimeInTransit")}>Time In Transit</th>
                                                            <th scope="col">Date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.orders.map((order) =>
                                                            <tr key={order.orderId}>
                                                                <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                                                <td>{order.companyName}</td>
                                                                <td className="currency">{this.utils.toCurrency(order.total, order.currency)}</td>
                                                                <td>{order.accountType}</td>
                                                                <td>{order.shippingMethod}</td>
                                                                <td>{order.timeInTransit}</td>
                                                                <td>{order.dateTimeStamp}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.startPackingOrder([order])}>Pack This Order</button>
                                                                    <div className="btn-group dropdown ml-2">
                                                                        <button type="button" className="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                            <span className="btn-label"><i className="fas fa-ellipsis-h"></i></span>
                                                                        </button>
                                                                        <ul className="dropdown-menu" role="menu" x-placement="bottom-start">
                                                                            <li>
                                                                                <button className="dropdown-item" onClick={(e) => this.openOrderOnHoldModal(order)}>Place On Hold</button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        {Object.keys(grandTotals).map((currency, index) =>
                                                            <tr key={index}>
                                                                <td colSpan={3} align="right">{index === 0 && <strong>Grand Total</strong>}</td>
                                                                <td className="currency"><strong>{this.utils.toCurrency(grandTotals[currency], currency)}</strong></td>
                                                                <td colSpan={4}></td>
                                                            </tr>
                                                        )}
                                                    </tfoot>
                                                </table>
                                            }
                                        </div>


                                        <div className={"tab-pane fade" + this.state.activeTab === "beingPacked" ? " show active" : ""} id="pills-being-packed" role="tabpanel" aria-labelledby="pills-being-packed-tab">
                                            {this.state.activeTab === "beingPacked" &&
                                                <table className="table table-striped mt-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="sortable" onClick={() => this.sortOrders("OrderID")}>Order</th>
                                                            <th scope="col">Customer</th>
                                                            <th className="currency" scope="col">Total</th>
                                                            <th scope="col">Account Type</th>
                                                            <th scope="col">Shipping Method</th>
                                                            <th scope="col" className="sortable" onClick={() => this.sortOrders("TimeInTransit")}>Time In Transit</th>
                                                            <th scope="col">Date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.orders.map((order) =>
                                                            <tr key={order.orderId}>
                                                                <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                                                <td>{order.companyName}</td>
                                                                <td className="currency">{this.utils.toCurrency(order.total, order.currency)}</td>
                                                                <td>{order.accountType}</td>
                                                                <td>{order.shippingMethod}</td>
                                                                <td>{order.timeInTransit}</td>
                                                                <td>{order.dateTimeStamp}</td>
                                                                <td>
                                                                    <button type="button" className="btn btn-sm btn-danger mr-2" onClick={(e) => this.revertToReadyToPack([order])}><i className="fas fa-undo"></i> Revert</button>
                                                                    <button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.openShippingDetailsModal([order])}>Enter Packing Details</button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        {Object.keys(grandTotals).map((currency, index) =>
                                                            <tr>
                                                                <td colSpan={3} align="right">{index === 0 && <strong>Grand Total</strong>}</td>
                                                                <td className="currency"><strong>{this.utils.toCurrency(grandTotals[currency], currency)}</strong></td>
                                                                <td colSpan={4}></td>
                                                            </tr>
                                                        )}
                                                    </tfoot>
                                                </table>
                                            }
                                        </div>


                                        <div className={"tab-pane fade" + this.state.activeTab === "packed" ? " show active" : ""} id="pills-packed" role="tabpanel" aria-labelledby="pills-packed-tab">
                                            {this.state.activeTab === "packed" &&
                                                <table className="table table-striped mt-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="sortable" onClick={() => this.sortOrders("OrderID")}>Order</th>
                                                            <th scope="col">Customer</th>
                                                            <th className="currency" scope="col">Total</th>
                                                            <th scope="col">Account Type</th>
                                                            <th scope="col">Shipping Method</th>
                                                            <th scope="col" className="sortable" onClick={() => this.sortOrders("OrderID")}>Time In Transit</th>
                                                            <th scope="col">Date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.orders.map((order) =>
                                                            <tr key={order.orderId}>
                                                                <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                                                <td>{order.companyName}</td>
                                                                <td className="currency">{this.utils.toCurrency(order.total, order.currency)}</td>
                                                                <td>{order.accountType}</td>
                                                                <td>{order.shippingMethod}</td>
                                                                <td>{order.timeInTransit}</td>
                                                                <td>{order.dateTimeStamp}</td>
                                                                <td><button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.printShippingLabels([order])}>Print Shipping Label</button></td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        {Object.keys(grandTotals).map((currency, index) =>
                                                            <tr>
                                                                <td colSpan={3} align="right">{index === 0 && <strong>Grand Total</strong>}</td>
                                                                <td className="currency"><strong>{this.utils.toCurrency(grandTotals[currency], currency)}</strong></td>
                                                                <td colSpan={4}></td>
                                                            </tr>
                                                        )}
                                                    </tfoot>
                                                </table>
                                            }
                                        </div>

                                        <div className={"tab-pane fade" + this.state.activeTab === "recentlyFulfilled" ? " show active" : ""} id="pills-recently-fulfilled" role="tabpanel" aria-labelledby="pills-recently-fulfilled-tab">
                                            {this.state.activeTab === "recentlyFulfilled" &&
                                                <table className="table table-striped mt-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="sortable" onClick={() => this.sortOrders("OrderID")}>Order</th>
                                                            <th scope="col">Customer</th>
                                                            <th className="currency" scope="col">Total</th>
                                                            <th scope="col">Account Type</th>
                                                            <th scope="col">Shipping Method</th>
                                                            <th scope="col" className="sortable" onClick={() => this.sortOrders("OrderID")}>Time In Transit</th>
                                                            <th scope="col">Date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.orders.map((order) =>
                                                            <tr key={order.orderId}>
                                                                <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                                                <td>{order.companyName}</td>
                                                                <td className="currency">{this.utils.toCurrency(order.total, order.currency)}</td>
                                                                <td>{order.accountType}</td>
                                                                <td>{order.shippingMethod}</td>
                                                                <td>{order.timeInTransit}</td>
                                                                <td>{order.dateTimeStamp}</td>
                                                                <td><button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.printShippingLabels([order], true)}>Print Shipping Label</button></td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        {Object.keys(grandTotals).map((currency, index) =>
                                                            <tr>
                                                                <td colSpan={3} align="right">{index === 0 && <strong>Grand Total</strong>}</td>
                                                                <td className="currency"><strong>{this.utils.toCurrency(grandTotals[currency], currency)}</strong></td>
                                                                <td colSpan={4}></td>
                                                            </tr>
                                                        )}
                                                    </tfoot>
                                                </table>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.ordersToPrint &&
                    <Modal
                        id="printShippingLabelsModal"
                        title="Print Shipping Labels"
                        hideCloseButton={true}
                        width="85%"
                    >
                        {this.state.ordersToPrint && this.state.ordersToPrint.length > 0 &&
                            <div className="pull-right col-md-12">
                                <button className="btn btn-default btn-sm pull-right" type="button" onClick={(e) => this.batchPrintShippingLabels(this.state.ordersToPrint)}>Batch Print Shipping Labels</button>
                            </div>
                        }
                        <table className="table table-striped mt-0">
                            <thead>
                                <tr>
                                    <th scope="col">Order</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Account Type</th>
                                    <th scope="col">Shipping Method</th>
                                    <th scope="col">Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.ordersToPrint && this.state.ordersToPrint.map((order) =>
                                    <tr key={order.orderId}>
                                        <td><Link to={"/fulfillment/" + order.orderId}>{order.orderNumber}</Link></td>
                                        <td>{order.companyName ? order.companyName : order.customerName}</td>
                                        <td>{order.accountType ? order.accountType : order.salesman}</td>
                                        <td>{order.shippingMethod}</td>
                                        <td>{order.dateTimeStamp ? order.dateTimeStamp : order.orderDate}</td>
                                        <td><button type="button" className="btn btn-sm btn-primary" onClick={(e) => this.printShippingLabels([order])}>Print Shipping Label</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Modal>
                }
                <Modal height={500} id="placeOrderOnHoldModal" title="Order Hold" onOk={this.placeOrderOnHold}>
                    <PlaceOrderOnHold onChange={this.handleOrderHoldChange}></PlaceOrderOnHold>
                </Modal>
                {this.state.ordersToShip &&
                    <React.Fragment>
                        <Modal
                            width="85%"
                            id="shipmentDetailsModal"
                            title={this.getShipmentDetailsTitle(this.state.ordersToShip)}
                            onOk={this.submitShipmentDetails}
                            okText="Print Shipping Label"
                            onCancel={(e) => {
                                this.revertToReadyToPack(this.state.ordersToShip, true);
                                this.setState({ ordersToShip: null })
                            }
                            }
                            hideCloseButton={true}>
                            <OrderShipmentDetails onChange={this.handleShipmentDetailsChange} orders={this.state.ordersToShip}></OrderShipmentDetails>
                        </Modal>
                        <Modal
                            id="traditionalShippingModal"
                            hideCloseButton={true}
                        >
                            <h2>Please use traditional process</h2>
                            <img src={this.http.BASE_URL + "/api/BarcodeHandler?key=" + this.state.ordersToShip[0].orderNumber + "&width=240&height=65"} />
                        </Modal>
                    </React.Fragment>
                }

            </div>
        );
    }
}

export default PaperlessFulfillList;
