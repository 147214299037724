import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import Modal from '../../common/Modal';
import SitesSelect from '../../common/SitesSelect';
import CouponInformation from './CouponInformation';
import ProductSelect from '../../common/ProductSelect';

class EditCoupon extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            coupon: null,
            products: [],
            sites: [],
            selectedSite: 0,
            selectedProduct: 0,
        }
        this.handleCouponInformationChange = this.handleCouponInformationChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleSiteChange = this.handleSiteChange.bind(this);
        this.updateCoupon = this.updateCoupon.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.addSite = this.addSite.bind(this);
        this.removeSite = this.removeSite.bind(this);        
    }

    componentDidMount() {
        this.getCoupon();
    }

    handleCouponInformationChange(couponInformation) {
        this.couponInformation = couponInformation;
    }

    handleProductChange(product){
        this.setState({
            selectedProduct: product.productID
        });
    }

    handleSiteChange(site){
        this.setState({
            selectedSite: site
        });
    }

    getCoupon() {
        var params = {
            id: this.props.match.params.id
        }
        this.http.get('/api/Admin/GetCoupon', params).then(data => {
            this.updateState(data);
        })
    }

    updateCoupon() {
        var params = this.couponInformation;
        params.couponId = this.props.match.params.id;
        this.http.post('/api/Admin/UpdateCoupon', params).then(data => {
            this.utils.success("");
        });
    }

    addSite() {
        var params = {
            couponId: this.props.match.params.id,
            siteId: this.state.selectedSite
        }
        this.http.post('/api/Admin/AddCouponSite', params).then(data => {
            window.$("#addSiteModal").modal("hide");
            this.updateState(data);
        });
    }

    removeSite(site) {
        var params = {
            couponId: this.props.match.params.id,
            siteName: site
        }
        this.http.post('/api/Admin/RemoveCouponSite', params).then(data => {
            this.updateState(data);
        });
    }

    addProduct() {
        var params = {
            couponId: this.props.match.params.id,
            productId: this.state.selectedProduct
        }
        this.http.post('/api/Admin/AddCouponProduct', params).then(data => {
            window.$("#addProductModal").modal("hide");
            this.updateState(data);
        });
    }

    removeProduct(product) {
        var params = {
            couponId: this.props.match.params.id,
            productId: product.productID
        }
        this.http.post('/api/Admin/RemoveCouponProduct', params).then(data => {
            this.updateState(data);
        });
    }

    updateState(data){
        this.setState({
            coupon: data,
            products: this.utils.dictToArray(data.products),
            sites: this.utils.dictToArray(data.sites),
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Edit Coupon</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Coupon Information</div>
                            </div>
                            <div className="card-body">
                                {this.state.coupon &&
                                    <CouponInformation coupon={this.state.coupon} onChange={this.handleCouponInformationChange}></CouponInformation>
                                }
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateCoupon}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Dependent Products <p className="text-muted">(1 or more must be in Customer's cart)</p></div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addProductModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
											</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Product</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.products.map(item =>
                                                <tr key={item.productID}>
                                                    <td>{item.name}</td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeProduct(item, e)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Modal id="addProductModal" title="Add Product" onOk={this.addProduct}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="site">Product</label>
                                            <ProductSelect allProducts={true} value={this.state.selectedProduct} onChange={this.handleProductChange}></ProductSelect>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card full-height">
                            <div className="card-header">
                                <div className="card-head-row">
                                    <div className="card-title">Sites</div>
                                    <div className="card-tools">
                                        <button className="btn btn-info btn-border btn-round btn-sm mr-2" data-toggle="modal" data-target="#addSiteModal">
                                            <span className="btn-label">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                            Add
											</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Site</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.sites.map(item =>
                                                <tr key={item}>
                                                    <td>{item}</td>
                                                    <td><button className="btn btn-sm btn-danger" onClick={(e) => this.removeSite(item, e)}><i className="fas fa-trash"></i></button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <Modal id="addSiteModal" title="Add Site" onOk={this.addSite}>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="site">Site</label>
                                            <SitesSelect value={this.state.selectedSite} onChange={this.handleSiteChange}></SitesSelect>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        );
    }
}

export default EditCoupon