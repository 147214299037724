import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { ScaleService } from '../../services/ScaleService';
import printJS from 'print-js';

class OrderShipmentDetails extends Component {
    constructor(props) {
        super(props);
        this.scaleService = new ScaleService();
        this.products = this.props.orders[0].cartItems;
        var defaultPackageItems = this.getDefaultPackageItems();
        this.state = {
            packageType: "YOUR_PACKAGING",
            trackingNumber: "",
            totalWeight: 0,
            numberOfPackages: 1,
            shipDate: moment(new Date()).toDate(),
            packages: [
                {
                    weight: 0,
                    items: defaultPackageItems
                }
            ],
            printUCCLabel: true,
            scaleConnected: false,
            shipmentCategory: ""
        };

        this.http = new HttpRequestService();
        this.handlePkgTypeSelect = this.handlePkgTypeSelect.bind(this);
        this.handleTotalWeightChange = this.handleTotalWeightChange.bind(this);
        this.handleShipDateChange = this.handleShipDateChange.bind(this);
        this.handleTrackingNumberChange = this.handleTrackingNumberChange.bind(this);
        this.handleNumberOfPackagesChange = this.handleNumberOfPackagesChange.bind(this);
        this.handlePrintUCCLabelChange = this.handlePrintUCCLabelChange.bind(this);
        this.handleShipmentCategorySelect = this.handleShipmentCategorySelect.bind(this);
        this.connectToScale = this.connectToScale.bind(this);
        this.notifyChange();
    }

    componentDidMount() {
        this.initScale();
    }

    getDefaultPackageItems() {
        var items = [];
        for (var i = 0; i < this.products.length; i++) {
            items.push({
                cartItemId: this.products[i].id,
                quantity: this.getDefaultQuantity(this.products[i])
            });
        }
        return items;
    }

    handleTotalWeightChange(e) {
        let num = 0;
        try {
            num = parseFloat(e.target.value);
        }
        catch { }
        this.setState({
            totalWeight: num
        }, this.notifyChange);
    }

    handleShipDateChange(e) {
        this.setState({
            shipDate: e
        }, this.notifyChange);
    }

    handleTrackingNumberChange(e) {
        this.setState({
            trackingNumber: e.target.value
        }, this.notifyChange);
    }

    handlePrintUCCLabelChange(value) {
        this.setState({
            printUCCLabel: value
        }, this.notifyChange);
    }

    handleNumberOfPackagesChange(e) {
        var num = parseInt(e.target.value);
        if (num <= 0) {
            return;
        }
        var pkgs = this.state.packages;
        var numOfPkgs = pkgs.length;
        if (num > numOfPkgs) {
            for (var i = 0; i < num - numOfPkgs; i++) {
                pkgs.push({
                    weight: 0,
                    items: [
                        {
                            cartItemId: 0,
                            quantity: 0
                        }
                    ]
                });
            }
        }
        else {
            pkgs.splice(numOfPkgs - num, numOfPkgs - num);
        }

        if (num === 1) {
            var defaultPackageItems = this.getDefaultPackageItems();
            pkgs = [{
                items: defaultPackageItems
            }];
        }

        this.setState({
            numberOfPackages: parseInt(e.target.value),
            packages: pkgs
        }, this.notifyChange);
    }

    handlePkgTypeSelect(e) {
        this.setState({
            packageType: e.target.value
        }, this.notifyChange);
    }

    handleShipmentCategorySelect(e) {
        this.setState({
            shipmentCategory: e.target.value
        }, this.notifyChange);
    }

    handlePkgWeightChange(e, pkg) {
        let num = 0;
        try {
            num = parseFloat(e.target.value);
        }
        catch { }

        pkg.weight = num;
        let totalWeight = 0;
        for (const pkg of this.state.packages) {
            totalWeight += pkg.weight;
        }

        this.setState({
            packages: this.state.packages,
            totalWeight: totalWeight
        }, this.notifyChange);
    }

    handleProductSelect(e, itemIndex, pkgIndex) {
        var pkgs = this.state.packages;
        pkgs[pkgIndex].items[itemIndex].cartItemId = parseInt(e.target.value);
        var cartItem = this.products.find(p => p.id === parseInt(e.target.value));
        if (cartItem) {
            pkgs[pkgIndex].items[itemIndex].quantity = this.getDefaultQuantity(cartItem);
        }
        else {
            pkgs[pkgIndex].items[itemIndex].quantity = 0;
        }
        this.markDuplicates(pkgs);
        this.setState({
            packages: pkgs
        }, this.notifyChange);
    }

    getDefaultQuantity(cartItem) {
        return parseInt(cartItem.quantityShipped) ? parseInt(cartItem.quantityShipped) : cartItem.quantity;
    }

    handleQuantityChange(e, itemIndex, pkgIndex) {
        if (e.target.value > 0) {
            var pkgs = this.state.packages;
            var cartItem = this.products.find(p => p.id === pkgs[pkgIndex].items[itemIndex].cartItemId);
            var enteredQuantity = parseInt(e.target.value);
            pkgs[pkgIndex].items[itemIndex].quantity = enteredQuantity;
            if (cartItem !== null) {
                var defaultQuantity = this.getDefaultQuantity(cartItem);
                var totalEnteredQuantity = 0;
                // Calculate entered quantity by looking at previous packages
                for (let i = 0; i <= pkgIndex; i++) {
                    var item = pkgs[i].items.find(i => i.cartItemId === cartItem.id);
                    if (item) {
                        totalEnteredQuantity += item.quantity;
                    }
                }

                if (defaultQuantity > totalEnteredQuantity && pkgs.length > 1) {
                    // distribute items evenly to the next packages
                    var remainingQuantity = defaultQuantity - totalEnteredQuantity;
                    for (let i = pkgIndex + 1; i < pkgs.length && remainingQuantity >= 0; i++) {
                        var distributedQuantity = remainingQuantity > enteredQuantity ? enteredQuantity : remainingQuantity;
                        let item = pkgs[i].items.find(i => i.cartItemId === cartItem.id);
                        if (item) {
                            item.quantity = distributedQuantity;
                        }
                        else {
                            if (pkgs[i].items[0].cartItemId === 0) {
                                pkgs[i].items.splice(0, 1);
                            }
                            pkgs[i].items.push({
                                cartItemId: cartItem.id,
                                quantity: distributedQuantity
                            });
                        }

                        remainingQuantity -= distributedQuantity;
                    }
                }
            }

            this.setState({
                packages: pkgs
            });
        }
    }

    addPackItem(pkgIndex, e) {
        e.preventDefault();
        e.stopPropagation();
        var pkgs = this.state.packages;
        pkgs[pkgIndex].items.push({
            cartItemId: 0,
            quantity: 0
        });
        this.markDuplicates(pkgs);
        this.setState({
            packages: pkgs
        }, this.notifyChange);
    }

    removePackItem(itemIndex, pkgIndex, e) {
        e.preventDefault();
        e.stopPropagation();
        var pkgs = this.state.packages;
        pkgs[pkgIndex].items.splice(itemIndex, 1);
        this.markDuplicates(pkgs);
        this.setState({
            packages: pkgs,
        }, this.notifyChange);
    }

    markDuplicates(pkgs) {
        // clean previous marks
        for (const pkg of pkgs) {
            for (const item of pkg.items) {
                item.duplicate = false;
            }
        }

        for (const product of this.products) {
            // check if this item exists in multiple locations
            const itemIndexes = this.findItemIndexes(pkgs, product);
            if (itemIndexes.length > 1) {
                for (const itemIndex of itemIndexes) {
                    pkgs[itemIndex.pkgIndex].items[itemIndex.itemIndex].duplicate = true;
                }
            }
        }
    }

    findItemIndexes(pkgs, product) {
        const indexes = [];
        for (let i = 0; i < pkgs.length; i++) {
            for (let j = 0; j < pkgs[i].items.length; j++) {
                if (pkgs[i].items[j].cartItemId === product.id) {
                    indexes.push({
                        pkgIndex: i,
                        itemIndex: j
                    });
                }
            }
        }
        console.log(indexes);
        return indexes;
    }

    getCartItems(shoppingCart) {
        if (!!shoppingCart) {
            var sortedProductItems = shoppingCart.sortedNutraCartProductItems;
            var cartItems = [];
            for (var key in sortedProductItems) {
                var item = sortedProductItems[key];
                item.id = item.productID;
                cartItems.push(item);
            }
            return cartItems;
        }
        else {
            return [];
        }
    }

    getRegularProducts(products) {
        return products.filter(p => {
            return p.name.indexOf("BROCHURE") < 0 && p.name.indexOf("LIT") < 0
        });
    }

    getLitsAndBrochures(products) {
        const litsAndBrochures = products.filter(p => {
            return p.name.indexOf("BROCHURE") >= 0 || p.name.indexOf("LIT") >= 0
        });

        console.log(litsAndBrochures);
        return litsAndBrochures;
    }

    async initScale() {
        await this.scaleService.init();
        this.scaleService.onWeightChange = (data) => {
            this.setState({
                totalWeight: data.lbs
            }, this.notifyChange);
        }

        if (this.scaleService.connected) {
            this.setState({
                scaleConnected: true
            });
        }
    }

    async connectToScale() {
        await this.scaleService.connect();
        if (this.scaleService.connected) {
            this.setState({
                scaleConnected: true
            });
        }
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    getPrintPDFUrl(order, type) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + order.orderId + "&OrderNumber=" + order.orderNumber;
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ position: "absolute", top: 16, right: 16 }} className="row">
                    <div className="col-md-3">
                        <button type="button" className={"btn btn-info btn-border btn-round btn-sm mr-2 " + (this.state.scaleConnected ? "btn-success" : "btn-danger")} onClick={this.connectToScale}>
                            <span className="btn-label">
                                <i className={this.state.scaleConnected ? "fas fa-check" : "fas fa-cross"}></i>
                            </span>
                            Scale Connection
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-2">
                        {this.props.orders.length > 1 &&
                            <React.Fragment>
                                <strong>NUMBER OF ORDERS: {this.props.orders.length}</strong>
                                <br />
                            </React.Fragment>
                        }
                        <strong>SHIPPING METHOD:</strong> {this.props.orders[0].shippingMethod}
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-md-4">
                        <strong>PRODUCTS:</strong><br />
                        <table>
                            <thead>
                                <tr>
                                    <th>item#</th>
                                    <th>qty</th>
                                    <th>item</th>
                                </tr>
                            </thead>
                            {this.getRegularProducts(this.products).map(product =>
                                <React.Fragment>
                                    <tr>
                                        <td className="pr-2">
                                            <i>{product.productNumber}</i>
                                        </td>
                                        <td className="pr-2">
                                            <i>{product.quantity}</i>
                                        </td>
                                        <td>
                                            <i>{product.name}</i>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}
                        </table>
                    </div>
                    <div className="col-md-4">
                        <strong>LIT &amp; BROCHURES:</strong><br />
                        <table>
                            <thead>
                                <tr>
                                    <th>item#</th>
                                    <th>qty</th>
                                    <th>item</th>
                                </tr>
                            </thead>
                            {this.getLitsAndBrochures(this.products).map(product =>
                                <React.Fragment>
                                    <tr>
                                        <td className="pr-2">
                                            <i>{product.productNumber}</i>
                                        </td>
                                        <td className="pr-2">
                                            <i>{product.quantity}</i>
                                        </td>
                                        <td>
                                            <i>{product.name}</i>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}
                        </table>
                    </div>
                </div>
                {this.props.orders.length === 1 &&
                    <>
                        {this.props.orders[0].orderNotes !== "" &&
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <strong>ORDER NOTES: </strong><mark>{this.props.orders[0].orderNotes}</mark>
                                </div>
                            </div>
                        }
                        {this.props.orders[0].standardComment && this.props.orders[0].standardComment.value &&
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <strong>STANDARD COMMENT: </strong><mark>{this.props.orders[0].standardComment.value}</mark>
                                </div>
                            </div>
                        }
                        {this.props.orders[0].customer && this.props.orders[0].customer.autoPrintInvoices && (!this.props.orders[0].standardComment || this.props.orders[0].standardComment.value !== "Print Invoice Only") &&
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <strong>OTHER NOTES: </strong><mark>Print Invoice</mark>
                                </div>
                            </div>
                        }
                        {(this.props.orders[0].printInvoice || this.props.orders[0].printPackingSlip) &&
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    {this.props.orders[0].printInvoice &&
                                        <a className="btn btn-info btn-border btn-sm mr-2"
                                            onClick={() => {
                                                printJS({
                                                    printable: this.getPrintPDFUrl(this.props.orders[0], 'Invoice'),
                                                    type: 'pdf',
                                                    showModal: true,
                                                    modalMessage: "Retrieving Invoice",
                                                });
                                            }}
                                            rel="noopener noreferrer">
                                            PRINT INVOICE
                                        </a>
                                    }
                                    {this.props.orders[0].printPackingSlip &&
                                        <a className="btn btn-info btn-border btn-sm mr-2"
                                            onClick={() => {
                                                printJS({
                                                    printable: this.getPrintPDFUrl(this.props.orders[0], 'PackingSlip'),
                                                    type: 'pdf',
                                                    showModal: true,
                                                    modalMessage: "Retrieving Packing Slip",
                                                });
                                            }}
                                            rel="noopener noreferrer">
                                            PRINT PACKING SLIP
                                        </a>
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
                <div className="form-row">
                    {/* <div className="form-group col-md-4">
                        <label>Package Type</label>
                        <select className="form-control" value={this.state.packageType} onChange={this.handlePkgTypeSelect}>
                            <option value="YOUR_PACKAGING">Custom</option>
                            <option value="FEDEX_ENVELOPE">Fedex Envelope</option>
                        </select>
                    </div> */}
                    <div className="form-group col-md-4">
                        <label>Total Weight (lb)</label>
                        <input readOnly={this.state.numberOfPackages !== 1} type="number" className="form-control" value={this.state.totalWeight} onChange={this.handleTotalWeightChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Number of Packages</label>
                        <input type="number" className="form-control" value={this.state.numberOfPackages} onChange={this.handleNumberOfPackagesChange}></input>
                    </div>
                    <div className="form-group col-md-4">
                        <label>Ship Date</label>
                        <DatePicker popperPlacement="left" className="form-control" selected={this.state.shipDate} onChange={this.handleShipDateChange} />
                    </div>
                    {/* <div className="form-group col-md-4">
                        <label>(ounces)</label>
                        <input readOnly={true} type="number" className="form-control" value={this.state.totalWeight * 16}></input>
                    </div> */}
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label>Shipment Category</label>
                        <select className="form-control" value={this.state.shipmentCategory} onChange={this.handleShipmentCategorySelect}>
                            <option value="">--SELECT--</option>
                            <option value="Sample">Sample</option>
                            <option value="Trade">Trade</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                {this.state.numberOfPackages > 1 &&
                    <React.Fragment>
                        <h4 className="mt-2 p-2">Packages</h4>
                        <div className="col-md-12">
                            {this.state.packages.map((pkg, pkgIndex) =>
                                <div key={pkgIndex}>
                                    <div style={{ backgroundColor: "#000", color: "#fff", padding: "6px" }}><strong>PACKAGE {pkgIndex + 1}</strong></div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Package Weight (lb)</label>
                                            <input type="number" className="form-control" value={pkg.weight} onChange={(e) => this.handlePkgWeightChange(e, pkg)}></input>
                                        </div>
                                    </div>
                                    <table className="table table-striped table-bordered mt-3 packaging">
                                        <thead>
                                            <tr>
                                                <th scope="col">PRODUCT</th>
                                                <th scope="col">QUANTITY</th>
                                                <th scope="col">
                                                    <button className="btn btn-xs btn-default" onClick={(e) => this.addPackItem(pkgIndex, e)}><i className="fas fa-plus"></i></button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pkg.items.map((item, itemIndex) =>
                                                <tr key={itemIndex} className={item.duplicate ? "duplicate" : ""}>
                                                    <td className="col-md-9">
                                                        <select className="form-control" value={item.cartItemId} onChange={(e) => this.handleProductSelect(e, itemIndex, pkgIndex)}>
                                                            <option value="0">--SELECT--</option>
                                                            {this.products.map(product =>
                                                                <option key={product.id} value={product.id}>{product.name}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                    <td className="col-md-2">
                                                        <input className="form-control" type="text" value={item.quantity} onChange={(e) => this.handleQuantityChange(e, itemIndex, pkgIndex)}></input>
                                                    </td>
                                                    <td>
                                                        {pkg.items.length > 1 &&
                                                            <button className="btn btn-xs btn-danger" onClick={(e) => this.removePackItem(itemIndex, pkgIndex, e)}><i className="fas fa-minus"></i></button>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                }
                {this.props.orders.length === 1 &&
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <img className="pull-right" src={this.http.BASE_URL + "/api/BarcodeHandler?key=" + this.props.orders[0].orderNumber + "&width=240&height=65"} />
                        </div>
                    </div>
                }
            </React.Fragment >
        );
    }
}

export default OrderShipmentDetails
