import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import { UtilsService } from '../../services/UtilsService';
import SmtpAccountInformation from './SmtpAccountInformation';
import Modal from '../../common/Modal';

class EditSmtpAccount extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.utils = new UtilsService();
        this.state = {
            account: null,
            testAddress: null,
        }
        this.handleAccountInformationChange = this.handleAccountInformationChange.bind(this);
        this.handleTestAddressChange = this.handleTestAddressChange.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.sendTestEmail = this.sendTestEmail.bind(this);
    }

    componentDidMount() {
        this.getAccount();
    }

    handleAccountInformationChange(accountInformation) {
        this.accountInformation = accountInformation;
    }

    handleTestAddressChange(e) {
        this.setState({
            testAddress: e.target.value
        });
    }

    getAccount() {
        var params = {
            id: this.props.match.params.id
        }
        this.http.get('/api/Admin/GetSmtpAccount', params).then(data => {
            this.updateState(data);
        })
    }

    updateAccount() {
        var params = this.accountInformation;
        params.accountId = this.props.match.params.id;
        this.http.post('/api/Admin/UpdateSmtpAccount', params).then(data => {
            this.utils.success("");
        });
    }

    sendTestEmail() {
        var params = this.accountInformation;
        params.accountId = this.props.match.params.id;
        params.to = this.state.testAddress;
        this.http.post('/api/Admin/TestSmtpAccount', params).then(data => {
            this.utils.success("Email sent successfully, please check your inbox.");
        });
    }

    updateState(data) {
        this.setState({
            account: data,
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Edit SMTP Account</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Account Information</div>
                            </div>
                            <div className="card-body">
                                {this.state.account &&
                                    <SmtpAccountInformation account={this.state.account} onChange={this.handleAccountInformationChange}></SmtpAccountInformation>
                                }
                            </div>
                            <div className="card-footer">                                
                                <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.updateAccount}>Update</button>
                                <button className="btn btn-default pull-right mr-2" data-toggle="modal" data-target="#testAccountModal">Test Account</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal id="testAccountModal" title="Test SMTP Settings" onOk={this.sendTestEmail}>
                    <div className="form-row">
                    <div className="form-group col-md-12">
                            <small>*This will test your saved settings.</small>
                        </div>
                        <div className="form-group col-md-12">
                            <label>Send Email To</label>
                            <input type="text" value={this.state.testAddress} onChange={this.handleTestAddressChange} className="form-control" />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default EditSmtpAccount