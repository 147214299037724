import React, { Component } from 'react';
import { HttpRequestService } from '../../services/HttpRequestService';
import Checkbox from '../../common/Checkbox';
import AccountTypesSelect from '../../common/AccountTypesSelect';
import moment from 'moment'

class AccountInformation extends Component {
    constructor(props) {
        super(props);
        this.http = new HttpRequestService();
        this.state = {
            name: props.account ? props.account.name : "",
            accountNumber: props.account ? props.account.accountNumber : "",
            accountTypeId: props.account ? props.account.accountTypeID : 8,
            customerID: props.account ? props.account.customerID : 0,
            isActive: props.account ? props.account.isActive : true,
        };

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleAccountNumberChange = this.handleAccountNumberChange.bind(this);
        this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
        this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
        this.notifyChange();
    }
    
    handleNameChange(e) {
        this.setState({
            name: e.target.value
        }, this.notifyChange)
    }    

    handleAccountNumberChange(e) {
        this.setState({
            accountNumber: e.target.value
        }, this.notifyChange)
    }    

    handleAccountTypeChange(value) {
        this.setState({
            accountTypeId: value
        }, this.notifyChange)
    }    

    handleIsActiveChange(checked) {
        this.setState({
            isActive: checked
        }, this.notifyChange)
    }

    notifyChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return (
            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-group col-md-12">
                        <label>Account Name</label>
                        <input type="text" className="form-control" value={this.state.name} onChange={this.handleNameChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Account Type</label>
                        <AccountTypesSelect value={this.state.accountTypeId} onChange={this.handleAccountTypeChange}></AccountTypesSelect>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Account Number</label>
                        <input type="text" className="form-control" value={this.state.accountNumber} onChange={this.handleAccountNumberChange}></input>
                    </div>
                    <div className="form-group col-md-12">
                        <Checkbox label="Active" checked={this.state.isActive} onChange={this.handleIsActiveChange}></Checkbox>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Creation Date</label>
                        <input type="text" readOnly className="form-control" value={moment(this.props.account.customer.dateEntered).format('l')}></input>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountInformation;
