import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class PaymentTypeSelect extends Component {

    constructor(props) {
        super(props);
        this.state = { items: [] };
        this.getPaymentTypes = this.getPaymentTypes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getPaymentTypes();
    }

    componentWillUnmount() {

    }

    getPaymentTypes() {
        this.http.get('/api/Orders/GetPaymentTypes', null).then(data=>{
            this.setState({ items: data });
            if(data.length > 0 && this.props.value === 0){
                this.props.onChange(data[0].key);
            }
        });
    }

    handleChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {
        var options = this.state.items.map((item) =>
            <option key={item.key} value={item.key}>{item.value}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {options}
            </select>
        );
    }
}
export default PaymentTypeSelect