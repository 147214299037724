import React, { Component } from 'react';
import { UtilsService } from '../../services/UtilsService';
import { HttpRequestService } from '../../services/HttpRequestService';
import Pagination from '../../common/Pagination';
import moment from 'moment'

class AmazonIntegrationLog extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            status: "Unresolved",
            logs: [],
            pageCount: 0,
            pageIndex: 0
        }
        this.paramsKey = "params.amazon-integragion-log";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.pageSize = 50;
        this.getLogs = this.getLogs.bind(this);
    }

    componentDidMount() {
        this.getLogs(this.state.pageIndex);
    }

    handleStatusChange(e) {
        this.setState({ status: e.target.value });
    }

    handlePageChange(page) {
        this.getLogs(page);
    }

    getLogs(page) {
        this.setState({ pageIndex: page });
        var params = {
            status: this.state.status,
            pageSize: this.pageSize,
            pageIndex: page
        }

        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));

        this.http.get('/api/Orders/GetAmazonIntegrationLogs', params).then(data => {
            this.setState({ logs: data.data, pageCount: parseInt(Math.ceil(data.count / this.pageSize)) });
        });
    }

    unResolveLog(log) {
        var params = {
            id: log.id
        }
        this.http.get('/api/Orders/UnresolveAmazonLog', params).then(data => {
            this.getLogs(this.state.pageIndex);
        });
    }

    resolveLog(log) {
        var params = {
            id: log.id
        }
        this.http.get('/api/Orders/ResolveAmazonLog', params).then(data => {
            this.getLogs(this.state.pageIndex);
        });
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Amazon Integration Log</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-3">
                                        <label>Status</label>
                                        <select className="form-control" value={this.state.status} onChange={this.handleStatusChange}>
                                            <option>Unresolved</option>
                                            <option>Resolved</option>
                                            <option>All</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-3">
                                        <button className="btn btn-primary mt-4" onClick={(e) => this.getLogs(0, e)}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="form-row">
                                    <table className="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th>Marketplace</th>
                                                <th scope="col">Marketplace Order Id</th>
                                                <th scope="col">Message</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Resolved</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.logs.map((log) =>
                                                <tr key={log.id}>
                                                    <td>{log.marketplace}</td>
                                                    <td>{log.marketplaceOrderId}</td>
                                                    <td>{log.message}</td>
                                                    <td>{moment(log.timestamp).format("l")}</td>
                                                    <td>{log.resolved ? "yes" : "no"}</td>
                                                    {log.resolved &&
                                                        <td><button className="btn btn-sm btn-info" onClick={e => this.unResolveLog(log)}>Un-resolve <i className="fas fa-undo"></i></button></td>
                                                    }
                                                    {!log.resolved &&
                                                        <td><button className="btn btn-sm btn-info" onClick={e => this.resolveLog(log)}>Resolve <i className="fas fa-check"></i></button></td>
                                                    }
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.logs.length > 0 &&
                                            <Pagination pageIndex={this.state.pageIndex} pageCount={this.state.pageCount} onPageChanged={this.handlePageChange}></Pagination>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AmazonIntegrationLog;
