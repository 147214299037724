import React, { Component } from 'react';
import { HttpRequestService } from '../services/HttpRequestService';

class CustomerCategorySelect extends Component {

    constructor(props) {
        super(props);
        this.state = { items: [] };
        this.getCategories = this.getCategories.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.http = new HttpRequestService();
    }

    componentDidMount() {
        this.getCategories();
    }

    componentWillUnmount() {

    }

    getCategories() {
        this.http.get('/api/Customers/GetCustomerCategories', null).then(data=>{
            this.setState({ items: data });
            if(data.length > 0 && this.props.value === 0){
                this.props.onChange(data[0].customerTypeID);
            }
        });
    }

    handleChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {
        var options = this.state.items.map((item) =>
            <option key={item.customerTypeID} value={item.customerTypeID}>{item.customerTypeDescription}</option>
        );

        return (
            <select className="form-control" value={this.props.value} onChange={this.handleChange}>
                {this.props.hasAllOption === true &&
                    <option value="-1">-- ALL --</option>
                }
                {options}
            </select>
        );
    }
}
export default CustomerCategorySelect