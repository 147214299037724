import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { UtilsService } from '../../services/UtilsService';
import { HttpRequestService } from '../../services/HttpRequestService';
class PinLogin extends Component {
    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        this.state = {
            username: this.props.username ? this.props.username : "",
            pin: "",
            loggedIn: false,
            users: [],
        };
        this.utils = new UtilsService();
        this.http = new HttpRequestService();
        this.handlePinChange = this.handlePinChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        this.getWarehouseUsers();
    }

    getWarehouseUsers() {
        this.http.setLoading(true);
        this.http.get('/api/Users/GetWarehouseUsers', null).then(data => {
            this.http.setLoading(false);
            this.setState({ users: data });
        }).catch(() => {
            this.http.setLoading(false);
        });
    }

    handlePinChange(e) {
        this.setState({
            pin: e.target.value
        })
    }

    handleUsernameChange(e) {
        this.setState({
            username: e.target.value
        })
    }

    handleKeyUp(e) {
        if (e.keyCode === 13) {
            this.login();
        }
    }

    login() {
        // Token endpoint expects application/x-www-form-urlencoded content
        var params = new URLSearchParams();
        params.append("grant_type", "password");
        params.append("username", this.state.username);
        params.append("pin", this.state.pin);

        localStorage.removeItem("auth-token");
        this.http.setLoading(true);
        axios.post(this.http.HOST + "/Token", params).then(data => {
            this.http.setLoading(false);
            localStorage.setItem("auth-token", data.data.access_token);
            localStorage.setItem("lastRequestTime", new Date().getTime());
            localStorage.setItem("username", this.state.username);
            localStorage.setItem("loginType", "pin");
            this.props.onLoggedIn();
        }).catch(() => {
            this.http.setLoading(false);
            this.utils.alert("Unable to log in. Please check your username and PIN.");
        }
        );
    }

    render() {
        return (
            <div className="card">
                <div className="card-header card-primary">
                    <div className="card-title">
                        {this.props.title ? this.props.title : "PowerD Warehouse Login"} <i className="fas fa-bolt"></i>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group col-md-12">
                                <label>Username</label>
                                <select className="form-control" value={this.state.username} onChange={this.handleUsernameChange}>
                                    <option value="">-- SELECT --</option>
                                    {this.state.users.map(u =>
                                        <option key={u.username} value={u.username}>{u.username}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-group col-md-12">
                                <label>PIN</label>
                                <input type="password" value={this.state.password} onChange={this.handlePinChange} className="form-control" onKeyUp={this.handleKeyUp} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    {this.props.onBack &&
                        <button className="btn btn-primary btn-link pull-left mr-2" onClick={() => this.props.onBack()}>
                            {this.props.backButtonText &&
                                <React.Fragment>
                                    {this.props.backButtonText}
                                </React.Fragment>
                            }
                            {!this.props.backButtonText &&
                                <React.Fragment>
                                    <i className="fas fa-arrow-left"></i> Back
                                </React.Fragment>
                            }
                        </button>
                    }
                    <button className="btn btn-primary btn-default pull-right mr-2" onClick={this.login}>{this.props.loginButtonText ? this.props.loginButtonText : "Log In"}</button>
                </div>
            </div>
        );
    }
}

export default withRouter(PinLogin)
