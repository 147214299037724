import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HttpRequestService } from '../../services/HttpRequestService';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UtilsService } from '../../services/UtilsService';
import Checkbox from '../../common/Checkbox';
import Report from '../../common/Report';
import CompanySelect from '../../common/CompanySelect';

class FedExEndOfDayReport extends Component {

    constructor(props) {
        super(props);
        this.utils = new UtilsService();
        var initialState = {
            companyId: 2,
            beginDate: moment(new Date()).toDate(),
            summaryOnly: false,
            includeTraditionalShipping: false,
            report: null,
        }
        this.sortBy = "";
        this.sortDirection = "";
        this.paramsKey = "params.fedex-end-of-day-report";
        this.state = this.utils.prepareSessionState(initialState, this.paramsKey);

        this.http = new HttpRequestService();
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
        this.handleIncludeTraditionalShippingChange = this.handleIncludeTraditionalShippingChange.bind(this);
        this.handleSummaryOnlyChange = this.handleSummaryOnlyChange.bind(this);
        this.getReport = this.getReport.bind(this);
        this.sortReport = this.sortReport.bind(this);
    }

    componentDidMount() {
    }

    handleCompanyChange(companyId) {
        this.setState({ companyId: companyId });
    }

    handleBeginDateChange(e) {
        this.setState({ beginDate: e });
    }

    handleIncludeTraditionalShippingChange(checked) {
        this.setState({ includeTraditionalShipping: checked });
    }

    handleSummaryOnlyChange(checked) {
        this.setState({ summaryOnly: checked });
    }

    getPrintPDFUrl(type, item) {
        return this.http.HOST + "/Print/Order?type=" + type + "&OrderID=" + item.orderID + "&OrderNumber=" + item.orderNumber;
    }

    getReport(e) {
        if (!!e) {
            e.preventDefault();
        }

        var params = {
            companyId: this.state.companyId,
            beginDate: this.state.beginDate != null ? moment(this.state.beginDate).format('L') : "",
            summaryOnly: this.state.summaryOnly,
            includeTraditionalShipping: this.state.includeTraditionalShipping,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }
        sessionStorage.setItem(this.paramsKey, JSON.stringify(params));
        this.http.get('/api/Report/GetFedexEndOfDayReport', params).then(data => {
            this.setState({ report: data });
        });

    }

    sortReport(field) {
        if (this.sortBy !== field) {
            this.sortDirection = "ASC";
        }
        else {
            if (this.sortDirection === "ASC") {
                this.sortDirection = "DESC";
            }
            else {
                this.sortDirection = "ASC";
            }
        }
        this.sortBy = field;
        this.getReport();
    }

    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">FedEx End of Day Report</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={e => this.getReport(e)}>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="orderStatus">Company</label>
                                                <CompanySelect value={this.state.companyId} onChange={this.handleCompanyChange} hasAllOption={true}></CompanySelect>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="beginDate">Ship Date</label>
                                                <DatePicker className="form-control" selected={this.state.beginDate} onChange={this.handleBeginDateChange} />
                                            </div>
                                            <div className="form-group">
                                                <Checkbox label="Include Traditional Shipping" checked={this.state.includeTraditionalShipping} onChange={this.handleIncludeTraditionalShippingChange}></Checkbox>
                                            </div>
                                            <div className="form-group">
                                                <Checkbox label="Summary Only" checked={this.state.summaryOnly} onChange={this.handleSummaryOnlyChange}></Checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row justify-content-end">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.state.report != null &&
                            <Report name="FedExEndOfDayReport" hasData={this.state.report.items && this.state.report.items.length > 0} title="FedEx End Of Day Report" description={this.state.report.description}>
                                <div className="form-row">
                                    <table className="table table-striped mt-1 report-table">
                                        {!this.state.summaryOnly &&
                                            <React.Fragment>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">ORDER #</th>
                                                        <th scope="col">CUSTOMER</th>
                                                        <th scope="col">SHIPPING METHOD</th>
                                                        <th scope="col">TRACKING #</th>
                                                        <th scope="col">PACKAGE COUNT</th>
                                                        <th scope="col">FULFILL DATE</th>
                                                        <th scope="col">SHIP DATE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.report.items.map(item =>
                                                        <tr key={item.orderID}>
                                                            <td><a href={this.getPrintPDFUrl("Invoice", item)} target="_blank" rel="noopener noreferrer">{item.orderNumber}</a></td>
                                                            <td><Link to={"/customer/" + item.customerID}>{item.companyName}</Link></td>
                                                            <td>{item.shipType}</td>
                                                            <td>{item.trackingNumber}</td>
                                                            <td>{item.numberOfPackages}</td>
                                                            <td>{moment(item.fulfillDate).format('l')}</td>
                                                            <td>{item.shipDate ? moment(item.shipDate).format('l') : ""}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </React.Fragment>
                                        }
                                        <React.Fragment>
                                            <thead>
                                                <tr>
                                                    <th scope="col">SHIPPING METHOD</th>
                                                    <th scope="col">PACKAGE COUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.report.summaryItems.map(summaryItem =>
                                                    <tr>
                                                        <td>{summaryItem.shipType}</td>
                                                        <td>{summaryItem.numberOfPackages}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>TOTAL</th>
                                                    <th>{this.state.report.numberOfPackages}</th>
                                                </tr>
                                            </tfoot>
                                        </React.Fragment>
                                    </table>
                                </div>
                            </Report>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default FedExEndOfDayReport;
