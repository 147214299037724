import React, { Component } from 'react';
import CustomerSearch from './CustomerSearch';

class CustomerList extends Component {
    render() {
        return (
            <div className="page-inner">
                <div className="page-header">
                    <h4 className="page-title">Customers</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <CustomerSearch paramsKey="params.customer-list">
                        </CustomerSearch>
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerList;
